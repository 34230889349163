import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, ThemePalette } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-select-queue-popup',
  templateUrl: './select-queue-popup.component.html',
  styleUrls: ['./select-queue-popup.component.scss']
})
export class SelectQueuePopupComponent implements OnInit {
  form: FormGroup;
  selectApiOptions: [];

  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<SelectQueuePopupComponent>,
    private translate: TranslateService
  ) {
    this.selectApiOptions = data;
  }

  ngOnInit() {
    console.log('this.selectApiOptions', this.selectApiOptions);
    this.initializeForm();
  }

  private initializeForm() {
    this.form = this.formBuilder.group({
      selectQueue: ['salesExportQueue']
    });
  }

  save() {
    // this.form.markAllAsTouched();
    console.log("this.form.get('selectQueue').value : ", this.form.get('selectQueue').value);
    console.log('this.form.valid : ', this.form.valid);
    if (this.form.valid) {
      this.dialogRef.close(this.form.get('selectQueue').value);
    }
  }
}
