import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@services/notification.service';
import { PersistanceService } from '@services/persistance.service';
import { ForgotPasswordService } from '../forgot-password.service';
import { IValidateEmailRes } from '../model';
import { environment } from '@env/environment';
@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss']
})
export class EmailComponent implements OnInit, OnDestroy {
  form: FormGroup;
  submitted;
  logoURL = '';
  isValidateError: boolean;
  isSuccess: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private service: ForgotPasswordService,
    private persistanceService: PersistanceService
  ) {}

  public hasError(controlName: string, errorName: string) {
    return this.form.controls[controlName].hasError(errorName);
  }

  ngOnInit(): void {
    this.logoURL = this.persistanceService.getAppSetting().logoURL
      ? this.persistanceService.getAppSetting().logoURL
      : this.persistanceService.getAppSetting().logoURLPortrait
      ? this.persistanceService.getAppSetting().logoURLPortrait
      : '';
    this.form = this.formBuilder.group({
      emailId: ['', [Validators.required, Validators.email]]
    });
  }

  ngOnDestroy(): void {}

  public async onSubmit() {
    // stop here if form is invalid
    this.isValidateError = false;
    this.isSuccess = false;
    if (this.form.invalid) {
      return;
    }
    const emailId = this.form.controls.emailId.value;
    const domainName =
      window.location.hostname.indexOf('localhost') >= 0
        ? window.location.hostname.replace('localhost', environment.oldHost)
        : window.location.hostname.replace(environment.mainHost, environment.oldHost);
    const request = {
      emailId,
      domainName
    };
    this.service.validateEmailId(request).subscribe(
      (response: IValidateEmailRes) => {
        if (response.message.userId) {
          this.service.sendEmail(response.message.userId).subscribe(
            (res) => {
              console.log(res);
              this.isSuccess = true;
            },
            (error) => {
              console.log(error);
            }
          );
        }
      },
      (error) => {
        if (error.includes('Message: undefined')) {
          this.isValidateError = false;
        } else {
          this.isValidateError = true;
        }
      }
    );
  }

  noWhiteSpaceAllow(event: any) {
    if (event.which === 32) {
      event.preventDefault();
    }
  }
}
