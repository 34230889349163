import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login.component';
import { ForcePasswordResetComponent } from './forcePasswordReset/forcePasswordReset.component';
import { ForceResetPasswordGuard } from '../forceResetPassword-guard.service';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    data: { title: 'Login' },
    canActivate: [ForceResetPasswordGuard]
  },
  {
    path: 'reset-password',
    component: ForcePasswordResetComponent,
    canActivate: [ForceResetPasswordGuard],
    data: { title: 'reset-password' }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LoginRoutingModule {
  static components = [LoginComponent, ForcePasswordResetComponent];
}
