import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '@env/environment';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import {
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
  TranslateModule
} from '@ngx-translate/core';
import { DynamicThemeModule } from '@pmcretail/angular-corelib';
import { CommonConfigModule } from '@shared/common-config/common-config.module';
import { IdleBoxComponent } from '@shared/idle-box/idle.component';
import { SharedModule } from '@shared/shared.module';
import { AppComponent } from './app.component';
import { CommonServiceService } from './common-service.service';
import { ApiService } from './core/api/api.service';
import { CoreModule } from './core/core.module';
import { CustomMatPaginatorIntl } from './core/customMatPaginatorIntl';
import { AuthModule } from './feature/auth/auth.module';
import { ForgotPasswordModule } from './feature/forgot-password/forgot-password.module';
import { HomeLayoutModule } from './layout/home-layout.module';
import { PageNotFoundComponent } from './page-not-found.component';
import { AppRoutingModule } from './routes/app-routing.module';
import { NodeStructureService } from '@shared/service/node-structure.service';
import { UserService } from '@services/user.service';

export class MissingTranslationHelper implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    // If translation not found we could pass default value by below commented code..
    // if (params.interpolateParams) {
    //   console.log(params.key);
    //   return params.interpolateParams['Default'] || params.key;
    // }
    // return params.key;
    console.warn(
      `No translation provided for key ${params.key} in lang file ${params.translateService.currentLang}.json`
    );
    return `TRANSLATION MISSING: ${params.key}`;
  }
}
@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AuthModule, // Eager loaded since we may need to go here right away as browser loads based on route user enters
    ForgotPasswordModule,
    CommonConfigModule,
    CoreModule, // Singleton objects (services, components that are loaded only once, etc.)
    SharedModule, // Shared (multi-instance) objects
    DynamicThemeModule,
    TranslateModule.forRoot({
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MissingTranslationHelper
      },
      useDefaultLang: true
    }),
    NgIdleKeepaliveModule.forRoot(),
    HomeLayoutModule,
    HttpClientModule,
    AppRoutingModule, // Main routes for application
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    environment.environmentName === 'development' || environment.environmentName === 'testing'
      ? StoreDevtoolsModule.instrument()
      : []
  ],
  declarations: [AppComponent, PageNotFoundComponent, IdleBoxComponent],
  providers: [
    CommonServiceService,
    NodeStructureService,
    UserService,
    ApiService,
    {
      provide: MatPaginatorIntl,
      useClass: CustomMatPaginatorIntl
    }
  ],
  exports: [AppComponent],
  bootstrap: [AppComponent]
})
export class AppModule {}
// required for AOT compilation
