import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NotificationService } from '@services/notification.service';
import { fromEvent, Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';

@Component({
  selector: 'app-receipt-file-upload',
  templateUrl: './file-upload.component.html'
})
export class ReceiptFileUploadComponent {
  @Input() imgupload;
  @Input() allowedTypes;
  @Output() uploadedFileBase64String: EventEmitter<string | ArrayBuffer> = new EventEmitter();
  @Input() imageSRC: string;

  constructor(private readonly notification: NotificationService) {}

  onUploadImage(event) {
    if (event.target.files.length > 0) {
      const mimeType = event.target.files[0].type;
      if (mimeType.match(this.allowedTypes) == null && this.allowedTypes !== 'image/svg+xml') {
        this.notification.error('Only png svg and jpeg images are supported');
        return;
      }

      if (event.target.files[0].size / 1024 / 1024 > 2) {
        this.notification.error('File is bigger than 2MB');
        return;
      }

      const fileReader = new FileReader();
      const imageToUpload = event.target.files.item(0);
      this.imageToBase64(fileReader, imageToUpload).subscribe((base64image) => {
        this.uploadedFileBase64String.emit(base64image);
      });
    }
  }

  imageToBase64(fileReader: FileReader, fileToRead: File): Observable<string> {
    fileReader.readAsDataURL(fileToRead);
    return fromEvent(fileReader, 'load').pipe(pluck('currentTarget', 'result'));
  }
}
