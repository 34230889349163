import { Action, ActionReducer, createReducer, MetaReducer, on } from '@ngrx/store';
import { localStorageSync } from '@pmcretail/ngrx-secretstorage';
import * as CommonConfigActions from './common-config.actions';
import {
  Country,
  Currency,
  GenericDateTimeFormat,
  Language,
  Modules,
  NodeThemeSetting,
  Purge,
  Theme,
  TimeZone,
  Node
} from './common-config.model';

export interface CommonConfig {
  currencies: Currency[];
  languages: Language[];
  timezones: TimeZone[];
  shortDateFormats: GenericDateTimeFormat[];
  shortTimeFormats: GenericDateTimeFormat[];
  longDateFormats: GenericDateTimeFormat[];
  longTimeFormats: GenericDateTimeFormat[];
  themes: Theme[];
  modules: Modules[];
  purge: Purge;
  countries: Country[];
  currentUserTheme: NodeThemeSetting;
  orgDetails: Node;
}

export interface CommonConfigState {
  readonly commonConfig: CommonConfig;
}

export const commonConfigInitialState: CommonConfig = {
  currencies: [],
  languages: [],
  timezones: [],
  longDateFormats: [],
  longTimeFormats: [],
  modules: [],
  shortDateFormats: [],
  shortTimeFormats: [],
  themes: [],
  purge: null,
  countries: [],
  currentUserTheme: null,
  orgDetails: null
};

const reducer = createReducer(
  commonConfigInitialState,
  on(CommonConfigActions.getCurrencies, (state, action) => ({
    ...state
  })),
  on(CommonConfigActions.getCurrenciesSuccess, (state, action) => ({
    ...state,
    currencies: action.currencies
  })),
  on(CommonConfigActions.getPurge, (state, action) => ({
    ...state
  })),
  on(CommonConfigActions.getPurgeSuccess, (state, action) => ({
    ...state,
    purge: action.purge
  })),
  on(CommonConfigActions.getLanguages, (state, action) => ({
    ...state
  })),
  on(CommonConfigActions.getLanguagesSuccess, (state, action) => ({
    ...state,
    languages: action.languages
  })),
  on(CommonConfigActions.getDateTimeFormats, (state, action) => ({
    ...state
  })),
  on(CommonConfigActions.getDateTimeFormats_SUCCESS, (state, action) => ({
    ...state,
    longDateFormats: action.dateTimeFormates.longDateFormat,
    longTimeFormats: action.dateTimeFormates.longTimeFormat,
    shortDateFormats: action.dateTimeFormates.shortDateFormat,
    shortTimeFormats: action.dateTimeFormates.shortTimeFormat
  })),
  on(CommonConfigActions.getThemes, (state, action) => ({
    ...state
  })),
  on(CommonConfigActions.getThemesSuccess, (state, action) => ({
    ...state,
    themes: action.themes
  })),
  on(CommonConfigActions.getTimeZones, (state, action) => ({
    ...state
  })),
  on(CommonConfigActions.getTimeZonesSuccess, (state, action) => ({
    ...state,
    timezones: action.timezones
  })),
  on(CommonConfigActions.getModules, (state, action) => ({
    ...state
  })),
  on(CommonConfigActions.getModulesSuccess, (state, action) => ({
    ...state,
    modules: action.modules
  })),
  on(CommonConfigActions.getCountries, (state, action) => ({
    ...state
  })),
  on(CommonConfigActions.getCountriesSuccess, (state, action) => ({
    ...state,
    countries: action.countries
  })),
  on(CommonConfigActions.getCurrentUserTheme, (state, action) => ({
    ...state,
    currentUserTheme: action.currentUserTheme
  })),
  on(CommonConfigActions.getOrgDetails, (state, action) => ({
    ...state
  })),
  on(CommonConfigActions.getOrgDetailsSuccess, (state, action) => ({
    ...state,
    orgDetails: action.orgDetails
  }))
);

export function commonConfigReducer(state: CommonConfig | undefined, action: Action): CommonConfig {
  return reducer(state, action);
}

export function localStorageSyncReducer(
  // tslint:disable-next-line: no-shadowed-variable
  reducer: ActionReducer<CommonConfig>
): ActionReducer<CommonConfig> {
  const keys = Object.keys(commonConfigInitialState);
  return localStorageSync({ keys, rehydrate: true })(reducer);
}

export const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];
