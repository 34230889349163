import { Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { MaleShirtAndShortsService } from '../male-shirt-and-shorts-configuration.service';

@Component({
  selector: 'app-module-assignment-add-maleShirt-And-Shorts-dialog',
  templateUrl: './add-maleShirt-And-Shorts-dialog.component.html',
  styleUrls: ['./add-maleShirt-And-Shorts-dialog.component.scss']
})
export class AddMaleShirtAndShortDialogComponent implements OnInit {
  @ViewChild('inputSku', { static: false }) inputSku: ElementRef;
  @ViewChild('inputProductName', { static: false }) inputProductName: ElementRef;

  frmMaleShirtKey: FormGroup;
  orgId: string;
  rowdata: any;
  options: any[] = [];
  filteredOptions: Observable<string[]>;
  isProductSelected = false;
  searching = false;
  @Input() nameAndNumbers: any[];
  nameAndNumbersColumns: any[] = ['SKU_ID', 'SKU_Name', 'action'];

  pageSizeOptions: number[] = [5, 10, 25, 50, 100];

  nameAndNumberDataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  selected = [];
  selectedColumns = ['SKU_ID', 'SKU_Name', 'VAT', 'action'];
  isDisabledOk = true;
  constructor(
    private formBuilder: FormBuilder,
    private maleShirtAndShortsService: MaleShirtAndShortsService,
    public dialogRef: MatDialogRef<AddMaleShirtAndShortDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.orgId = data.orgId;
    this.rowdata = data.rowdata;
  }

  ngOnInit() {
    this.initForm();
    this.nameAndNumbers = [];
    this.nameAndNumberDataSource.data = [...this.nameAndNumbers];
    this.validate();
  }

  initForm() {
    this.frmMaleShirtKey = this.formBuilder.group({
      filterBySkuCtrl: ['', [Validators.required]],
      sku: [this.rowdata && this.rowdata.SKU_selection ? this.rowdata.SKU_selection : ''],
      badgeURL: [this.rowdata ? this.rowdata.badgeUrl : ''],
      logoURL: [this.rowdata ? this.rowdata.logoUrl : '']
    });
    this.subscribeProducts();
  }

  subscribeProducts() {
    if (this.rowdata && this.rowdata.SKU_selection) {
      this.frmMaleShirtKey.get('filterBySkuCtrl').clearValidators();
      this.fetchProductToDisplayonEdit();
      this.frmMaleShirtKey.get('sku').setValue(this.rowdata.SKU_selection);
    }
    this.frmMaleShirtKey
      .get('filterBySkuCtrl')
      .valueChanges.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => {
          if (this.frmMaleShirtKey.get('filterBySkuCtrl').value) {
            this.fetchProducts();
          } else {
            this.options = [];
          }
        })
      )
      .subscribe();
  }

  fetchProductToDisplayonEdit() {
    this.searching = true;
    let toDisplay = this.rowdata.SKU_selection.split(',');
    let toFilter = [...toDisplay];
    toDisplay.map((el) => {
      if (el.length > 0) {
        this.maleShirtAndShortsService.getProductList(this.orgId, el).subscribe((result: any) => {
          this.searching = false;
          this.options = result.result;
          this.options = this.options.map((ele) => {
            if (this.rowdata.isVat) {
              ele['isVat'] = this.rowdata.isVat[el];
              return ele;
            } else {
              ele['isVat'] = false;
              return ele;
            }
          });
          this.options = this.options.filter((ele) => {
            if (toFilter.indexOf(ele.SKU) !== -1) {
              toFilter.splice(toFilter.indexOf(ele.SKU), 1);
              return ele;
            }
          });
          this.selected = [...this.selected, ...this.options];
          this.selected.map((ele) => {
            ele.isChecked = this.rowdata.isVat[ele.SKU];
          });
          this.validate();
        });
      }
    });
  }

  fetchProducts() {
    this.searching = true;
    this.maleShirtAndShortsService
      .getProductList(this.orgId, this.frmMaleShirtKey.get('filterBySkuCtrl').value)
      .subscribe((result: any) => {
        this.searching = false;
        this.options = result.result;
        this.nameAndNumbers = this.options;
      });
  }

  onSearchInput(e) {
    this.isProductSelected = false;
    if (!e.target.value) {
      this.options = [];
    }
  }

  onSelectProduct(e) {
    const product = e.option.value;
    this.frmMaleShirtKey.get('sku').setValue(product.SKU);

    // if(product.descriptions && product.descriptions.length > 0) {
    //   this.frmMaleShirtKey.get('displayName').setValue(product.descriptions[0].name);
    // }
    // else
    // {
    //   this.frmMaleShirtKey.get('displayName').setValue('');
    // }
    // this.frmMaleShirtKey
    //   .get('image')
    //   .setValue(product.imageUrls && product.imageUrls.length ? product.imageUrls[0] : '');
    let productObj = {
      skuID: product.SKU,
      skuName:
        product.descriptions && product.descriptions.length > 0 ? product.descriptions[0].name : ''
    };
    if (this.nameAndNumbers && !this.nameAndNumbers.some((el) => el.skuID === product.SKU)) {
      this.nameAndNumbers = [...this.nameAndNumbers, productObj];
    }

    this.isProductSelected = true;
    setTimeout(() => {
      this.frmMaleShirtKey.get('filterBySkuCtrl').setValue('');
    }, 100);
  }

  displaySku(product) {
    return '';
  }

  displayProductName(product) {
    return '';
  }

  onSave() {
    const formValue = this.frmMaleShirtKey.getRawValue();
    const data = {
      SKU: this.selected,
      badgeURL: formValue.badgeURL,
      logoURL: formValue.logoURL
    };
    this.dialogRef.close(data);
  }

  onCancel() {
    this.dialogRef.close();
  }

  addSKU(ele) {
    let newObj = { ...ele, isVat: false };
    console.log(newObj);
    this.selected = [...this.selected, newObj];
    console.log('this.selectde ->', this.selected);
    this.validate();
  }

  removeRow(ele) {
    this.selected = this.selected.filter((el) => el.SKU !== ele.SKU);
    this.validate();
  }

  validate() {
    if (this.selected.length > 1) {
      if (this.selected[0].isVat || this.selected[1].isVat) {
        this.isDisabledOk = false;
      } else {
        this.isDisabledOk = true;
      }
    } else if (this.selected.length === 1) {
      this.isDisabledOk = false;
    } else {
      this.isDisabledOk = true;
    }
  }

  vatableSelected(ele) {
    this.selected.map((el) => {
      if (ele.SKU === el.SKU) {
        el.isVat = true;
      } else {
        el.isVat = false;
      }
    });
    this.validate();
  }
}
