export const FORGOT_PASSWORD_ROUTES_BASE_PATH = 'forgot-password';

export const FORGOT_PASSWORD_ROUTES_NAME = {
  FORGOT_PASSWORD: {
    path: FORGOT_PASSWORD_ROUTES_BASE_PATH,
    name: 'Forgot Password'
  },
  EMAIL: {
    path: `${FORGOT_PASSWORD_ROUTES_BASE_PATH}/email`,
    name: 'Email'
  },
  SECRET_WORD: {
    path: `${FORGOT_PASSWORD_ROUTES_BASE_PATH}/secret-word`,
    name: 'Secret Word'
  },
  RESET_PASSWORD: {
    path: `resetPassword/:resetToken`,
    name: 'Reset Password'
  }
};
