import { Action, ActionReducer, createReducer, MetaReducer, on } from '@ngrx/store';
import { localStorageSync } from '@pmcretail/ngrx-secretstorage';
import { User } from '../../users/state/user.model';
import * as AuthActions from './auth.actions';
import { Modules } from './auth.model';

export interface Auth {
  loggedIn: boolean;
  user: User;
  userModules: Modules[];
}

export interface AuthState {
  readonly auth: Auth;
}

export const authInitialState: Auth = {
  loggedIn: false,
  user: null,
  userModules: []
};

const reducer = createReducer(
  authInitialState,
  on(AuthActions.login, (state, action) => ({
    ...state
  })),
  on(AuthActions.getUser, (state, action) => ({
    ...state,
    loggedIn: true
  })),
  on(AuthActions.refreshUser, (state, action) => ({
    ...state
  })),
  on(AuthActions.getUserModulesByRoleSuccess, (state, action) => ({
    ...state,
    userModules: action.userModules
  })),
  on(AuthActions.getUserModulesByRole, (state, action) => ({
    ...state
  })),
  on(AuthActions.loginSuccess, (state, action) => ({
    ...state,
    loggedIn: true,
    user: action.user
  })),
  on(AuthActions.refreshUserSuccess, (state, action) => ({
    ...state,
    user: action.user
  })),
  on(AuthActions.loginFail, (state, action) => ({
    ...state
  })),
  on(AuthActions.logout, (state, action) => ({
    ...authInitialState
  })),
  on(AuthActions.setUserPermission, (state, action) => ({
    ...state
  })),
  on(AuthActions.forcePasswordReset, (state, action) => ({
    ...state,
    user: action.user
  }))
);

export function authReducer(state: Auth | undefined, action: Action): Auth {
  return reducer(state, action);
}

// tslint:disable-next-line: no-shadowed-variable
export function localStorageSyncReducer(reducer: ActionReducer<Auth>): ActionReducer<Auth> {
  const keys = Object.keys(authInitialState);
  return localStorageSync({ keys, rehydrate: true })(reducer);
}

export const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];
