import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslationLoaderService } from '@services/translation-loader.service';
import { SharedModule } from '@shared/shared.module';
import { AuthGuardService } from './auth-guard.service';
import { AuthService } from './auth.service';
import { LocalStorageJwtService } from './local-storage-jwt.service';
import en from './login/i18n/en.json';
import zhcn from './login/i18n/zh-CN.json';
import fr from './login/i18n/fr.json';
import nl from './login/i18n/nl.json';
import jajp from './login/i18n/ja-JP.json';
import it from './login/i18n/it.json';
import de from './login/i18n/de.json';
import { LoginRoutingModule } from './login/login-routing.module';
import { NodeLevelGuard } from './node-level.guard';
import { PermissionGuard } from './permission.guard';
import { AuthEffects } from './state/auth.effects';
import { AuthFacade } from './state/auth.facade';
import { authInitialState, authReducer, metaReducers } from './state/auth.reducer';
import { TokenInterceptorService } from './token-interceptor.service';
import { NodeManagementService } from '../node-management/node-management.service';

@NgModule({
  imports: [
    LoginRoutingModule,
    ReactiveFormsModule,
    SharedModule,
    CommonModule,
    StoreModule.forFeature('auth', authReducer, { initialState: authInitialState, metaReducers }),
    EffectsModule.forFeature([AuthEffects])
  ],
  providers: [
    AuthEffects,
    AuthGuardService,
    NodeLevelGuard,
    PermissionGuard,
    AuthService,
    AuthFacade,
    TokenInterceptorService,
    LocalStorageJwtService,
    NodeManagementService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    }
  ],
  declarations: [LoginRoutingModule.components]
})
export class AuthModule {
  constructor(private translateService: TranslationLoaderService) {
    this.translateService.loadTranslations(fr);
    this.translateService.loadTranslations(en);
    this.translateService.loadTranslations(zhcn);
    this.translateService.loadTranslations(nl);
    this.translateService.loadTranslations(jajp);
    this.translateService.loadTranslations(it);
    this.translateService.loadTranslations(de);
  }
}
