import { Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { EbasketApiMethodTypes, EbasketInventorySelectApi, Providers } from '@shared/interfaces';
import { SelectApiPopupComponent } from '@shared/select-api-popup/select-api-popup.component';

@Component({
  selector: 'app-salesforce-provider-inventory-details-settings',
  templateUrl: './salesforce-provider-inventory-details-settings.component.html',
  styleUrls: ['./salesforce-provider-inventory-details-settings.component.scss']
})
export class SalesforceProviderInventoryDetailsSettingsComponent implements OnInit {
  @Input() action: string;
  @Input() parentForm: FormGroup;
  @Input() ebasketInventorySalesforceApiDetails: any;
  @Input() authControlKey: string;
  @Input() provider: string;
  @Input() inventory: string;

  apiDetails: FormGroup;
  isEBasketInventoryAddDisabled = false;
  ebasket_Inventory_select_api = [];
  method_types = [
    { id: 1, name: EbasketApiMethodTypes.Get, value: EbasketApiMethodTypes.Get },
    { id: 2, name: EbasketApiMethodTypes.Post, value: EbasketApiMethodTypes.Post },
    { id: 3, name: EbasketApiMethodTypes.Put, value: EbasketApiMethodTypes.Put },
    { id: 4, name: EbasketApiMethodTypes.Patch, value: EbasketApiMethodTypes.Patch },
    { id: 5, name: EbasketApiMethodTypes.Delete, value: EbasketApiMethodTypes.Delete }
  ];
  ship_to_store_select_api_inventory = [
    {
      id: 1,
      name: EbasketInventorySelectApi.StockEnquiry,
      value: EbasketInventorySelectApi.StockEnquiry,
      isDisabled: false
    }
  ];

  constructor(private formBuilder: FormBuilder, private dialog: MatDialog) {}

  ngOnInit() {
    console.log('api detils', this.ebasketInventorySalesforceApiDetails);
    // if (this.enableMagento2) {
    //   this.ebasket_Inventory_select_api = this.ship_to_store_select_api;
    // }
    if (this.inventory) {
      this.ebasket_Inventory_select_api = this.ship_to_store_select_api_inventory;
    }
    // if (this.webReturns) {
    //   this.ebasket_Inventory_select_api = this.web_returns_select_api;
    // }
    this.initForm();
  }
  initForm() {
    this.parentForm.addControl(
      this.authControlKey,
      this.formBuilder.group({
        ebasketInventoryOrderSalesforceVersion: [
          {
            value:
              this.ebasketInventorySalesforceApiDetails &&
              this.ebasketInventorySalesforceApiDetails.version
                ? this.ebasketInventorySalesforceApiDetails.version
                : '1',
            disabled: this.action === 'view' ? true : false
          },
          [Validators.required]
        ],
        ebasketInventorySalesforceClientId: [
          {
            value:
              this.ebasketInventorySalesforceApiDetails &&
              this.ebasketInventorySalesforceApiDetails.version
                ? Providers.SECRET
                : '',
            disabled: this.action === 'view' ? true : false
          },
          [Validators.required]
        ],
        ebasketInventorySalesforceSecretKey: [
          {
            value:
              this.ebasketInventorySalesforceApiDetails &&
              this.ebasketInventorySalesforceApiDetails.version
                ? Providers.SECRET
                : '',
            disabled: this.action === 'view' ? true : false
          }
        ],
        ebasketInventoryApiDetails: this.formBuilder.array([])
      })
    );

    this.bindEbasketInventoryApiDetailsFormGroup();
  }

  bindEbasketInventoryApiDetailsFormGroup() {
    const control = this.parentForm
      .get(this.authControlKey)
      .get('ebasketInventoryApiDetails') as FormArray;
    if (
      this.ebasketInventorySalesforceApiDetails &&
      this.ebasketInventorySalesforceApiDetails.apiDetails
    ) {
      this.ebasketInventorySalesforceApiDetails.apiDetails.map((apis: any) => {
        let apiDetails = {};
        apiDetails = {
          url: [
            {
              value: apis.url,
              disabled: this.action === 'view' ? true : false
            },
            Validators.required
          ],
          method: [
            {
              value: apis.method,
              disabled: this.action === 'view' ? true : false
            },
            Validators.required
          ],
          selectedApi: [apis.selectedApi]
        };
        control.push(this.formBuilder.group(apiDetails));
        this.ebasket_Inventory_select_api.map((key) => {
          if (key.value === apis.selectedApi) {
            key.isDisabled = true;
          }
        });
      });

      if (this.ebasket_Inventory_select_api.length === control.length) {
        this.isEBasketInventoryAddDisabled = true;
      }
    }
  }

  openEbasketInventorySelectApiPopup() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = this.ebasket_Inventory_select_api.filter((api) => !api.isDisabled);
    const dialogRef = this.dialog.open(SelectApiPopupComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result !== '') {
        this.ebasket_Inventory_select_api.map((element: any) => {
          if (element.value === result) {
            element.isDisabled = true;
            // this.tm_gift_card_api_details.push(element);
            const ebasketInventoryApiDetails = this.parentForm
              .get(this.authControlKey)
              .get('ebasketInventoryApiDetails') as FormArray;

            ebasketInventoryApiDetails.push(this.createInventoryApiDetailsFormGroup(result));
            if (this.ebasket_Inventory_select_api.length === ebasketInventoryApiDetails.length) {
              this.isEBasketInventoryAddDisabled = true;
            }
          }
        });
      }
    });
  }

  private createInventoryApiDetailsFormGroup(selectedApi: string): FormGroup {
    return new FormGroup({
      url: new FormControl('', Validators.required),
      method: new FormControl('', Validators.required),
      selectedApi: new FormControl(selectedApi)
    });
  }
  isRequired(control: string) {
    let isRequired = false;
    const formControlObject = this.parentForm.get(this.authControlKey).get(control);
    if (formControlObject !== null) {
      if (formControlObject.validator !== null) {
        if (formControlObject.validator({} as AbstractControl) !== null) {
          isRequired = formControlObject.validator({} as AbstractControl).required ? true : false;
        }
      }
    }
    return isRequired ? true : false;
  }

  deleteEbasketInventoryApiDetailContainer(index: number) {
    const ebasketInventoryApiDetails = this.parentForm
      .get(this.authControlKey)
      .get('ebasketInventoryApiDetails') as FormArray;
    this.ebasket_Inventory_select_api.map((element: any) => {
      console.log('ebasket_Inventory_select_api', this.ebasket_Inventory_select_api);
      if (element.value === ebasketInventoryApiDetails.value[index].selectedApi) {
        element.isDisabled = false;
        console.log(this.ebasket_Inventory_select_api);
      }
    });
    console.log('my val ->', ebasketInventoryApiDetails);
    if (ebasketInventoryApiDetails.length > 0) {
      ebasketInventoryApiDetails.removeAt(index);
    } else {
      ebasketInventoryApiDetails.reset();
    }
    if (this.ebasket_Inventory_select_api.length !== ebasketInventoryApiDetails.length) {
      this.isEBasketInventoryAddDisabled = false;
    }
  }
}
