import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationMenuService } from '@services/navigation-menu.service';
import { IMenu, IUser } from '@shared/interfaces';
import { Subscription } from 'rxjs';
import { LocalStorageJwtService } from 'src/app/feature/auth/local-storage-jwt.service';
import { AuthFacade } from 'src/app/feature/auth/state/auth.facade';

@Component({
  selector: 'app-left-panel-container',
  templateUrl: './left-panel.container.html',
  styleUrls: ['./left-panel.container.scss']
})
export class LeftPanelContainer implements OnInit, OnDestroy {
  menuItems: IMenu[] = [];
  userDetail: IUser;
  subscriptions: Subscription[] = [];
  depth = 0;
  rootNode;
  constructor(
    private navigationMenu: NavigationMenuService,
    private readonly authFacade: AuthFacade,
    private translateService: TranslateService,
    private readonly localStorageJwtService: LocalStorageJwtService
  ) {}

  ngOnInit() {
    this.authFacade.user$.subscribe((r) => {
      this.getMenuData();
    });
    const token = this.localStorageJwtService.getDecodedItem();
    if (token && token.idToken) {
      this.rootNode = token.idToken['custom:rootNode'];
    }
    this.getMenuData();
    // It will call when translate language is changed
    this.subscriptions.push(
      this.translateService.onLangChange.subscribe((result) => {
        this.subscriptions.push(
          this.navigationMenu.getUserMenus().subscribe((data: IMenu[]) => {
            this.menuItems = this.translateMenuAndSubMenu(data);
          })
        );
      })
    );
  }

  getMenuData() {
    this.subscriptions.push(
      this.navigationMenu.getUserMenus().subscribe((data: IMenu[]) => {
        this.menuItems = this.translateMenuAndSubMenu(data);
      })
    );
    // console.log('MENU items', this.menuItems);
    this.subscriptions.push(
      this.authFacade.user$.subscribe((user) => {
        this.userDetail = user;
      })
    );
  }
  translateMenuAndSubMenu(menuList: IMenu[]): IMenu[] {
    menuList.forEach((menu) => {
      if (menu.childs) {
        this.transateSubMenuItem(menu.childs);
      }
      menu.name = this.translateService.instant(menu.name);
    });
    return menuList;
  }

  transateSubMenuItem(subMenuItems) {
    if (subMenuItems) {
      subMenuItems.forEach((child) => {
        child.name = this.translateService.instant(child.name);
        if (child.childs) {
          this.transateSubMenuItem(child.childs);
        }
      });
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }
}
