import { PERMISSION } from '../../auth/has-permission/has-permission-directive';

export const ROSS_BASE_ROLE_PATH = '/roles';

export const ROSS_ROLE_ROUTES_NAME = {
  LIST: {
    basePath: '/roles',
    path: '',
    name: 'role_sub_menu.role_list_ross',
    visible: true,
    expectedPermission: [PERMISSION.LIST_ROLES]
  },
  CREATE: {
    basePath: '/roles',
    path: 'create',
    name: 'role_sub_menu.role_create_ross',
    visible: true,
    expectedPermission: [PERMISSION.CREATE_ROLE]
  },
  VIEW: {
    basePath: '/roles',
    path: ':id/view',
    name: 'role_sub_menu.role_view',
    visible: false,
    expectedPermission: [PERMISSION.VIEW_ROLE]
  },
  EDIT: {
    basePath: '/roles',
    path: ':id/edit',
    name: 'role_sub_menu.role_edit',
    visible: false,
    expectedPermission: [PERMISSION.EDIT_ROLE]
  }
};
