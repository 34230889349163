import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { PersistanceService } from '@services/persistance.service';
import { CommonConfigService } from '@shared/common-config/common-config.service';
import { forkJoin } from 'rxjs';
import { exhaustMap, map, switchMap, tap } from 'rxjs/operators';
import * as CommonConfigActions from './common-config.actions';
import { DateTimeFormat } from './common-config.model';

@Injectable()
export class CommonConfigEffects {
  constructor(
    private actions$: Actions,
    private readonly commonConfigService: CommonConfigService,
    private readonly persistanceService: PersistanceService
  ) {}

  getCurrencies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonConfigActions.getCurrencies),
      exhaustMap((action) => {
        return this.commonConfigService.getCurrencies().pipe(
          map((data) => {
            return CommonConfigActions.getCurrenciesSuccess({ currencies: data });
          })
        );
      })
    )
  );

  getCurrenciesSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CommonConfigActions.getCurrenciesSuccess),
        tap((action) => {})
      ),
    { dispatch: false }
  );

  getLanguages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonConfigActions.getLanguages),
      exhaustMap((action) => {
        return this.commonConfigService.getLanguages().pipe(
          map((data) => {
            return CommonConfigActions.getLanguagesSuccess({ languages: data });
          })
        );
      })
    )
  );

  getLanguagesSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CommonConfigActions.getLanguagesSuccess),
        tap((action) => {})
      ),
    { dispatch: false }
  );

  getDateAndTimeFormats$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonConfigActions.getDateTimeFormats),
      exhaustMap((action) => {
        return forkJoin([
          this.commonConfigService.getShortDateFormats(),
          this.commonConfigService.getShortTimeFormats(),
          this.commonConfigService.getLongDateFormats(),
          this.commonConfigService.getLongTimeFormats()
        ]).pipe(
          map((data) => {
            return data;
          })
        );
      }),
      map(([shortDate, shortTime, longDate, longTime]) => {
        const shortDateFormatsArray = shortDate.map((el) => ({
          id: el.dateId,
          format: el.dateFormat,
          isDefault: el.isDefaultSelected
        }));
        const shortTimeFormatsArray = shortTime.map((el) => ({
          id: el.timeId,
          format: el.timeFormat,
          isDefault: el.isDefaultSelected
        }));
        const longDateFormatsArray = longDate.map((el) => ({
          id: el.dateId,
          format: el.dateFormat,
          isDefault: el.isDefaultSelected
        }));
        const longTimeFormatsArray = longTime.map((el) => ({
          id: el.timeId,
          format: el.timeFormat,
          isDefault: el.isDefaultSelected
        }));
        const dateTimeFormats: DateTimeFormat = {
          shortDateFormat: shortDateFormatsArray,
          shortTimeFormat: shortTimeFormatsArray,
          longDateFormat: longDateFormatsArray,
          longTimeFormat: longTimeFormatsArray
        };
        return CommonConfigActions.getDateTimeFormats_SUCCESS({
          dateTimeFormates: dateTimeFormats
        });
      })
    )
  );

  getDateAndTimeFormatsSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CommonConfigActions.getDateTimeFormats_SUCCESS),
        tap((action) => {})
      ),
    { dispatch: false }
  );

  getCountries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonConfigActions.getCountries),
      exhaustMap((action) => {
        return this.commonConfigService.getCountries().pipe(
          map((data) => {
            return CommonConfigActions.getCountriesSuccess({ countries: data });
          })
        );
      })
    )
  );

  getCountriesSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CommonConfigActions.getCountriesSuccess),
        tap((action) => {})
      ),
    { dispatch: false }
  );

  getTimezone$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonConfigActions.getTimeZones),
      exhaustMap((action) => {
        return this.commonConfigService.getTimezones().pipe(
          map((data) => {
            return CommonConfigActions.getTimeZonesSuccess({ timezones: data });
          })
        );
      })
    )
  );

  getTimeZonesSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CommonConfigActions.getTimeZonesSuccess),
        tap((action) => {})
      ),
    { dispatch: false }
  );

  getPurge$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonConfigActions.getPurge),
      exhaustMap((action) => {
        return this.commonConfigService.getPurge().pipe(
          map((data) => {
            return CommonConfigActions.getPurgeSuccess({ purge: data });
          })
        );
      })
    )
  );

  getPurgeSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CommonConfigActions.getPurgeSuccess),
        tap((action) => {})
      ),
    { dispatch: false }
  );

  getModules$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonConfigActions.getModules),
      exhaustMap((action) => {
        return this.commonConfigService.getModules().pipe(
          map((data) => {
            return CommonConfigActions.getModulesSuccess({ modules: data });
          })
        );
      })
    )
  );

  getModulesSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CommonConfigActions.getModulesSuccess),
        tap((action) => {})
      ),
    { dispatch: false }
  );

  getThemes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonConfigActions.getThemes),
      exhaustMap((action) => {
        return this.commonConfigService.getThemes().pipe(
          map((data) => {
            return CommonConfigActions.getThemesSuccess({ themes: data });
          })
        );
      })
    )
  );

  getThemesSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CommonConfigActions.getThemesSuccess),
        tap((action) => {})
      ),
    { dispatch: false }
  );

  getOrgCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonConfigActions.getOrgCode),
      exhaustMap((action) => {
        return this.commonConfigService.getNodeDetails(action.nodeId, true).pipe(
          map((data) => {
            localStorage.setItem('ORG_CODE', data.nodeDetail.orgCode);
            return CommonConfigActions.getOrgCodeSuccess({ orgCode: data.nodeDetail.orgCode });
          })
        );
      })
    )
  );

  getOrgCodeSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CommonConfigActions.getOrgCodeSuccess),
        tap((action) => {})
      ),
    { dispatch: false }
  );

  getOrgDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonConfigActions.getOrgDetails),
      exhaustMap((action) => {
        return this.commonConfigService.getNodeDetails(action.orgId, true).pipe(
          map((data) => {
            return CommonConfigActions.getOrgDetailsSuccess({ orgDetails: data });
          })
        );
      })
    )
  );

  getOrgDetailsSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CommonConfigActions.getOrgDetailsSuccess),
        tap((action) => {})
      ),
    { dispatch: false }
  );

  getUserNodeConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonConfigActions.getUserNodeConfig),
      exhaustMap((action) =>
        this.commonConfigService.getNodeConfig(action.rootNodeId, true).pipe(
          switchMap((nodeConfig) => {
            /* const shortDateFormatsArray = {
              id: nodeConfig.nodeConfigSetting.shortDateTimeFormat.dateFormat.dateId,
              format: nodeConfig.nodeConfigSetting.shortDateTimeFormat.dateFormat.dateFormat,
              isDefault: true
            };
            const shortTimeFormatsArray = {
              id: nodeConfig.nodeConfigSetting.shortDateTimeFormat.timeFormat.timeId,
              format: nodeConfig.nodeConfigSetting.shortDateTimeFormat.timeFormat.timeFormat,
              isDefault: true
            };
            const longDateFormatsArray = {
              id: nodeConfig.nodeConfigSetting.longDateTimeFormat.dateFormat.dateId,
              format: nodeConfig.nodeConfigSetting.longDateTimeFormat.dateFormat.dateFormat,
              isDefault: true
            };
            const longTimeFormatsArray = {
              id: nodeConfig.nodeConfigSetting.longDateTimeFormat.timeFormat.timeId,
              format: nodeConfig.nodeConfigSetting.longDateTimeFormat.timeFormat.timeFormat,
              isDefault: true
            };
            const dateTimeFormats: DateTimeFormat = {
              shortDateFormat: [shortDateFormatsArray],
              shortTimeFormat: [shortTimeFormatsArray],
              longDateFormat: [longDateFormatsArray],
              longTimeFormat: [longTimeFormatsArray]
            }; */
            this.persistanceService.setAppSetting(nodeConfig.nodeThemeSetting);
            return [
              /* CommonConfigActions.getCurrenciesSuccess({
                currencies: [
                  ...[nodeConfig.nodeConfigSetting.baseCurrency],
                  ...nodeConfig.nodeConfigSetting.supportedCurrencies
                ]
              }),
              CommonConfigActions.getLanguagesSuccess({
                languages: [
                  ...[nodeConfig.nodeConfigSetting.defaultLanguage],
                  ...nodeConfig.nodeConfigSetting.supportedLanguages
                ]
              }),
              CommonConfigActions.getDateTimeFormats_SUCCESS({
                dateTimeFormates: dateTimeFormats
              }),

              CommonConfigActions.getModulesSuccess({
                modules: nodeConfig.nodeModuleAssignment.moduleAssignmentData
              }),

              CommonConfigActions.getPurgeSuccess({
                purge: { id: '1', value: nodeConfig.nodeConfigSetting.purge.value }
              }),
              CommonConfigActions.getTimeZonesSuccess({
                timezones: [nodeConfig.nodeConfigSetting.timezone]
              }), */
              CommonConfigActions.getCurrentUserTheme({
                currentUserTheme: nodeConfig.nodeThemeSetting
              })
            ];
          })
        )
      )
    )
  );
}
