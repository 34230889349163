import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthFacade } from './state/auth.facade';
/**
 *
 * This is example of single permission
 *  data: {
 *     title: APP_ROUTES_NAME.USERS.name,
 *     expectedPermission: [PERMISSION.CREATE_USER]
 *   },
 *   canActivate: [PermissionGuard]
 *
 *
 * This is example with OR condition of permisison
 *
 *  data: {
 *     title: APP_ROUTES_NAME.USERS.name,
 *     expectedPermission: [PERMISSION.CREATE_USER, PERMISSION.EDIT_USER]
 *   },
 *   canActivate: [PermissionGuard]
 *
 *
 *  This is example with AND conditon of all permisisons
 *  data: {
 *     title: APP_ROUTES_NAME.USERS.name,
 *     expectedPermission: [PERMISSION.CREATE_USER, PERMISSION.EDIT_USER],
 *     expectedPermissionType: 'all'
 *   },
 *   canActivate: [PermissionGuard]
 */
@Injectable({
  providedIn: 'root'
})
export class PermissionGuard implements CanActivate {
  constructor(public router: Router, private authFacade: AuthFacade) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const expectedPermission = route.data.expectedPermission;
    const expectedPermissionType = route.data.expectedPermissionType || 'any';
    return this.authFacade.user$.pipe(
      map((r) => {
        if (!r.permissions) {
          return false;
        } else {
          const flag = this.checkPermissions(
            expectedPermission,
            r.permissions,
            expectedPermissionType
          );
          if (!flag) {
            return false;
          } else {
            return true;
          }
        }
      })
    );
  }

  checkPermissions(
    expectedPermission: Array<string>,
    userPermission: Array<string>,
    type: string
  ): boolean {
    let result = false;
    switch (type) {
      case 'any':
        result = this.anyPermission(expectedPermission, userPermission);
        break;
      case 'all':
        result = this.allPermissoin(expectedPermission, userPermission);
        break;
    }
    return result;
  }

  private anyPermission(expectedPermission: Array<string>, userPermission: Array<string>): boolean {
    let result = false;
    expectedPermission.forEach((permissoin: string) => {
      if (userPermission.indexOf(permissoin) !== -1) {
        result = true;
      }
    });
    return result;
  }

  private allPermissoin(expectedPermission: Array<string>, userPermission: Array<string>): boolean {
    let count = 0;
    expectedPermission.forEach((permission: string) => {
      if (userPermission.indexOf(permission) !== -1) {
        count++;
      }
    });
    return expectedPermission.length === count;
  }
}
