import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthFacade } from './state/auth.facade';
/**
 *
 * This is example of single permission
 *  data: {
 *     title: APP_ROUTES_NAME.USERS.name,
 *     expectedPermission: [PERMISSION.CREATE_USER]
 *   },
 *   canActivate: [PermissionGuard]
 *
 *
 * This is example with OR condition of permisison
 *
 *  data: {
 *     title: APP_ROUTES_NAME.USERS.name,
 *     expectedPermission: [PERMISSION.CREATE_USER, PERMISSION.EDIT_USER]
 *   },
 *   canActivate: [PermissionGuard]
 *
 *
 *  This is example with AND conditon of all permisisons
 *  data: {
 *     title: APP_ROUTES_NAME.USERS.name,
 *     expectedPermission: [PERMISSION.CREATE_USER, PERMISSION.EDIT_USER],
 *     expectedPermissionType: 'all'
 *   },
 *   canActivate: [PermissionGuard]
 */
@Injectable({
  providedIn: 'root'
})
export class ForceResetPasswordGuard implements CanActivate {
  constructor(public router: Router, private authFacade: AuthFacade) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const customParameter = route.data.title;

    if (customParameter === 'reset-password') {
      return this.authFacade.user$.pipe(
        map((r) => {
          if (r) {
            if (!r.manualPassword) {
              return true;
            }
            return false;
          }
          return false;
        })
      );
    } else {
      return this.authFacade.user$.pipe(
        map((r) => {
          if (r) {
            if (!r.manualPassword) {
              this.router.navigate(['/reset-password']);
              return false;
            }
            return true;
          }
          return true;
        })
      );
    }
  }
}
