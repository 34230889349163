import { Injectable } from '@angular/core';
import { LocalStorageJwtService } from 'src/app/feature/auth/local-storage-jwt.service';
import { ApiService, PATH } from '../api/api.service';

@Injectable()
export class RefreshTokenService {
  constructor(
    private apiService: ApiService,
    private localStorageJwtService: LocalStorageJwtService
  ) {}

  resetSession() {
    try {
      if (this.localStorageJwtService.getDecodedItem()) {
        const params = {
          clientId: this.localStorageJwtService.getDecodedItem().accessToken.client_id,
          refreshToken: this.localStorageJwtService.getDecodedItem().refreshToken
        };
        this.apiService
          .post(PATH.POST_REFRESH_TOKEN, params)
          .toPromise()
          .then((data) => {
            if (this.localStorageJwtService.getItem()) {
              this.localStorageJwtService.setItem(data.result);
            }
          });
      } else {
        console.log('Session is Expired');
      }
    } catch (error) {
      console.error(error);
    }
  }
}
