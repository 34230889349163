import { Routes } from '@angular/router';
import { AuthGuardService } from '../feature/auth/auth-guard.service';
import { NodeLevelGuard } from '../feature/auth/node-level.guard';
import { PermissionGuard } from '../feature/auth/permission.guard';
import { PageNotFoundComponent } from '../page-not-found.component';
import { APP_ROUTES_NAME } from './app.routes.names';

export const APP_ROUTES: Routes = [
  {
    path: APP_ROUTES_NAME.SAMPLE_UI,
    loadChildren: () =>
      import('../feature/sample-ui/sample-ui.module').then((m) => m.SampleUIModule),
    data: {
      title: 'Sample Developments'
    },
    canLoad: [AuthGuardService]
  },
  {
    path: APP_ROUTES_NAME.USERS.path,
    loadChildren: () => import('../feature/users/users.module').then((m) => m.UsersModule),
    data: {
      title: APP_ROUTES_NAME.USERS.name,
      // No need of dyanmic title in user screen so remove dynamic method name
      // dynamicTitle: APP_ROUTES_NAME.USERS.dynamicName,
      expectedPermission: APP_ROUTES_NAME.USERS.expectedPermission
    },
    canLoad: [AuthGuardService],
    canActivate: [PermissionGuard]
  },
  {
    path: APP_ROUTES_NAME.ORGANIZATION.path,
    loadChildren: () =>
      import('../feature/organization/organization.module').then((m) => m.OrganizationModule),
    data: {
      title: APP_ROUTES_NAME.ORGANIZATION.name,
      expectedPermission: APP_ROUTES_NAME.ORGANIZATION.expectedPermission,
      expectedLevel: APP_ROUTES_NAME.ORGANIZATION.expectedLevel,
      expectedLevelType: APP_ROUTES_NAME.ORGANIZATION.expectedLevelType
    },
    canLoad: [AuthGuardService],
    canActivate: [NodeLevelGuard, PermissionGuard]
  },
  {
    path: APP_ROUTES_NAME.STRUCTURE.path,
    loadChildren: () =>
      import('../feature/node-management/node-management.module').then(
        (m) => m.NodeManagementModule
      ),
    data: {
      title: APP_ROUTES_NAME.STRUCTURE.name,
      expectedPermission: APP_ROUTES_NAME.STRUCTURE.expectedPermission,
      expectedLevel: APP_ROUTES_NAME.STRUCTURE.expectedLevel,
      expectedLevelType: APP_ROUTES_NAME.STRUCTURE.expectedLevelType
    },
    canLoad: [AuthGuardService],
    canActivate: [NodeLevelGuard, PermissionGuard]
  },
  {
    path: APP_ROUTES_NAME.PROFILE.path,
    loadChildren: () => import('../profile/profile.module').then((m) => m.ProfileModule),
    data: {
      title: APP_ROUTES_NAME.PROFILE.name,
      expectedPermission: APP_ROUTES_NAME.PROFILE.permissions
    },
    canLoad: [AuthGuardService],
    canActivate: [PermissionGuard]
  },
  {
    path: APP_ROUTES_NAME.ROLE.path,
    loadChildren: () => import('../feature/role/role.module').then((m) => m.RoleModule),
    data: {
      title: APP_ROUTES_NAME.ROLE.name,
      expectedPermission: APP_ROUTES_NAME.ROLE.expectedPermission,
      expectedPermissionType: 'all',
      expectedLevel: APP_ROUTES_NAME.ROLE.expectedLevel,
      expectedLevelType: APP_ROUTES_NAME.ROLE.expectedLevelType
    },
    canLoad: [AuthGuardService],
    canActivate: [NodeLevelGuard, PermissionGuard]
  },
  {
    path: APP_ROUTES_NAME.ROSS_ROLE.path,
    loadChildren: () =>
      import('../feature/global-portal-roles/global-role.module').then((m) => m.GlobalRoleModule),
    data: {
      title: APP_ROUTES_NAME.ROSS_ROLE.name,
      expectedPermission: APP_ROUTES_NAME.ROSS_ROLE.expectedPermission,
      expectedPermissionType: 'all',
      expectedLevel: APP_ROUTES_NAME.ROSS_ROLE.expectedLevel,
      expectedLevelType: APP_ROUTES_NAME.ROSS_ROLE.expectedLevelType
    },
    canLoad: [AuthGuardService],
    canActivate: [NodeLevelGuard, PermissionGuard]
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('../feature/dashboard/dashboard.module').then((m) => m.DashboardModule),
    data: {
      title: 'Dashboard'
    },
    canLoad: [AuthGuardService]
  },
  { path: '', redirectTo: '/dashboard', pathMatch: 'full', canLoad: [AuthGuardService] },
  { path: 'om', redirectTo: '/dashboard', pathMatch: 'full', canLoad: [AuthGuardService] },
  { path: 'ross', redirectTo: '/', pathMatch: 'full', canLoad: [AuthGuardService] },
  { path: '**', component: PageNotFoundComponent, canLoad: [AuthGuardService] }
];
