import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-site-middle-content',
  templateUrl: './site-middle-content.component.html',
  styleUrls: ['./site-middle-content.component.scss']
})
export class SiteMiddleContentComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
