import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslationLoaderService } from '@services/translation-loader.service';
import { SharedModule } from '@shared/shared.module';
import { OrganizationService } from '../feature/organization/organization.details.service';
import { ProfileService } from '../profile/profile.service';
import { LeftPanelContainer } from './common/left-panel/left-panel.container';
import { PageContentComponent } from './common/page-content/page-content.component';
import { PageHeaderComponent } from './common/page-header/page-header.component';
import { RightPanelComponent } from './common/right-panel/right-panel.component';
import { OrganizationPopupComponent } from './common/site-header/organization-popup/organization-popup.component';
import { OrganizationPopupContainer } from './common/site-header/organization-popup/organization-popup.container';
import { SiteHeaderComponent } from './common/site-header/site-header.component';
import { SiteMiddleContentComponent } from './common/site-middle-content/site-middle-content.component';
import en from './i18n/en.json';
import fr from './i18n/fr.json';
import zhcn from './i18n/zh-CN.json';
import nl from './i18n/nl.json';
import jajp from './i18n/ja-JP.json';
import it from './i18n/it.json';
import de from './i18n/de.json';
import { RossPopupComponent } from './common/site-header/ross-popup/ross-popup.component';
import { RossPopupContainerComponent } from './common/site-header/ross-popup/ross-popup-container.component';

@NgModule({
  imports: [CommonModule, RouterModule, SharedModule],
  exports: [
    RouterModule,
    SiteHeaderComponent,
    LeftPanelContainer,
    RightPanelComponent,
    SiteMiddleContentComponent,
    PageHeaderComponent,
    PageContentComponent
  ],
  declarations: [
    SiteHeaderComponent,
    LeftPanelContainer,
    RightPanelComponent,
    SiteMiddleContentComponent,
    PageHeaderComponent,
    PageContentComponent,
    OrganizationPopupComponent,
    OrganizationPopupContainer,
    RossPopupComponent,
    RossPopupContainerComponent
  ],
  providers: [ProfileService, OrganizationService]
})
export class HomeLayoutModule {
  // Looks for the module in the parent injector to see if it's already been loaded (only want it loaded once)
  constructor(private translateService: TranslationLoaderService) {
    this.translateService.loadTranslations(fr);
    this.translateService.loadTranslations(en);
    this.translateService.loadTranslations(zhcn);
    this.translateService.loadTranslations(nl);
    this.translateService.loadTranslations(jajp);
    this.translateService.loadTranslations(it);
    this.translateService.loadTranslations(de);
  }
}
