import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@services/notification.service';
import { PersistanceService } from '@services/persistance.service';
import { ForgotPasswordService } from '../forgot-password.service';
import { IValidateEmailRes, IValidateEmailReq } from '../model';
import { environment } from '@env/environment';
@Component({
  selector: 'app-secret-word',
  templateUrl: './secret-word.component.html',
  styleUrls: ['./secret-word.component.scss']
})
export class SecretWordComponent implements OnInit {
  forgotForm: FormGroup;
  isValidateError: boolean;
  validationError: string;
  isSuccess: boolean;
  logoURL = '';
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private service: ForgotPasswordService,
    private readonly notification: NotificationService,
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private persistanceService: PersistanceService
  ) {}

  ngOnInit() {
    this.logoURL = this.persistanceService.getAppSetting().logoURL;
    this.forgotForm = this.formBuilder.group({
      secretWord: ['', Validators.required],
      userName: ['', [Validators.required]],
      email: [''],
      isUserName: []
    });
  }

  noWhiteSpaceAllow(event: any) {
    if (event.which === 32) {
      event.preventDefault();
    }
  }

  updateFormValidation() {
    if (this.forgotForm.get('isUserName').value) {
      this.forgotForm.get('userName').reset();
      this.forgotForm.get('userName').clearValidators();
      this.forgotForm.get('email').setValidators([Validators.required, Validators.email]);
    } else {
      this.forgotForm.get('email').reset();
      this.forgotForm.get('email').clearValidators();
      this.forgotForm.get('userName').setValidators([Validators.required]);
    }
    this.forgotForm.get('email').updateValueAndValidity();
    this.forgotForm.get('userName').updateValueAndValidity();
  }

  public async checkSecretWordAnduserDetails() {
    // stop here if form is invalid
    if (this.forgotForm.invalid) {
      return;
    }
    try {
      const emailId = this.forgotForm.get('email').value
        ? this.forgotForm.get('email').value
        : undefined;
      const username =
        this.forgotForm.get('userName').value !== null
          ? this.forgotForm.get('userName').value
          : undefined;
      const secretWord = this.forgotForm.get('secretWord').value;
      const domainName =
        window.location.hostname.indexOf('localhost') >= 0
          ? window.location.hostname.replace('localhost', environment.mainHost)
          : window.location.hostname;
      const request: IValidateEmailReq = {
        emailId,
        domainName,
        username,
        secretWord
      };
      this.service.validateEmailId(request).subscribe(
        (response: IValidateEmailRes) => {
          if (response.message.userId) {
            this.router.navigate([`/resetPassword/${response.message.resetToken}`]);
          }
        },
        (error) => {
          this.isValidateError = true;
          this.validationError = error.split(':')[2];
          console.log(error);
        }
      );
    } catch (err) {
      this.isValidateError = true;
      this.validationError = err;
    }
  }
}
