import { Component, Input, OnChanges, OnInit, EventEmitter, Output } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material';
import moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import {
  EXCEPTION_DATE_FORMAT,
  reasonDefaultDataList
} from 'src/app/feature/organization/shared/configuration-settings/configuration-settings-default-data';

@Component({
  selector: 'app-exceptiones',
  templateUrl: './exceptiones.component.html',
  styleUrls: ['./exceptiones.component.scss']
})
export class ExceptionesComponent implements OnInit, OnChanges {
  exceptionDataSource = new BehaviorSubject<AbstractControl[]>([]);
  execeptionColumns: any[] = ['from', 'to', 'reason', 'openTime', 'closeTime', 'action'];
  reasonList = reasonDefaultDataList;
  @Input() action: string;
  @Input() form: FormGroup;
  @Input() formClone: FormGroup;
  exceptionArray: FormArray = this.formBuilder.array([]);
  @Input() orgConfig;
  @Input() isInherit: boolean;
  @Output() deletedRow: EventEmitter<number> = new EventEmitter();
  @Input() isnodeConfig: boolean;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    if (
      this.orgConfig &&
      this.orgConfig.exceptions &&
      this.orgConfig.exceptions.exceptions &&
      this.orgConfig.exceptions.exceptions.length > 0
    ) {
      const control = this.form.controls.exceptions as FormArray;
      control.clear();
      this.updateExceptionView();
      this.orgConfig.exceptions.exceptions.forEach((exception) => {
        const row = this.formBuilder.group({
          from: [moment(exception.from, EXCEPTION_DATE_FORMAT)],
          to: [moment(exception.to, EXCEPTION_DATE_FORMAT)],
          reason: [{ value: exception.reason, disabled: this.action === 'view' ? true : false }],
          openTime: [
            { value: exception.openTime, disabled: this.action === 'view' ? true : false }
          ],
          closeTime: [
            { value: exception.closeTime, disabled: this.action === 'view' ? true : false }
          ]
        });
        control.push(row);
      });
      this.updateExceptionView();
    }
  }

  getExceptionsLength() {
    return (this.form.get('exceptions') as FormArray).length;
  }
  /**
   * Add new exceptions in grid.
   */
  addExceptionRow() {
    const row = this.formBuilder.group({
      from: ['', Validators.required],
      to: ['', Validators.required],
      reason: [''],
      openTime: [''],
      closeTime: ['']
    });
    const control = this.form.controls.exceptions as FormArray;
    control.push(row);
    this.updateExceptionView();
  }

  /**
   * Delete exceptions from grid.
   */
  deleteExceptionRow(index: number) {
    const control = this.form.controls.exceptions as FormArray;
    control.removeAt(index);
    this.updateExceptionView();
    // delete row from formClone as well
    this.deletedRow.emit(index);
  }

  /**
   * update exceptions view.
   */
  updateExceptionView() {
    const exceptions = this.form.controls.exceptions as FormArray;
    this.exceptionDataSource.next(exceptions.controls);
  }

  fromDateChange(index: number, event: MatDatepickerInputEvent<Date>) {
    // tslint:disable-next-line: no-string-literal
    (this.form.get('exceptions') as FormArray).at(index)['controls'].to.setValue(event.value);
  }

  exceptionAt(index: number) {
    return (this.form.get('exceptions') as FormArray).at(index);
  }

  updateExceptionsTb() {
    if (this.orgConfig.exceptions.exceptions.length !== 0) {
      this.orgConfig.exceptions.exceptions.forEach((exception) => {
        const row = this.formBuilder.group({
          from: [moment(exception.from, EXCEPTION_DATE_FORMAT)],
          to: [moment(exception.to, EXCEPTION_DATE_FORMAT)],
          reason: [exception.reason],
          openTime: [exception.openTime],
          closeTime: [exception.closeTime]
        });
        this.exceptionArray.push(row);
      });
    }
    this.form.setControl('exceptions', this.exceptionArray);
    this.updateExceptionView();
  }

  ngOnChanges(value) {
    if (value.isInherit) {
      if ((this.form.get('exceptions') as FormArray).length > 0) {
        this.form.controls['exceptions'].reset();
        while ((this.form.get('exceptions') as FormArray).length !== 0) {
          (this.form.get('exceptions') as FormArray).removeAt(0);
          this.updateExceptionView();
        }
      }
      this.updateExceptionsTb();
    }
  }
}
