import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { AddHotKeyDialogComponent } from './add-hotkey-dialog/add-hotkey-dialog.component';

@Component({
  selector: 'app-module-assignment-hotkeys',
  templateUrl: './hotkeys.component.html'
})
export class HotKeysComponent implements OnInit, OnChanges {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('input', { static: false }) input: ElementRef;
  @Input() status: boolean;
  @Input() products: any[];
  @Input() orgId: string;
  @Output() updateData: EventEmitter<any> = new EventEmitter();
  frmHotKeys: FormGroup;
  productColumns: any[] = ['SKU', 'position', 'displayName', 'action'];
  productDataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  pageSizeOptions: number[] = [5, 10, 25, 50, 100];

  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.orgId = this.orgId ? `NODE::${this.orgId}` : '';
    this.initForm();
    this.productDataSource.data = [...this.products];
  }

  ngOnChanges() {
    this.productDataSource.data = [...this.products];
  }

  initForm() {
    this.frmHotKeys = this.formBuilder.group({
      ebasketSalesSetupHotkeys: [false, []],
      products: this.formBuilder.array([]),
      searchInput: ['']
    });
  }

  toggleEbasketSalesSetupHotkeys() {
    if (!this.status) {
      this.products = [];
      this.productDataSource.data = [];
    }
    this.updateData.emit({ status: this.status, products: this.products });
  }

  search() {
    const searchStr = this.input.nativeElement.value.toLowerCase();
    if (searchStr) {
      this.productDataSource.data = this.products.filter(
        (row: any) =>
          row.displayName.toLowerCase().includes(searchStr) ||
          row.SKU.toLowerCase().includes(searchStr) ||
          row.position.toString().includes(searchStr)
      );
    } else {
      this.productDataSource.data = [...this.products];
    }
    this.updateData.emit({ status: this.status, products: this.products });
  }

  removeRow(row) {
    this.products = this.products.filter((p) => p.SKU != row.SKU);
    this.search();
  }

  openAddHotkeyDialog() {
    const dialogRef = this.dialog.open(AddHotKeyDialogComponent, {
      width: '50%',
      data: { orgId: this.orgId }
    });
    dialogRef.afterClosed().subscribe((row) => {
      if (row) {
        this.products = [...this.products, row];
        this.productDataSource.data = [...this.products];
        this.updateData.emit({ status: this.status, products: this.products });
        setTimeout(() => {
          this.productDataSource.paginator = this.paginator;
          this.productDataSource.sort = this.sort;
        }, 100);
      }
    });
  }
}
