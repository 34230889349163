import { Component, EventEmitter, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { RefreshTokenService } from '@services/refreshToken.service';
import { CommonConfigFacade } from '@shared/common-config/state/common-config.facade';
import { BehaviorSubject, Subscription } from 'rxjs';
import { CommonServiceService } from './common-service.service';
import { LocalStorageJwtService } from './feature/auth/local-storage-jwt.service';
import { AuthFacade } from './feature/auth/state/auth.facade';
import CONSTANTS from './util/Constant';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  lastPing?: Date = null;
  idleMessage: BehaviorSubject<string> = new BehaviorSubject<string>('');
  subscriptions: Subscription[] = [];
  isIdle: EventEmitter<boolean> = new EventEmitter<boolean>(false);
  themeEmitter: EventEmitter<any> = new EventEmitter<any>();
  constructor(
    private authFacade: AuthFacade,
    private router: Router,
    private idle: Idle,
    private keepalive: Keepalive,
    public commonService: CommonServiceService,
    private localStorageJwtService: LocalStorageJwtService,
    private refreshTokenService: RefreshTokenService,
    private commonConfigFacade: CommonConfigFacade
  ) {
    if (this.enviornmentClass == 'pre-production') {
      console.log('preprod change if');
      console.log('Environment: ', this.enviornmentClass);
      this.idle.setIdle(7200);
    } else {
      console.log('Environment: ', this.enviornmentClass);
      console.log('preprod change else');
      this.idle.setIdle(CONSTANTS.IDLE_TIMEOUT);
    }
    this.idle.setTimeout(CONSTANTS.TIMEOUT);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.subscriptions.push(
      this.idle.onIdleEnd.subscribe(() => {
        this.idleMessage.next('');
        this.reset();
      })
    );

    this.subscriptions.push(
      this.idle.onTimeout.subscribe(() => {
        this.reset();
        this.logout();
        this.localStorageJwtService.removeItem();
      })
    );

    this.subscriptions.push(
      this.idle.onIdleStart.subscribe(() => {
        this.idleMessage.next('');
      })
    );

    this.subscriptions.push(
      this.idle.onTimeoutWarning.subscribe((countdown) => {
        const countdownInMin = Math.floor(countdown / 60);
        const countdownInSec = countdown - countdownInMin * 60;
        const message =
          'You will time out in ' + countdownInMin + ' minutes and ' + countdownInSec + ' seconds!';
        this.idleMessage.next(message);
      })
    );

    this.keepalive.interval(CONSTANTS.KEEP_ALIVE);

    this.subscriptions.push(
      this.keepalive.onPing.subscribe(() => {
        this.lastPing = new Date();
        this.subscriptions.push(
          this.authFacade.isLoggedIn$.subscribe((data) => {
            if (data && this.localStorageJwtService.getItem()) {
              console.log('reset session');
              this.refreshTokenService.resetSession();
            }
          })
        );
      })
    );

    this.subscriptions.push(
      this.authFacade.isLoggedIn$.subscribe((data) => {
        if (data) {
          this.idle.watch();
        } else {
          this.idle.stop();
        }
      })
    );
  }
  get enviornmentClass(): string {
    return this.env.environmentName.toLowerCase();
  }

  /**
   * Added forgot password router here because we need to load forgot password page
   * without menu, site bar and header
   */
  get isLoggedIn(): boolean {
    return (
      !(this.router.url.indexOf('login') > 0) &&
      !(this.router.url.indexOf('forgot-password') > 0) &&
      !(this.router.url.indexOf('resetPassword') > 0) &&
      !(this.router.url.indexOf('reset-password') > 0)
    );
  }
  public isLeftPanelOpend: boolean;

  env = environment;

  // go to top
  isShow: boolean;
  topPosToStartShowing = 20;

  ngOnInit() {
    const loginResponse = this.localStorageJwtService.getDecodedItem();
    if (loginResponse) {
      const currentDate: any = new Date().getTime();
      const tokenTime = loginResponse.accessToken['exp'] * 1000;
      if (currentDate > tokenTime) {
        this.localStorageJwtService.removeItem();
        this.router.navigateByUrl('login');
      } else {
        this.authFacade.user(loginResponse);
        this.authFacade.user$.subscribe((r) => {
          console.log('User in auth facade = ', r);
        });
      }
    }
    this.subscriptions.push(
      this.commonConfigFacade.currentUserTheme$.subscribe((theme: any) => {
        this.themeEmitter.emit(theme);
      })
    );
  }

  public drawerOpenEvent(e: boolean) {
    // console.log(e);
    this.isLeftPanelOpend = e;
    this.commonService.setDraweValue(e);
  }

  @HostListener('window:scroll')
  checkScroll() {
    const scrollPosition =
      window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    if (scrollPosition >= this.topPosToStartShowing) {
      this.isShow = true;
    } else {
      this.isShow = false;
    }
  }

  // TODO: Cross browsing
  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
  reset() {
    this.subscriptions.push(
      this.authFacade.isLoggedIn$.subscribe((data) => {
        if (data) {
          this.idle.watch();
        }
      })
    );
  }

  logout() {
    this.idleMessage.next('');
    this.authFacade.logout();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }
}
