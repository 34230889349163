import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
  ChangeDetectorRef
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  MatCheckboxChange,
  MatDialog,
  MatPaginator,
  MatSort,
  MatTableDataSource
} from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { AddHotKeyDialogComponent } from './add-hotkey-dialog/add-hotkey-dialog.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ConfirmDialogService } from '@shared/confirm-dialog/confirm-dialog.service';
import { TranslateService } from '@ngx-translate/core';
import CONSTANTS from 'src/app/util/Constant';
import { AddHotKeyGroupDialogComponent } from './add-hotkey-group-dialog/add-hotkey-group-dialog.component';
import { SelectionModel } from '@angular/cdk/collections';
import { HotkeyService } from './hotkeys.service';
import * as uuid from 'uuid';

@Component({
  selector: 'app-module-assignment-hotkeys',
  templateUrl: './hotkeys.component.html',
  styleUrls: ['./hotkeys.component.scss']
})
export class HotKeysComponent implements OnInit, OnChanges {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('input', { static: false }) input: ElementRef;
  @ViewChild('tableScrollContainer', { static: false }) tableScrollContainer: ElementRef;
  @ViewChild('matCell', { static: false }) matCell: ElementRef;
  @Input() status: boolean;
  @Input() products: any[];
  @Input() orgId: string;
  @Input() nodeId: string;
  @Input() action: string;
  @Output() updateData: EventEmitter<any> = new EventEmitter();

  frmHotKeys: FormGroup;
  productColumns: any[] = ['position', 'expand', 'select', 'drag', 'SKU', 'displayName', 'action'];
  displayedColumnsInternal: any[] = ['position', 'drag', 'SKU', 'displayName'];
  productDataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  selection = new SelectionModel<any>(true, []);
  expandedRowId: any = null;

  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private confirmDialogService: ConfirmDialogService,
    private translate: TranslateService,
    private hotkeyService: HotkeyService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.products = this.products.map((product) =>
      product.id ? product : { ...product, id: uuid.v4() }
    );
    this.orgId = this.orgId ? `NODE::${this.orgId}` : '';
    this.initForm();
    let tempProducts = this.products.filter((x) => !x.groupId);
    // tempProducts position will be displayed based on the index but in backend the position will be different
    tempProducts = this.hotkeyService.updateHotkeyPosition(tempProducts);
    this.productDataSource.data = [...tempProducts];
  }
  ngAfterViewInit() {
    this.updateMaxHeight();
  }

  updateMaxHeight() {
    if (this.tableScrollContainer) {
      const rowHeight = 48; // default row height
      const maxHeight = (rowHeight + 1.1) * 20 + 'px'; //dynamically updating row height (scroll after 20 rows)
      this.tableScrollContainer.nativeElement.style.maxHeight = maxHeight;
      this.tableScrollContainer.nativeElement.style.overflow = 'auto';
    }
  }

  ngOnChanges() {
    let tempProducts = this.products.filter((x) => !x.groupId);
    tempProducts = this.hotkeyService.updateHotkeyPosition(tempProducts);
    this.productDataSource.data = [...tempProducts];
    this.products = this.products.map((product) => {
      if (product.isHotKeyGroup) {
        const updatedProduct = tempProducts.find((p) => p.id === product.id);
        return updatedProduct ? { ...product, position: updatedProduct.position } : product;
      }
      return product;
    });
    this.products = [
      ...tempProducts,
      ...this.products.filter((x) => x.groupId) // Keep the grouped items
    ].sort((a, b) => a.position - b.position); // Ensure final order is correct
  }

  initForm() {
    this.frmHotKeys = this.formBuilder.group({
      ebasketSalesSetupHotkeys: [false, []],
      products: this.formBuilder.array([]),
      searchInput: ['']
    });
  }

  toggleRow(row: any) {
    this.expandedRowId = this.expandedRowId === row ? null : row;
  }
  // getting hotkeys inside of that particular hotkey group
  getHotkeysForGroup(group: any): any[] {
    return this.products.filter((product) => product.groupId === group.id);
  }
  // checking if row is expanded or not
  isExpanded(row: any): boolean {
    return this.expandedRowId === row;
  }
  toggleEbasketSalesSetupHotkeys() {
    if (!this.status) {
      this.products = [];
      this.productDataSource.data = [];
    }
    this.updateData.emit({ status: this.status, products: this.products });
  }

  search() {
    const searchStr = this.input.nativeElement.value.toLowerCase();
    if (searchStr) {
      if (this.products && this.products.length) {
        let tempProducts = this.products.filter((x) => !x.groupId);
        const tempData = tempProducts.filter((row: any) => {
          if (row.displayName && row.SKU) {
            return (
              row.displayName.toLowerCase().includes(searchStr) ||
              row.SKU.toLowerCase().includes(searchStr)
            );
          } else if (row.displayName) {
            return row.displayName.toLowerCase().includes(searchStr);
          } else if (row.SKU) {
            return row.SKU.toLowerCase().includes(searchStr);
          }
          return false;
        });
        this.productDataSource.data = tempData;
      }
    } else {
      const tempProducts = this.products.filter((x) => !x.groupId);
      this.productDataSource.data = [...tempProducts];
    }
  }

  removeRow(row) {
    let message = '';
    row.isHotKeyGroup
      ? (message = this.translate.instant('module-assignment.hotkeys.delete_hotkey_group'))
      : (message = this.translate.instant('module-assignment.hotkeys.delete_hotkey'));
    this.confirmDialogService.showConfirmDialog(message).subscribe((answer) => {
      if (answer === CONSTANTS.YES.toLowerCase()) {
        if (row.isHotKeyGroup) {
          const childData = this.products.filter((p1) => p1.groupId === row.id).map((p2) => p2.id);
          this.products = this.products.filter((x) => !childData.includes(x.id));
        }
        this.products = this.products.filter((p) => p.id != row.id);
        // updating the position here after deletion
        this.products = this.products.map((product, index) => {
          return { ...product, position: index + 1 };
        });
        const tempProducts = this.products.filter((x) => !x.groupId);
        this.productDataSource.data = [...tempProducts];
        this.updateData.emit({ status: this.status, products: this.products });
        // this.search();
      } else {
        //keep the selected group or hotkey as checked (need to modify this part during implementing checkbox selection logic)
        console.log('doesnt want to delete');
      }
    });
  }
  editRow(rowData, action) {
    if (rowData && rowData.isHotKeyGroup) {
      let childData = this.products.filter((x) => x.groupId == rowData.id);
      childData = childData.map((p, index) => {
        return {
          ...p,
          position: index + 1
        };
      });
      const dialogRef = this.dialog.open(AddHotKeyGroupDialogComponent, {
        height: '80vh',
        data: {
          formData: rowData,
          orgId: this.orgId,
          nodeId: this.nodeId,
          action: action,
          childData: childData
        }
      });
      dialogRef.afterClosed().subscribe((row) => {
        if (row) {
          const index = this.products.findIndex((product) => product.id === row.id);
          if (index !== -1) {
            this.products[index] = row.groupData;
          }
          if (row && row.deletedData && row.deletedData.length > 0) {
            row.deletedData.forEach((x) => {
              const i = this.products.findIndex((prod) => prod.id === x.id);
              this.products[i] = x;
            });
          }
          row.childData.forEach((x) => {
            const i = this.products.findIndex((prod) => prod.id === x.id);
            if (i !== -1) {
              this.products[i] = x;
            } else {
              this.products.push(x);
            }
          });
          const tempProducts = this.products.filter((x) => !x.groupId);
          this.productDataSource.data = [...tempProducts];
          this.updateData.emit({ status: this.status, products: this.products });
          setTimeout(() => {
            this.productDataSource.paginator = this.paginator;
            this.productDataSource.sort = this.sort;
          }, 100);
        }
      });
    } else {
      const dialogRef = this.dialog.open(AddHotKeyDialogComponent, {
        width: '50%',
        data: { formData: rowData, orgId: this.orgId, action: action, nodeId: this.nodeId }
      });
      dialogRef.afterClosed().subscribe((row) => {
        if (row) {
          const index = this.products.findIndex((product) => product.id == row.id);
          if (index !== -1) {
            this.products[index] = row;
          }
          const tempProducts = this.products.filter((x) => !x.groupId);
          this.productDataSource.data = [...tempProducts];
          this.updateData.emit({ status: this.status, products: this.products });
          setTimeout(() => {
            this.productDataSource.paginator = this.paginator;
            this.productDataSource.sort = this.sort;
          }, 100);
        }
      });
    }
  }
  drop(event: CdkDragDrop<any[]>) {
    let tempProducts = this.products.filter((x) => !x.groupId);
    moveItemInArray(tempProducts, event.previousIndex, event.currentIndex);
    tempProducts = this.hotkeyService.updateHotkeyPosition(tempProducts);
    // Preserve checked state
    const updatedData = tempProducts.map((item) => {
      const existingHotkey = this.productDataSource.data.find(
        (dataItem) => dataItem.SKU === item.SKU
      );
      return {
        ...item,
        checked: existingHotkey ? existingHotkey.checked : false
      };
    });
    this.products = [
      ...updatedData,
      ...this.products.filter((x) => x.groupId) // Keep the grouped items
    ].sort((a, b) => a.position - b.position); // Ensure final order is correct
    this.productDataSource.data = [...this.products];
    this.updateData.emit({ status: this.status, products: this.products });
  }

  dropChildHotkeys(event: CdkDragDrop<any[]>, group: any): void {
    if (event.previousIndex !== event.currentIndex) {
      let data = this.getHotkeysForGroup(group);
      moveItemInArray(data, event.previousIndex, event.currentIndex);
      data.forEach((item, index) => {
        item.position = index + 1;
      });
      this.products = this.products.map((product) => {
        const updatedProduct = data.find((hotkey) => hotkey.id === product.id);
        return updatedProduct ? { ...product, position: updatedProduct.position } : product;
      });
      this.products.sort((a, b) => a.position - b.position);
      this.cdr.detectChanges();
    }
  }

  openAddHotkeyDialog() {
    const dialogRef = this.dialog.open(AddHotKeyDialogComponent, {
      width: '50%',
      data: { orgId: this.orgId, action: 'Create', nodeId: this.nodeId }
    });
    dialogRef.afterClosed().subscribe((row) => {
      if (row) {
        this.products = this.hotkeyService.updateHotkeyPosition([...this.products, row]);
        const tempProducts = this.products.filter((x) => !x.groupId);
        this.productDataSource.data = [...tempProducts];
        this.updateData.emit({ status: this.status, products: this.products });
        setTimeout(() => {
          this.productDataSource.paginator = this.paginator;
          this.productDataSource.sort = this.sort;
        }, 100);
      }
    });
  }
  openAddHotkeyGroupDialog() {
    let selectedHotkeys = this.productDataSource.data.filter((x) => x.checked);
    selectedHotkeys = this.hotkeyService.updateHotkeyPosition(selectedHotkeys);
    const dialogRef = this.dialog.open(AddHotKeyGroupDialogComponent, {
      height: '80vh',
      data: { orgId: this.orgId, nodeId: this.nodeId, childData: selectedHotkeys }
    });
    dialogRef.afterClosed().subscribe((row) => {
      if (row) {
        this.products = this.hotkeyService.updateHotkeyPosition2(
          [...this.products, row.groupData],
          null,
          row
        );
        if (row && row.deletedData && row.deletedData.length > 0) {
          row.deletedData.forEach((x) => {
            const i = this.products.findIndex((prod) => prod.id === x.id);
            this.products[i] = x;
          });
        }
        row.childData.forEach((x) => {
          const i = this.products.findIndex((prod) => prod.id === x.id);
          if (i != -1) {
            this.products[i] = x;
          } else {
            this.products = [...this.products, x].map((product, index) => {
              return { ...product, checked: false };
            });
          }
        });
        const tempProducts = this.products.filter((x) => !x.groupId);
        this.productDataSource.data = [...tempProducts];
        // this.products = [...tempProducts, ...this.products.filter((x) => x.groupId)].sort(
        //   (a, b) => a.position - b.position
        // );
        this.updateData.emit({ status: this.status, products: this.products });
      }
    });
  }
  addGroupShouldEnable() {
    return this.productDataSource.data.filter((x) => x.checked).length <= 0 ? true : false;
  }
  checkboxLabel(row?: any): string {
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  onCheckboxChange(element: any, event: MatCheckboxChange) {
    element.checked = event.checked;
    this.selection.toggle(element);
    this.productDataSource.data = [...this.productDataSource.data];
  }
}
