import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CommonConfigFacade } from '@shared/common-config/state/common-config.facade';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-shorttime-dropdown',
  templateUrl: './shorttime-dropdown.component.html',
  styleUrls: ['./shorttime-dropdown.component.scss']
})
export class ShorttimeDropdownComponent implements OnInit, OnDestroy {
  @Input() ctrlShortTime: FormControl;
  @Input() placeholder: string;
  @Input() defaultShortTime;
  @Input() isOverriden: false;
  shortTimeList;
  subscriptions: Subscription[] = [];

  constructor(private readonly commonFacade: CommonConfigFacade) {}

  ngOnInit() {
    this.subscriptions.push(
      this.commonFacade.shortTimeFormats$.subscribe((data) => {
        this.shortTimeList = data;
        /* Temp put this both condition because some times default short time
         *  object get id attributr and some times it's get timeId attribute so put both condition
         */
        if (this.defaultShortTime && this.defaultShortTime.timeId) {
          this.ctrlShortTime.setValue(
            this.shortTimeList.find((s) => s.id === this.defaultShortTime.timeId)
          );
        }
        if (this.defaultShortTime && this.defaultShortTime.id) {
          this.ctrlShortTime.setValue(
            this.shortTimeList.find((s) => s.id === this.defaultShortTime.id)
          );
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }
}
