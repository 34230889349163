import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable()
export class ApiService {
  constructor(private http: HttpClient) {}

  get headers(): HttpHeaders {
    const headersConfig = {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    };
    return new HttpHeaders(headersConfig);
  }

  get(
    url: string,
    params: HttpParams = new HttpParams(),
    httpInterceptorSkipper = false
  ): Observable<any> {
    let headers = this.headers;
    if (httpInterceptorSkipper) {
      headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-Skip-Interceptor': ''
      });
    }
    return this.http.get(`${environment.apiUrl}${url}`, {
      headers,
      params
    });
  }

  post(url: string, data: object = {}): Observable<any> {
    return this.http.post(`${environment.apiUrl}${url}`, JSON.stringify(data), {
      headers: this.headers
    });
  }

  put(url: string, data: object = {}): Observable<any> {
    return this.http.put(`${environment.apiUrl}${url}`, JSON.stringify(data), {
      headers: this.headers
    });
  }

  delete(url: string): Observable<any> {
    return this.http.delete(`${environment.apiUrl}${url}`, {
      headers: this.headers,
      observe: 'response'
    });
  }
}

export const PATH = {
  // User Management
  POST_LOGIN: '/user-management/login',
  GET_USER: (userId) => `/user-management/users/${userId}`,
  POST_CREATE_USER: '/user-management/users',
  GET_USER_LIST: '/user-management/users/',
  PUT_USER_STATUS: (userId) => `/user-management/users/${userId}/status`,
  DELETE_USER: (userId) => `/user-management/users/${userId}/delete`,
  PUT_UPDATE_USER: (userId) => `/user-management/users/${userId}`,
  POST_RESET_PASSWORD: '/user-management/resetpassword',
  POST_REFRESH_TOKEN: '/user-management/refresh-token',

  // Node Management
  GET_ORGANIZATION_LIST: '/node-management/organization/',
  GET_ORGANISATION_BY_ID: (nodeId) => `/node-management/node/${nodeId}`,
  GET_NODE_THEME: (domain) => `/node-management/organization/${domain}/theme`,
  GET_DOMAIN_LIST: (subdomain) => `/node-management/domain/${subdomain}`,
  POST_CREATE_NODE: '/node-management/node',
  PUT_EDIT_NODE: (nodeId) => `/node-management/node/${nodeId}`,
  POST_CREATE_ORGANIZATION: '/node-management/node',
  GET_NODE_CONFIG_BY_ID: (nodeId) => `/node-management/node/${nodeId}/configuration`,
  GET_NODE_BY_ID: (nodeId, status = true) => `/node-management/node/${nodeId}?status=${status}`,
  GET_NODE_OVERRIDE_BY_ID: (nodeId) => `/node-management/node/override/${nodeId}`,
  PUT_UPDATE_NODE_STATUS: (nodeId) => `/node-management/node/${nodeId}/status`,
  DELETE_NODE_BY_ID: (nodeId) => `/node-management/node/${nodeId}/delete`,
  // Notification Management
  NOTIFICATION: '/notfication',
  PRE_SIGNED_URL: '/common/getPreSignedUrl',
  DELETE_AWS_SECRETS: '/common/deleteAwsSecrets',

  // Common Management
  UPLOAD_PROFILE_PHOTO: '/common/uploadfile',
  UPLOAD_USER_CSV: '/common/uploadfileons3',
  GET_COUNTRIES: '/common/countries',
  GET_LANGUAGES: '/common/language',
  GET_SHORT_DATE_FORMATS: '/common/short-date',
  GET_SHORT_TIME_FORMATS: '/common/short-time',
  GET_PURGE: '/common/purge',
  GET_TIMEZONES: '/common/timezones',
  GET_LONG_DATE_FORMATS: '/common/long-date',
  GET_LONG_TIME_FORMATS: '/common/long-time',
  GET_CURRENCIES: '/common/currency',
  GET_THEMES: '/common/themes',
  GET_ALL_MODULE: '/common/module',
  POST_QRCODE: '/common/qrcode/generate',

  // OM access right
  GET_OM_ACCESS_RIGHTS: '/om-config/access-rights',

  // TM access right
  GET_TM_ACCESS_RIGHTS: '/tm-config/access-rights',

  // saga access right
  GET_SAGA_ACCESS_RIGHTS: '/saga-config/access-rights',

  // RJ access right
  GET_RJ_ACCESS_RIGHT: `/rj-config/access-right`,
  // eBasket access right
  GET_EBASKET_ACCESS_RIGHTS: `/ebasket-config/access-rights`,
  // T2S access right
  GET_T2S_ACCESS_RIGHTS: `/t2s-config/access-rights`,

  // SOTK access right
  GET_SOTK_ACCESS_RIGHTS: `/sotk-config/access-rights`,

  // ROSS access rights

  GET_ROSS_ACCESS_RIGHTS: `/ross-config/access-rights`,

  // Role Management
  GET_MODULES_BY_ROLE: (roleName) => `/role-management/roles/modules/${roleName}`,
  POST_CREATE_ROLE: '/role-management/roles',
  GET_ROLE_LIST: '/role-management/roles',
  PUT_ROLE: (roleId) => `/role-management/roles/${roleId}`,
  PUT_UPDATE_ROLE_STATUS: (roleId) => `/role-management/roles/${roleId}/status`,
  DELETE_ROLE: (roleId) => `/role-management/roles/${roleId}`,
  GET_ROLE_BY_ID: (roleId) => `/role-management/roles/${roleId}`,

  // Transaction Management
  GET_REASON_CATEGORY: '/transaction-management-common/reasons/category',
  GET_TENDER_TYPE: '/transaction-management-common/tender/type',

  // Dashboard
  GET_USER_COUNT: '/user-management/dashboard/statistic',
  GET_DEVICE_COUNT: '/module-management/device/statistic',
  GET_NODE_COUNT: '/node-management/dashboard/statistic',
  GET_USER_DETAILS_LIST: '/user-management/dashboard/list',
  GET_DASHBOARD_STORE_INFO: '/node-management/node/dashboard/stores',
  GET_DASHBOARD_ORG_INFO: '/node-management/dashboard/list',
  GET_TOTAL_LOGINS_COUNT: '/user-management/dashboard/login-counts',
  GET_TOTAL_STORE_STATISTICS: '/transaction-management-common/dashboard/store-statistics',
  GET_TOTAL_STORE_USER_STATISTICS: '/transaction-management-common/dashboard/user-statistics',

  // Forgot_Password
  VALIDATE_EMAIL_ID: '​/user-management/resetpassword/validate-attributes',
  SEND_EMAIL: (userId) => `/user-management/resetLink/${userId}`,
  RESET_PASSWORD: (resetToken) => `/user-management/reset-password/${resetToken}`,
  VALIDATE_RESET_TOKEN: (resetToken) => `​/user-management/reset-password/validation/${resetToken}`,
  LOGOUT: '/user-management/logout',
  GET_PRODUCT_LIST: `/product-management/products`,
  GET_COMPANY_LIST: `/common/companies`,
  GET_TEMPLATE_BY_NAME: (templateName) => `/common/template/${templateName}`
};
