import { Component, Input, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl,
  FormControl,
  FormArray
} from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { EbasketApiMethodTypes, EbasketCustomerSelectApi, Providers } from '@shared/interfaces';
import { SelectApiPopupComponent } from '@shared/select-api-popup/select-api-popup.component';

@Component({
  selector: 'ax-configDetails-settings',
  templateUrl: './ax-configDetails-settings.component.html',
  styleUrls: ['./ax-configDetails-settings.component.scss']
})
export class AxConfigDetailsSettingsComponent implements OnInit {
  @Input() action: string;
  @Input() parentForm: FormGroup;
  @Input() customerHubDetails: any;
  @Input() authControlKey: string;
  @Input() salesExport?: boolean = false;
  apiDetails: FormGroup;
  defaultValue = 'United Kingdom';
  defaultSignatureMethod = 'HMAC-SHA256';
  isEBasketCustomerHubAddDisabled = false;

  constructor(private formBuilder: FormBuilder, private dialog: MatDialog) {}

  ngOnInit() {
    this.initForm();
  }
  initForm() {
    console.log('mine ->', this.customerHubDetails);
    this.parentForm.addControl(
      this.authControlKey,
      this.formBuilder.group({
        axConfigDetailsUserName: [
          {
            value:
              this.customerHubDetails &&
              this.customerHubDetails.authenticationDetails &&
              this.customerHubDetails.authenticationDetailsKey
                ? Providers.SECRET
                : '',
            disabled: this.action === 'view' ? true : false
          },
          Validators.required
        ],
        axConfigDetailsPassword: [
          {
            value:
              this.customerHubDetails &&
              this.customerHubDetails.authenticationDetails &&
              this.customerHubDetails.authenticationDetailsKey
                ? Providers.SECRET
                : '',
            disabled: this.action === 'view' ? true : false
          },
          Validators.required
        ],
        axConfigDetailsDatabase: [
          {
            value:
              this.customerHubDetails &&
              this.customerHubDetails.authenticationDetails &&
              this.customerHubDetails.authenticationDetailsKey
                ? Providers.SECRET
                : '',
            disabled: this.action === 'view' ? true : false
          },
          Validators.required
        ],
        axConfigDetailsServer: [
          {
            value:
              this.customerHubDetails &&
              this.customerHubDetails.authenticationDetails &&
              this.customerHubDetails.authenticationDetailsKey
                ? Providers.SECRET
                : '',
            disabled: this.action === 'view' ? true : false
          },
          Validators.required
        ],
        ...(!this.salesExport
          ? {
              axConfigDetailsCustomerTable: [
                {
                  value:
                    this.customerHubDetails &&
                    this.customerHubDetails.authenticationDetails &&
                    this.customerHubDetails.authenticationDetailsKey
                      ? Providers.SECRET
                      : '',
                  disabled: this.action === 'view' ? true : false
                },
                Validators.required
              ]
            }
          : {}),
        ...(this.salesExport
          ? {
              axConfigDetailsHeaderTable: [
                {
                  value:
                    this.customerHubDetails &&
                    this.customerHubDetails.authenticationDetails &&
                    this.customerHubDetails.authenticationDetailsKey
                      ? Providers.SECRET
                      : '',
                  disabled: this.action === 'view' ? true : false
                },
                Validators.required
              ]
            }
          : {}),
        ...(this.salesExport
          ? {
              axConfigDetailsOrderTable: [
                {
                  value:
                    this.customerHubDetails &&
                    this.customerHubDetails.authenticationDetails &&
                    this.customerHubDetails.authenticationDetailsKey
                      ? Providers.SECRET
                      : '',
                  disabled: this.action === 'view' ? true : false
                },
                Validators.required
              ]
            }
          : {}),
        ...(this.salesExport
          ? {
              axConfigDetailsPaymentTable: [
                {
                  value:
                    this.customerHubDetails &&
                    this.customerHubDetails.authenticationDetails &&
                    this.customerHubDetails.authenticationDetailsKey
                      ? Providers.SECRET
                      : '',
                  disabled: this.action === 'view' ? true : false
                },
                Validators.required
              ]
            }
          : {}),
        authenticationDetailKey: [
          {
            value:
              this.customerHubDetails &&
              this.customerHubDetails.authenticationDetails &&
              this.customerHubDetails.authenticationDetailsKey
                ? this.customerHubDetails.authenticationDetailsKey
                : '',
            disabled: this.action === 'view' ? true : false
          }
        ]
      })
    );
  }

  isRequired(control: string) {
    let isRequired = false;
    const formControlObject = this.parentForm.get(this.authControlKey).get(control);

    if (formControlObject !== null) {
      if (formControlObject.validator !== null) {
        if (formControlObject.validator({} as AbstractControl) !== null) {
          isRequired = formControlObject.validator({} as AbstractControl).required ? true : false;
        }
      }
    }
    return isRequired ? true : false;
  }
}
