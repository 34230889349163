import { Component, OnDestroy, OnInit } from '@angular/core';
import { IOrganization, IPaginationResponse, NodeType } from '@shared/interfaces';
import { Subscription } from 'rxjs';
import { LocalStorageJwtService } from 'src/app/feature/auth/local-storage-jwt.service';
import { AuthFacade } from 'src/app/feature/auth/state/auth.facade';
import { OrganizationService } from '../../../../feature/organization/organization.details.service';

@Component({
  selector: 'app-ross-popup-container',
  templateUrl: './ross-popup-container.component.html'
})
export class RossPopupContainerComponent implements OnInit, OnDestroy {
  organizations: IOrganization[] = [];
  applications = [];
  subscriptions: Subscription[] = [];
  constructor(
    private organizationService: OrganizationService,
    private readonly localStorageJwtService: LocalStorageJwtService,
    private authFacade: AuthFacade
  ) {}

  ngOnInit() {
    const token = this.localStorageJwtService.getDecodedItem();
    if (token && token.idToken) {
      const rootNode = token.idToken['custom:rootNode'];

      // console.log('rootnode---', rootNode);
      // if (rootNode) {
      //   if (rootNode === NodeType.GLOBAL) {
      //     this.subscriptions.push(
      //       this.authFacade.userModule$.subscribe((data) => {
      //         //     console.log('data---->', data);
      //         if (data && data.length > 0) {
      //           // const hostname = window.location.origin;
      //           // data.forEach((a) => {
      //           //   if (a.shortName === 'OM') {
      //           //     a.logoUrl = `${hostname}/assets/images/icon-app-orchestration.svg`;
      //           //   }
      //           //   if (a.shortName === 'TM') {
      //           //     a.logoUrl = `${hostname}/assets/images/icon-app-transaction-manager_coloured.svg`;
      //           //   }
      //           //   if (a.shortName === 'SAGA') {
      //           //     a.logoUrl = `${hostname}/assets/images/icon_scan and go_coloured.svg`;
      //           //   }
      //           //   if (a.shortName === 'RJ') {
      //           //     a.logoUrl = `${hostname}/assets/images/icon-app-rj.svg`;
      //           //   }
      //           //   if (a.shortName === 'eBasket') {
      //           //     a.logoUrl = `${hostname}/assets/images/icon-app-temp-ebasket.svg`;
      //           //   }
      //           //   if (a.shortName === 'T2S') {
      //           //     a.logoUrl = `${hostname}/assets/images/icon-app-t2b.svg`;
      //           //   }
      //           //   if (a.shortName === 'SOTK') {
      //           //     a.logoUrl = `${hostname}/assets/images/icon-app-temp-sotk.svg`;
      //           //   }
      //           // });
      //           this.applications = data;
      //           //        console.log('ROSS applications', this.applications);
      //         } else {
      //           this.authFacade.module();
      //         }
      //       })
      //     );
      //   }
      // }
    }
  }
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }
}
