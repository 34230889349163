import { Component, Input, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl,
  FormControl,
  FormArray
} from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { EbasketApiMethodTypes, EbasketCustomerSelectApi, Providers } from '@shared/interfaces';
import { SelectApiPopupComponent } from '@shared/select-api-popup/select-api-popup.component';

@Component({
  selector: 'netsuite-customer-hub-details-settings',
  templateUrl: './netsuite-customer-hub-details-settings.component.html',
  styleUrls: ['./netsuite-customer-hub-details-settings.component.scss']
})
export class NetsuiteCustomerHubDetailsSettingsComponent implements OnInit {
  @Input() action: string;
  @Input() parentForm: FormGroup;
  @Input() customerHubDetails: any;
  @Input() authControlKey: string;
  apiDetails: FormGroup;
  defaultValue = 'United Kingdom';
  defaultSignatureMethod = 'HMAC-SHA256';
  isEBasketCustomerHubAddDisabled = false;
  ebasket_customer_hub_select_api = [
    {
      id: 1,
      name: EbasketCustomerSelectApi.CreateCustomer,
      value: EbasketCustomerSelectApi.CreateCustomer,
      isDisabled: false
    },
    {
      id: 2,
      name: EbasketCustomerSelectApi.UpdateCustomer,
      value: EbasketCustomerSelectApi.UpdateCustomer,
      isDisabled: false
    }
  ];
  method_types = [
    { id: 1, name: EbasketApiMethodTypes.Get, value: EbasketApiMethodTypes.Get },
    { id: 2, name: EbasketApiMethodTypes.Post, value: EbasketApiMethodTypes.Post },
    { id: 3, name: EbasketApiMethodTypes.Put, value: EbasketApiMethodTypes.Put },
    { id: 4, name: EbasketApiMethodTypes.Patch, value: EbasketApiMethodTypes.Patch },
    { id: 5, name: EbasketApiMethodTypes.Delete, value: EbasketApiMethodTypes.Delete }
  ];
  constructor(private formBuilder: FormBuilder, private dialog: MatDialog) {}

  ngOnInit() {
    this.initForm();
  }
  initForm() {
    this.parentForm.addControl(
      this.authControlKey,
      this.formBuilder.group({
        ebasketCustHubSignatureMethod: [
          {
            value:
              this.customerHubDetails &&
              this.customerHubDetails.authenticationDetails &&
              this.customerHubDetails.authenticationDetailsKey
                ? Providers.SECRET
                : this.defaultSignatureMethod,
            disabled: this.action === 'view' ? true : false
          },
          Validators.required
        ],
        ebasketCustHubConsumerKey: [
          {
            value:
              this.customerHubDetails &&
              this.customerHubDetails.authenticationDetails &&
              this.customerHubDetails.authenticationDetailsKey
                ? Providers.SECRET
                : '',
            disabled: this.action === 'view' ? true : false
          },
          Validators.required
        ],
        ebasketCustHubConsumerSecret: [
          {
            value:
              this.customerHubDetails &&
              this.customerHubDetails.authenticationDetails &&
              this.customerHubDetails.authenticationDetailsKey
                ? Providers.SECRET
                : '',
            disabled: this.action === 'view' ? true : false
          },
          Validators.required
        ],
        ebasketCustHubAccessToken: [
          {
            value:
              this.customerHubDetails &&
              this.customerHubDetails.authenticationDetails &&
              this.customerHubDetails.authenticationDetailsKey
                ? Providers.SECRET
                : '',
            disabled: this.action === 'view' ? true : false
          },
          Validators.required
        ],
        ebasketCustHubTokenSecret: [
          {
            value:
              this.customerHubDetails &&
              this.customerHubDetails.authenticationDetails &&
              this.customerHubDetails.authenticationDetailsKey
                ? Providers.SECRET
                : '',
            disabled: this.action === 'view' ? true : false
          },
          Validators.required
        ],

        ebasketCustHubAccountId: [
          {
            value:
              this.customerHubDetails &&
              this.customerHubDetails.authenticationDetails &&
              this.customerHubDetails.authenticationDetailsKey
                ? Providers.SECRET
                : '',
            disabled: this.action === 'view' ? true : false
          },
          Validators.required
        ],
        authenticationDetailKey: [
          {
            value:
              this.customerHubDetails &&
              this.customerHubDetails.authenticationDetails &&
              this.customerHubDetails.authenticationDetailsKey
                ? this.customerHubDetails.authenticationDetailsKey
                : '',
            disabled: this.action === 'view' ? true : false
          }
        ],
        ebasketCustHubApiDetails: this.formBuilder.array([])
      })
    );
    this.bindEbaskeCustHubDetailsFormGroup();
  }

  openEbasketCustomerHubSelectApiPopup() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = this.ebasket_customer_hub_select_api.filter((api) => !api.isDisabled);
    const dialogRef = this.dialog.open(SelectApiPopupComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result !== '') {
        this.ebasket_customer_hub_select_api.map((element: any) => {
          if (element.value === result) {
            element.isDisabled = true;
            const ebasketCustHubApiDetails = this.parentForm
              .get(this.authControlKey)
              .get('ebasketCustHubApiDetails') as FormArray;

            ebasketCustHubApiDetails.push(this.createApiDetailsFormGroup(result));
            if (this.ebasket_customer_hub_select_api.length === ebasketCustHubApiDetails.length) {
              this.isEBasketCustomerHubAddDisabled = true;
            }
          }
        });
      }
    });
  }
  private createApiDetailsFormGroup(selectedApi: string): FormGroup {
    return new FormGroup({
      url: new FormControl('', Validators.required),
      script: new FormControl('', Validators.required),
      deploy: new FormControl('', Validators.required),
      method: new FormControl('', Validators.required),
      selectedApi: new FormControl(selectedApi)
    });
  }

  deleteEbasketCustomerHubApiDetailContainer(index: number) {
    const ebasketCustHubApiDetails = this.parentForm
      .get(this.authControlKey)
      .get('ebasketCustHubApiDetails') as FormArray;
    this.ebasket_customer_hub_select_api.map((element: any) => {
      if (element.value === ebasketCustHubApiDetails.value[index].selectedApi) {
        element.isDisabled = false;
      }
    });
    if (ebasketCustHubApiDetails.length > 0) {
      ebasketCustHubApiDetails.removeAt(index);
    } else {
      ebasketCustHubApiDetails.reset();
    }
    if (this.ebasket_customer_hub_select_api.length !== ebasketCustHubApiDetails.length) {
      this.isEBasketCustomerHubAddDisabled = false;
    }
  }

  bindEbaskeCustHubDetailsFormGroup() {
    if (
      this.customerHubDetails &&
      this.customerHubDetails.authenticationDetails &&
      this.customerHubDetails.authenticationDetails.apiDetails
    ) {
      if (this.customerHubDetails.provider === Providers.Netsuite) {
        const control = this.parentForm
          .get(this.authControlKey)
          .get('ebasketCustHubApiDetails') as FormArray;
        this.customerHubDetails.authenticationDetails.apiDetails.map((apis: any) => {
          control.push(
            this.formBuilder.group({
              url: [
                {
                  value: apis.url,
                  disabled: this.action === 'view' ? true : false
                },
                Validators.required
              ],
              script: [
                {
                  value: apis.script,
                  disabled: this.action === 'view' ? true : false
                },
                Validators.required
              ],
              deploy: [
                {
                  value: apis.deploy,
                  disabled: this.action === 'view' ? true : false
                },
                Validators.required
              ],
              method: [
                {
                  value: apis.method,
                  disabled: this.action === 'view' ? true : false
                },
                Validators.required
              ],
              selectedApi: [apis.selectedApi]
            })
          );
          this.ebasket_customer_hub_select_api.map((key) => {
            if (key.value === apis.selectedApi) {
              key.isDisabled = true;
            }
          });
        });
        if (this.ebasket_customer_hub_select_api.length === control.length) {
          this.isEBasketCustomerHubAddDisabled = true;
        }
      }
    }
  }
  isRequired(control: string) {
    let isRequired = false;
    const formControlObject = this.parentForm.get(this.authControlKey).get(control);

    if (formControlObject !== null) {
      if (formControlObject.validator !== null) {
        if (formControlObject.validator({} as AbstractControl) !== null) {
          isRequired = formControlObject.validator({} as AbstractControl).required ? true : false;
        }
      }
    }
    return isRequired ? true : false;
  }
}
