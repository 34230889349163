import { createAction, props } from '@ngrx/store';
import {
  Country,
  Currency,
  DateTimeFormat,
  Language,
  Modules,
  NodeThemeSetting,
  Purge,
  Theme,
  TimeZone,
  Node
} from './common-config.model';

export const getCurrencies = createAction('[commonConfig] GET_CURRENCIES');

export const getCurrenciesSuccess = createAction(
  '[commonConfig] GET_CURRENCIES_SUCCESS',
  props<{ currencies: Currency[] }>()
);

export const getLanguages = createAction('[commonConfig] GET_LANGUAGES');

export const getLanguagesSuccess = createAction(
  '[commonConfig] GET_LANGUAGES_SUCCESS',
  props<{ languages: Language[] }>()
);

export const getTimeZones = createAction('[commonConfig] GET_TIMEZONES');

export const getTimeZonesSuccess = createAction(
  '[commonConfig] GET_TIMEZONES_SUCCESS',
  props<{ timezones: TimeZone[] }>()
);

export const getPurge = createAction('[commonConfig] GET_PURGE');

export const getPurgeSuccess = createAction(
  '[commonConfig] GET_PURGE_SUCCESS',
  props<{ purge: Purge }>()
);

export const getCountries = createAction('[commonConfig] GET_COUNTIRES');

export const getCountriesSuccess = createAction(
  '[commonConfig] GET_COUNTRIES_SUCCESS',
  props<{ countries: Country[] }>()
);

export const getModules = createAction('[commonConfig] GET_MODULES');

export const getModulesSuccess = createAction(
  '[commonConfig] GET_MODULES_SUCCESS',
  props<{ modules: Modules[] }>()
);

export const getThemes = createAction('[commonConfig] GET_THEMES');

export const getThemesSuccess = createAction(
  '[commonConfig] GET_THEMES_SUCCESS',
  props<{ themes: Theme[] }>()
);

export const getDateTimeFormats = createAction('[commonConfig] GET_DATE_AND_TIME_FORMATS');

export const getDateTimeFormats_SUCCESS = createAction(
  '[commonConfig] GET_DATE_AND_TIME_FORMATS_SUCCESS',
  props<{ dateTimeFormates: DateTimeFormat }>()
);

export const getUserNodeConfig = createAction(
  '[commonConfig] GET_USER_NODE_CONFIG',
  props<{ rootNodeId: string }>()
);

export const getCurrentUserTheme = createAction(
  '[commonConfig] GET_CURRENT_USER_THEME',
  props<{ currentUserTheme: NodeThemeSetting }>()
);

export const getOrgCode = createAction('[commonConfig] GET_ORG_CODE', props<{ nodeId: string }>());

export const getOrgCodeSuccess = createAction(
  '[commonConfig] GET_ORG_CODE_SUCCESS',
  props<{ orgCode: string }>()
);

export const getOrgDetails = createAction(
  '[commonConfig] GET_ORG_DETAILS',
  props<{ orgId: string }>()
);

export const getOrgDetailsSuccess = createAction(
  '[commonConfig] GET_ORG_DETAILS_SUCCESS',
  props<{ orgDetails: Node }>()
);
