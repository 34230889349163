// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  mainHost: 'dev.graphene-pmc.com',
  oldHost: 'dev10520.ciep-pmcretail.com',
  apiUrl: 'https://api.dev10520.ciep-pmcretail.com',
  environmentName: 'development',
  displayEnvName: true,
  dbSyncDomain: 'ws://LBSyncGateway-e3c3c47ed80fdd96.elb.eu-west-1.amazonaws.com/',
  chinaApiUrl: 'https://api.ciep-pmcretail.cn',
  engage_bucket: 'ciep-engage-dev'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
