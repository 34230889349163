import { Injectable } from '@angular/core';

@Injectable()
export class PersistanceService {
  constructor() {}

  set(key: string, data: any): void {
    try {
      localStorage.setItem(key, JSON.stringify(data));
    } catch (e) {
      console.error('Error saving to localStorage', e);
    }
  }

  get(key: string) {
    try {
      return JSON.parse(localStorage.getItem(key));
    } catch (e) {
      return null;
    }
  }

  setAppSetting(data) {
    this.set('APP_SETTING', data);
  }

  getAppSetting() {
    return this.get('APP_SETTING');
  }

  removeItems() {
    localStorage.removeItem('SESSION');
    localStorage.removeItem('APP_SETTING');
  }

  removeAllItems() {
    localStorage.clear();
  }
}
