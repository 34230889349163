import { PERMISSION } from '../../auth/has-permission/has-permission-directive';

export const NODE_ROUTES_BASE_PATH = 'structure';

export const NODE_ROUTES_NAME = {
  HIERARCHY: {
    path: 'hierarchy',
    name: 'structure_sub_menu.hierarchy',
    visibile: true,
    expectedPermission: [PERMISSION.LIST_NODE_STRUCTURE]
  },
  CREATE: {
    path: 'create',
    name: 'structure_sub_menu.add_node',
    visibile: true,
    expectedPermission: [PERMISSION.CREATE_NODE]
  },
  VIEW: {
    path: ':id/view',
    name: 'structure_sub_menu.view_node',
    visible: false,
    expectedPermission: [PERMISSION.VIEW_NODE]
  },
  EDIT: {
    path: ':id/edit',
    name: 'structure_sub_menu.edit_node',
    visible: false,
    expectedPermission: [PERMISSION.EDIT_NODE]
  }
};
