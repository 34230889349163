import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { LocalStorageJwtService } from './local-storage-jwt.service';
import { AuthFacade } from './state/auth.facade';
import { UserLogin } from './state/auth.model';
@Injectable()
export class AuthGuardService implements CanActivate, CanLoad {
  constructor(
    private storage: LocalStorageJwtService,
    private router: Router,
    private authService: AuthService,
    private authFacade: AuthFacade
  ) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    this.authService.redirectUrl = state.url;
    return this.validAuth();
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
    this.authService.redirectUrl = segments.reduce((path, currentSegment) => {
      return `${path}/${currentSegment.path}`;
    }, '');
    return this.validAuth();
  }

  private validAuth(): Observable<boolean> {
    return of(this.storage.getItem()).pipe(
      map((token) => {
        console.log('window.location.hostname = ', window.location.hostname);
        if (!token) {
          this.router.navigate(['/login']);
          return false;
        }
        return true;
      }),
      take(1)
    );
  }
}
