import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CBDataType, NodeType } from '@shared/interfaces';
import { LocalStorageJwtService } from 'src/app/feature/auth/local-storage-jwt.service';
import * as CommonConfigActions from './common-config.actions';
import { CommonConfigState } from './common-config.reducer';
import { commonConfigQuery } from './common-config.selectors';

@Injectable()
export class CommonConfigFacade {
  constructor(
    private store: Store<CommonConfigState>,
    private localStorageJwtService: LocalStorageJwtService
  ) {}
  common$ = this.store.select(commonConfigQuery.getCommonConfig);
  currencies$ = this.store.select(commonConfigQuery.getCurrencies);
  languages$ = this.store.select(commonConfigQuery.getLanguages);
  longDateFormats$ = this.store.select(commonConfigQuery.getLongDateFormats);
  longTimeFormats$ = this.store.select(commonConfigQuery.getLongTimeFormats);
  modules$ = this.store.select(commonConfigQuery.getModules);
  purge$ = this.store.select(commonConfigQuery.getPurge);
  shortDateFormats$ = this.store.select(commonConfigQuery.getShortDateFormats);
  shortTimeFormats$ = this.store.select(commonConfigQuery.getShortTimeFormats);
  themes$ = this.store.select(commonConfigQuery.getThemes);
  timezones$ = this.store.select(commonConfigQuery.getTimezones);
  countries$ = this.store.select(commonConfigQuery.getCountries);
  currentUserTheme$ = this.store.select(commonConfigQuery.getCurrentUserTheme);
  getOrgDetails$ = this.store.select(commonConfigQuery.getOrgDetails);

  commonConfiguration() {
    try {
      const currentUser = this.localStorageJwtService.getCurrentUser();
      // Fething countries and themes all for common irrespective of user type / root node
      this.countries();
      this.themes();
      // if (currentUser.rootNode === NodeType.GLOBAL) {
      this.currencies();
      this.language();
      this.dateAndTimesFormats();
      this.modules();
      this.purge();
      this.timeZones();
      // } else {
      //   console.log(
      //     `Fetch from Node Config base on Root Node ${currentUser.rootNode} and Root Node Id "Pending to Fetch"`
      //   );
      if (currentUser.rootNodeId && currentUser.rootNode !== NodeType.GLOBAL) {
        this.getNodeConfig(currentUser.rootNodeId.replace(`${CBDataType.NODE}::`, ''));
      }
      if (currentUser.organizationId && currentUser.rootNode !== NodeType.GLOBAL) {
        this.getOrgCode(
          currentUser.organizationId ? currentUser.organizationId.split('::')[1] : ''
        );
      }
      this.getOrgDetails(
        currentUser.organizationId ? currentUser.organizationId.split('::')[1] : ''
      );
      // }
    } catch (ex) {
      console.error(ex);
    }
  }

  currencies() {
    this.store.dispatch(CommonConfigActions.getCurrencies());
  }

  language() {
    this.store.dispatch(CommonConfigActions.getLanguages());
  }

  dateAndTimesFormats() {
    this.store.dispatch(CommonConfigActions.getDateTimeFormats());
  }

  modules() {
    this.store.dispatch(CommonConfigActions.getModules());
  }

  purge() {
    this.store.dispatch(CommonConfigActions.getPurge());
  }

  themes() {
    this.store.dispatch(CommonConfigActions.getThemes());
  }

  timeZones() {
    this.store.dispatch(CommonConfigActions.getTimeZones());
  }

  countries() {
    this.store.dispatch(CommonConfigActions.getCountries());
  }

  getNodeConfig(rootNodeId: string) {
    this.store.dispatch(CommonConfigActions.getUserNodeConfig({ rootNodeId }));
  }

  getOrgCode(nodeId: string) {
    this.store.dispatch(CommonConfigActions.getOrgCode({ nodeId }));
  }

  getOrgDetails(orgId: string) {
    this.store.dispatch(CommonConfigActions.getOrgDetails({ orgId }));
  }
}
