import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DisableControlDirective } from '@directives/disable-control-directive';
import { NumberOnlyDirective } from '@directives/number-only-directive';
import { TranslateModule } from '@ngx-translate/core';
import {
  AppMatSelectSearchModule,
  LoaderModule,
  LoadingBarModule,
  MenuitemsModule,
  RadioGroupModule,
  SpinnerModule,
  TreeviewModule,
  PaymentTenderModule
} from '@pmcretail/angular-corelib';
import { TranslationLoaderService } from '@services/translation-loader.service';
import { InlineSVGModule } from 'ng-inline-svg';
// https://www.npmjs.com/package/ng2-panzoom
import { Ng2PanZoomModule } from 'ng2-panzoom';
import { ToastrModule } from 'ngx-toastr';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { OrganizationChartModule } from 'primeng/organizationchart';
// ngprime
import { TableModule } from 'primeng/table';
import { TreeTableModule } from 'primeng/treetable';
import { HasPermissionDirectiveModule } from '../feature/auth/has-permission/has-permission-directive.module';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ConfirmDialogService } from './confirm-dialog/confirm-dialog.service';
import { CurrencyDropdownComponent } from './currency-dropdown/currency-dropdown.component';
import { FileUploadComponent } from './fileupload/file-upload.component';
import { SvgFileUploadComponent } from './svgfileupload/file-upload.component';

import en from './i18n/en.json';
import zhcn from './i18n/zh-CN.json';
import fr from './i18n/fr.json';
import nl from './i18n/nl.json';
import jajp from './i18n/ja-JP.json';
import it from './i18n/it.json';
import de from './i18n/de.json';
import { LanguageDropdownComponent } from './language-dropdown/language-dropdown.component';
import { LongDateDropdownComponent } from './long-date-dropdown/long-date-dropdown.component';
import { LongTimeDropdownComponent } from './long-time-dropdown/long-time-dropdown.component';
import { MaterialModule } from './material.module';
import { ShortdateDropdownComponent } from './shortdate-dropdown/shortdate-dropdown.component';
import { ShorttimeDropdownComponent } from './shorttime-dropdown/shorttime-dropdown.component'; // ngprime
import { TimezoneDropdownComponent } from './timezone-dropdown/timezone-dropdown.component';
import { ExceptionesComponent } from './exceptiones/exceptiones.component';
import { DatePipe } from '@angular/common';
import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule
} from '@angular-material-components/datetime-picker';
import { SelectApiPopupComponent } from './select-api-popup/select-api-popup.component';
import { ModuleDisplayNamePipe } from './pipes/module-display-name.component';
import { ModuleDisplayLogoPipe } from './pipes/module-display-logo.component';
import { HotKeysComponent } from './module-assignment/hotkeys/hotkeys.component';
import { AddHotKeyDialogComponent } from './module-assignment/hotkeys/add-hotkey-dialog/add-hotkey-dialog.component';
import { HotkeyService } from './module-assignment/hotkeys/hotkeys.service';
import { SelectQueuePopupComponent } from './select-queue-popup/select-queue-popup.component';
import { SelectTransactionExportPopupComponent } from './select-transaction-export-popup/select-transaction-export-popup.component';
import { ReceiptFileUploadComponent } from './receiptfileupload/file-upload.component';
import { SotkSettingsComponent } from './module-assignment/sotk-settings/sotk-settings.component';
import { ReceiptSettingsComponent } from './module-assignment/receipt-settings/receipt-settings.component';
import { ApiDetailsSettingsComponent } from './module-assignment/magento-provider-details-settings/magento-provider-details-settings.component';
import { NetsuitApiDetailsSettingsComponent } from './module-assignment/netsuit-provider-details-settings/netsuit-provider-details-settings.component';
import { AptosApiDetailsSettingsComponent } from './module-assignment/aptos-provider-details-settings/aptos-provider-details-settings.component';
import { SalesExportIslandPacificComponent } from './module-assignment/ebasket-settings/sales-export/island-pacific-provider/island-pacific-provider.component';
// tslint:disable-next-line: import-spacing
import { RjProviderDetailsSettingsComponent } from './module-assignment/rj-provider-details-settings/rj-provider-details-settings.component';
import { CrestEngagementApiDetailsSettingsComponent } from './module-assignment/crestEngagement-provider-details-settings/crestEngagement-provider-details-settings.component';

import { SalesExportMagentoApiDetailsSettingsComponent } from './module-assignment/sales-export-magento-provider-details-settings/magento-provider-details-settings.component';
import { SalesExportNetsuitApiDetailsSettingsComponent } from './module-assignment/sales-export-netsuit-provider-details-settings/netsuit-provider-details-settings.component';
import { ParasparApiDetailsSettingsComponent } from './module-assignment/paraspar-provider-details-settings/paraspar-provider-details-settings.component';
import { MagentoV1V2DetailsComponent } from './module-assignment/magentov1v2-provider-details-settings/magentov1v2-provider-details-settings.component';
import { AdyenDetailsSettingsComponent } from './module-assignment/adyen-provider-details-settings/adyen-provider-details-settings.component';
import { ShirtPrintingModelComponent } from './module-assignment/shirt-printing-model/shirt-printing-model.component';
import { MaleShirtAndShortsConfigurationComponent } from './module-assignment/man-shirt-and-shorts-configuration/male-shirt-and-shorts-configuration.component';
import { AddMaleShirtAndShortDialogComponent } from './module-assignment/man-shirt-and-shorts-configuration/add-maleShirt-And-Shorts-dialog/add-maleShirt-And-Shorts-dialog.component';
import { AddNameAndNumberDialogComponent } from './module-assignment/man-shirt-and-shorts-configuration/add-name-and-number-dialog/add-name-and-number-dialog.component';
import { MaleShirtAndShortsService } from './module-assignment/man-shirt-and-shorts-configuration/male-shirt-and-shorts-configuration.service';
import { FemaleShirtAndShortsService } from './module-assignment/female-shirt-and-shorts-configuration/female-shirt-and-shorts-configuration.service';
import { FemaleShirtAndShortsConfigurationComponent } from './module-assignment/female-shirt-and-shorts-configuration/female-shirt-and-shorts-configuration.component';
import { AddFemaleShirtAndShortDialogComponent } from './module-assignment/female-shirt-and-shorts-configuration/add-femaleShirt-And-Shorts-dialog/add-femaleShirt-And-Shorts-dialog.component';
import { AddFemaleNameAndNumberDialogComponent } from './module-assignment/female-shirt-and-shorts-configuration/add-name-and-number-dialog/add-name-and-number-dialog.component';
import { CronJobFrequencyConfigurationComponent1 } from './module-assignment/cron-job-frequency-configuration/cron-job-frequency-configuration.component';
import { TMGiveXGiftCardConfigurationComponent } from './module-assignment/tm-features/gift-card/gift-card-providers/givex-gift-card-configuration/givex-gift-configuration.component';
import { ConfigSettingService } from '../feature/organization/shared/configuration-settings/configuration-settings.service';
import { TMGiftCardCommonConfigurationComponent } from './module-assignment/tm-features/gift-card/gift-card-providers/gift-card-common-configuration/gift-card-common-configuration.component';
import { SalesforceProviderInventoryDetailsSettingsComponent } from './module-assignment/salesforce-provider-inventory-details-settings/salesforce-provider-inventory-details-settings/salesforce-provider-inventory-details-settings.component';
import { SalesforceApiDetailsSettingsComponent } from './module-assignment/salesforce-provider-details-settings/salesforce-provider-details-settings.component';
import { SalesforceXmlProviderDetailsSettingsComponent } from './module-assignment/salesforce-xml-provider-details-settings/salesforce-xml-provider-details-settings.component';
import { ProductImportSalesforceProviderDetailsSettingsComponent } from './module-assignment/product-import-salesforce-provider-details-settings/product-import-salesforce-provider-details-settings.component';
import { CronJobConfigurationComponent } from './module-assignment/cron-job-configuration/cron-job-configuration.component';
import { SalesforceOSUProviderDetailsSettingsComponent } from './module-assignment/salesforce-osu-provider-details-settings/salesforce-osu-provider-details-settings.component';
import { PaymentTenderConfigurationComponent } from './module-assignment/payment-tender-configuration/payment-tender-configuration.component';
import { XiatechSalesExportProviderDetailsSettingsComponent } from './module-assignment/xiatech-salesexport-provider-details-settings/xiatech-salesexport-provider-details-settings.component';
import { ImprintProviderDetailsSettingsComponent } from './module-assignment/imprint-provider-details-settings/imprint-provider-details-settings.component';
import { CustomerHubDetailsSettingsComponent } from './module-assignment/ebasket-features/customer-hub-details-settings/customer-hub-provider-details-settings.component';
import { CustomerHubImportExportSettingsComponent } from './module-assignment/ebasket-features/customer-hub/customer-hub-import-export-settings/customer-hub-import-export-settings.component';
import { NetsuiteCustomerHubDetailsSettingsComponent } from './module-assignment/ebasket-features/customer-hub/customer-hub-providers/netsuite-customer-hub-settings/netsuite-customer-hub-details-settings.component';
import { MajorelCustomerHubDetailsSettingsComponent } from './module-assignment/ebasket-features/customer-hub/customer-hub-providers/majorel-customer-hub-settings/majorel-customer-hub-details-settings.component';
import { XiatechCustomerHubDetailsSettingsComponent } from './module-assignment/ebasket-features/customer-hub/customer-hub-providers/xiatech-customer-hub-settings/xiatech-customer-hub-details-settings.component';
import { TMNetsuiteGiftCardConfigurationComponent } from './module-assignment/tm-features/gift-card/gift-card-providers/netsuite-gift-card-configuration/netsuite-gift-configuration.component';
import { GiftCardSettingsComponent } from './module-assignment/tm-features/gift-card/gift-card-setting/gift-card-settings.component';
import { AxConfigDetailsSettingsComponent } from './module-assignment/ebasket-features/customer-hub/customer-hub-providers/ax-configDetails-settings/ax-configDetails-settings.component';
import { TwoDigitDecimaNumberDirective } from '@directives/two-decimal-number-directive';
import { PettyCashRJProviderDetailsComponent } from './module-assignment/tm-features/petty-cash/petty-cash-providers/rj-provider-details-settings/rj-provider-details-settings.component';
import { EngageSettingsComponent } from './module-assignment/engage-settings/engage-settings.component';
import { TableComponent } from './table/table.component';
import { SharedService } from './shared.service';
@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    LoadingBarModule,
    LoaderModule,
    MenuitemsModule,
    AppMatSelectSearchModule,
    InlineSVGModule.forRoot(),
    FlexLayoutModule,
    ToastrModule.forRoot(), // ToastrModule added
    TranslateModule,
    TreeviewModule.forRoot(),
    TableModule, // ngprime
    OrganizationChartModule, // ngprime
    TreeTableModule, // ngprime
    CalendarModule, // ngprime
    DropdownModule, // ngprime
    SpinnerModule,
    RadioGroupModule,
    Ng2PanZoomModule, // zoom/pan
    HasPermissionDirectiveModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    NgxMatTimepickerModule,
    PaymentTenderModule
  ],
  declarations: [
    LanguageDropdownComponent,
    FileUploadComponent,
    SvgFileUploadComponent,
    ReceiptFileUploadComponent,
    ConfirmDialogComponent,
    ShortdateDropdownComponent,
    ShorttimeDropdownComponent,
    TableComponent,
    TimezoneDropdownComponent,
    LongDateDropdownComponent,
    LongTimeDropdownComponent,
    CurrencyDropdownComponent,
    DisableControlDirective,
    NumberOnlyDirective,
    TwoDigitDecimaNumberDirective,
    ExceptionesComponent,
    SelectApiPopupComponent,
    ModuleDisplayNamePipe,
    ModuleDisplayLogoPipe,
    HotKeysComponent,
    ShirtPrintingModelComponent,
    MaleShirtAndShortsConfigurationComponent,
    FemaleShirtAndShortsConfigurationComponent,
    AddHotKeyDialogComponent,
    AddMaleShirtAndShortDialogComponent,
    AddFemaleShirtAndShortDialogComponent,
    AddNameAndNumberDialogComponent,
    AddFemaleNameAndNumberDialogComponent,
    SelectQueuePopupComponent,
    SelectTransactionExportPopupComponent,
    SotkSettingsComponent,
    EngageSettingsComponent,
    ReceiptSettingsComponent,
    ApiDetailsSettingsComponent,
    AdyenDetailsSettingsComponent,
    NetsuitApiDetailsSettingsComponent,
    AptosApiDetailsSettingsComponent,
    SalesExportIslandPacificComponent,
    RjProviderDetailsSettingsComponent,
    PettyCashRJProviderDetailsComponent,
    CrestEngagementApiDetailsSettingsComponent,
    SalesExportMagentoApiDetailsSettingsComponent,
    SalesExportNetsuitApiDetailsSettingsComponent,
    ParasparApiDetailsSettingsComponent,
    MagentoV1V2DetailsComponent,
    CronJobFrequencyConfigurationComponent1,
    TMGiveXGiftCardConfigurationComponent,
    TMNetsuiteGiftCardConfigurationComponent,
    GiftCardSettingsComponent,
    TMGiftCardCommonConfigurationComponent,
    SalesforceProviderInventoryDetailsSettingsComponent,
    ProductImportSalesforceProviderDetailsSettingsComponent,
    CronJobConfigurationComponent,
    SalesforceApiDetailsSettingsComponent,
    SalesforceXmlProviderDetailsSettingsComponent,
    SalesforceOSUProviderDetailsSettingsComponent,
    XiatechSalesExportProviderDetailsSettingsComponent,
    PaymentTenderConfigurationComponent,
    ImprintProviderDetailsSettingsComponent,
    CustomerHubDetailsSettingsComponent,
    CustomerHubImportExportSettingsComponent,
    NetsuiteCustomerHubDetailsSettingsComponent,
    MajorelCustomerHubDetailsSettingsComponent,
    XiatechCustomerHubDetailsSettingsComponent,
    AxConfigDetailsSettingsComponent
  ],
  schemas: [],
  providers: [
    ConfirmDialogService,
    DatePipe,
    HotkeyService,
    ModuleDisplayLogoPipe,
    MaleShirtAndShortsService,
    FemaleShirtAndShortsService,
    ConfigSettingService,
    SharedService
  ],
  entryComponents: [
    ConfirmDialogComponent,
    SelectApiPopupComponent,
    AddHotKeyDialogComponent,
    AddMaleShirtAndShortDialogComponent,
    AddFemaleShirtAndShortDialogComponent,
    AddNameAndNumberDialogComponent,
    AddFemaleNameAndNumberDialogComponent,
    SelectQueuePopupComponent,
    SelectTransactionExportPopupComponent
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    InlineSVGModule,
    FlexLayoutModule,
    LoadingBarModule,
    ConfirmDialogComponent,
    LoaderModule,
    MenuitemsModule,
    AppMatSelectSearchModule,
    SpinnerModule,
    TranslateModule,
    ToastrModule,
    FileUploadComponent,
    SvgFileUploadComponent,
    ReceiptFileUploadComponent,
    TreeviewModule,

    TableModule, // ngprime
    OrganizationChartModule, // ngprime
    TreeTableModule, // ng prime
    CalendarModule, // ngprime
    DropdownModule, // ngprime
    LanguageDropdownComponent,
    ShortdateDropdownComponent,
    ShorttimeDropdownComponent,
    TableComponent,
    TimezoneDropdownComponent,
    LongDateDropdownComponent,
    LongTimeDropdownComponent,
    CurrencyDropdownComponent,
    DisableControlDirective,
    NumberOnlyDirective,
    TwoDigitDecimaNumberDirective,
    RadioGroupModule,
    Ng2PanZoomModule, // zoom/pan
    HasPermissionDirectiveModule,
    ExceptionesComponent,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    NgxMatTimepickerModule,
    ModuleDisplayNamePipe,
    ModuleDisplayLogoPipe,
    HotKeysComponent,
    ShirtPrintingModelComponent,
    MaleShirtAndShortsConfigurationComponent,
    FemaleShirtAndShortsConfigurationComponent,
    AddHotKeyDialogComponent,
    AddMaleShirtAndShortDialogComponent,
    AddFemaleShirtAndShortDialogComponent,
    AddNameAndNumberDialogComponent,
    AddFemaleNameAndNumberDialogComponent,
    SotkSettingsComponent,
    EngageSettingsComponent,
    ApiDetailsSettingsComponent,
    AdyenDetailsSettingsComponent,
    NetsuitApiDetailsSettingsComponent,
    AptosApiDetailsSettingsComponent,
    SalesforceXmlProviderDetailsSettingsComponent,
    SalesforceOSUProviderDetailsSettingsComponent,
    XiatechSalesExportProviderDetailsSettingsComponent,
    ReceiptSettingsComponent,
    SalesExportIslandPacificComponent,
    RjProviderDetailsSettingsComponent,
    PettyCashRJProviderDetailsComponent,
    CrestEngagementApiDetailsSettingsComponent,
    SalesExportMagentoApiDetailsSettingsComponent,
    MagentoV1V2DetailsComponent,
    SalesExportNetsuitApiDetailsSettingsComponent,
    ParasparApiDetailsSettingsComponent,
    CronJobFrequencyConfigurationComponent1,
    TMGiveXGiftCardConfigurationComponent,
    TMNetsuiteGiftCardConfigurationComponent,
    GiftCardSettingsComponent,
    TMGiftCardCommonConfigurationComponent,
    SalesforceProviderInventoryDetailsSettingsComponent,
    SalesforceApiDetailsSettingsComponent,
    ProductImportSalesforceProviderDetailsSettingsComponent,
    CronJobConfigurationComponent,
    TMGiftCardCommonConfigurationComponent,
    PaymentTenderModule,
    PaymentTenderConfigurationComponent,
    ImprintProviderDetailsSettingsComponent,
    CustomerHubDetailsSettingsComponent,
    CustomerHubImportExportSettingsComponent,
    NetsuiteCustomerHubDetailsSettingsComponent,
    MajorelCustomerHubDetailsSettingsComponent,
    XiatechCustomerHubDetailsSettingsComponent,
    AxConfigDetailsSettingsComponent
  ]
})
export class SharedModule {
  constructor(private translateService: TranslationLoaderService) {
    this.translateService.loadTranslations(fr);
    this.translateService.loadTranslations(en);
    this.translateService.loadTranslations(zhcn);
    this.translateService.loadTranslations(nl);
    this.translateService.loadTranslations(jajp);
    this.translateService.loadTranslations(it);
    this.translateService.loadTranslations(de);
  }
}
