import { FormGroup } from '@angular/forms';

export interface IUserLogin {
  username: string;
  password: string;
  domainName?: string;
}

export interface IApiResponse {
  status: string;
  message?: string | undefined;
  messageCode?: string | undefined;
}

export interface IMenu {
  name: string;
  icon?: string;
  url?: string;
  svg?: string;
  childs?: IMenu[];
}
export interface ICompany {
  id: string;
  name: string;
  type?: string;
}

export interface ITradingRegion {
  id: string;
  name: string;
  type?: string;
}

export interface IStoreGroup {
  id: string;
  name: string;
  type?: string;
}

export interface IStore {
  id: string;
  name: string;
  type?: string;
}

export interface IDrawerGroup {
  id: string;
  name: string;
  drawerGroupType: string;
  type?: string;
}

export interface NodeDropDown {
  text: string;
  // tslint:disable-next-line: ban-types
  value: Object | string;
  child?: NodeDropDown[];
}
export enum NodeType {
  GLOBAL = 'GLOBAL',
  ORGANIZATION = 'ORGANIZATION',
  COMPANY = 'COMPANY',
  TRADING_REGION = 'TRADING_REGION',
  STORE_GROUP = 'STORE_GROUP',
  SUB_STORE_GROUP = 'SUB_STORE_GROUP',
  STORE = 'STORE'
}

export enum DashboardAccessType {
  GLOBAL = 'Global Dashboard',
  ROSS = 'Ross Dashboard',
  ORGANIZATION = 'Org Dashboard',
  COMPANY = 'Comp Dashboard',
  TRADING_REGION = 'TR Dashboard',
  STORE_GROUP = 'S.Grp Dashboard',
  SUB_STORE_GROUP = 'S.Manager Dashboard',
  STORE = 'Store User Dashboard'
}

export enum CBDataType {
  NODE = 'NODE'
}

export enum NodeLevelType {
  EQUAL = 'equal',
  GREATER = 'greater',
  LOWER = 'lower',
  NOT_EQUAL = 'notEqual'
}

export interface INodeStructure {
  isOverride: boolean;
  name: string;
  nodeId: string;
  nodeType: string;
  parentId: string;
  status: boolean;
  checked?: boolean;
  data?: any;
  expanded?: boolean;
  label?: string;
  styleClass?: string;
  region?: IRegion[];
}

export interface IThemeSettings {
  logoURL: string;
  theme: string[];
  fontFamily: string;
  fontSize: string;
  textColour: string;
  backgroundColor: string;
  neutralColour: string;
  primaryColour: string;
  contractPrimaryColour: string;
  secondaryColour: string;
  contractSecondaryColour: string;
  warnColour: string;
  contractWarnColour: string;
  successColour: string;
  contrastSuccessColour: string;
  errorColour: string;
  contrastErrorColour: string;
  disabledColour: string;
  contrastDisabledColour: string;
  indentedBackground: string;
  name?: string;
  isOverride?: boolean;
}

export interface ICountry {
  country_code: string;
  id: string;
  iso_code: string;
  iso_code2: string;
  name: string;
  status: boolean;
}
export interface IUser {
  orgConfig?: any;
  id?: string;
  profilePicture?: string;
  userName: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  secretWord?: string;
  dateOfBirth?: Date;
  countryCode?: string;
  phoneNumber?: number;
  emailId: string;
  street1?: string;
  street2?: string;
  city?: string;
  country: ICountry;
  postcode?: string;
  userRole: any[];
  language: ILanguage;
  timeZone?: string;
  communicationEmail?: boolean;
  communicationSms?: boolean;
  communicationPush?: boolean;
  lastActive?: Date;
  status?: boolean;
  manualPassword: boolean;
  password?: string;
  type?: string;
  created_date?: Date;
  created_by?: string;
  updated_date?: Date;
  updated_by?: string;
  confirmPassword?: string;
  nodeStructure?: INodeStructure[];
  dashboardAccess?: string;
  rootNode?: NodeType;
  pin: number;
  rjSetting?: IRjSetting;
  printableName?: string;
  locale?: string;
  position?: string;
  resetToken?: any;
  resetDate?: any;
  isResetTokenUsed?: any;
  // added this field for delete unused data
  locationType?: string;
  trainingUser?: boolean;
  leftHanded?: boolean;
  domainName?: string;
}

export interface IRjSetting {
  locationType?: string;
  trainingUser?: boolean;
  leftHanded?: boolean;
}
export interface IPaginationResponse<T> {
  status?: string;
  message?: string | undefined;
  messageCode?: string | undefined;
  totalCount?: number;
  offset?: number | undefined;
  limit?: number | undefined;
  result?: T | undefined;
}

/**
 * This abstract class is meant for disable the form/component
 */
export class CanDisable {
  public form: FormGroup;
}

export interface IOrgUserList {
  id: string;
  firstName: string;
  lastName: string;
  userName: string;
  company: string;
  role: string;
  status: string;
}

export interface IOrganizationAdminUser {
  firstName: string;
  lastName?: string;
  userName: string;
  password: string;
  confirmPassword: string;
  secretWord?: number;
  email: string;
  contactInfo?: number;
  language: string;
  countryCode: string;
  role: string;
}

export interface IOrganization {
  id?: string;
  name?: string;
  image?: string; // name and image used organization-popup which is used in show all organizations.
  address?: string;
  country?: string;
  emailId?: string;
  defaultLanguage?: string;
  status?: boolean;
  type?: string;
  created_date?: Date;
  created_by?: string;
  updated_date?: Date;
  updated_by?: string;
  accessUrl?: string;
}

export interface IProfilePhotoUpdate {
  url: string;
}

/*export interface ILanguage {
  id: string;
  code: string;
  name: string;
  isOverride?: boolean;
}*/

export interface IUserLoginResponse {
  idToken: string;
  accessToken: string;
  refreshToken: string;
}

export interface ICurrentUser {
  userId: string;
  username: string;
  email: string;
  roles: string[];
  isLoggedIn: boolean;
  rootNode?: string;
}

export interface IPasswordResetRequest {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
  updatedBy: string;
  accessKey: string;
}

export interface IApplications {
  id: string;
  name: string;
  accessUrl: string;
  logoUrl: string;
  shortName: string;
  domain: string;
  status: boolean;
  accessRight: string[];
}
/*export interface IDateTimeFormat {
  dateFormat: string;
  dateId: number;
  id: number;
  isDefaultSelected: boolean;
  timeFormat: string;
  timeId: number;
}

export interface IDateTimeFormats {
  shortDateFormats: IFormat[];
  shortTimeFormats: IFormat[];
  longDateFormats: IFormat[];
  longTimeFormats: IFormat[];
}

export interface IFormat {
  id: number;
  format: string;
}

export interface IPurge {
  id: string;
  value: number;
}

export interface ITimezone {
  id: string;
  name: string;
  abbreviation: string;
  offset: string;
}

export interface ICurrency {
  id: string;
  code: string;
}*/

// Organization config related interfaces
/*export interface IOrgCurrency {
  id: string;
  code: string;
}

export interface IOrgCountry {
  id: string;
  name: string;
}

export interface IOrgLanguage {
  id: string;
  name: string;
  isOverride?: boolean;
}

export interface IDate {
  dateId: string;
  dateFormat: string;
}

export interface ITime {
  timeId: string;
  timeFormat: string;
}

export interface IOrgLongDateTime {
  id?: string;
  dateFormat: IDate;
  timeFormat: ITime;
}

export interface IOrgShortDateTime {
  dateFormat: IDate;
  timeFormat: ITime;
  isOverride?: boolean;
}

export interface IOrgTimezone {
  id: string;
  name: string;
  abbreviation: string;
  offset: string;
  isOverride?: boolean;
}

export interface IStoreTiming {
  day: string;
  openTime: string;
  closeTime: string;
  status: boolean;
}

export interface IStoreTimingException {
  from: string;
  to: string;
  reason: string;
  openTime: string;
  closeTime: string;
}

export interface IConfigurationSettings {
  defaultLanguage: IOrgLanguage;
  supportedLanguages: IOrgLanguage[];
  baseCurrency: IOrgCurrency;
  supportedCurrencies: IOrgCurrency[];
  timezone: IOrgTimezone;
  currencyFormat: string;
  shortDateTimeFormat: IOrgShortDateTime;
  longDateTimeFormat: IOrgLongDateTime;
  firstDayOfWeek: string;
  storeTimings: IStoreTiming[];
  exceptions: IStoreTimingException[];
  purge: number;
}*/

export interface IConfigurationSettings {
  baseCurrency: ICurrency;
  supportedCurrencies: ISupportedCurrencies;
  defaultLanguage: ILanguage;
  supportedLanguages: ISupportedLanguages;
  currencyFormat: ICurrencyFormat;
  timezone: ITimezone;
  shortDateFormat: IDateTime;
  shortTimeFormat: IDateTime;
  longDateFormat: IDateTime;
  longTimeFormat: IDateTime;
  firstDayOfWeek: IWeekDay;
  storeTimings: IStoreTimingWrapper;
  exceptions: IStoreTimingExceptionWrapper;
  purge: IPurge;
  helpDeskEmail: IHelpDeskEmail;
  vatNumber?: IVatNumber;
  authenticationSettings?: IAdfsSettings;
  scim?: IScim;
  domainSettings?: IDomainStatus;
  region?: IRegion[];
  productScope?: string;
  parentRegion?: IRegion[];
  type?: string;
}

/*export interface IConfigurationSettingsDto {
  baseCurrency: ICurrency;
  supportedCurrencies: ISupportedCurrencies;
  defaultLanguage: ILanguage;
  supportedLanguages: ISupportedLanguages;
  currencyFormat: ICurrencyFormat;
  timezone: ITimezone;
  shortDateFormat: IDateTime;
  shortTimeFormat: IDateTime;
  longDateFormat: IDateTime;
  longTimeFormat: IDateTime;
  firstDayOfWeek: IWeekDay;
  storeTimings: IStoreTimingWrapper;
  exceptions: IStoreTimingExceptionWrapper;
  purge: IPurge;
}*/

export interface ICurrency {
  id: string;
  name?: string;
  code: string;
  symbol?: string;
  origin?: string;
  status?: boolean;
  isOverride?: boolean;
}

export interface ISupportedCurrencies {
  currencies: ICurrency[];
  isOverride?: boolean;
}

export interface ILanguage {
  id: string;
  code?: string;
  name: string;
  isOverride?: boolean;
  status?: boolean;
}

export interface ISupportedLanguages {
  languages: ILanguage[];
  isOverride?: boolean;
}

export interface ICurrencyFormat {
  id: string;
  format: string;
  isOverride?: boolean;
}

export interface ITimezone {
  id: string;
  name: string;
  abbreviation: string;
  offset: string;
  isOverride?: boolean;
}

export interface IDateTime {
  id: string;
  format: string;
  isDefault?: boolean;
  isOverride?: boolean;
}

export interface IWeekDay {
  id: string;
  day: string;
  isOverride?: boolean;
}

export interface IStoreTimingWrapper {
  storeTimings: IStoreTiming[];
  isOverride?: boolean;
}

export interface IStoreTiming {
  day: string;
  openTime: string;
  closeTime: string;
  status: boolean;
  isOverride?: boolean;
}

export interface IStoreTimingExceptionWrapper {
  exceptions: IStoreTimingException[];
  isOverride?: boolean;
}

export interface IStoreTimingException {
  from: Date;
  to: Date;
  reason: string;
  openTime: string;
  closeTime: string;
  isOverride?: boolean;
}

export interface IPurge {
  id?: string;
  value: number;
  isOverride?: boolean;
}
export interface ICiepName {
  name: string;
  value: string;
}
export interface IHelpDeskEmail {
  value: string;
  isOverride?: boolean;
}

export interface IVatNumber {
  value: string;
  isOverride?: boolean;
}

export interface IAdfsSettings {
  status: boolean;
  authenticationProtocol: string;
  authenticationProvider: string;
  identityProvider: string;
  userNamePrefix: string;
  authenticationDetails?: IAuthenticationDetails;
  authenticationDetailsKey?: string;
}

export interface IScim {
  status: boolean;
  removeUser: boolean;
  autoAssigning: string;
  customCompanyMapping: ICustomCompanyMapping;
}

export interface IDomainStatus {
  status: boolean;
  domains: IDomains[];
}
export interface IRegion {
  key: string;
  apiDomain: string;
  dbSyncDomain: string;
}
export interface IDomains {
  domainName: string;
}

export interface ICustomCompanyMapping {
  status: boolean;
  scimNodeConfiguration?: ICompanyNames[];
  companyNames?: ICompanyNames[];
}

export interface ICompanyNames {
  azureName: string;
  nodeId: string;
  nodeType: string;
  ciepName: string;
}

export interface IAuthenticationDetails {
  clientId: string;
  clientSecret: string;
  scope: string;
  grantType: string;
  loginUrl: string;
  authorisationUrl: string;
  issuer: string;
}
export interface IModule {
  accessUrl: string;
  default: boolean;
  domain: string;
  icon: string;
  id: string;
  name: string;
  shortName: string;
  status: boolean;
}

export interface IModuleDto {
  id: string;
  name: string;
  shortName?: string;
  status: boolean;
  isOverride?: boolean;
}

export interface ITransMgmtSettings {
  tmSettingConfig?: any;
  settingConfig?: any;
  reasonCodeConfig?: IReasonConfigWrapper;
  exchangeRateConfig?: IExchangeRateConfigWrapper;
  sessionConfig?: ISessionConfig;
  tenderTypeConfig?: ITenderTypeConfigWrapper;
}

export interface IFlexibleCardConfigurations {
  status?: boolean;
  cardPrefix?: string;
  minimumValue?: number;
  maximumValue?: number;
}

export interface IRJMgmtSettings {
  roleImportSettings: IEmbddedRJSettings;
  userExportSettings: IEmbddedRJSettings;
}
export interface IEBasketMgmtSettings {
  provider: string;
  url: string;
  isOverride?: boolean;
  salesExport?: boolean;
}

export interface IEBasketMgmtNewSettings {
  receiptLogo?: { logoUrl?: string; status: string };
  displaySettings?: any;
  saleFlow?: string;
  promotionApplicationOption?: IPromotionApplicationOption;
  maxCustomerLimit?: number;
  scanAutoclose?: boolean;
  allowProductReturnForPromotion?: any;
  simplifiedReceipts?: any;
  salesExport?: any;
  salesImport?: any;
  customerHub?: any;
  isOverride?: boolean;
  productImport?: any;
  splashScreen?: any;
  mixedSales?: ImixedSales;
  inventorySection?: any;
  fiscalCompliance?: any;
  taxsetupSection?: any;
}
export interface IPromotionApplicationOption {
  value: string;
}

export interface ImixedSales {
  status: boolean;
  loyaltyScheme?: ILoyaltyScheme;
  mixSaleProvider?: ImixSaleProvider;
  paymentSettings?: any;
  webOrder?: any;
  hotKeys?: any;
  inStore?: any;
  clickAndCollect?: any;
  salesImport?: any;
  authenticationDetails?: any;
  authenticationDetailsKey?: any;
  externalProvider?: any;
  subsidiary?: any;
  provider?: any;
  receiptSettings?: any;
  orderInquiry?: any;
  webReturns?: any;
  portalRefunds?: any;
  shipToStore?: any;
  addSalesperson?: any;
  shirtPrinting?: any;
  preAuthorisation?: boolean;
  disablePromotion?: boolean;
  couponCode?: boolean;
  productWastage?: boolean;
}
export interface ILoyaltyScheme {
  status: boolean;
  thresholdStampsValue?: number;
  predefinedCouponCode?: string;
  providers?: ILoyaltySchemeProviders[];
}
export interface ILoyaltySchemeProviders {
  provider: string;
  apiDetails?: [];
}

export interface ImixSaleProvider {
  status: boolean;
  salesExport?: any;
  inStore?: any;
  webOrder?: any;
  clickAndCollect?: any;
  salesImport?: any;
  shipToStore?: any;
  allowReturn?: any;
  allowExchange?: any;
  disablePromotion?: any;
  disableDiscount?: any;
  couponCode?: any;
}
export interface ITaxsetupSection {
  // status: boolean;
  type: string;
  independentPrice?: IIndependentPrice;
  isOverride: boolean;
}
export interface IIndependentPrice {
  import: ITaxImportSettings;
  taxOverride: boolean;
  taxInclusive: boolean;
}
export interface ITaxImportSettings {
  status: boolean;
  countryName?: string;
  details?: ICountryTaxDetails;
}
export interface ICountryTaxDetails {
  provider?: string;
  authId?: string;
}
export interface ICRESTEngagementMgmtSettings {
  crestAccessRights?: any;
  isOverride?: boolean;
}

export interface IT2BshowQRCode {
  showQRCode?: boolean;
}
export interface IT2BMgmtSettings {
  t2bCustomer?: boolean;
  t2bEmployee?: boolean;
  salesExport?: boolean;
  paymentConfiguration?: ID2SPaymentConfiguration;
  additionalCampaignDetails?: IAdditionalCampaignDetails;
}

export interface IAdditionalCampaignDetails {
  terminalId?: any;
  externalDeviceId?: any;
  externalTerminalId?: any;
}

export interface ID2SPaymentConfiguration {
  provider: string;
  authenticationDetails?: IPaymentProviderAuthenticationDetails;
  authenticationDetailsKey?: string;
}
export interface IPaymentProviderAuthenticationDetails {
  clientKey?: string;
  apiKey?: string;
  merchantAccount?: string;
  liveEndpointURLPrefix?: string;
}
export interface IEmbddedRJSettings {
  ftpUrl: string;
  ftpUserName: string;
  ftpPassword: string;
}
export interface IReasonConfig {
  reasonId?: string;
  reasonDescription?: string;
  reasonCode: string;
  reasonCategory: INameMap;
  reasonType: INameMap;
}

export interface IReasonConfigWrapper {
  configs: IReasonConfig[];
  isOverride?: boolean;
}

export interface IExchangeRateConfig {
  currency: ICurrency;
  isFixedRate?: boolean;
  fixedRateValue?: number;
  dateTime: Date;
}

export interface IExchangeRateConfigWrapper {
  configs: IExchangeRateConfig[];
  isOverride?: boolean;
}

export interface ISessionConfig {
  endTime: string;
  isOverride?: boolean;
}

export interface ITenderTypeConfig {
  dateTime: Date;
  tenderType: INameMap;
  subTenderType: INameMap;
}

export interface ITenderTypeConfigWrapper {
  configs: ITenderTypeConfig[];
  isOverride?: boolean;
}

export interface INameMap {
  id: string;
  name: string;
}

export interface IModule {
  accessUrl: string;
  default: boolean;
  domain: string;
  icon: string;
  id: string;
  name: string;
  shortName: string;
  status: boolean;
}

export interface IIdentifyToken {
  'custom:rootNode': string;
  sub: string;
  'custom:roles': string;
  email_verified: boolean;
  iss: string;
  'custom:user_id': string;
  phone_number_verified: boolean;
  'cognito:username': string;
  aud: string;
  event_id: string;
  token_use: string;
  auth_time: string;
  exp: string;
  iat: string;
  email: string;
}

export interface IAccessToken {
  sub: string;
  device_key: string;
  event_id: string;
  token_use: string;
  scope: string;
  auth_time: number;
  iss: string;
  exp: number;
  iat: number;
  jti: string;
  client_id: string;
  username: string;
}

export interface INode {
  id?: string;
  nodeId?: string;
  name?: string;
  parentId?: string;
  nodeType?: NodeType;
  storeCode?: string;
  countryCode?: string;
  phoneNumber?: string;
  emailId?: string;
  address1?: string;
  address2?: string;
  city?: string;
  country?: ICountry;
  description?: string;
  status?: boolean;
  nodeStructure?: INodeStructure[];
  postCode?: string;
  websiteUrl?: string;
}

export interface INodeDropDownAccess {
  company: boolean;
  tradingRegion: boolean;
  storeGroup: boolean;
  store: boolean;
}

/*export interface ITransMgmtSettings {
  reasonCodeConfig?: IReasonConfig[];
  exchangeRateConfig?: IExchangeRateConfig[];
  sessionConfig?: ISessionConfig;
  tenderTypeConfig?: ITenderTypeConfig[];
}

export interface ISessionConfig {
  endTime: string;
  isOverride?: boolean;
}

export interface IReasonConfig {
  reasonCode: string;
  reasonCategory: IMap;
  reasonType: IMap;
  isOverride?: boolean;
}

export interface IExchangeRateConfig {
  currency: ICurrencyDto;
  isFixedRate?: boolean;
  fixedRateValue?: number;
  dateTime: Date;
  isOverride?: boolean;
}

export interface ITenderTypeConfig {
  dateTime: Date;
  tenderType: IMap;
  subTenderType: IMap;
  isOverride?: boolean;
}

export interface IMap {
  id: string;
  name: string;
}*/

export interface IDashboardPaginationResponse<T> {
  status: string;
  result: IDashboardResult;
}
export interface IDashboardResult {
  activeUser: IDashboardUsers;
  disabledUser: IDashboardUsers;
}
export interface IDashboardUsers {
  result: [];
  totalCount: number;
  offset: number;
  limit: number;
}
export interface IDashboardUser {
  firstName: string;
  id: string;
  lastName: string;
  rootNode: string;
  userName: string;
}
export interface IDashboardStoreInformation {
  enableStoreCount: number;
  disableStoreCount: number;
  userCount?: number;
  deviceCount?: number;
  storeList?: IDashboardStoreDetail[];
}

export interface IDashboardStoreDetail {
  nodeId: string;
  parentId: string;
  nodeType: string;
  name: string;
  countryCode: string;
  phoneNumber: string;
  status: boolean;
  emailId: string;
  countryName: string;
  city: string;
  address1: string;
  address2: string;
  storeTimings: IStoreTiming[];
  userCount: number;
  deviceCount: number;
}

export interface IDashboardOrgInformation {
  address: string;
  id: string;
  email: string;
  name: string;
  status: string;
  phonenumber: string;
  nodeCount?: INodeCountInfo[];
  userCount?: IUserCount;
}

export interface INodeCountInfo {
  total: number;
  enable: number;
  disable: number;
}

export interface IUserCount {
  total: number;
  active: number;
  disable: number;
}

export enum EbasketCustomerSelectApi {
  CreateCustomer = 'Create Customer',
  UpdateCustomer = 'Update Customer'
}

export enum EbasketApiMethodTypes {
  Get = 'Get',
  Post = 'Post',
  Put = 'Put',
  Patch = 'Patch',
  Delete = 'Delete'
}

export enum TaxTypes {
  INDEPENDENT_OF_PRICE = 'INDEPENDENT_OF_PRICE',
  INCLUDED_IN_PRICE = 'INCLUDED_IN_PRICE'
}
export enum CountryName {
  US = 'US',
  CHINA = 'CHINA',
  JAPAN = 'JAPAN',
  EMEA = 'EMEA'
}
export enum Providers {
  CIEP = 'CIEP',
  GRAPHENE = 'Graphene',
  External = 'External',
  IEW = 'IEW.net',
  RJ = 'RJ',
  RJLocal = 'RJLocal',
  Netsuite = 'Netsuite',
  Magento = 'Magento',
  Aptos = 'APTOS',
  SECRET = '******',
  CrestEngegement = 'CrestEngagement',
  CrestMajorel = 'Majorel',
  IslandPacific = 'IslandPacific',
  ADFS = 'ADFS',
  AZUREAD = 'AZUREAD',
  AX = 'AX',
  QUANTUM = 'Quantum',
  PARASPAR = 'Paraspar',
  GIVEX = 'GiveX',
  SALESFORCE = 'Salesforce',
  SVS = 'SVS',
  SALESFORCEXML = 'SalesforceXML',
  SALESFORCEOSU = 'SalesforceOSU',
  IMPRINT = 'Imprint',
  XIATECH = 'Xiatech'
}
export enum GiftCardType {
  PGC = 'PGC',
  EGC = 'EGC'
}
export interface GiftCardSetting {
  giftCard: boolean;
  mixGiftCards: boolean;
  providers: GiftCardProvider[];
}
export interface GiftCardProvider {
  provider: string;
  giftCardType: string;
  authenticationDetails?: GiftCardProviderAuthDetails;
  authenticationDetailsKey?: string;
  minimumAmount?: number;
  maximumAmount?: number;
  giftCardSKU?: string;
  giftCardUrl?: string;
  vatCode?: string;
  vatRate?: number;
  shippingDetails?: ShippingDetails[];
  maxPerBasket?: number;
  expiryDate?: number;
  expirySetByUser?: boolean;
  extraSlipForSaleOfNewGC?: object;
  flexibleCardAllowed?: IFlexibleCardConfigurations;
  allowSaleTopupGiftCard?: boolean;
  includeGCInCalculationOfTransactionPromotionOrDiscount?: object;
}
export interface ShippingDetails {
  shippingVat: number;
  shippingTotal: number;
  shippingName: string;
  shippingCode: string;
}
export interface GiftCardProviderAuthDetails {
  accessToken?: string;
  accountId?: string;
  consumerKey?: string;
  consumerSecret?: string;
  signatureMethod?: string;
  tokenSecret?: string;
  username?: string;
  password?: string;
  apiDetails?: [];
}
export enum PaymentProviders {
  ADYEN = 'Adyen'
}

export enum MagentoVersions {
  v1 = 'v1'
}

export enum MagentoShippingMethodCode {
  FAKE_SHIPPING = 'fakeshipping_fakeshipping'
}
export enum MagentoPaymentMethodCode {
  POS_ORDER = 'pos_order'
}

export enum TMGiftCardSelectApi {
  RetrieveGiftCard = 'Retrieve Gift Card'
}
export enum TMGiftCardGiveXSelectApi {
  CheckBalance = 'Check Balance',
  ActivateNewCard = 'Activate New Card',
  TopUpCard = 'Top Up Card',
  Redemption = 'Redemption',
  MerchandiseCredit = 'Merchandise Credit',
  CancelRedemption = 'Cancel Redemption'
}
export enum EbasketLoyaltySchemeSelectApi {
  balanceEnquiry = 'Balance Enquiry'
}

export enum EbasketInventorySelectApi {
  StockEnquiry = 'Stock Enquiry'
}
export enum EbasketGetProductApi {
  GetProducts = 'Get Products'
}
export enum EbasketInventoryParasparSelectApi {
  ShippingEnquiry = 'Shipping Enquiry'
}

export enum EbasketSlectQueue {
  SalesExportQueue = 'salesExportQueue'
}
export enum EbasketSalesExportSelectApi {
  ProcessOrders = 'Process Orders',
  ProcessReturns = 'Process Returns',
  ProcessExchanges = 'Process Exchanges'
}

export enum EbasketSalesImportSelectApi {
  ProcessOrders = 'Process Orders',
  ProcessReturns = 'Process Returns',
  ProcessExchanges = 'Process Exchanges'
}

export enum EbasketSalesCrestMajorelSelectApi {
  CreateOrders = 'Create Orders'
}
export enum EbasketSalesSetupSelectApi {
  OrderEnquiry = 'Order Enquiry'
}
export enum WebReturnsSelectApi {
  ProcessOrders = 'Process Orders'
}
export enum EbasketSalesExportXiatechSelectApi {
  ProcessOrders = 'Process Orders',
  ProcessReturns = 'Process Returns',
  ProcessExchanges = 'Process Exchanges',
  PartialRefunds = 'Process Partial Refunds'
}
export enum WebReturnsSalesforceOSUSelectApi {
  ProcessReturns = 'Process Returns',
  GetOrder = 'Get Order'
}
export enum LogoOptions {
  Default = 'Default',
  Deckers = 'Deckers'
}

export enum DefaultModuleNames {
  ebasket = 'E-Basket'
}

export enum ModuleLogos {
  om = 'icon-app-orchestration.svg',
  ebasket = 'icon-app-temp-ebasket.svg',
  deckers = 'icon-app-ebasket-deckers.svg',
  ebasketDeckers = 'icon-app-ebasket-deckers.svg',
  tm = 'icon-app-transaction-manager_coloured.svg',
  saga = 'icon_scan and go_coloured.svg',
  rj = 'icon-app-rj.svg',
  t2s = 'icon-app-t2b.svg',
  sotk = 'icon-app-temp-sotk.svg',
  crestEngagement = 'crest-engagement.svg',
  engage = 'icon-app-engage-coloured.svg'
}

export enum IModuleNamesInLower {
  om = 'om',
  ebasket = 'ebasket',
  tm = 'tm',
  saga = 'saga',
  rj = 'rj',
  t2s = 't2s',
  sotk = 'sotk',
  engage = 'engage'
}

export interface ICategory {
  id: string;
  categoryId: string;
  parentId: string;
  name: string;
}
export interface IReportButton {
  buttonName: string;
  savedSearchName: string;
}
export interface ISplunkReportConfiguration {
  appName?: string;
  userName?: string;
  password?: string;
  host?: string;
  port?: string;
  authenticationDetailsKey?: string;
}
export interface IProductListResponse {
  id: string;
  productId?: string;
  SKU: string;
  description: string;
  barcode?: string;
  imageUrls?: string[];
  size: string;
  colour: string;
  styleCode?: any;
  updatedDate: string;
  text?: string;
  value?: any;
  categories?: ICategory[];
  isChecked?: boolean;
}

export interface ICompanyList {
  id: string;
  name: string;
  nodeType: string;
  parentId: string;
  status: boolean;
}
export interface ISOTKMgmtSettings {
  expectedQuantity: boolean;
  location: boolean;
  mod10: boolean;
  mod11: boolean;
  addReportBtn: boolean;
  reportButton: IReportButton[];
  splunkReportConfiguration: ISplunkReportConfiguration;
}
export interface IEngageSettings {
  deviceManagementSetting?: IDeviceManagementSetting;
  interactSetting?: IInteractSetting;
  informSetting?: IInformSetting;
}

export interface IInformSetting {
  mediaConfig?: IMediaConfig[];
  status?: boolean;
  isOverride?: boolean;
}

export interface IMediaConfig {
  priority?: number;
  mediaPath?: string;
  mediaDuration?: number;
  mediaType?: string;
}
export interface IDeviceManagementSetting {
  displayList?: IDisplayList[];
  isOverride?: boolean;
}
export interface IInteractSetting {
  status?: boolean;
  isOverride?: boolean;
  values?: IInteractSettingValues[];
}

export interface IInteractSettingValues {
  id?: number;
  name?: string;
}

export interface IDisplayList {
  displayName: string;
  ipAddress: string;
}

export const SalesExportProviders = [
  { name: 'Netsuite', value: 'Netsuite' },
  { name: 'AX', value: 'AX' },
  { name: 'RJ Cloud', value: 'RJ' },
  { name: 'RJ Local', value: 'RJLocal' },
  { name: 'Magento', value: 'Magento' },
  { name: 'Crest Engagement', value: 'CrestEngagement' },
  { name: 'Island Pacific', value: 'IslandPacific' },
  { name: 'Paraspar', value: 'Paraspar' },
  { name: 'Salesforce', value: 'Salesforce' },
  { name: 'Xiatech', value: 'Xiatech' }
];
export const CustomerHubProviders = [
  { name: 'AX', value: 'AX' },
  { name: 'Netsuite', value: 'Netsuite' },
  { name: 'Graphene', value: 'Graphene' },
  { name: 'IEW.net', value: 'IEW.net' },
  { name: 'RJ', value: 'RJ' },
  { name: 'Majorel', value: 'Majorel' },
  { name: 'Crest Engagement', value: 'CrestEngagement' },
  { name: 'Xiatech', value: 'Xiatech' }
];

export const SalesImportProviders = [{ name: 'RJ Cloud', value: 'RJ' }];

export const SalesExportProvidersWithoutParaspar = [
  { name: 'Netsuite', value: 'Netsuite' },
  { name: 'AX', value: 'AX' },
  { name: 'RJ Cloud', value: 'RJ' },
  { name: 'RJ Local', value: 'RJLocal' },
  { name: 'Magento', value: 'Magento' },
  { name: 'Crest Engagement', value: 'CrestEngagement' },
  { name: 'Island Pacific', value: 'IslandPacific' },
  { name: 'Xiatech', value: 'Xiatech' }
];
export const SalesExportProvidersShipToStore = [
  { name: 'Magento', value: 'Magento' },
  { name: 'Salesforce', value: 'Salesforce' },
  { name: 'Xiatech', value: 'Xiatech' }
];
