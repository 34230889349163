import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { Providers } from '@shared/interfaces';
@Component({
  selector: 'app-rj-provider-details-settings',
  templateUrl: './rj-provider-details-settings.component.html',
  styleUrls: ['./rj-provider-details-settings.component.scss']
})
export class RjProviderDetailsSettingsComponent implements OnInit {
  @Input() action: string;
  @Input() parentForm: FormGroup;
  @Input() ebasketRjApiDetails: any;
  @Input() authControlKey: string;
  @Input() provider: string;
  @Input() enableflag: boolean;
  // @Output() updateApiData: EventEmitter<any> = new EventEmitter();
  apiDetails: FormGroup;
  flag: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.initForm();
    console.log(this.enableflag);
    if (this.enableflag == true) {
      this.flag = true;
    }
  }
  initForm() {
    console.log('ebasketRjApiDetails');
    console.log(this.ebasketRjApiDetails);
    this.parentForm.addControl(
      this.authControlKey,
      this.formBuilder.group({
        exportOptionsProducts: [
          {
            value:
              this.ebasketRjApiDetails && this.ebasketRjApiDetails.exportOptionsProducts
                ? this.ebasketRjApiDetails.exportOptionsProducts
                : false,
            disabled: this.action === 'view' ? true : false
          }
        ],
        userFtpUrl: [
          {
            value:
              this.ebasketRjApiDetails &&
              (this.ebasketRjApiDetails.authenticationDetails ||
                this.ebasketRjApiDetails.authenticationDetailsKey)
                ? Providers.SECRET
                : '',
            disabled: this.action === 'view' ? true : false
          },
          [Validators.required]
        ],

        userFtpUserName: [
          {
            value:
              this.ebasketRjApiDetails &&
              (this.ebasketRjApiDetails.authenticationDetails ||
                this.ebasketRjApiDetails.authenticationDetailsKey)
                ? Providers.SECRET
                : '',
            disabled: this.action === 'view' ? true : false
          },
          [Validators.required]
        ],

        userFtpPassword: [
          {
            value:
              this.ebasketRjApiDetails &&
              (this.ebasketRjApiDetails.authenticationDetails ||
                this.ebasketRjApiDetails.authenticationDetailsKey)
                ? Providers.SECRET
                : '',
            disabled: this.action === 'view' ? true : false
          },
          [Validators.required]
        ],

        userFtpFolderName: [
          {
            value:
              this.ebasketRjApiDetails &&
              (this.ebasketRjApiDetails.authenticationDetails ||
                this.ebasketRjApiDetails.authenticationDetailsKey)
                ? Providers.SECRET
                : '',
            disabled: this.action === 'view' ? true : false
          },
          [Validators.required]
        ],
        userFtpPort: [
          {
            value:
              this.ebasketRjApiDetails &&
              (this.ebasketRjApiDetails.authenticationDetails ||
                this.ebasketRjApiDetails.authenticationDetailsKey)
                ? Providers.SECRET
                : '',
            disabled: this.action === 'view' ? true : false
          },
          [Validators.required]
        ],
        sftp: [
          {
            value:
              this.ebasketRjApiDetails &&
              this.ebasketRjApiDetails.authenticationDetails &&
              this.ebasketRjApiDetails.authenticationDetails.sftp
                ? this.ebasketRjApiDetails.authenticationDetails.sftp
                : false,
            disabled: this.action === 'view' ? true : false
          }
        ]
      })
    );
  }

  isRequired(control: string) {
    let isRequired = false;
    const formControlObject = this.parentForm.get(this.authControlKey).get(control);
    if (formControlObject !== null) {
      if (formControlObject.validator !== null) {
        if (formControlObject.validator({} as AbstractControl) !== null) {
          isRequired = formControlObject.validator({} as AbstractControl).required ? true : false;
        }
      }
    }
    return isRequired ? true : false;
  }
}
