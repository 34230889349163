import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl,
  FormArray,
  FormControl
} from '@angular/forms';
import { CommonConfigFacade } from '@shared/common-config/state/common-config.facade';
import { CustomerHubProviders, ICountry, Providers } from '@shared/interfaces';
import { Subscription, throwError } from 'rxjs';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';

const REGEXP = {
  postcode: /^[A-Za-z0-9\s\-\_]+$/,
  mobile: /^[1-9]{1}[0-9]/,
  name: /^[^\s][\d\a-zA-Z0-9\s\-\_]*$/,
  title: /^[\a-zA-Z]*$/,
  email: /^$|[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+$/
};

@Component({
  selector: 'customer-hub-details-settings',
  templateUrl: './customer-hub-details-settings.component.html',
  styleUrls: ['./customer-hub-details-settings.component.scss']
})
export class CustomerHubDetailsSettingsComponent implements OnInit, OnDestroy {
  @Input() action: string;
  @Input() parentForm: FormGroup;
  @Input() customerHubDetails: any;
  @Input() authControlKey: string;
  countryList: ICountry[];
  apiDetails: FormGroup;
  customerHubProviderOptions: any[] = [CustomerHubProviders];
  defaultValue = 'United Kingdom';
  defaultSignatureMethod = 'HMAC-SHA256';
  get dc() {
    return this.parentForm.get(this.authControlKey);
  }

  // Variables to manage visibility
  isToggleWooseMap = true;
  isToggleAddCustomer = true;
  isToggleMarketingPreference = true;
  isToggleCustomerSupport = true;
  isToggleDefaultCustomer = false;
  // Provider base varibales for shared component's visibility
  // NOTE: Each array contains numbers of boolen for available providers
  enableCustomerHubNetSuiteDetails = [false, false, false, false, false, false, false, false];
  enableCustomerHubMajorelDetails = [false, false, false, false, false, false, false, false];
  enableCustomerHubAXDetails = [false, false, false, false, false, false, false, false];
  enableCustomerHubRJDetails = [false, false, false, false, false, false, false, false];
  enableCustomerHubGrapheneDetails = [false, false, false, false, false, false, false, false];
  enableCustomerHubIEWDetails = [false, false, false, false, false, false, false, false];
  enableCustomerHubCrestEngagementDetails = [
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false
  ];
  enableCustomerHubXiatechDetails = [false, false, false, false, false, false, false, false];

  //ProviderBasedDetails
  customerHubNetsuiteAuthDetails;
  customerHubMajorelAuthDetails;
  customerHubAXAuthDetails;
  customerHubRJAuthDetails;
  customerHubGrapheneAuthDetails;
  customerHubIEWAuthDetails;
  customerHubCrestEngagementAuthDetails;
  customerHubXiatechAuthDetails;

  subscriptions: Subscription[] = [];
  public filteredCountries: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  constructor(private formBuilder: FormBuilder, private commonFacade: CommonConfigFacade) {}
  ngOnInit() {
    this.initForm();
    this.subscriptions.push(
      this.commonFacade.countries$.subscribe(
        (response: any) => {
          this.countryList = response;
        },
        (error) => throwError(error)
      )
    );
    this.subscribeSelectCountries();
  }
  initForm() {
    this.toggleVisibility();
    this.parentForm.addControl(
      this.authControlKey,
      this.formBuilder.group({
        woosemap: [
          {
            value:
              this.customerHubDetails && this.customerHubDetails.woosmap
                ? this.customerHubDetails.woosmap.status
                : false,
            disabled: this.action === 'view' ? true : false
          }
        ],
        privateKey: [
          {
            value:
              this.customerHubDetails && this.customerHubDetails.woosmap
                ? this.customerHubDetails.woosmap.privateKey
                : '',
            disabled: this.action === 'view' ? true : false
          }
        ],
        country: [
          {
            value:
              this.customerHubDetails && this.customerHubDetails.woosmap
                ? this.customerHubDetails.woosmap.country
                : this.defaultValue,
            disabled: this.action === 'view' ? true : false
          }
        ],
        countries: [
          {
            value:
              this.customerHubDetails &&
              this.customerHubDetails.woosmap &&
              this.customerHubDetails.woosmap.countries
                ? this.customerHubDetails.woosmap.countries.map((c) => c.country_code)
                : '',
            disabled: this.action === 'view' ? true : false
          }
        ],
        countriesMultiFilterCtrl: [''],
        addCustomer: [
          {
            value:
              this.customerHubDetails && this.customerHubDetails.addCustomer
                ? this.customerHubDetails.addCustomer.status
                : false,
            disabled: this.action === 'view' ? true : false
          }
        ],
        isDefaultCustomer: [
          {
            value:
              this.customerHubDetails && this.customerHubDetails.isDefaultCustomer
                ? this.customerHubDetails.isDefaultCustomer
                : false,
            disabled: this.action === 'view' ? true : false
          }
        ],
        customerMobileNumber: [
          {
            value:
              this.customerHubDetails &&
              this.customerHubDetails.addCustomer &&
              this.customerHubDetails.addCustomer.additionalCustomerInfo
                ? this.customerHubDetails.addCustomer.additionalCustomerInfo.customerMobileNumber
                : false,
            disabled: this.action === 'view' ? true : false
          }
        ],
        customerAddressLine: [
          {
            value:
              this.customerHubDetails &&
              this.customerHubDetails.addCustomer &&
              this.customerHubDetails.addCustomer.additionalCustomerInfo
                ? this.customerHubDetails.addCustomer.additionalCustomerInfo.addressLine
                : false,
            disabled: this.action === 'view' ? true : false
          }
        ],
        customerCity: [
          {
            value:
              this.customerHubDetails &&
              this.customerHubDetails.addCustomer &&
              this.customerHubDetails.addCustomer.additionalCustomerInfo
                ? this.customerHubDetails.addCustomer.additionalCustomerInfo.city
                : false,
            disabled: this.action === 'view' ? true : false
          }
        ],
        customerRegion: [
          {
            value:
              this.customerHubDetails &&
              this.customerHubDetails.addCustomer &&
              this.customerHubDetails.addCustomer.additionalCustomerInfo
                ? this.customerHubDetails.addCustomer.additionalCustomerInfo.region
                : false,
            disabled: this.action === 'view' ? true : false
          }
        ],
        customerCountry: [
          {
            value:
              this.customerHubDetails &&
              this.customerHubDetails.addCustomer &&
              this.customerHubDetails.addCustomer.additionalCustomerInfo
                ? this.customerHubDetails.addCustomer.additionalCustomerInfo.country
                : false,
            disabled: this.action === 'view' ? true : false
          }
        ],
        customerPostCode: [
          {
            value:
              this.customerHubDetails &&
              this.customerHubDetails.addCustomer &&
              this.customerHubDetails.addCustomer.additionalCustomerInfo
                ? this.customerHubDetails.addCustomer.additionalCustomerInfo.postalCode
                : false,
            disabled: this.action === 'view' ? true : false
          }
        ],
        customerMarketingPreference: [
          {
            value:
              this.customerHubDetails &&
              this.customerHubDetails.addCustomer &&
              this.customerHubDetails.addCustomer.additionalCustomerInfo &&
              this.customerHubDetails.addCustomer.additionalCustomerInfo.marketingPreference
                ? this.customerHubDetails.addCustomer.additionalCustomerInfo.marketingPreference
                    .status
                : false,
            disabled: this.action === 'view' ? true : false
          }
        ],
        text_marketing: [
          {
            value:
              this.customerHubDetails &&
              this.customerHubDetails.addCustomer &&
              this.customerHubDetails.addCustomer.additionalCustomerInfo &&
              this.customerHubDetails.addCustomer.additionalCustomerInfo.marketingPreference
                ? this.customerHubDetails.addCustomer.additionalCustomerInfo.marketingPreference
                    .textMarketing
                : false,
            disabled: this.action === 'view' ? true : false
          }
        ],
        catalogue_by_post: [
          {
            value:
              this.customerHubDetails &&
              this.customerHubDetails.addCustomer &&
              this.customerHubDetails.addCustomer.additionalCustomerInfo &&
              this.customerHubDetails.addCustomer.additionalCustomerInfo.marketingPreference
                ? this.customerHubDetails.addCustomer.additionalCustomerInfo.marketingPreference
                    .catalogueByPost
                : false,
            disabled: this.action === 'view' ? true : false
          }
        ],
        third_party_mailing: [
          {
            value:
              this.customerHubDetails &&
              this.customerHubDetails.addCustomer &&
              this.customerHubDetails.addCustomer.additionalCustomerInfo &&
              this.customerHubDetails.addCustomer.additionalCustomerInfo.marketingPreference
                ? this.customerHubDetails.addCustomer.additionalCustomerInfo.marketingPreference
                    .thirdPartyMailing
                : false,
            disabled: this.action === 'view' ? true : false
          }
        ],
        phone_marketing: [
          {
            value:
              this.customerHubDetails &&
              this.customerHubDetails.addCustomer &&
              this.customerHubDetails.addCustomer.additionalCustomerInfo &&
              this.customerHubDetails.addCustomer.additionalCustomerInfo.marketingPreference
                ? this.customerHubDetails.addCustomer.additionalCustomerInfo.marketingPreference
                    .phoneMarketing
                : false,
            disabled: this.action === 'view' ? true : false
          }
        ],
        email_marketing: [
          {
            value:
              this.customerHubDetails &&
              this.customerHubDetails.addCustomer &&
              this.customerHubDetails.addCustomer.additionalCustomerInfo &&
              this.customerHubDetails.addCustomer.additionalCustomerInfo.marketingPreference
                ? this.customerHubDetails.addCustomer.additionalCustomerInfo.marketingPreference
                    .emailMarketing
                : false,
            disabled: this.action === 'view' ? true : false
          }
        ],
        customerSupport: [
          {
            value:
              this.customerHubDetails && this.customerHubDetails.customerSupport
                ? this.customerHubDetails.customerSupport.status
                : false,
            disabled: this.action === 'view' ? true : false
          }
        ],
        customerSupportEmail: [
          {
            value:
              this.customerHubDetails && this.customerHubDetails.customerSupport
                ? this.customerHubDetails.customerSupport.email
                : '',
            disabled: this.action === 'view' ? true : false
          }
        ],
        basketScreen: [
          {
            value:
              this.customerHubDetails && this.customerHubDetails.addCustomer
                ? this.customerHubDetails.addCustomer.basketScreen
                : false,
            disabled: this.action === 'view' ? true : false
          }
        ],
        paymentScreen: [
          {
            value:
              this.customerHubDetails && this.customerHubDetails.addCustomer
                ? this.customerHubDetails.addCustomer.paymentScreen
                : false,
            disabled: this.action === 'view' ? true : false
          }
        ],
        ebasketCustomerHubProviderDetails: this.formBuilder.array([])
      })
    );
    if (
      this.customerHubDetails &&
      this.customerHubDetails.status &&
      (this.customerHubDetails.provider || this.customerHubDetails.providers)
    ) {
      this.bindCustomerHubProvider();
    }
    this.bindDefaultCustomerView();
  }

  toggleVisibility() {
    // Toggle value for visibility
    this.isToggleWooseMap =
      this.customerHubDetails && this.customerHubDetails.woosmap
        ? !this.customerHubDetails.woosmap.status
        : true;
    this.isToggleAddCustomer =
      this.customerHubDetails && this.customerHubDetails.addCustomer
        ? !this.customerHubDetails.addCustomer.status
        : true;
    this.isToggleMarketingPreference =
      this.customerHubDetails &&
      this.customerHubDetails.addCustomer &&
      this.customerHubDetails.addCustomer.additionalCustomerInfo &&
      this.customerHubDetails.addCustomer.additionalCustomerInfo.marketingPreference
        ? this.customerHubDetails.addCustomer.additionalCustomerInfo.marketingPreference.status
        : false;
    this.isToggleCustomerSupport =
      this.customerHubDetails && this.customerHubDetails.customerSupport
        ? !this.customerHubDetails.customerSupport.status
        : true;
    this.isToggleDefaultCustomer =
      this.customerHubDetails && this.customerHubDetails.isDefaultCustomer
        ? this.customerHubDetails.isDefaultCustomer
        : false;
  }
  changeEbasketCustomerHubProvider(provider: any, i) {
    const selectedProviders = this.parentForm
      .get(this.authControlKey)
      .get('ebasketCustomerHubProviderDetails')
      .value.map((p) => {
        return p.customerHubProviderControl;
      });

    if (provider === Providers.Netsuite) {
      this.enableCustomerHubNetSuiteDetails[i] = true;
      this.enableCustomerHubMajorelDetails[i] = false;
      this.enableCustomerHubRJDetails[i] = false;
      this.enableCustomerHubAXDetails[i] = false;
      this.enableCustomerHubGrapheneDetails[i] = false;
      this.enableCustomerHubIEWDetails[i] = false;
      this.enableCustomerHubCrestEngagementDetails[i] = false;
      this.enableCustomerHubXiatechDetails[i] = false;
    } else if (provider === Providers.CrestMajorel) {
      this.enableCustomerHubNetSuiteDetails[i] = false;
      this.enableCustomerHubMajorelDetails[i] = true;
      this.enableCustomerHubRJDetails[i] = false;
      this.enableCustomerHubAXDetails[i] = false;
      this.enableCustomerHubGrapheneDetails[i] = false;
      this.enableCustomerHubIEWDetails[i] = false;
      this.enableCustomerHubCrestEngagementDetails[i] = false;
      this.enableCustomerHubXiatechDetails[i] = false;
    } else if (provider === Providers.RJ) {
      this.enableCustomerHubNetSuiteDetails[i] = false;
      this.enableCustomerHubMajorelDetails[i] = false;
      this.enableCustomerHubRJDetails[i] = true;
      this.enableCustomerHubAXDetails[i] = false;
      this.enableCustomerHubGrapheneDetails[i] = false;
      this.enableCustomerHubIEWDetails[i] = false;
      this.enableCustomerHubCrestEngagementDetails[i] = false;
      this.enableCustomerHubXiatechDetails[i] = false;
    } else if (provider === Providers.AX) {
      this.enableCustomerHubNetSuiteDetails[i] = false;
      this.enableCustomerHubMajorelDetails[i] = false;
      this.enableCustomerHubRJDetails[i] = false;
      this.enableCustomerHubAXDetails[i] = true;
      this.enableCustomerHubGrapheneDetails[i] = false;
      this.enableCustomerHubIEWDetails[i] = false;
      this.enableCustomerHubCrestEngagementDetails[i] = false;
      this.enableCustomerHubXiatechDetails[i] = false;
    } else if (provider === Providers.CrestEngegement) {
      this.enableCustomerHubNetSuiteDetails[i] = false;
      this.enableCustomerHubMajorelDetails[i] = false;
      this.enableCustomerHubRJDetails[i] = false;
      this.enableCustomerHubAXDetails[i] = false;
      this.enableCustomerHubGrapheneDetails[i] = false;
      this.enableCustomerHubIEWDetails[i] = false;
      this.enableCustomerHubCrestEngagementDetails[i] = true;
      this.enableCustomerHubXiatechDetails[i] = false;
    } else if (provider === Providers.IEW) {
      this.enableCustomerHubNetSuiteDetails[i] = false;
      this.enableCustomerHubMajorelDetails[i] = false;
      this.enableCustomerHubRJDetails[i] = false;
      this.enableCustomerHubAXDetails[i] = false;
      this.enableCustomerHubGrapheneDetails[i] = false;
      this.enableCustomerHubIEWDetails[i] = true;
      this.enableCustomerHubCrestEngagementDetails[i] = false;
      this.enableCustomerHubXiatechDetails[i] = false;
    } else if (provider === Providers.GRAPHENE) {
      this.enableCustomerHubNetSuiteDetails[i] = false;
      this.enableCustomerHubMajorelDetails[i] = false;
      this.enableCustomerHubRJDetails[i] = false;
      this.enableCustomerHubAXDetails[i] = false;
      this.enableCustomerHubGrapheneDetails[i] = true;
      this.enableCustomerHubIEWDetails[i] = false;
      this.enableCustomerHubCrestEngagementDetails[i] = false;
      this.enableCustomerHubXiatechDetails[i] = false;
    } else if (provider === Providers.XIATECH) {
      this.enableCustomerHubXiatechDetails[i] = true;
      this.enableCustomerHubNetSuiteDetails[i] = false;
      this.enableCustomerHubMajorelDetails[i] = false;
      this.enableCustomerHubRJDetails[i] = false;
      this.enableCustomerHubAXDetails[i] = false;
      this.enableCustomerHubGrapheneDetails[i] = false;
      this.enableCustomerHubIEWDetails[i] = false;
      this.enableCustomerHubCrestEngagementDetails[i] = false;
    } else {
      this.enableCustomerHubNetSuiteDetails[i] = false;
      this.enableCustomerHubMajorelDetails[i] = false;
      this.enableCustomerHubRJDetails[i] = false;
      this.enableCustomerHubAXDetails[i] = false;
      this.enableCustomerHubGrapheneDetails[i] = false;
      this.enableCustomerHubIEWDetails[i] = false;
      this.enableCustomerHubCrestEngagementDetails[i] = false;
      this.enableCustomerHubXiatechDetails[i] = false;
    }
    this.assigenCustomerHubProviders();
    CustomerHubProviders.forEach((provider) => {
      if (!selectedProviders.includes(provider.value)) {
        if (provider.value === Providers.RJ) {
          (this.parentForm.get(this.authControlKey) as FormGroup).removeControl(
            'customerHubRJImportExportKey'
          );
        } else if (provider.value === Providers.AX) {
          (this.parentForm.get(this.authControlKey) as FormGroup).removeControl(
            'customerHubAXImportExportKey'
          );
        } else if (provider.value === Providers.GRAPHENE) {
          (this.parentForm.get(this.authControlKey) as FormGroup).removeControl(
            'customerHubGrapheneImportExportKey'
          );
        } else if (provider.value === Providers.Netsuite) {
          (this.parentForm.get(this.authControlKey) as FormGroup).removeControl(
            'customerHubNetsuiteAuthenticationKey'
          );
          (this.parentForm.get(this.authControlKey) as FormGroup).removeControl(
            'customerHubNetsuiteImportExportKey'
          );
        } else if (provider.value === Providers.CrestMajorel) {
          (this.parentForm.get(this.authControlKey) as FormGroup).removeControl(
            'customerHubMajorelAuthenticationKey'
          );
          (this.parentForm.get(this.authControlKey) as FormGroup).removeControl(
            'customerHubMajorelImportExportKey'
          );
        } else if (provider.value === Providers.IEW) {
          (this.parentForm.get(this.authControlKey) as FormGroup).removeControl(
            'customerHubIEWImportExportKey'
          );
        } else if (provider.value === Providers.CrestEngegement) {
          (this.parentForm.get(this.authControlKey) as FormGroup).removeControl(
            'customerHubCrestEngagementImportExportKey'
          );
        } else if (provider.value === Providers.XIATECH) {
          (this.parentForm.get(this.authControlKey) as FormGroup).removeControl(
            'customerHubXiatechAuthenticationKey'
          );
          (this.parentForm.get(this.authControlKey) as FormGroup).removeControl(
            'customerHubXiatechImportExportKey'
          );
        }
      }
    });
  }
  assigenCustomerHubProviders() {
    const selectedProviders = this.parentForm
      .get(this.authControlKey)
      .get('ebasketCustomerHubProviderDetails')
      .value.map((p) => {
        return p.customerHubProviderControl;
      });

    this.customerHubProviderOptions = this.customerHubProviderOptions
      .map((e, index) => {
        if (index === 0) {
          return e;
        } else if (
          index <
          this.parentForm.get(this.authControlKey).get('ebasketCustomerHubProviderDetails').value
            .length
        ) {
          return e;
        }
      })
      .filter((element) => {
        return element !== undefined;
      });
    this.customerHubProviderOptions.map((e, index) => {
      if (
        this.parentForm.get(this.authControlKey).get('ebasketCustomerHubProviderDetails').value
          .length > 0
      ) {
        const data = e.filter((ea) => {
          if (
            this.parentForm.get(this.authControlKey).get('ebasketCustomerHubProviderDetails').value[
              index
            ].customerHubProviderControl === ea.value
          ) {
            return ea;
          } else if (!selectedProviders.includes(ea.value)) {
            return ea;
          }
        });
        const providerslenght = index + 1;
        if (
          this.parentForm.get(this.authControlKey).get('ebasketCustomerHubProviderDetails').value
            .length > providerslenght
        ) {
          this.customerHubProviderOptions[index] = data;
        } else {
          this.customerHubProviderOptions[index] = data;
          const newProviderlist = e.filter((ea) => {
            if (!selectedProviders.includes(ea.value)) {
              return ea;
            }
          });
          this.customerHubProviderOptions.push(newProviderlist);
        }
      }
    });
  }

  private defultCustomerFormGroupInit(defaultCustomer?: any) {
    return this.formBuilder.group({
      allowEditCustomer: [
        defaultCustomer && defaultCustomer.allowEditCustomer
          ? defaultCustomer.allowEditCustomer
          : false,
        []
      ],
      id: [defaultCustomer && defaultCustomer.id ? defaultCustomer.id : '', []],
      telephone: [
        defaultCustomer && defaultCustomer.telephone ? defaultCustomer.telephone : '',
        [Validators.required, Validators.maxLength(500)]
      ],
      email: [
        defaultCustomer && defaultCustomer.email ? defaultCustomer.email : '',
        [Validators.required, Validators.pattern(REGEXP.email), Validators.maxLength(500)]
      ],
      person: this.formBuilder.group({
        title: [
          defaultCustomer && defaultCustomer.person ? defaultCustomer.person.title : '',
          [Validators.maxLength(500)]
        ],
        firstName: [
          defaultCustomer && defaultCustomer.person ? defaultCustomer.person.firstName : '',
          [Validators.required, Validators.maxLength(500)]
        ],
        lastName: [
          defaultCustomer && defaultCustomer.person ? defaultCustomer.person.lastName : '',
          [Validators.required, Validators.maxLength(500)]
        ]
      }),
      addressDetail: this.formBuilder.group({
        addressCountry: [
          defaultCustomer && defaultCustomer.address && defaultCustomer.address.addressDetail
            ? defaultCustomer.address.addressDetail.addressCountry
            : '',
          [Validators.required, Validators.maxLength(500)]
        ],
        postalCode: [
          defaultCustomer && defaultCustomer.address && defaultCustomer.address.addressDetail
            ? defaultCustomer.address.addressDetail.postalCode
            : '',
          [Validators.required, Validators.maxLength(500)]
        ],
        addressRegion: [
          defaultCustomer && defaultCustomer.address && defaultCustomer.address.addressDetail
            ? defaultCustomer.address.addressDetail.addressRegion
            : '',
          [Validators.required, Validators.maxLength(500)]
        ],
        addressCity: [
          defaultCustomer && defaultCustomer.address && defaultCustomer.address.addressDetail
            ? defaultCustomer.address.addressDetail.addressCity
            : '',
          [Validators.required, Validators.maxLength(500)]
        ],
        addressLine1: [
          defaultCustomer && defaultCustomer.address && defaultCustomer.address.addressDetail
            ? defaultCustomer.address.addressDetail.addressLine1
            : '',
          [Validators.required, Validators.maxLength(500)]
        ],
        addressLine2: [
          defaultCustomer && defaultCustomer.address && defaultCustomer.address.addressDetail
            ? defaultCustomer.address.addressDetail.addressLine2
            : '',
          [Validators.maxLength(500)]
        ]
      }),
      marketingPreference: this.formBuilder.group({
        emailMarketing: [
          defaultCustomer && defaultCustomer.marketingPreference
            ? defaultCustomer.marketingPreference.emailMarketing
            : false,
          []
        ],
        textMarketing: [
          defaultCustomer && defaultCustomer.marketingPreference
            ? defaultCustomer.marketingPreference.textMarketing
            : false,
          []
        ],
        catalogueByPost: [
          defaultCustomer && defaultCustomer.marketingPreference
            ? defaultCustomer.marketingPreference.catalogueByPost
            : false,
          []
        ],
        thirdPartyMailing: [
          defaultCustomer && defaultCustomer.marketingPreference
            ? defaultCustomer.marketingPreference.thirdPartyMailing
            : false,
          []
        ],
        phoneMarketing: [
          defaultCustomer && defaultCustomer.marketingPreference
            ? defaultCustomer.marketingPreference.phoneMarketing
            : false,
          []
        ]
      })
    });
  }

  bindCustomerHubProvider() {
    const ebasketCustomerHubProviderDetails = this.parentForm
      .get(this.authControlKey)
      .get('ebasketCustomerHubProviderDetails') as FormArray;
    let providers = [];
    let insertedProviders = [];
    if (
      this.customerHubDetails &&
      this.customerHubDetails.status &&
      this.customerHubDetails.providers
    ) {
      providers = this.customerHubDetails.providers;
    } else if (
      this.customerHubDetails &&
      this.customerHubDetails.status &&
      this.customerHubDetails.provider &&
      !insertedProviders.includes(this.customerHubDetails.provider)
    ) {
      insertedProviders.push(this.customerHubDetails.provider);
      providers = [
        {
          provider: this.customerHubDetails.provider,
          authenticationDetails:
            this.customerHubDetails && this.customerHubDetails.authenticationDetails
              ? this.customerHubDetails.authenticationDetails
              : {},
          authenticationDetailsKey:
            this.customerHubDetails && this.customerHubDetails.authenticationDetailsKey
              ? this.customerHubDetails.authenticationDetailsKey
              : '',
          customerImport:
            this.customerHubDetails.customerImport && this.customerHubDetails.customerImport.status
              ? true
              : false,
          customerExport:
            this.customerHubDetails.customerExport && this.customerHubDetails.customerExport.status
              ? true
              : false
        }
      ];
    }
    providers.forEach((providerDetail, sindex) => {
      const selectedProviders = ebasketCustomerHubProviderDetails.value.map((p) => {
        return p.customerHubProviderControl;
      });

      this.customerHubProviderOptions = this.customerHubProviderOptions
        .map((e, index) => {
          if (index === 0) {
            return e;
          } else if (index < ebasketCustomerHubProviderDetails.value.length) {
            return e;
          }
        })
        .filter((element) => {
          return element !== undefined;
        });

      this.customerHubProviderOptions.map((e, index) => {
        if (ebasketCustomerHubProviderDetails.value.length > 0) {
          const data = e.filter((ea) => {
            if (
              ebasketCustomerHubProviderDetails.value[index].customerHubProviderControl === ea.value
            ) {
              return ea;
            } else if (!selectedProviders.includes(ea.value)) {
              return ea;
            }
          });
          const providerslenght = index + 1;
          if (ebasketCustomerHubProviderDetails.value.length > providerslenght) {
            this.customerHubProviderOptions[index] = data;
          } else {
            this.customerHubProviderOptions[index] = data;
            const newProviderlist = e.filter((ea) => {
              if (!selectedProviders.includes(ea.value)) {
                return ea;
              }
            });
            this.customerHubProviderOptions.push(newProviderlist);
          }
        }
      });

      ebasketCustomerHubProviderDetails.push(
        this.createSelectProviderFormGroup(providerDetail.provider)
      );
      this.changeEbasketCustomerHubProvider(providerDetail.provider, sindex);
      if (providerDetail.provider === Providers.Netsuite) {
        this.customerHubNetsuiteAuthDetails = providerDetail;
      } else if (providerDetail.provider === Providers.CrestMajorel) {
        this.customerHubMajorelAuthDetails = providerDetail;
      } else if (providerDetail.provider === Providers.RJ) {
        this.customerHubRJAuthDetails = providerDetail;
      } else if (providerDetail.provider === Providers.AX) {
        this.customerHubAXAuthDetails = providerDetail;
      } else if (providerDetail.provider === Providers.GRAPHENE) {
        this.customerHubGrapheneAuthDetails = providerDetail;
      } else if (providerDetail.provider === Providers.IEW) {
        this.customerHubIEWAuthDetails = providerDetail;
      } else if (providerDetail.provider === Providers.CrestEngegement) {
        this.customerHubCrestEngagementAuthDetails = providerDetail;
      } else if (providerDetail.provider === Providers.XIATECH) {
        this.customerHubXiatechAuthDetails = providerDetail;
      }
    });
  }
  private createSelectProviderFormGroup(providerName?: string): FormGroup {
    providerName = providerName ? providerName : '';
    let form = new FormGroup({});
    form = new FormGroup({
      customerHubProviderControl: new FormControl(
        {
          value: providerName,
          disabled: this.action === 'view' ? true : false
        },
        Validators.required
      )
    });
    return form;
  }
  deleteCustomerHubProviderDetailContainer(pindex: number) {
    const ebasketCustomerHubProviderDetails = this.parentForm
      .get(this.authControlKey)
      .get('ebasketCustomerHubProviderDetails') as FormArray;
    // this.ebasket_sales_export_select_api.map((element: any) => {
    //   if (element.value === ebasketCustomerHubProviderDetails.value[pindex].selectedApi) {
    //     element.isDisabled = false;
    //   }
    // });
    if (ebasketCustomerHubProviderDetails.length > 0) {
      const deletedProvider =
        ebasketCustomerHubProviderDetails.value[pindex].customerHubProviderControl;
      if (
        ebasketCustomerHubProviderDetails.value[pindex].customerHubProviderControl ===
        Providers.Netsuite
      ) {
        (this.parentForm.get(this.authControlKey) as FormGroup).removeControl(
          'customerHubNetsuiteAuthenticationKey'
        );
        (this.parentForm.get(this.authControlKey) as FormGroup).removeControl(
          'customerHubNetsuiteImportExportKey'
        );
        this.customerHubNetsuiteAuthDetails = [];
        this.enableCustomerHubNetSuiteDetails[pindex] = false;
      } else if (
        ebasketCustomerHubProviderDetails.value[pindex].customerHubProviderControl ===
        Providers.CrestMajorel
      ) {
        (this.parentForm.get(this.authControlKey) as FormGroup).removeControl(
          'customerHubMajorelAuthenticationKey'
        );
        (this.parentForm.get(this.authControlKey) as FormGroup).removeControl(
          'customerHubMajorelImportExportKey'
        );
        this.customerHubMajorelAuthDetails = [];
        this.enableCustomerHubMajorelDetails[pindex] = false;
      } else if (
        ebasketCustomerHubProviderDetails.value[pindex].customerHubProviderControl === Providers.RJ
      ) {
        (this.parentForm.get(this.authControlKey) as FormGroup).removeControl(
          'customerHubRJImportExportKey'
        );
        this.customerHubRJAuthDetails = [];
        this.enableCustomerHubRJDetails[pindex] = false;
      } else if (
        ebasketCustomerHubProviderDetails.value[pindex].customerHubProviderControl === Providers.AX
      ) {
        (this.parentForm.get(this.authControlKey) as FormGroup).removeControl(
          'customerHubAXImportExportKey'
        );
        this.customerHubAXAuthDetails = [];
        this.enableCustomerHubAXDetails[pindex] = false;
      } else if (
        ebasketCustomerHubProviderDetails.value[pindex].customerHubProviderControl ===
        Providers.GRAPHENE
      ) {
        (this.parentForm.get(this.authControlKey) as FormGroup).removeControl(
          'customerHubGrapheneImportExportKey'
        );
        this.customerHubGrapheneAuthDetails = [];
        this.enableCustomerHubGrapheneDetails[pindex] = false;
      } else if (
        ebasketCustomerHubProviderDetails.value[pindex].customerHubProviderControl === Providers.IEW
      ) {
        (this.parentForm.get(this.authControlKey) as FormGroup).removeControl(
          'customerHubIEWImportExportKey'
        );
        this.customerHubIEWAuthDetails = [];
        this.enableCustomerHubIEWDetails[pindex] = false;
      } else if (
        ebasketCustomerHubProviderDetails.value[pindex].customerHubProviderControl ===
        Providers.CrestEngegement
      ) {
        (this.parentForm.get(this.authControlKey) as FormGroup).removeControl(
          'customerHubCrestEngagementImportExportKey'
        );
        this.customerHubCrestEngagementAuthDetails = [];
        this.enableCustomerHubCrestEngagementDetails[pindex] = false;
      } else if (
        ebasketCustomerHubProviderDetails.value[pindex].customerHubProviderControl ===
        Providers.XIATECH
      ) {
        (this.parentForm.get(this.authControlKey) as FormGroup).removeControl(
          'customerHubXiatechAuthenticationKey'
        );
        (this.parentForm.get(this.authControlKey) as FormGroup).removeControl(
          'customerHubXiatechImportExportKey'
        );
        this.customerHubXiatechAuthDetails = [];
        this.enableCustomerHubXiatechDetails[pindex] = false;
      }

      if (
        ebasketCustomerHubProviderDetails.value.length !== this.customerHubProviderOptions.length &&
        ebasketCustomerHubProviderDetails.value.length > 0
      ) {
        const addProvider = CustomerHubProviders.filter((p) => p.value === deletedProvider);
        this.customerHubProviderOptions.map((e, index) => {
          this.customerHubProviderOptions[index].push(addProvider[0]);
          return pindex !== index;
        });
      }

      ebasketCustomerHubProviderDetails.removeAt(pindex);
      if (deletedProvider != '') {
        this.customerHubProviderOptions = this.customerHubProviderOptions.filter((item, index) => {
          return pindex !== index;
        });
      }

      const ebasketSalesExport = ebasketCustomerHubProviderDetails.value;
      ebasketSalesExport.map((p, index) => {
        const provider = p.customerHubProviderControl;
        // tslint:disable-next-line: one-variable-per-declaration
        const i = index;
        if (provider === Providers.Netsuite) {
          this.enableCustomerHubNetSuiteDetails[i] = true;
          this.enableCustomerHubRJDetails[i] = false;
          this.enableCustomerHubAXDetails[i] = false;
          this.enableCustomerHubGrapheneDetails[i] = false;
          this.enableCustomerHubIEWDetails[i] = false;
          this.enableCustomerHubMajorelDetails[i] = false;
          this.enableCustomerHubCrestEngagementDetails[i] = false;
          this.enableCustomerHubXiatechDetails[i] = false;
        } else if (provider === Providers.CrestMajorel) {
          this.enableCustomerHubNetSuiteDetails[i] = false;
          this.enableCustomerHubRJDetails[i] = false;
          this.enableCustomerHubAXDetails[i] = false;
          this.enableCustomerHubGrapheneDetails[i] = false;
          this.enableCustomerHubIEWDetails[i] = false;
          this.enableCustomerHubMajorelDetails[i] = true;
          this.enableCustomerHubCrestEngagementDetails[i] = false;
          this.enableCustomerHubXiatechDetails[i] = false;
        } else if (provider === Providers.RJ) {
          this.enableCustomerHubNetSuiteDetails[i] = false;
          this.enableCustomerHubRJDetails[i] = true;
          this.enableCustomerHubAXDetails[i] = false;
          this.enableCustomerHubGrapheneDetails[i] = false;
          this.enableCustomerHubIEWDetails[i] = false;
          this.enableCustomerHubMajorelDetails[i] = false;
          this.enableCustomerHubCrestEngagementDetails[i] = false;
          this.enableCustomerHubXiatechDetails[i] = false;
        } else if (provider === Providers.AX) {
          this.enableCustomerHubNetSuiteDetails[i] = false;
          this.enableCustomerHubRJDetails[i] = false;
          this.enableCustomerHubAXDetails[i] = true;
          this.enableCustomerHubGrapheneDetails[i] = false;
          this.enableCustomerHubIEWDetails[i] = false;
          this.enableCustomerHubMajorelDetails[i] = false;
          this.enableCustomerHubCrestEngagementDetails[i] = false;
          this.enableCustomerHubXiatechDetails[i] = false;
        } else if (provider === Providers.GRAPHENE) {
          this.enableCustomerHubNetSuiteDetails[i] = false;
          this.enableCustomerHubRJDetails[i] = false;
          this.enableCustomerHubAXDetails[i] = false;
          this.enableCustomerHubGrapheneDetails[i] = true;
          this.enableCustomerHubIEWDetails[i] = false;
          this.enableCustomerHubMajorelDetails[i] = false;
          this.enableCustomerHubCrestEngagementDetails[i] = false;
          this.enableCustomerHubXiatechDetails[i] = false;
        } else if (provider === Providers.CrestEngegement) {
          this.enableCustomerHubNetSuiteDetails[i] = false;
          this.enableCustomerHubRJDetails[i] = false;
          this.enableCustomerHubAXDetails[i] = false;
          this.enableCustomerHubGrapheneDetails[i] = false;
          this.enableCustomerHubIEWDetails[i] = false;
          this.enableCustomerHubMajorelDetails[i] = false;
          this.enableCustomerHubCrestEngagementDetails[i] = true;
          this.enableCustomerHubXiatechDetails[i] = false;
        } else if (provider === Providers.IEW) {
          this.enableCustomerHubNetSuiteDetails[i] = false;
          this.enableCustomerHubRJDetails[i] = false;
          this.enableCustomerHubAXDetails[i] = false;
          this.enableCustomerHubGrapheneDetails[i] = false;
          this.enableCustomerHubIEWDetails[i] = true;
          this.enableCustomerHubMajorelDetails[i] = false;
          this.enableCustomerHubCrestEngagementDetails[i] = false;
          this.enableCustomerHubXiatechDetails[i] = false;
        } else if (provider === Providers.XIATECH) {
          this.enableCustomerHubXiatechDetails[i] = true;
          this.enableCustomerHubNetSuiteDetails[i] = false;
          this.enableCustomerHubRJDetails[i] = false;
          this.enableCustomerHubAXDetails[i] = false;
          this.enableCustomerHubGrapheneDetails[i] = false;
          this.enableCustomerHubIEWDetails[i] = false;
          this.enableCustomerHubMajorelDetails[i] = false;
          this.enableCustomerHubCrestEngagementDetails[i] = false;
        }
      });
    } else {
      ebasketCustomerHubProviderDetails.reset();
    }
    // if (this.ebasket_sales_export_select_api.length !== ebasketCustomerHubProviderDetails.length) {
    //   this.isEBasketSalesExportAddDisabled = false;
    // }
  }
  openCustomerHubSelectProviderPopup() {
    const ebasketCustomerHubProviderDetails = this.parentForm
      .get(this.authControlKey)
      .get('ebasketCustomerHubProviderDetails') as FormArray;
    this.assigenCustomerHubProviders();
    ebasketCustomerHubProviderDetails.push(this.createSelectProviderFormGroup());

    const i = ebasketCustomerHubProviderDetails.value.length - 1;
    this.enableCustomerHubNetSuiteDetails[i] = false;
    this.enableCustomerHubMajorelDetails[i] = false;
    this.enableCustomerHubRJDetails[i] = false;
    this.enableCustomerHubAXDetails[i] = false;
    this.enableCustomerHubIEWDetails[i] = false;
    this.enableCustomerHubGrapheneDetails[i] = false;
    this.enableCustomerHubCrestEngagementDetails[i] = false;
    this.enableCustomerHubXiatechDetails[i] = false;
  }
  enableDisableAddProviders() {
    return this.parentForm.get(this.authControlKey).get('ebasketCustomerHubProviderDetails').value
      .length === CustomerHubProviders.length
      ? true
      : false;
  }
  isProviderSelected() {
    const ebasketCustomerHubProviderDetails = this.parentForm
      .get(this.authControlKey)
      .get('ebasketCustomerHubProviderDetails') as FormArray;
    return ebasketCustomerHubProviderDetails.value.some((p) => {
      return p.customerHubProviderControl === '';
    });
  }

  clearApiDetailsFormArray = (formArray: FormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
  };

  toggleWooseMap() {
    this.isToggleWooseMap = this.isToggleWooseMap ? false : true;
  }
  toggleAddCustomer() {
    this.isToggleAddCustomer = this.isToggleAddCustomer ? false : true;
    this.vailidateAddCustomer();
  }
  toggleCustomerSupport() {
    this.isToggleCustomerSupport = this.isToggleCustomerSupport ? false : true;
  }
  toggleDefaultCustomer() {
    this.isToggleDefaultCustomer = this.isToggleDefaultCustomer ? false : true;
    this.bindDefaultCustomerView();
  }
  toggleMarketingPreference() {
    this.isToggleMarketingPreference = this.isToggleMarketingPreference ? false : true;
  }

  bindDefaultCustomerView() {
    let defaultCustomerFormGroup;
    if (this.isToggleDefaultCustomer) {
      if (
        this.customerHubDetails &&
        this.customerHubDetails.isDefaultCustomer &&
        this.customerHubDetails.defaultCustomer
      ) {
        defaultCustomerFormGroup = this.defultCustomerFormGroupInit(
          this.customerHubDetails.defaultCustomer
        );
      } else {
        defaultCustomerFormGroup = this.defultCustomerFormGroupInit();
      }

      (this.parentForm.get(this.authControlKey) as FormGroup).addControl(
        'defaultCustomer',
        defaultCustomerFormGroup
      );
    } else {
      if (
        this.customerHubDetails &&
        this.customerHubDetails.isDefaultCustomer &&
        this.customerHubDetails.defaultCustomer
      ) {
        this.customerHubDetails.defaultCustomer = {};
      }
      (this.parentForm.get(this.authControlKey) as FormGroup).removeControl('defaultCustomer');
    }
  }
  vailidateAddCustomer() {
    if (!this.parentForm.get(this.authControlKey).get('addCustomer').value) {
      this.parentForm
        .get(this.authControlKey)
        .get('basketScreen')
        .reset();
      this.parentForm
        .get(this.authControlKey)
        .get('paymentScreen')
        .reset();
      this.parentForm
        .get(this.authControlKey)
        .get('customerMobileNumber')
        .reset();
      this.parentForm
        .get(this.authControlKey)
        .get('customerAddressLine')
        .reset();
      this.parentForm
        .get(this.authControlKey)
        .get('customerCity')
        .reset();
      this.parentForm
        .get(this.authControlKey)
        .get('customerRegion')
        .reset();
      this.parentForm
        .get(this.authControlKey)
        .get('customerCountry')
        .reset();
      this.parentForm
        .get(this.authControlKey)
        .get('customerPostCode')
        .reset();
      this.parentForm
        .get(this.authControlKey)
        .get('customerMarketingPreference')
        .reset();
      this.isToggleMarketingPreference = false;
      this.isToggleAddCustomer = true;
      this.parentForm
        .get(this.authControlKey)
        .get('email_marketing')
        .reset();
      this.parentForm
        .get(this.authControlKey)
        .get('text_marketing')
        .reset();
      this.parentForm
        .get(this.authControlKey)
        .get('catalogue_by_post')
        .reset();
      this.parentForm
        .get(this.authControlKey)
        .get('third_party_mailing')
        .reset();
      this.parentForm
        .get(this.authControlKey)
        .get('phone_marketing')
        .reset();
    }
  }
  subscribeSelectCountries() {
    // Countries subscribe
    this.filteredCountries.next(this.countryList.slice());

    this.parentForm
      .get(this.authControlKey)
      .get('countriesMultiFilterCtrl')
      .valueChanges.pipe()
      .subscribe(() => {
        this.filterCountries();
      });
  }
  filterCountries() {
    if (!this.countryList) {
      return;
    }
    // get the search keyword
    let search = this.parentForm.get(this.authControlKey).get('countriesMultiFilterCtrl').value;
    if (!search) {
      this.filteredCountries.next(this.countryList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredCountries.next(
      this.countryList.filter((country) => country.name.toLowerCase().indexOf(search) > -1)
    );
  }

  isRequired(control: string) {
    let isRequired = false;
    const formControlObject = this.parentForm!.get(this.authControlKey)!.get(control);
    if (formControlObject !== null) {
      if (formControlObject!.validator !== null) {
        if (formControlObject!.validator({} as AbstractControl) !== null) {
          isRequired = formControlObject!.validator({} as AbstractControl)!.required ? true : false;
        }
      }
    }
    return isRequired ? true : false;
  }
  // ngAfterContentInit(): void {
  //   this.subscriptions.push(
  //     this.parentForm.get(this.authControlKey).valueChanges.subscribe((value) => {
  //       const invalid = [];
  //       const controls = (this.parentForm.get(this.authControlKey) as FormGroup).controls;

  //       for (const name in controls) {
  //         if (controls[name].invalid) {
  //           invalid.push(name);
  //         }
  //       }
  //       console.log(invalid);
  //     })
  //   );
  // }
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }
}
