import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { RadioGroupItem, TreeviewItem } from '@pmcretail/angular-corelib';
import { UserService } from '@services/user.service';
import { INodeStructure, NodeType } from '@shared/interfaces';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class NodeStructureService {
  constructor(private userService: UserService, @Inject(DOCUMENT) private doc: Document) {}
  parentNodes = new Map();
  parentNodeArray: INodeStructure[] = [];
  nodeStructureValue: INodeStructure[] = [];
  nodeStructureChildern: any[] = [];

  /**
   * As of now it will handle for single organization.
   */
  getCompanies(parent: string[] = null, haveChild: boolean = false): Observable<any[]> {
    return this.userService.getUserNodeStructure().pipe(
      map((userNodeStructure) => {
        if (haveChild) {
          // create nested hierachy from company to store
          return this.toTree(userNodeStructure);
        } else {
          return this.getNodesWithTypeAndParent(userNodeStructure, NodeType.COMPANY, parent);
        }
      })
    );
  }

  /**
   *
   * @param userNodeStructure node Structure which we get from userService having method (getUserNodeStructure)
   * @param nodeType [ like COMPANY ,  TRADING_REGION ,  STORE_GROUP ,  STORE ]
   * @param parent optional if we want to get node with specific parent like get trading regions having parent  company 1 and company 2
   */
  getNodesWithTypeAndParent(
    userNodeStructure: any[],
    nodeType: NodeType,
    parent: string[] = null
  ): any[] {
    if (userNodeStructure) {
      return userNodeStructure
        .filter(
          (node) =>
            node.nodeType === nodeType &&
            (parent == null || (parent && parent.includes(node.parentId)))
        )
        .map((i) => ({
          __type: i.nodeType,
          text: i.name,
          value: {
            id: i.nodeId,
            parentId: i.parentId,
            status: i.status,
            isOverride: i.isOverride
          },
          child: []
        }));
    }
  }

  /**
   * To get whole tree structure from organization to store.
   */
  getTreestructure(
    removeNodeType: NodeType = null,
    isChildern: boolean = null,
    parentNodeType: NodeType = null,
    status: boolean = true
  ): Observable<any> {
    if (isChildern) {
      return this.userService
        .getUserNodeStructure()
        .pipe(
          map((data) => this.toTreeWithChildren(data, null, removeNodeType, parentNodeType, status))
        );
    }
    return this.userService
      .getUserNodeStructure()
      .pipe(map((data) => this.toTree(data, null, removeNodeType)));
  }

  /**
   * This will work only for single organization
   * recursive call which basically generation whole hierarchy.
   * @param userNodeStructure  node Structure which we get from userService having method (getUserNodeStructur
   * @param parentNode no need pass parentNode.
   */
  private toTree(
    userNodeStructure: any[],
    parentNode: any = null,
    removeNodeType: NodeType = null
  ) {
    if (userNodeStructure) {
      if (!parentNode) {
        parentNode = userNodeStructure.find((node) => node.parentId === '');
        parentNode.value = { ...parentNode };
        parentNode.text = parentNode.name;
        parentNode.value.__type = parentNode.nodeType;
      }

      const parent = { ...parentNode };
      const children = userNodeStructure
        .filter((x) => x.parentId === parent.nodeId && x.nodeType !== removeNodeType)
        .map((y) => this.toTree(userNodeStructure, y, removeNodeType));

      if (children.length !== 0) {
        parent.children = children.map((i) => ({
          text: i.name,
          value: {
            id: i.nodeId,
            parentId: i.parentId,
            status: i.status,
            isOverride: i.isOverride,
            __type: i.nodeType,
            value: {
              id: i.nodeId,
              parentId: i.parentId,
              status: i.status,
              isOverride: i.isOverride,
              __type: i.nodeType
            }
          },
          children: i.children
        }));
      }
      return parent;
    }
  }

  /**
   * This will work only for single organization
   * recursive call which basically generation whole hierarchy.
   * @param userNodeStructure  node Structure which we get from userService having method (getUserNodeStructur
   * @param parentNode no need pass parentNode.
   */
  toTreeWithChildren(
    userNodeStructure: any[],
    parentNode: any = null,
    removeNodeType: NodeType = null,
    parentNodeType: NodeType = null,
    status: boolean = true
  ) {
    if (userNodeStructure) {
      if (!parentNode) {
        parentNode = userNodeStructure.find((node) => node.nodeType === parentNodeType);
        parentNode.value = { ...parentNode };
        parentNode.text = parentNode.name;
        parentNode.value.__type = parentNode.nodeType;
        parentNode.action = parentNode.nodeId;
      }
      const parent = { ...parentNode };
      const children = userNodeStructure
        .filter(
          (x) =>
            x.parentId === parent.nodeId &&
            x.nodeType !== removeNodeType &&
            (x.status === true || x.status === status)
        )
        .map((y) =>
          this.toTreeWithChildren(userNodeStructure, y, removeNodeType, parentNodeType, status)
        );
      if (children.length !== 0) {
        parent.children = children.map((i) => ({
          text: i.name,
          value: {
            id: i.nodeId,
            parentId: i.parentId,
            status: i.status,
            isOverride: i.isOverride,
            region: i.region,
            __type: i.nodeType
          },
          action: i.nodeId,
          children: i.children,
          nodeCount: i.children ? i.children.length : ''
        }));
      }
      return parent;
    }
  }
  /**
   * As of now it will handle for single organization and retunr list of trading regions
   */
  getTradingRegions(parent: string[] = null, haveChild: boolean = false): Observable<any[]> {
    return this.userService.getUserNodeStructure().pipe(
      map((userNodeStructure) => {
        if (haveChild) {
          // create nested hierachy from company to store
          return this.toTree(userNodeStructure);
        } else {
          return this.getNodesWithTypeAndParent(userNodeStructure, NodeType.TRADING_REGION, parent);
        }
      })
    );
  }

  /**
   * As of now it will handle for single organization and return list of trading regions
   */
  getStores(parent: string[] = null, haveChild: boolean = false): Observable<any[]> {
    return this.userService.getUserNodeStructure().pipe(
      map((userNodeStructure) => {
        if (haveChild) {
          // create nested hierachy from company to store
          return this.toTree(userNodeStructure);
        } else {
          return this.getNodesWithTypeAndParent(userNodeStructure, NodeType.STORE, parent);
        }
      })
    );
  }

  /**
   * It will migrate Node structure store group hierarchy to tree view structure
   */
  getStoreGroups(tradingRegions, haveChild = false): Observable<RadioGroupItem[]> {
    return this.userService.getUserNodeStructure().pipe(
      map((userNodeStructure) => {
        return this.buildTree(userNodeStructure, tradingRegions);
      })
    );
  }

  private buildTree(
    arr: INodeStructure[],
    parent: string[] = null,
    haveChild: boolean = false
  ): RadioGroupItem[] {
    let tree: RadioGroupItem[] = [];

    tree = arr
      .filter((node) => node.nodeType === NodeType.STORE_GROUP)
      .map(
        (ele) =>
          new RadioGroupItem({
            id: ele.nodeId,
            parentId: ele.parentId,
            text: ele.name,
            value: { name: ele.name, value: ele.nodeId },
            children: []
          })
      );

    // this method create hierarchy of parent child
    tree.forEach((ele) => {
      const parentElement: any = tree.find((val) => {
        return ele.parentId === val.id;
      });
      if (ele.parentId && ele.parentId.trim() !== '' && parentElement !== undefined) {
        let existingChildren = parentElement.childs;
        if (existingChildren === undefined) {
          existingChildren = [];
        }
        existingChildren.push(ele);
        parentElement.childs = existingChildren;
      }
    });

    // this method returns list of parent nodes of store group
    const parentNode = [];
    const listOfRootNode = tree
      .filter((el) => !tree.filter((p) => p.id === el.parentId).length)
      .map((el) => ({ id: el.id }));
    listOfRootNode.forEach((el) => parentNode.push(el.id));

    if (parent && parent.length) {
      return tree.filter((el) => {
        return (
          parentNode.includes(el.id) && (parent == null || (parent && parent.includes(el.parentId)))
        );
      });
    }

    // this will returns new array of parent node only
    return tree.filter((el) => parentNode.includes(el.id));
  }

  getParentNodesByChild(child): Observable<any> {
    return this.userService.getUserNodeStructure().pipe(
      map((data) => {
        // this.parentNodes.clear();
        return this.getNodesByChilds(data, child);
      })
    );
  }

  private getNodesByChilds(userNodeStructure, child): Map<string, string> {
    userNodeStructure
      .filter((x) => x.nodeId === child.parentId)
      .map((y) => this.getNodesByChilds(userNodeStructure, y));

    if (child) {
      this.parentNodes.set(child.nodeType ? child.nodeType : '', child.name ? child.name : '');
    }
    return this.parentNodes;
  }
  /**
   * This method will convert node structure that is in tree structure into flat structure
   */
  convertNodeStructureInFlatStructure(nodeStructure: any[]) {
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < nodeStructure.length; i++) {
      if (nodeStructure[i].value) {
        const nodeFormate: INodeStructure = {
          name: nodeStructure[i].text,
          nodeId: nodeStructure[i].value.id
            ? nodeStructure[i].value.id
            : nodeStructure[i].value.nodeId,
          parentId: nodeStructure[i].value.parentId,
          nodeType: nodeStructure[i].value.__type,
          status: nodeStructure[i].value.status,
          isOverride: nodeStructure[i].value.isOverride,
          region:
            nodeStructure[i] && nodeStructure[i].value && nodeStructure[i].value.region
              ? nodeStructure[i].value.region
              : []
        };
        this.nodeStructureValue.push(nodeFormate);
      }

      if (nodeStructure[i].children) {
        this.nodeStructureChildern.push(nodeStructure[i].children);
        this.convertNodeStructureInFlatStructure(nodeStructure[i].children);
      }
    }
    return this.nodeStructureValue;
  }

  /**
   *
   * @param child This method will return flat node structure by child
   */
  getparentFlatNodeStrutureByChild(child): Observable<INodeStructure[]> {
    return this.userService
      .getUserNodeStructure()
      .pipe(map((data) => this.getFlatNodesStructureByChild(data, child, child)));
  }
  getFlatNodesStructureByChild(userNodeStructure, child, currentNode): INodeStructure[] {
    const parentId = child.value ? child.value.parentId : child.parentId;
    userNodeStructure
      .filter((x) => x.nodeId === parentId)
      .map((y) => this.getFlatNodesStructureByChild(userNodeStructure, y, ''));
    if (child !== currentNode) {
      delete child.text;
      delete child.value;
      // TODO: When we develop is override functionality will pass proper value
      child.isOverride = false;
      this.parentNodeArray.push(child);
    }
    return this.parentNodeArray;
  }

  /**
   * To get whole tree structure from organization to store for organisation vie screen.
   * We have created this method because organisation view screen required node structure with
   * label, styleClass, data, value fields
   */
  getOrganizationTreeStructure(
    removeNodeType: NodeType = null,
    parentNodeType: NodeType = null
  ): Observable<any> {
    return this.userService
      .getUserNodeStructure()
      .pipe(map((data) => this.toTreeForOrgStructure(data, null, removeNodeType, parentNodeType)));
  }

  /**
   * This will develop only for organization view screen
   * recursive call which basically generation whole hierarchy.
   * @param userNodeStructure  node Structure which we get from userService having method (getUserNodeStructur
   * @param parentNode no need pass parentNode.
   */
  private toTreeForOrgStructure(
    userNodeStructure: any[],
    parentNode: any = null,
    removeNodeType: NodeType = null,
    parentNodeType: NodeType = null
  ) {
    /** TODO:
     * Created static map in that key is node type and value is styleClass
     * StyleClass attribute is required in ui/html for identify types of node using css
     */
    const styleClassMap = new Map();
    styleClassMap.set(NodeType.ORGANIZATION, 'c-org-chart-node');
    styleClassMap.set(NodeType.COMPANY, 'c-org-chart-company-node');
    styleClassMap.set(NodeType.TRADING_REGION, 'c-org-chart-tradingregion-node');
    styleClassMap.set(NodeType.STORE_GROUP, 'c-org-chart-storegroup-node');
    styleClassMap.set(NodeType.STORE, 'c-org-chart-store-node');

    if (userNodeStructure) {
      if (!parentNode) {
        if (parentNodeType) {
          parentNode = userNodeStructure.find((node) => node.nodeType === parentNodeType);
        } else {
          parentNode = userNodeStructure.find((node) => node.parentId === '');
        }
        parentNode.data = { ...parentNode };
        parentNode.label = parentNode.name;
        parentNode.expanded = true;
        parentNode.type = parentNode.nodeType;
        parentNode.styleClass = styleClassMap.get(parentNode.nodeType);
      }
      const parent = { ...parentNode };
      const children = userNodeStructure
        .filter((x) => x.parentId === parent.nodeId && x.nodeType !== removeNodeType)
        .map((y) =>
          this.toTreeForOrgStructure(userNodeStructure, y, removeNodeType, parentNodeType)
        );

      if (children.length !== 0) {
        parent.children = children.map((i) => ({
          label: i.name,
          data: {
            id: i.nodeId,
            parentId: i.parentId,
            status: i.status,
            isOverride: i.isOverride,
            __type: i.nodeType
          },
          children: i.children,
          expanded: true,
          type: i.nodeType,
          styleClass: styleClassMap.get(i.nodeType)
        }));
      }
      return parent;
    }
  }
  getIdsFromStoreGroupTreeView(treeview: TreeviewItem[]) {
    const storeGroupIds: string[] = [];

    treeview.forEach((t) => {
      this.buildStoreGroupIds(t, storeGroupIds);
    });

    return storeGroupIds;
  }

  buildStoreGroupIds(obj: any, storeGroupIds: string[]) {
    storeGroupIds.push(obj.value.value);
    if (!obj.internalChildren) {
      return storeGroupIds;
    }

    obj.internalChildren.forEach((child) => this.buildStoreGroupIds(child, storeGroupIds));
  }

  getNodeStructureForLoginUser(
    userNodeStructure: any[],
    parentNode: any = null,
    removeNodeType: NodeType = null,
    parentNodeType: NodeType = null,
    iseditForm: boolean = false
  ): Observable<any> {
    return this.userService.getUserNodeStructure().pipe(
      map((data) => {
        // filter only nodes whose status is true
        const filterEnabledNodes = data.filter((n) => n.status === true);
        // code will un checked nodes that is not assign to login user
        filterEnabledNodes.filter((node) => {
          node.checked = false;
          const isEnable = userNodeStructure.some((userNode) => {
            return userNode.nodeId === node.nodeId;
          });
          if (isEnable) {
            node.checked = true;
          }
        });
        // Code will filter only login user nodes and disabled that nodes
        const userNodes = userNodeStructure.filter((userNode) => {
          const isContain = filterEnabledNodes.some((elem) => {
            return userNode.nodeId === elem.nodeId;
          });
          if (!isContain) {
            userNode.disabled = true;
            return userNode;
          }
        });
        return this.getEnableLoginUserTreeStructure(
          filterEnabledNodes.concat(userNodes),
          parentNode,
          removeNodeType,
          parentNodeType,
          iseditForm
        );
      })
    );
  }
  private getEnableLoginUserTreeStructure(
    loginUserNodeStructure: any[],
    parentNode: any = null,
    removeNodeType: NodeType = null,
    parentNodeType: NodeType = null,
    iseditForm: boolean = null
  ) {
    if (loginUserNodeStructure) {
      if (iseditForm) {
        loginUserNodeStructure = loginUserNodeStructure.filter((item) => item.checked === true);
      }
      if (!parentNode) {
        parentNode = loginUserNodeStructure.find((node) => node.nodeType === parentNodeType);
        parentNode.value = { ...parentNode };
        parentNode.text = parentNode.name;
        parentNode.value.__type = parentNode.nodeType;
      }
      const parent = { ...parentNode };
      const children = loginUserNodeStructure
        .filter((x) => x.parentId === parent.nodeId && x.nodeType !== removeNodeType)
        .map((y) =>
          this.getEnableLoginUserTreeStructure(
            loginUserNodeStructure,
            y,
            removeNodeType,
            parentNodeType,
            iseditForm
          )
        );
      if (children.length !== 0) {
        parent.children = children.map((i) => ({
          text: i.name,
          disabled: i.disabled,
          checked: i.checked,
          region: i.region,
          value: {
            id: i.nodeId,
            parentId: i.parentId,
            status: i.status,
            isOverride: i.isOverride,
            region: i.region,
            __type: i.nodeType
          },
          children: i.children
        }));
      }
      return parent;
    }
  }

  getNodeStructureByRootNode(rootNodeType: NodeType = null): Observable<any> {
    return this.userService.getUserNodeStructure().pipe(
      map((userNodeStructure) => {
        if (userNodeStructure) {
          const childNode = userNodeStructure.filter((node) => node.nodeType === rootNodeType);
          return this.getFlatNodesStructureByChild(
            userNodeStructure,
            childNode[0],
            childNode[0]
          ).concat(childNode);
        }
      })
    );
  }
}
