import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { Providers } from '@shared/interfaces';

@Component({
  selector: 'xiatech-customer-hub-details-settings',
  templateUrl: './xiatech-customer-hub-details-settings.component.html',
  styleUrls: ['./xiatech-customer-hub-details-settings.component.scss']
})
export class XiatechCustomerHubDetailsSettingsComponent implements OnInit {
  @Input() action: string;
  @Input() parentForm: FormGroup;
  @Input() customerHubDetails: any;
  @Input() authControlKey: string;
  apiDetails: FormGroup;
  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.initForm();
  }
  initForm() {
    this.parentForm.addControl(
      this.authControlKey,
      this.formBuilder.group({
        xiatechToken: [
          {
            value:
              this.customerHubDetails &&
              this.customerHubDetails.authenticationDetails &&
              this.customerHubDetails.authenticationDetailsKey
                ? Providers.SECRET
                : '',
            disabled: this.action === 'view' ? true : false
          },
          Validators.required
        ],
        authenticationDetailKey: [
          {
            value:
              this.customerHubDetails &&
              this.customerHubDetails.authenticationDetails &&
              this.customerHubDetails.authenticationDetailsKey
                ? this.customerHubDetails.authenticationDetailsKey
                : '',
            disabled: this.action === 'view' ? true : false
          }
        ]
      })
    );
  }

  isRequired(control: string) {
    let isRequired = false;
    const formControlObject = this.parentForm.get(this.authControlKey).get(control);

    if (formControlObject !== null) {
      if (formControlObject.validator !== null) {
        if (formControlObject.validator({} as AbstractControl) !== null) {
          isRequired = formControlObject.validator({} as AbstractControl).required ? true : false;
        }
      }
    }
    return isRequired ? true : false;
  }
}
