import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommonServiceService {
  isDrawerOpened: boolean;
  constructor() {}

  setDraweValue(status: boolean) {
    this.isDrawerOpened = status;
  }

  getDrawerStatus() {
    return this.isDrawerOpened;
  }
}
