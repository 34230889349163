import { Injectable } from '@angular/core';
import * as jwt_decode from 'jwt-decode';
import { Observable, of } from 'rxjs';
import { UserLoginResponse, UserLoginResponseDecoded } from './state/auth.model';

@Injectable()
export class LocalStorageJwtService {
  getItem(): UserLoginResponse | null {
    try {
      const data: UserLoginResponse = JSON.parse(localStorage.getItem('SESSION'));
      if (data) {
        const decodeAccessToken = jwt_decode.default(data.accessToken);
        const tokenTime = decodeAccessToken['exp'] * 1000;
        const currentDate: any = new Date().getTime();
        if (currentDate > tokenTime) {
          this.removeItem();
          return null;
        } else {
          return data;
        }
      }
      return null;
    } catch (ex) {
      return null;
    }
  }

  getDecodedItem(data = this.getItem()): UserLoginResponseDecoded | null {
    if (data) {
      return {
        idToken: jwt_decode.default(data.idToken),
        accessToken: jwt_decode.default(data.accessToken),
        refreshToken: data.refreshToken
      };
    }
    return null;
  }

  getCurrentUser() {
    const token = this.getDecodedItem();
    if (token && token.idToken) {
      return {
        email: token.idToken.email,
        isLoggedIn: true,
        roles: JSON.parse(token.idToken['custom:roles']),
        userId: token.idToken['custom:user_id'],
        username: token.idToken['cognito:username'],
        rootNode: token.idToken['custom:rootNode'],
        apiKey: token.idToken['custom:apiKey'],
        rootNodeId: token.idToken['custom:rootNodeId'],
        organizationId: token.idToken['custom:organizationId']
      };
    }
    return null;
  }

  setItem(data: UserLoginResponse): UserLoginResponse {
    localStorage.setItem('SESSION', JSON.stringify(data));
    return data;
  }

  removeItem(): Observable<boolean> {
    for (const [KEY] of Object.entries(localStorage)) {
      if (KEY !== 'APP_SETTING') {
        localStorage.removeItem(KEY);
      }
    }
    return of(true);
  }
}
