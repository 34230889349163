import { Component, Input, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl,
  FormControl,
  FormArray
} from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import {
  EbasketApiMethodTypes,
  Providers,
  WebReturnsSalesforceOSUSelectApi
} from '@shared/interfaces';
import { SelectApiPopupComponent } from '@shared/select-api-popup/select-api-popup.component';

@Component({
  selector: 'app-salesforce-xml-provider-details-settings',
  templateUrl: './salesforce-xml-provider-details-settings.component.html',
  styleUrls: ['./salesforce-xml-provider-details-settings.component.scss']
})
export class SalesforceXmlProviderDetailsSettingsComponent implements OnInit {
  @Input() action: string;
  @Input() parentForm: FormGroup;
  @Input() webReturnsSalesforceXmlFtpDetails: any;
  @Input() webReturnsSalesforceXmlAuthDetails: any;
  @Input() authControlKey: string;
  @Input() provider: string;
  // @Output() updateApiData: EventEmitter<any> = new EventEmitter();
  apiDetails: FormGroup;
  webreturns_salesforcexml_select_api = [
    {
      id: 1,
      name: WebReturnsSalesforceOSUSelectApi.GetOrder,
      value: WebReturnsSalesforceOSUSelectApi.GetOrder,
      isDisabled: false
    }
  ];
  isWebReturnsSalesforcexmlAddDisabled = false;
  method_types = [
    { id: 1, name: EbasketApiMethodTypes.Get, value: EbasketApiMethodTypes.Get },
    { id: 2, name: EbasketApiMethodTypes.Post, value: EbasketApiMethodTypes.Post },
    { id: 3, name: EbasketApiMethodTypes.Put, value: EbasketApiMethodTypes.Put },
    { id: 4, name: EbasketApiMethodTypes.Patch, value: EbasketApiMethodTypes.Patch },
    { id: 5, name: EbasketApiMethodTypes.Delete, value: EbasketApiMethodTypes.Delete }
  ];
  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.initForm();
  }
  initForm() {
    this.parentForm.addControl(
      this.authControlKey,
      this.formBuilder.group({
        userFtpUrl: [
          {
            value:
              this.webReturnsSalesforceXmlFtpDetails &&
              (this.webReturnsSalesforceXmlFtpDetails.ftpConfiguration ||
                this.webReturnsSalesforceXmlFtpDetails.ftpConfigurationKey)
                ? Providers.SECRET
                : '',
            disabled: this.action === 'view' ? true : false
          },
          [Validators.required]
        ],

        userFtpUserName: [
          {
            value:
              this.webReturnsSalesforceXmlFtpDetails &&
              (this.webReturnsSalesforceXmlFtpDetails.ftpConfiguration ||
                this.webReturnsSalesforceXmlFtpDetails.ftpConfigurationKey)
                ? Providers.SECRET
                : '',
            disabled: this.action === 'view' ? true : false
          },
          [Validators.required]
        ],

        userFtpPassword: [
          {
            value:
              this.webReturnsSalesforceXmlFtpDetails &&
              (this.webReturnsSalesforceXmlFtpDetails.ftpConfiguration ||
                this.webReturnsSalesforceXmlFtpDetails.ftpConfigurationKey)
                ? Providers.SECRET
                : '',
            disabled: this.action === 'view' ? true : false
          },
          [Validators.required]
        ],

        ftpPath: [
          {
            value:
              this.webReturnsSalesforceXmlFtpDetails &&
              (this.webReturnsSalesforceXmlFtpDetails.ftpConfiguration ||
                this.webReturnsSalesforceXmlFtpDetails.ftpConfigurationKey)
                ? Providers.SECRET
                : '',
            disabled: this.action === 'view' ? true : false
          },
          [Validators.required]
        ],

        userFtpPort: [
          {
            value:
              this.webReturnsSalesforceXmlFtpDetails &&
              (this.webReturnsSalesforceXmlFtpDetails.ftpConfiguration ||
                this.webReturnsSalesforceXmlFtpDetails.ftpConfigurationKey)
                ? Providers.SECRET
                : '',
            disabled: this.action === 'view' ? true : false
          },
          [Validators.required]
        ],

        sftp: [
          {
            value:
              this.webReturnsSalesforceXmlFtpDetails &&
              this.webReturnsSalesforceXmlFtpDetails.ftpConfiguration &&
              this.webReturnsSalesforceXmlFtpDetails.ftpConfiguration.sftp
                ? this.webReturnsSalesforceXmlFtpDetails.ftpConfiguration.sftp
                : false,
            disabled: this.action === 'view' ? true : false
          }
        ],
        clientId: [
          {
            value:
              this.webReturnsSalesforceXmlAuthDetails &&
              (this.webReturnsSalesforceXmlAuthDetails.authenticationDetails ||
                this.webReturnsSalesforceXmlAuthDetails.authenticationDetailsKey)
                ? Providers.SECRET
                : '',
            disabled: this.action === 'view' ? true : false
          },
          [Validators.required]
        ],
        url: [
          {
            value:
              this.webReturnsSalesforceXmlAuthDetails &&
              this.webReturnsSalesforceXmlAuthDetails.authenticationDetails &&
              this.webReturnsSalesforceXmlAuthDetails.authenticationDetails.url
                ? this.webReturnsSalesforceXmlAuthDetails.authenticationDetails.url
                : '',
            disabled: this.action === 'view' ? true : false
          },
          [Validators.required]
        ],
        secretKey: [
          {
            value:
              this.webReturnsSalesforceXmlAuthDetails &&
              (this.webReturnsSalesforceXmlAuthDetails.authenticationDetails ||
                this.webReturnsSalesforceXmlAuthDetails.authenticationDetailsKey)
                ? Providers.SECRET
                : '',
            disabled: this.action === 'view' ? true : false
          },
          [Validators.required]
        ],
        grantType: [
          {
            value:
              this.webReturnsSalesforceXmlAuthDetails &&
              this.webReturnsSalesforceXmlAuthDetails.authenticationDetails &&
              this.webReturnsSalesforceXmlAuthDetails.authenticationDetails.grantType
                ? this.webReturnsSalesforceXmlAuthDetails.authenticationDetails.grantType
                : '',
            disabled: this.action === 'view' ? true : false
          },
          [Validators.required]
        ],
        salesforcexmlApiDetails: this.formBuilder.array([])
      })
    );
    this.bindSalesforcexmlApiDetailsFormGroup();
  }

  openSalesforcexmlSelectApiPopup() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = this.webreturns_salesforcexml_select_api.filter((api) => !api.isDisabled);
    const dialogRef = this.dialog.open(SelectApiPopupComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result !== '') {
        this.webreturns_salesforcexml_select_api.map((element: any) => {
          if (element.value === result) {
            element.isDisabled = true;
            // this.tm_gift_card_api_details.push(element);
            const salesforcexmlApiDetails = this.parentForm
              .get(this.authControlKey)
              .get('salesforcexmlApiDetails') as FormArray;

            salesforcexmlApiDetails.push(this.createSalesforcexmlApiDetailsFormGroup(result));
            if (
              this.webreturns_salesforcexml_select_api.length === salesforcexmlApiDetails.length
            ) {
              this.isWebReturnsSalesforcexmlAddDisabled = true;
            }
          }
        });
      }
    });
  }
  private createSalesforcexmlApiDetailsFormGroup(selectedApi: string): FormGroup {
    return new FormGroup({
      url: new FormControl('', Validators.required),
      method: new FormControl('', Validators.required),
      selectedApi: new FormControl(selectedApi)
    });
  }
  deleteSalesforcexmlApiDetailContainer(index: number) {
    const salesforcexmlApiDetails = this.parentForm
      .get(this.authControlKey)
      .get('salesforcexmlApiDetails') as FormArray;
    this.webreturns_salesforcexml_select_api.map((element: any) => {
      if (element.value === salesforcexmlApiDetails.value[index].selectedApi) {
        element.isDisabled = false;
      }
    });
    if (salesforcexmlApiDetails.length > 0) {
      salesforcexmlApiDetails.removeAt(index);
    } else {
      salesforcexmlApiDetails.reset();
    }
    if (this.webreturns_salesforcexml_select_api.length !== salesforcexmlApiDetails.length) {
      this.isWebReturnsSalesforcexmlAddDisabled = false;
    }
  }

  bindSalesforcexmlApiDetailsFormGroup() {
    const control = this.parentForm
      .get(this.authControlKey)
      .get('salesforcexmlApiDetails') as FormArray;
    if (
      this.webReturnsSalesforceXmlAuthDetails &&
      this.webReturnsSalesforceXmlAuthDetails.authenticationDetails &&
      this.webReturnsSalesforceXmlAuthDetails.authenticationDetails.apiDetails
    ) {
      this.webReturnsSalesforceXmlAuthDetails.authenticationDetails.apiDetails.map((apis: any) => {
        let apiDetails = {};
        apiDetails = {
          url: [
            {
              value: apis.url,
              disabled: this.action === 'view' ? true : false
            },
            Validators.required
          ],
          method: [
            {
              value: apis.method,
              disabled: this.action === 'view' ? true : false
            },
            Validators.required
          ],
          selectedApi: [apis.selectedApi]
        };
        control.push(this.formBuilder.group(apiDetails));
        this.webreturns_salesforcexml_select_api.map((key) => {
          if (key.value === apis.selectedApi) {
            key.isDisabled = true;
          }
        });
      });

      if (this.webreturns_salesforcexml_select_api.length === control.length) {
        this.isWebReturnsSalesforcexmlAddDisabled = true;
      }
    }
  }
  isRequired(control: string) {
    let isRequired = false;
    const formControlObject = this.parentForm.get(this.authControlKey).get(control);

    if (formControlObject !== null) {
      if (formControlObject.validator !== null) {
        if (formControlObject.validator({} as AbstractControl) !== null) {
          isRequired = formControlObject.validator({} as AbstractControl).required ? true : false;
        }
      }
    }
    return isRequired ? true : false;
  }
}
