import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from '@services/notification.service';
import { Observable, of, throwError } from 'rxjs';
import { catchError, concatMap, delay, retry, retryWhen } from 'rxjs/operators';
import { AuthFacade } from 'src/app/feature/auth/state/auth.facade';

export const InterceptorSkipHeader = 'X-Skip-Interceptor';
const SESSION_TIMEOUT = 'Session Timeout';

@Injectable({ providedIn: 'root' })
export class ServerErrorInterceptor implements HttpInterceptor {
  constructor(
    private authFacade: AuthFacade,
    private notificationService: NotificationService,
    private router: Router
  ) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.headers && request.headers.has(InterceptorSkipHeader)) {
      const headers = request.headers.delete(InterceptorSkipHeader);
      return next.handle(request.clone({ headers }));
    } else {
      return next.handle(request).pipe(
        retryWhen((errors) =>
          errors.pipe(
            concatMap((error, count) => {
              if (
                count < 1 &&
                (error.status === 500 || error.status === 502 || error.status === 0)
              ) {
                return of(error.status);
              }
              return throwError(error);
            })
          )
        ),
        catchError((resError: HttpErrorResponse) => {
          let errorMessage = '';
          if (resError.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `Error: ${resError.error.message}`;
          } else {
            // server-side error
            if (resError.status === 401 || resError.status === 403) {
              // refresh token expired
              errorMessage = `Error Code: ${resError.error.messageCode}\nMessage: ${resError.error.message}`;
              // this.notificationService.error(resError.error.message);
              // this.authFacade.logout();
              this.notificationService.error(SESSION_TIMEOUT);
              localStorage.removeItem('SESSION');
              this.router.navigate([`/login`]);
            } else {
              errorMessage = `Error Code: ${resError.error.messageCode}\nMessage: ${resError.error.message}`;
              this.notificationService.error(resError.error.message);
            }
          }
          console.log(errorMessage);
          return throwError(errorMessage);
        })
      );
    }
  }
}
