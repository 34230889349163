import { Pipe, PipeTransform } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IModuleNamesInLower, NodeType } from '@shared/interfaces';
import { LocalStorageJwtService } from 'src/app/feature/auth/local-storage-jwt.service';
import { OrganizationService } from 'src/app/feature/organization/organization.details.service';

@Pipe({
  name: 'moduleDisplayName'
})
export class ModuleDisplayNamePipe implements PipeTransform {
  private result: string | undefined;

  constructor(
    private readonly localStorageJwtService: LocalStorageJwtService,
    private organizationService: OrganizationService,
    private activatedRoute: ActivatedRoute
  ) {}

  transform(value: string): any {
    const moduleName = value.replace(/-/g, '').toLowerCase();
    if (moduleName.includes(IModuleNamesInLower.ebasket)) {
      const token = this.localStorageJwtService.getDecodedItem();
      if (token && token.idToken) {
        const rootNodeId =
          token.idToken['custom:rootNode'] === NodeType.GLOBAL
            ? this.activatedRoute.snapshot.paramMap.get('id')
            : token.idToken['custom:rootNodeId'].replace('NODE::', '');
        if (rootNodeId) {
          return this.organizationService
            .getNodeConfigById(rootNodeId, true)
            .toPromise()
            .then((res: any) => {
              const nodeConfig: any = res.result;
              if (
                nodeConfig.eBasketMgmtSettings &&
                nodeConfig.eBasketMgmtSettings.length &&
                nodeConfig.eBasketMgmtSettings[0].displaySettings &&
                nodeConfig.eBasketMgmtSettings[0].displaySettings.displayName
              ) {
                const displayName = nodeConfig.eBasketMgmtSettings[0].displaySettings.displayName;
                const regEx = new RegExp(IModuleNamesInLower.ebasket, 'g');
                this.result = moduleName.replace(regEx, displayName);
              } else {
                this.result = value;
              }
              return this.result;
            });
        } else {
          this.result = value;
        }
      } else {
        this.result = value;
      }
    } else {
      this.result = value;
    }
    return Promise.resolve(this.result);
  }
}
