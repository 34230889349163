import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IPaginationResponse, IProductListResponse } from '@shared/interfaces';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService, PATH } from 'src/app/core/api/api.service';

@Injectable()
export class HotkeyService {
  constructor(private apiService: ApiService) {}

  getProductList(
    orgId: string,
    sku: string,
    description = ''
    // filter = '',
    // sortOrder = '',
    // pageNumber = 0,
    // pageSize = 10
  ): Observable<IPaginationResponse<IProductListResponse[]>> {
    return this.apiService
      .get(
        PATH.GET_PRODUCT_LIST,
        new HttpParams()
          .set('orgId', orgId)
          .set('SKU', sku)
          .set('description', description)
        // .set('filter', filter)
        // .set('sortby', sortOrder)
        // .set('offset', pageNumber.toString())
        // .set('limit', pageSize.toString())
      )
      .pipe(
        // tslint:disable-next-line: no-string-literal
        map((res) => res as IPaginationResponse<IProductListResponse[]>)
      );
  }
}
