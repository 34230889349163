import { ICurrencyFormat, IStoreTiming, IWeekDay } from '@shared/interfaces';

const currencyFormatDefaultDataList: ICurrencyFormat[] = [
  {
    id: '1',
    format: '### ###.##'
  },
  {
    id: '2',
    format: '###,###,###.##'
  }
];

const weekDayDefaultDataList: IWeekDay[] = [
  { id: '1', day: 'Sunday' },
  { id: '2', day: 'Monday' },
  { id: '3', day: 'Tuesday' },
  { id: '4', day: 'Wednesday' },
  { id: '5', day: 'Thursday' },
  { id: '6', day: 'Friday' },
  { id: '7', day: 'Saturday' }
];

const storeTimeDefaultDataList: IStoreTiming[] = [
  {
    day: 'Monday',
    openTime: '07:00',
    closeTime: '21:00',
    status: true
  },
  {
    day: 'Tuesday',
    openTime: '07:00',
    closeTime: '21:00',
    status: true
  },
  {
    day: 'Wednesday',
    openTime: '07:00',
    closeTime: '21:00',
    status: true
  },
  {
    day: 'Thursday',
    openTime: '07:00',
    closeTime: '21:00',
    status: true
  },
  {
    day: 'Friday',
    openTime: '07:00',
    closeTime: '21:00',
    status: true
  },
  {
    day: 'Saturday',
    openTime: '07:00',
    closeTime: '21:00',
    status: true
  },
  {
    day: 'Sunday',
    openTime: '07:00',
    closeTime: '21:00',
    status: true
  }
];

const reasonDefaultDataList = ['Christmas', 'Easter'];

const EXCEPTION_DATE_FORMAT = 'DD/MM/YYYY';

const DefaultTimeZone = {
  abbreviation: 'GMT',
  id: '69',
  name: 'Greenwich Mean Time (GMT)',
  offset: '+00:00'
};

export {
  currencyFormatDefaultDataList,
  weekDayDefaultDataList,
  storeTimeDefaultDataList,
  reasonDefaultDataList,
  EXCEPTION_DATE_FORMAT,
  DefaultTimeZone
};
