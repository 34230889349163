import { Directive, OnInit, Optional } from '@angular/core';
import { CanDisable } from '@shared/interfaces';

@Directive({
  selector: '[disable-control]'
})
export class DisableControlDirective implements OnInit {
  constructor(@Optional() private canDisable: CanDisable) {}

  ngOnInit() {
    if (this.canDisable) {
      this.canDisable.form.disable();
    }
  }
}
