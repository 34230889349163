import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CommonConfigFacade } from '@shared/common-config/state/common-config.facade';
import { ICurrency } from '@shared/interfaces';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ng-currency-dropdown',
  templateUrl: './currency-dropdown.component.html',
  styleUrls: ['./currency-dropdown.component.scss']
})
export class CurrencyDropdownComponent implements OnInit, OnDestroy {
  constructor(public commonFacade: CommonConfigFacade) {}
  currencyList: ICurrency[];
  subscriptions: Subscription[] = [];

  @Input() ctrlBaseCurrency: FormControl;
  @Input() placeholder: string;
  @Input() baseCurrency: ICurrency;
  currency: ICurrency;
  @Input() isOrgNode: boolean;

  ngOnInit() {
    this.subscriptions.push(
      this.commonFacade.currencies$.subscribe((data) => {
        this.currencyList = data;
        if (this.baseCurrency) {
          this.currency = this.currencyList.find((c) => c.id === this.baseCurrency.id);
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  compareCurrencies(o1: ICurrency, o2: ICurrency): boolean {
    return o1.id === o2.id;
  }
}
