import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  OnChanges,
  Output,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { AddFemaleShirtAndShortDialogComponent } from './add-femaleShirt-And-Shorts-dialog/add-femaleShirt-And-Shorts-dialog.component';
import { AddFemaleNameAndNumberDialogComponent } from './add-name-and-number-dialog/add-name-and-number-dialog.component';

@Component({
  selector: 'app-module-assignment-female-shirt-and-shorts-configuration',
  templateUrl: './female-shirt-and-shorts-configuration.component.html',
  styleUrls: ['./female-shirt-and-shorts-configuration.component.scss']
})
export class FemaleShirtAndShortsConfigurationComponent implements OnInit, OnChanges {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('input', { static: false }) input: ElementRef;
  @Input() status: boolean;
  @Input() action: string;
  @Input() modelSKU: any[];
  @Input() orgId: string;
  @Output() updateData: EventEmitter<any> = new EventEmitter();
  femaleBadges: any[];
  femaleNameAndNumbers: any[];
  productColumns: any[] = ['Female_BADGE', 'SKU_selection', 'badgeUrl', 'logoUrl', 'action'];
  nameAndNumbersColumns: any[] = [
    'FEMALE_NAME_AND_NUMBER',
    'name_SKU_selection',
    'number_SKU_selection',
    'action'
  ];
  femaleBadgeDataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  femaleNameAndNumberDataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  pageSizeOptions: number[] = [5, 10, 25, 50, 100];

  constructor(private dialog: MatDialog) {}

  ngOnInit() {
    this.orgId = this.orgId ? `NODE::${this.orgId}` : '';
    this.initForm();
  }
  ngOnChanges() {
    console.log('ngOnChanges');
    this.initForm();
    this.femaleBadgeDataSource.data = [...this.femaleBadges];
    this.femaleNameAndNumberDataSource.data = [...this.femaleNameAndNumbers];
    this.updateData.emit({
      status: this.status,
      femaleBadges: this.femaleBadges,
      femaleNameAndNumbers: this.femaleNameAndNumbers
    });
  }

  initForm() {
    this.femaleBadges = [
      {
        id: 'ADULY_SHIRT_PREMIER_BADGE',
        value: 'ADULT_SHIRT_BADGE',
        Female_BADGE: 'ADULT SHIRT PREMIER LEAGUE',
        SKU_selection: '',
        badgeUrl: '',
        logoUrl: ''
      },
      {
        id: 'ADULY_SHIRT_EUROPA_BADGE',
        value: 'ADULT_SHIRT_BADGE',
        Female_BADGE: 'ADULT SHIRT EUROPA LEAGUE',
        SKU_selection: '',
        badgeUrl: '',
        logoUrl: ''
      },
      {
        id: 'ADULY_SHIRT_CHAMPIONS_BADGE',
        value: 'ADULT_SHIRT_BADGE',
        Female_BADGE: 'ADULT SHIRT CHAMPIONS LEAGUE',
        SKU_selection: '',
        badgeUrl: '',
        logoUrl: ''
      },
      {
        id: 'KID_SHIRT_PREMIER_BADGE',
        value: 'KID_SHIRT_BADGE',
        Female_BADGE: 'KID SHIRT PREMIER LEAGUE',
        SKU_selection: '',
        badgeUrl: '',
        logoUrl: ''
      },
      {
        id: 'KID_SHIRT_EUROPA_BADGE',
        value: 'KID_SHIRT_BADGE',
        Female_BADGE: 'KID SHIRT EUROPA LEAGUE',
        SKU_selection: '',
        badgeUrl: '',
        logoUrl: ''
      },
      {
        id: 'KID_SHIRT_CHAMPIONS_BADGE',
        value: 'KID_SHIRT_BADGE',
        Female_BADGE: 'KID SHIRT CHAMPIONS LEAGUE',
        SKU_selection: '',
        badgeUrl: '',
        logoUrl: ''
      },
      {
        id: 'ADULT_SHORTS_PREMIER_BADGE',
        value: 'ADULT_SHORTS_BADGE',
        Female_BADGE: 'ADULT SHORTS PREMIER LEAGUE',
        SKU_selection: '',
        badgeUrl: '',
        logoUrl: ''
      },
      {
        id: 'ADULT_SHORTS_EUROPA_BADGE',
        value: 'ADULT_SHORTS_BADGE',
        Female_BADGE: 'ADULT SHORTS EUROPA LEAGUE',
        SKU_selection: '',
        badgeUrl: '',
        logoUrl: ''
      },
      {
        id: 'ADULT_SHORTS_CHAMPIONS_BADGE',
        value: 'ADULT_SHORTS_BADGE',
        Female_BADGE: 'ADULT SHORTS CHAMPIONS LEAGUE',
        SKU_selection: '',
        badgeUrl: '',
        logoUrl: ''
      },
      {
        id: 'KID_SHORTS_PREMIER_BADGE',
        value: 'KID_SHORTS_BADGE',
        Female_BADGE: 'KID SHORTS PREMIER LEAGUE',
        SKU_selection: '',
        badgeUrl: '',
        logoUrl: ''
      },
      {
        id: 'KID_SHORTS_EUROPA_BADGE',
        value: 'KID_SHORTS_BADGE',
        Female_BADGE: 'KID SHORTS EUROPA LEAGUE',
        SKU_selection: '',
        badgeUrl: '',
        logoUrl: ''
      },
      {
        id: 'KID_SHORTS_CHAMPIONS_BADGE',
        value: 'KID_SHORTS_BADGE',
        Female_BADGE: 'KID SHORTS CHAMPIONS LEAGUE',
        SKU_selection: '',
        badgeUrl: '',
        logoUrl: ''
      },
      {
        id: 'LARGE_SUPER_LEAGUE_PATCH',
        value: 'FEMALE_SHIRT_BADGE',
        Female_BADGE: 'LARGE SUPER LEAGUE PATCH',
        SKU_selection: '',
        badgeUrl: '',
        logoUrl: ''
      },
      {
        id: 'CHAMPIONS_LEAGUE_PATCH',
        value: 'FEMALE_SHIRT_BADGE',
        Female_BADGE: 'CHAMPIONS LEAGUE PATCH',
        SKU_selection: '',
        badgeUrl: '',
        logoUrl: ''
      }
    ];

    this.femaleNameAndNumbers = [
      {
        value: 'ADULT_SHIRT_HOME_NAME_AND_NUMBER',
        id: 'ADULT_SHIRT_HOME',
        FEMALE_NAME_AND_NUMBER: 'ADULT SHIRT HOME',
        name_SKU_selection: '',
        number_SKU_selection: '',
        disable_for: ''
      },
      {
        value: 'ADULT_SHIRT_AWAY_NAME_AND_NUMBER',
        id: 'ADULT_SHIRT_AWAY',
        FEMALE_NAME_AND_NUMBER: 'ADULT SHIRT AWAY',
        name_SKU_selection: '',
        number_SKU_selection: '',
        disable_for: ''
      },
      {
        value: 'ADULT_SHIRT_THIRD_NAME_AND_NUMBER',
        id: 'ADULT_SHIRT_THIRD',
        FEMALE_NAME_AND_NUMBER: 'ADULT SHIRT THIRD',
        name_SKU_selection: '',
        number_SKU_selection: '',
        disable_for: ''
      },
      {
        value: 'KID_SHIRT_HOME_NAME_AND_NUMBER',
        id: 'KID_SHIRT_HOME',
        FEMALE_NAME_AND_NUMBER: 'KID SHIRT HOME',
        name_SKU_selection: '',
        number_SKU_selection: '',
        disable_for: ''
      },
      {
        value: 'KID_SHIRT_AWAY_NAME_AND_NUMBER',
        id: 'KID_SHIRT_AWAY',
        FEMALE_NAME_AND_NUMBER: 'KID SHIRT AWAY',
        name_SKU_selection: '',
        number_SKU_selection: '',
        disable_for: ''
      },
      {
        value: 'KID_SHIRT_THIRD_NAME_AND_NUMBER',
        id: 'KID_SHIRT_THIRD',
        FEMALE_NAME_AND_NUMBER: 'KID SHIRT THIRD',
        name_SKU_selection: '',
        number_SKU_selection: '',
        disable_for: ''
      },
      {
        value: 'ADULT_SHORTS_HOME_NAME_AND_NUMBER',
        id: 'ADULT_SHORTS_HOME',
        FEMALE_NAME_AND_NUMBER: 'ADULT SHORTS HOME',
        name_SKU_selection: '',
        number_SKU_selection: '',
        disable_for: 'name'
      },
      {
        value: 'ADULT_SHORTS_AWAY_NAME_AND_NUMBER',
        id: 'ADULT_SHORTS_AWAY',
        FEMALE_NAME_AND_NUMBER: 'ADULT SHORTS AWAY',
        name_SKU_selection: '',
        number_SKU_selection: '',
        disable_for: 'name'
      },
      {
        value: 'ADULT_SHORTS_THIRD_NAME_AND_NUMBER',
        id: 'ADULT_SHORTS_THIRD',
        FEMALE_NAME_AND_NUMBER: 'ADULT SHORTS THIRD',
        name_SKU_selection: '',
        number_SKU_selection: '',
        disable_for: 'name'
      },
      {
        value: 'KID_SHORTS_HOME_NAME_AND_NUMBER',
        id: 'KID_SHORTS_HOME',
        FEMALE_NAME_AND_NUMBER: 'KID SHORTS HOME',
        name_SKU_selection: '',
        number_SKU_selection: '',
        disable_for: 'name'
      },
      {
        value: 'KID_SHORTS_AWAY_NAME_AND_NUMBER',
        id: 'KID_SHORTS_AWAY',
        FEMALE_NAME_AND_NUMBER: 'KID SHORTS AWAY',
        name_SKU_selection: '',
        number_SKU_selection: '',
        disable_for: 'name'
      },
      {
        value: 'KID_SHORTS_THIRD_NAME_AND_NUMBER',
        id: 'KID_SHORTS_THIRD',
        FEMALE_NAME_AND_NUMBER: 'KID SHORTS THIRD',
        name_SKU_selection: '',
        number_SKU_selection: '',
        disable_for: 'name'
      },
      {
        value: 'ADULT_SHIRT_RETRO_NAME',
        id: 'ADULT_SHIRT_RETRO',
        FEMALE_NAME_AND_NUMBER: 'ADULT SHIRT RETRO',
        name_SKU_selection: '',
        number_SKU_selection: '',
        disable_for: 'number'
      },
      {
        value: 'ADULT_SHIRT_RETRO_NAME',
        id: 'KID_SHIRT_RETRO',
        FEMALE_NAME_AND_NUMBER: 'KID SHIRT RETRO',
        name_SKU_selection: '',
        number_SKU_selection: '',
        disable_for: 'number'
      }
    ];
    this.modelSKU.forEach((item: any) => {
      if (item.type === 'BADGE' && item.gender === 'FEMALE') {
        this.femaleBadges = this.femaleBadges.map((x: any) => {
          let isVat = {};
          let sku_selection = '';
          item.SKU.map((ele) => {
            if (ele.isVat) {
              isVat[`${ele.SKU}`] = true;
              if (sku_selection.length > 0) {
                sku_selection += `${ele.SKU}`;
              } else {
                sku_selection += `${ele.SKU},`;
              }
            } else {
              isVat[`${ele.SKU}`] = false;
              if (sku_selection.length > 0) {
                sku_selection += `${ele.SKU}`;
              } else {
                sku_selection += `${ele.SKU},`;
              }
            }
          });
          if (x.id === item.id) {
            x.SKU_selection = sku_selection;
            x.badgeUrl = item.SKU[0] ? item.SKU[0].badgeUrl : '';
            x.logoUrl = item.SKU[0] ? item.SKU[0].logoUrl : '';
            x.isVat = isVat;
          }
          return x;
        });
      }
      if (item.type === 'NAME_AND_NUMBER' && item.gender === 'FEMALE') {
        this.femaleNameAndNumbers = this.femaleNameAndNumbers.map((x: any) => {
          if (x.id === item.id) {
            x.name_SKU_selection = item.SKU.filter((y) => y.type === 'name')[0]
              ? item.SKU.filter((y) => y.type === 'name')[0].SKU
              : '';
            x.number_SKU_selection = item.SKU.filter((y) => y.type === 'number')[0]
              ? item.SKU.filter((y) => y.type === 'number')[0].SKU
              : '';
          }
          return x;
        });
      }
    });
    this.femaleBadgeDataSource.data = [...this.femaleBadges];
    this.femaleNameAndNumberDataSource.data = [...this.femaleNameAndNumbers];
  }

  toggleEbasketFemaleShirtConfiguration() {
    if (!this.status) {
      this.femaleBadges = [];
      this.femaleBadgeDataSource.data = [];
      this.femaleNameAndNumbers = [];
      this.femaleNameAndNumberDataSource.data = [];
    } else {
      this.initForm();
    }
    this.updateData.emit({
      status: this.status,
      femaleBadges: this.femaleBadges,
      femaleNameAndNumbers: this.femaleNameAndNumbers
    });
  }

  removeRow(element: any) {
    this.femaleBadges = this.femaleBadges.map((p) => {
      if (p.Female_BADGE === element.Female_BADGE) {
        p.SKU_selection = '';
        p.badgeUrl = '';
        p.logoUrl = '';
      }
      return p;
    });
    this.updateData.emit({
      status: this.status,
      femaleBadges: this.femaleBadges,
      femaleNameAndNumbers: this.femaleNameAndNumbers
    });
    this.femaleBadgeDataSource.data = [...this.femaleBadges];
  }

  removeNameAndNumberRow(element: any) {
    this.femaleNameAndNumbers = this.femaleNameAndNumbers.map((p: any) => {
      if (p.FEMALE_NAME_AND_NUMBER === element.FEMALE_NAME_AND_NUMBER) {
        p.SKU_selection = '';
        p.name_SKU_selection = '';
        p.number_SKU_selection = '';
      }
      return p;
    });
    this.updateData.emit({
      status: this.status,
      femaleBadges: this.femaleBadges,
      femaleNameAndNumbers: this.femaleNameAndNumbers
    });
    this.femaleNameAndNumberDataSource.data = [...this.femaleNameAndNumbers];
  }

  openFemaleBedgesDialog(rowdata: any) {
    const dialogRef = this.dialog.open(AddFemaleShirtAndShortDialogComponent, {
      width: '50%',
      data: { orgId: this.orgId, rowdata }
    });
    dialogRef.afterClosed().subscribe((row) => {
      if (row) {
        this.femaleBadges = this.femaleBadges.map((p) => {
          let isVat = {};
          if (rowdata && p.id === rowdata.id) {
            if (row.SKU) {
              if (typeof row.SKU === 'string') {
                p.SKU_selection = row.SKU;
              }
              if (typeof row.SKU === 'object') {
                let sku_selection = '';
                row.SKU.map((ele) => {
                  if (ele.isVat) {
                    isVat[`${ele.SKU}`] = true;
                    if (sku_selection.length > 0) {
                      sku_selection += `${ele.SKU}`;
                    } else {
                      sku_selection += `${ele.SKU},`;
                    }
                  } else {
                    isVat[`${ele.SKU}`] = false;
                    if (sku_selection.length > 0) {
                      sku_selection += `${ele.SKU}`;
                    } else {
                      sku_selection += `${ele.SKU},`;
                    }
                  }
                });
                p.SKU_selection = sku_selection;
              }
            }
            p.badgeUrl = row.badgeURL;
            p.logoUrl = row.logoURL;
            p.isVat = isVat;
          }
          return p;
        });
        this.femaleBadges = [...this.femaleBadges];
        this.femaleBadgeDataSource.data = [...this.femaleBadges];
        this.updateData.emit({
          status: this.status,
          femaleBadges: this.femaleBadges,
          femaleNameAndNumbers: this.femaleNameAndNumbers
        });
      }
    });
  }

  displayName(element) {
    let display = '';
    if (element && element.SKU_selection) {
      let temp = element.SKU_selection.split(',');
      temp.map((el) => {
        if (el.length > 0) {
          if (display.length > 0) {
            display += `,${el}(${element.isVat[el] ? 'VAT' : 'NON-VAT'})`;
          } else {
            display += `${el}(${element.isVat[el] ? 'VAT' : 'NON-VAT'})`;
          }
        }
      });
    }
    return display;
  }

  openAddNameAndNumberDialog(rowdata: any) {
    const dialogRef = this.dialog.open(AddFemaleNameAndNumberDialogComponent, {
      width: '50%',
      data: { orgId: this.orgId, rowdata }
    });
    dialogRef.afterClosed().subscribe((row) => {
      if (row) {
        this.femaleNameAndNumbers = this.femaleNameAndNumbers.map((p) => {
          if (rowdata && p.id === rowdata.id) {
            p.SKU_selection = row.SKU.skuID;
            p.name_SKU_selection = row.nameSKU;
            p.number_SKU_selection = row.numberSKU;
          }
          return p;
        });

        this.femaleNameAndNumbers = [...this.femaleNameAndNumbers];
        this.femaleNameAndNumberDataSource.data = [...this.femaleNameAndNumbers];
        this.updateData.emit({
          status: this.status,
          femaleNameAndNumbers: this.femaleNameAndNumbers,
          femaleBadges: this.femaleBadges
        });
      }
    });
  }
}
