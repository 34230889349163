import { Injectable } from '@angular/core';
import { INodeDropDownAccess, NodeType } from '@shared/interfaces';
import { AuthFacade } from 'src/app/feature/auth/state/auth.facade';

@Injectable()
export class UtilityService {
  private NODE_MAPS = new Map<string, INodeDropDownAccess>();
  private userRootNode: string;

  private GLOBAL_USER: INodeDropDownAccess = {
    company: false,
    tradingRegion: false,
    storeGroup: false,
    store: false
  };

  private ORGANIZATION_USER: INodeDropDownAccess = {
    company: true,
    tradingRegion: true,
    storeGroup: true,
    store: true
  };

  private COMAPANY_USER: INodeDropDownAccess = {
    company: false,
    tradingRegion: true,
    storeGroup: true,
    store: true
  };

  private TRADING_REGION_USER: INodeDropDownAccess = {
    company: false,
    tradingRegion: false,
    storeGroup: true,
    store: true
  };

  private STORE_GROUP_USER: INodeDropDownAccess = {
    company: false,
    tradingRegion: false,
    storeGroup: false,
    store: true
  };

  private STORE_USER: INodeDropDownAccess = {
    company: false,
    tradingRegion: false,
    storeGroup: false,
    store: false
  };
  constructor(private authFacade: AuthFacade) {
    this.NODE_MAPS.set(NodeType.GLOBAL, this.GLOBAL_USER);
    this.NODE_MAPS.set(NodeType.ORGANIZATION, this.ORGANIZATION_USER);
    this.NODE_MAPS.set(NodeType.COMPANY, this.COMAPANY_USER);
    this.NODE_MAPS.set(NodeType.TRADING_REGION, this.TRADING_REGION_USER);
    this.NODE_MAPS.set(NodeType.STORE_GROUP, this.STORE_GROUP_USER);
    this.NODE_MAPS.set(NodeType.STORE, this.STORE_USER);

    authFacade.user$.subscribe((user: any) => {
      this.userRootNode = user ? user.rootNode : '';
    });
  }

  getCurrentUserNodeDropDownAccess(): INodeDropDownAccess {
    return this.NODE_MAPS.get(this.userRootNode);
  }
}
