import { Component, OnInit } from '@angular/core';
import { PersistanceService } from '@services/persistance.service';

@Component({
  selector: 'app-shared',
  templateUrl: './shared.component.html',
  styleUrls: ['./shared.component.scss']
})
export class SharedComponent implements OnInit {
  logoURL = '';
  constructor(private persistanceService: PersistanceService) {}

  ngOnInit() {
    this.logoURL = this.persistanceService.getAppSetting().logoURL;
  }
}
