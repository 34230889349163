import { Injectable } from '@angular/core';
import { IUser } from '@shared/interfaces';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthFacade } from 'src/app/feature/auth/state/auth.facade';

@Injectable()
export class UserService {
  constructor(private authFacade: AuthFacade) {}

  getUserNodeStructure(): Observable<any> {
    return this.authFacade.user$.pipe(map((r: IUser) => (r ? r.nodeStructure : [])));
  }
}
