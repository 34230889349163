import { Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { FemaleShirtAndShortsService } from '../female-shirt-and-shorts-configuration.service';

@Component({
  selector: 'app-module-assignment-add-maleShirt-And-Shorts-dialog',
  templateUrl: './add-femaleShirt-And-Shorts-dialog.component.html',
  styleUrls: ['./add-femaleShirt-And-Shorts-dialog.component.scss']
})
export class AddFemaleShirtAndShortDialogComponent implements OnInit {
  frmAddFemaleBadge: FormGroup;
  orgId: string;
  rowdata: any;
  options: any[] = [];
  isProductSelected = false;
  searching = false;
  @Input() nameAndNumbers: any[];
  nameAndNumbersColumns: any[] = ['SKU_ID', 'SKU_Name', 'action'];

  pageSizeOptions: number[] = [5, 10, 25, 50, 100];
  isDisabledOk = true;
  nameAndNumberDataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  selected = [];
  selectedColumns = ['SKU_ID', 'SKU_Name', 'VAT', 'action'];
  constructor(
    private formBuilder: FormBuilder,
    private femaleShirtAndShortsService: FemaleShirtAndShortsService,
    public dialogRef: MatDialogRef<AddFemaleShirtAndShortDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.orgId = data.orgId;
    this.rowdata = data.rowdata;
  }

  ngOnInit() {
    this.initForm();
    this.nameAndNumbers = [];
    this.nameAndNumberDataSource.data = [...this.nameAndNumbers];
    this.validate();
  }

  initForm() {
    this.frmAddFemaleBadge = this.formBuilder.group({
      filterBySkuCtrl: [''],
      sku: [this.rowdata && this.rowdata.SKU_selection ? this.rowdata.SKU_selection : ''],
      badgeURL: [this.rowdata ? this.rowdata.badgeUrl : ''],
      logoURL: [this.rowdata ? this.rowdata.logoUrl : '']
    });
    this.subscribeProducts();
  }

  subscribeProducts() {
    if (this.rowdata && this.rowdata.SKU_selection) {
      this.frmAddFemaleBadge.get('filterBySkuCtrl').clearValidators();
      this.fetchProductToDisplayonEdit();
      this.frmAddFemaleBadge.get('sku').setValue(this.rowdata.SKU_selection);
    }
    this.frmAddFemaleBadge
      .get('filterBySkuCtrl')
      .valueChanges.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => {
          if (this.frmAddFemaleBadge.get('filterBySkuCtrl').value) {
            this.fetchProducts();
          } else {
            this.options = [];
          }
        })
      )
      .subscribe();
  }

  fetchProductToDisplayonEdit() {
    this.searching = true;
    let toDisplay = this.rowdata.SKU_selection.split(',');
    let toFilter = [...toDisplay];
    toDisplay.map((el) => {
      if (el.length > 0) {
        this.femaleShirtAndShortsService.getProductList(this.orgId, el).subscribe((result: any) => {
          this.searching = false;
          this.options = result.result;
          this.options = this.options.map((ele) => {
            if (this.rowdata.isVat) {
              ele['isVat'] = this.rowdata.isVat[el];
              return ele;
            } else {
              ele['isVat'] = false;
              return ele;
            }
          });
          this.options = this.options.filter((ele) => {
            if (toFilter.indexOf(ele.SKU) !== -1) {
              toFilter.splice(toFilter.indexOf(ele.SKU), 1);
              return ele;
            }
          });
          this.selected = [...this.selected, ...this.options];
          this.selected.map((ele) => {
            ele.isChecked = this.rowdata.isVat[ele.SKU];
          });
          this.validate();
        });
      }
    });
  }

  fetchProducts() {
    this.searching = true;
    this.femaleShirtAndShortsService
      .getProductList(this.orgId, this.frmAddFemaleBadge.get('filterBySkuCtrl').value)
      .subscribe((result: any) => {
        this.searching = false;
        this.options = result.result;
        this.nameAndNumbers = this.options;
      });
  }

  onSearchInput(e) {
    this.isProductSelected = false;
    if (!e.target.value) {
      this.options = [];
    }
  }

  onSave() {
    const formValue = this.frmAddFemaleBadge.getRawValue();
    const data = {
      SKU: this.selected,
      badgeURL: formValue.badgeURL,
      logoURL: formValue.logoURL
    };
    this.dialogRef.close(data);
  }

  onCancel() {
    this.dialogRef.close();
  }

  addSKU(ele) {
    console.log(ele);
    let newObj = { ...ele, isVat: false };
    console.log(newObj);
    this.selected = [...this.selected, newObj];
    this.validate();
    console.log(this.selected);
  }

  removeRow(ele) {
    this.selected = this.selected.filter((el) => el.SKU !== ele.SKU);
    this.validate();
  }

  validate() {
    if (this.selected.length > 1) {
      if (this.selected[0].isVat || this.selected[1].isVat) {
        this.isDisabledOk = false;
      } else {
        this.isDisabledOk = true;
      }
    } else if (this.selected.length === 1) {
      this.isDisabledOk = false;
    } else {
      this.isDisabledOk = true;
    }
  }

  vatableSelected(ele) {
    this.selected.map((el) => {
      if (ele.SKU === el.SKU) {
        el.isVat = true;
      } else {
        el.isVat = false;
      }
    });
    this.validate();
  }
}
