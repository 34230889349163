import { Component, OnInit } from '@angular/core';
import { BreadcrumbsService } from '@services/breadcrumbs.service';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent implements OnInit {
  title: string;

  constructor(private breadcrumb: BreadcrumbsService) {}

  ngOnInit() {
    this.breadcrumb.getTitle().subscribe((t) => {
      this.title = t;
    });
  }
}
