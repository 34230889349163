import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthFacade } from 'src/app/feature/auth/state/auth.facade';
/**
 *   Exmaple with AND : *hasPermission="[PERMISSION.CREATE_USER,PERMISSION.LIST_USER]; operation 'AND'"
 *   Exmaple with OR :  *hasPermission="[PERMISSION.CREATE_USER,PERMISSION.LIST_USER]"
 *   Single Value Exmaple  :  *hasPermission="[PERMISSION.CREATE_USER]"
 */
@Directive({
  selector: '[hasPermission]'
})
export class HasPermissionDirective implements OnInit, OnDestroy {
  private currentUser;
  private permissions = [];
  private logicalOperation = 'OR';
  private isHidden = true;
  private subscriptions: Subscription[] = [];

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authFacade: AuthFacade
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.authFacade.user$.subscribe((user) => {
        this.currentUser = user;
        this.updateView();
      })
    );
  }

  @Input()
  set hasPermission(val) {
    this.permissions = val;
    this.updateView();
  }

  @Input()
  set hasPermissionOperation(permop) {
    this.logicalOperation = permop;
    this.updateView();
  }

  private updateView() {
    if (this.checkPermission()) {
      if (this.isHidden) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.isHidden = false;
      }
    } else {
      this.isHidden = true;
      this.viewContainer.clear();
    }
  }

  private checkPermission() {
    let hasPermission = false;
    if (this.currentUser && this.currentUser.permissions) {
      const permissions = this.permissions.filter((x) => x && x.trim().length);
      for (const checkPermission of permissions) {
        const permissionFound = this.currentUser.permissions.find(
          (x) => x.toUpperCase() === checkPermission.toUpperCase()
        );
        if (permissionFound) {
          hasPermission = true;

          if (this.logicalOperation === 'OR') {
            break;
          }
        } else {
          hasPermission = false;
          if (this.logicalOperation === 'AND') {
            break;
          }
        }
      }
    }
    return hasPermission;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }
}

export enum PERMISSION {
  OM_DASHBOARD = 'OM_DASHBOARD',
  LIST_SETTING = 'LIST_SETTING',
  CREATE_SETTING = 'CREATE_SETTING',
  EDIT_SETTING = 'EDIT_SETTING',
  VIEW_SETTING = 'VIEW_SETTING',
  ORGANISATION_APP_ICON = 'ORGANISATION_APP_ICON',
  VIEW_PROFILE = 'VIEW_PROFILE',
  LIST_ORGANISATIONS = 'LIST_ORGANISATIONS',
  CREATE_ORGANISATION = 'CREATE_ORGANISATION',
  UPDATE_ORGANISATION = 'UPDATE_ORGANISATION',
  UPDATE_ORGANISATION_DETAILS = 'UPDATE_ORGANISATION_DETAILS',
  UPDATE_ORGANISATION_CONFIG = 'UPDATE_ORGANISATION_CONFIG',
  UPDATE_ORGANISATION_THEME = 'UPDATE_ORGANISATION_THEME',
  UPDATE_ORGANISATION_MODULE = 'UPDATE_ORGANISATION_MODULE',
  ENABLED_ORGANISATION = 'ENABLED_ORGANISATION',
  VIEW_ORGANISATION = 'VIEW_ORGANISATION',
  DELETE_ORGANISATION = 'DELETE_ORGANISATION',
  ACCESS_ORGANISATION_LEVEL = 'ACCESS_ORGANISATION_LEVEL',
  LIST_USERS = 'LIST_USERS',
  CREATE_USER = 'CREATE_USER',
  VIEW_USER = 'VIEW_USER',
  ENABLED_USER = 'ENABLED_USER',
  EDIT_USER = 'EDIT_USER',
  DELETE_USER = 'DELETE_USER',
  MODULE_APP_ICON = 'MODULE_APP_ICON',
  LIST_ROLES = 'LIST_ROLES',
  CREATE_ROLE = 'CREATE_ROLE',
  DELETE_ROLE = 'DELETE_ROLE',
  EDIT_ROLE = 'EDIT_ROLE',
  VIEW_ROLE = 'VIEW_ROLE',
  ENABLED_ROLE = 'ENABLED_ROLE',
  LIST_NODE_STRUCTURE = 'LIST_NODE_STRUCTURE',
  LIST_BULK_OVERRIDE = 'LIST_BULK_OVERRIDE',
  CREATE_NODE = 'CREATE_NODE',
  DELETE_NODE = 'DELETE_NODE',
  EDIT_NODE = 'EDIT_NODE',
  EDIT_NODE_DETAILS = 'EDIT_NODE_DETAILS',
  EDIT_NODE_CONFIGURATION = 'EDIT_NODE_CONFIGURATION',
  EDIT_NODE_THEME = 'EDIT_NODE_THEME',
  EDIT_NODE_MODULE = 'EDIT_NODE_MODULE',
  VIEW_NODE = 'VIEW_NODE',
  ENABLED_NODE = 'ENABLED_NODE',
  BULK_OVERRIDE = 'BULK_OVERRIDE',
  ASSIGN_STORE_DEVICE = 'ASSIGN_STORE_DEVICE',
  ALLOW_TO_VIEW_MODULE_ICON = 'ALLOW_TO_VIEW_MODULE_ICON',
  SHOW_BULK_USER_IMPORT_SUBMENU = 'SHOW_BULK_USER_IMPORT_SUBMENU',
  ALLOW_UPLOAD_USER_IMPORT_CSV = 'ALLOW_UPLOAD_USER_IMPORT_CSV',
  ALLOW_DOWNLOAD_USER_IMPORT_CSV_TEMPLATE = 'ALLOW_DOWNLOAD_USER_IMPORT_CSV_TEMPLATE'
}
