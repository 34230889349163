import { Component, Input, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl,
  FormArray,
  FormControl
} from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material';
import {
  EbasketApiMethodTypes,
  Providers,
  WebReturnsSalesforceOSUSelectApi
} from '@shared/interfaces';
import { SelectApiPopupComponent } from '@shared/select-api-popup/select-api-popup.component';

@Component({
  selector: 'app-salesforce-osu-provider-details-settings',
  templateUrl: './salesforce-osu-provider-details-settings.component.html',
  styleUrls: ['./salesforce-osu-provider-details-settings.component.scss']
})
export class SalesforceOSUProviderDetailsSettingsComponent implements OnInit {
  @Input() action: string;
  @Input() parentForm: FormGroup;
  @Input() webReturnsSalesforceOSUAuthDetails: any;
  @Input() authControlKey: string;
  apiDetails: FormGroup;
  isWebReturnsSalesforceOSUAddDisabled = false;
  method_types = [
    { id: 1, name: EbasketApiMethodTypes.Get, value: EbasketApiMethodTypes.Get },
    { id: 2, name: EbasketApiMethodTypes.Post, value: EbasketApiMethodTypes.Post },
    { id: 3, name: EbasketApiMethodTypes.Put, value: EbasketApiMethodTypes.Put },
    { id: 4, name: EbasketApiMethodTypes.Patch, value: EbasketApiMethodTypes.Patch },
    { id: 5, name: EbasketApiMethodTypes.Delete, value: EbasketApiMethodTypes.Delete }
  ];
  webreturns_salesforceOSU_select_api = [
    {
      id: 1,
      name: WebReturnsSalesforceOSUSelectApi.GetOrder,
      value: WebReturnsSalesforceOSUSelectApi.GetOrder,
      isDisabled: false
    },
    {
      id: 2,
      name: WebReturnsSalesforceOSUSelectApi.ProcessReturns,
      value: WebReturnsSalesforceOSUSelectApi.ProcessReturns,
      isDisabled: false
    }
  ];

  constructor(private formBuilder: FormBuilder, private dialog: MatDialog) {}

  ngOnInit() {
    this.initForm();
  }
  initForm() {
    console.log(this.webReturnsSalesforceOSUAuthDetails);
    this.parentForm.addControl(
      this.authControlKey,
      this.formBuilder.group({
        url: [
          {
            value:
              this.webReturnsSalesforceOSUAuthDetails &&
              this.webReturnsSalesforceOSUAuthDetails.authenticationDetails &&
              this.webReturnsSalesforceOSUAuthDetails.authenticationDetails.url
                ? this.webReturnsSalesforceOSUAuthDetails.authenticationDetails.url
                : '',
            disabled: this.action === 'view' ? true : false
          },
          [Validators.required]
        ],
        clientId: [
          {
            value:
              this.webReturnsSalesforceOSUAuthDetails &&
              (this.webReturnsSalesforceOSUAuthDetails.authenticationDetails ||
                this.webReturnsSalesforceOSUAuthDetails.authenticationDetailsKey)
                ? Providers.SECRET
                : '',
            disabled: this.action === 'view' ? true : false
          },
          [Validators.required]
        ],
        secretKey: [
          {
            value:
              this.webReturnsSalesforceOSUAuthDetails &&
              (this.webReturnsSalesforceOSUAuthDetails.authenticationDetails ||
                this.webReturnsSalesforceOSUAuthDetails.authenticationDetailsKey)
                ? Providers.SECRET
                : '',
            disabled: this.action === 'view' ? true : false
          },
          [Validators.required]
        ],
        grantType: [
          {
            value:
              this.webReturnsSalesforceOSUAuthDetails &&
              this.webReturnsSalesforceOSUAuthDetails.authenticationDetails &&
              this.webReturnsSalesforceOSUAuthDetails.authenticationDetails.grantType
                ? this.webReturnsSalesforceOSUAuthDetails.authenticationDetails.grantType
                : '',
            disabled: this.action === 'view' ? true : false
          },
          [Validators.required]
        ],
        salesforceOSUApiDetails: this.formBuilder.array([])
      })
    );
    this.bindSalesforceOSUApiDetailsFormGroup();
  }

  bindSalesforceOSUApiDetailsFormGroup() {
    const control = this.parentForm
      .get(this.authControlKey)
      .get('salesforceOSUApiDetails') as FormArray;
    if (
      this.webReturnsSalesforceOSUAuthDetails &&
      this.webReturnsSalesforceOSUAuthDetails.authenticationDetails &&
      this.webReturnsSalesforceOSUAuthDetails.authenticationDetails.apiDetails
    ) {
      this.webReturnsSalesforceOSUAuthDetails.authenticationDetails.apiDetails.map((apis: any) => {
        let apiDetails = {};
        apiDetails = {
          url: [
            {
              value: apis.url,
              disabled: this.action === 'view' ? true : false
            },
            Validators.required
          ],
          method: [
            {
              value: apis.method,
              disabled: this.action === 'view' ? true : false
            },
            Validators.required
          ],
          selectedApi: [apis.selectedApi]
        };
        control.push(this.formBuilder.group(apiDetails));
        this.webreturns_salesforceOSU_select_api.map((key) => {
          if (key.value === apis.selectedApi) {
            key.isDisabled = true;
          }
        });
      });

      if (this.webreturns_salesforceOSU_select_api.length === control.length) {
        this.isWebReturnsSalesforceOSUAddDisabled = true;
      }
    }
  }

  openSalesforceOSUSelectApiPopup() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = this.webreturns_salesforceOSU_select_api.filter((api) => !api.isDisabled);
    const dialogRef = this.dialog.open(SelectApiPopupComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result !== '') {
        this.webreturns_salesforceOSU_select_api.map((element: any) => {
          if (element.value === result) {
            element.isDisabled = true;
            // this.tm_gift_card_api_details.push(element);
            const salesforceOSUApiDetails = this.parentForm
              .get(this.authControlKey)
              .get('salesforceOSUApiDetails') as FormArray;

            salesforceOSUApiDetails.push(this.createSalesforceOSUApiDetailsFormGroup(result));
            if (
              this.webreturns_salesforceOSU_select_api.length === salesforceOSUApiDetails.length
            ) {
              this.isWebReturnsSalesforceOSUAddDisabled = true;
            }
          }
        });
      }
    });
  }

  private createSalesforceOSUApiDetailsFormGroup(selectedApi: string): FormGroup {
    return new FormGroup({
      url: new FormControl('', Validators.required),
      method: new FormControl('', Validators.required),
      selectedApi: new FormControl(selectedApi)
    });
  }
  deleteSalesforceOSUApiDetailContainer(index: number) {
    const salesforceOSUApiDetails = this.parentForm
      .get(this.authControlKey)
      .get('salesforceOSUApiDetails') as FormArray;
    this.webreturns_salesforceOSU_select_api.map((element: any) => {
      if (element.value === salesforceOSUApiDetails.value[index].selectedApi) {
        element.isDisabled = false;
      }
    });
    if (salesforceOSUApiDetails.length > 0) {
      salesforceOSUApiDetails.removeAt(index);
    } else {
      salesforceOSUApiDetails.reset();
    }
    if (this.webreturns_salesforceOSU_select_api.length !== salesforceOSUApiDetails.length) {
      this.isWebReturnsSalesforceOSUAddDisabled = false;
    }
  }
  isRequired(control: string) {
    let isRequired = false;
    const formControlObject = this.parentForm.get(this.authControlKey).get(control);
    if (formControlObject !== null) {
      if (formControlObject.validator !== null) {
        if (formControlObject.validator({} as AbstractControl) !== null) {
          isRequired = formControlObject.validator({} as AbstractControl).required ? true : false;
        }
      }
    }
    return isRequired ? true : false;
  }
}
