import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICurrency, IOrganization, IPaginationResponse, NodeType } from '@shared/interfaces';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService, PATH } from 'src/app/core/api/api.service';
import {
  INodeConfigurationResponse,
  INodeRequest,
  IReasonCategory,
  ITenderType,
  ITheme
} from './model/organization.model';

@Injectable()
export class OrganizationService {
  themes$: Observable<ITheme[]>;
  selectedCurrencies = new BehaviorSubject<ICurrency[]>([]);
  selectedCurrencies$ = this.selectedCurrencies.asObservable();

  selectedProductScope = new BehaviorSubject<string>('');
  selectedProductScope$ = this.selectedProductScope.asObservable();

  constructor(private readonly apiService: ApiService) {}

  passCurrencyList(data) {
    this.selectedCurrencies.next(data);
  }
  passProductScope(data) {
    this.selectedProductScope.next(data);
  }

  createOrganization(organizationRequest: INodeRequest) {
    organizationRequest.nodeType = NodeType.ORGANIZATION;
    return this.apiService.post(PATH.POST_CREATE_NODE, organizationRequest);
  }

  public organizations(
    filter = '',
    sortOrder = 'asc',
    pageNumber = 0,
    pageSize = 10
  ): Observable<IPaginationResponse<IOrganization[]>> {
    let organizationList: IOrganization[] = [];

    if (filter) {
      organizationList = organizationList.filter(
        (user) =>
          user.name
            .trim()
            .toLowerCase()
            .search(filter.toLowerCase()) >= 0 ||
          user.address
            .trim()
            .toLowerCase()
            .search(filter.toLowerCase()) >= 0 ||
          user.country
            .trim()
            .toLowerCase()
            .search(filter.toLowerCase()) >= 0 ||
          user.emailId
            .trim()
            .toLowerCase()
            .search(filter.toLowerCase()) >= 0 ||
          user.defaultLanguage
            .trim()
            .toLowerCase()
            .search(filter.toLowerCase()) >= 0
      );
    }

    if (sortOrder === 'desc') {
      organizationList = organizationList.reverse();
    } else {
      organizationList = organizationList;
    }

    const initialPos = pageNumber * pageSize;
    const filteredOrganization = organizationList.slice(initialPos, initialPos + pageSize);
    // const organizationListPage = {
    //   totalCount: 3,
    //   offset: pageNumber,
    //   limit: pageSize,
    //   result: filteredOrganization
    // };
    // return of(organizationListPage);
    return this.apiService
      .get(
        PATH.GET_ORGANIZATION_LIST,
        new HttpParams()
          .set('filter', filter)
          .set('sortby', sortOrder)
          .set('offset', pageNumber.toString())
          .set('limit', pageSize.toString())
      )
      .pipe(
        // tslint:disable-next-line: no-string-literal
        map((res) => res as IPaginationResponse<IOrganization[]>)
      );
  }

  public getOrganisationById(nodeId: string): Observable<ITheme> {
    return this.apiService.get(PATH.GET_ORGANISATION_BY_ID(nodeId));
  }

  generateQrcode(data: any) {
    return this.apiService.post(PATH.POST_QRCODE, data);
  }

  public getNodeConfigById(
    nodeId: string,
    status: boolean
  ): Observable<INodeConfigurationResponse> {
    return this.apiService.get(
      PATH.GET_NODE_CONFIG_BY_ID(nodeId),
      new HttpParams().set('status', status.toString())
    );
  }
  public getReasonCategory(): Observable<IReasonCategory[]> {
    return this.apiService
      .get(PATH.GET_REASON_CATEGORY)
      .pipe(map((response) => response.result as IReasonCategory[]));
  }

  public getTenderType(): Observable<ITenderType[]> {
    return this.apiService
      .get(PATH.GET_TENDER_TYPE)
      .pipe(map((response) => response.result as ITenderType[]));
  }

  public editOrganisation(node: any, nodeId: string) {
    return this.apiService.put(PATH.PUT_EDIT_NODE(nodeId), node);
  }

  public enableDisableNode(status, nodeId): Observable<any> {
    const body: any = { status };
    return this.apiService.put(PATH.PUT_UPDATE_NODE_STATUS(nodeId), body);
  }

  public deleteNode(nodeId: string): Observable<any> {
    return this.apiService.delete(PATH.DELETE_NODE_BY_ID(nodeId)).pipe(map((data) => data.result));
  }
}
