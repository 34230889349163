import { Component, Input, OnInit } from '@angular/core';
import { VIEW } from 'src/app/util/Constant';

@Component({
  selector: 'app-login-background-image-preview',
  templateUrl: './background-image-preview.component.html',
  styleUrls: ['./background-image-preview.component.scss']
})
export class BackgroundImagePreviewComponent implements OnInit {
  view: string = 'mobile';
  @Input() landscapeImage: string;
  @Input() portraitImage: any;
  @Input() action: any;
  viewType = VIEW;

  constructor() {}

  ngOnInit() {}

  changeView(event: any) {
    this.view = event.value;
  }
}
