import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'cron-job-frequency-configuration',
  templateUrl: './cron-job-frequency-configuration.component.html',
  styleUrls: ['./cron-job-frequency-configuration.component.scss']
})
export class CronJobFrequencyConfigurationComponent1 implements OnInit {
  @Input() action: string;
  @Input() parentForm: FormGroup;
  @Input() CronJobConfigurationDetails: any;
  @Input() authControlKey: string;

  mondayKey;
  tuesdayKey;
  wednesdayKey;
  thursdayKey;
  fridayKey;
  saturdayKey;
  sundayKey;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.initForm();
    this.mondayKey = `${this.authControlKey}monday`;
    this.tuesdayKey = `${this.authControlKey}tueday`;
    this.wednesdayKey = `${this.authControlKey}wednesday`;
    this.thursdayKey = `${this.authControlKey}thursday`;
    this.fridayKey = `${this.authControlKey}friday`;
    this.saturdayKey = `${this.authControlKey}saturday`;
    this.sundayKey = `${this.authControlKey}sunday`;
  }
  initForm() {
    console.log(this.CronJobConfigurationDetails);
    this.parentForm.addControl(
      this.authControlKey,
      this.formBuilder.group({
        multipleTimeFrequency: [
          {
            value:
              this.CronJobConfigurationDetails && this.CronJobConfigurationDetails.time
                ? this.CronJobConfigurationDetails.time
                : '',
            disabled: this.action === 'view' ? true : false
          },
          Validators.required
        ],
        monday: [
          {
            value:
              this.CronJobConfigurationDetails &&
              this.CronJobConfigurationDetails.frequency &&
              this.CronJobConfigurationDetails.frequency.indexOf('MON') > -1
                ? true
                : false,
            disabled: this.action === 'view' ? true : false
          }
        ],
        tuesday: [
          {
            value:
              this.CronJobConfigurationDetails &&
              this.CronJobConfigurationDetails.frequency &&
              this.CronJobConfigurationDetails.frequency.indexOf('TUE') > -1
                ? true
                : false,
            disabled: this.action === 'view' ? true : false
          }
        ],
        wednesday: [
          {
            value:
              this.CronJobConfigurationDetails &&
              this.CronJobConfigurationDetails.frequency &&
              this.CronJobConfigurationDetails.frequency.indexOf('WED') > -1
                ? true
                : false,
            disabled: this.action === 'view' ? true : false
          }
        ],
        thursday: [
          {
            value:
              this.CronJobConfigurationDetails &&
              this.CronJobConfigurationDetails.frequency &&
              this.CronJobConfigurationDetails.frequency.indexOf('THU') > -1
                ? true
                : false,
            disabled: this.action === 'view' ? true : false
          }
        ],
        friday: [
          {
            value:
              this.CronJobConfigurationDetails &&
              this.CronJobConfigurationDetails.frequency &&
              this.CronJobConfigurationDetails.frequency.indexOf('FRI') > -1
                ? true
                : false,
            disabled: this.action === 'view' ? true : false
          }
        ],
        saturday: [
          {
            value:
              this.CronJobConfigurationDetails &&
              this.CronJobConfigurationDetails.frequency &&
              this.CronJobConfigurationDetails.frequency.indexOf('SAT') > -1
                ? true
                : false,
            disabled: this.action === 'view' ? true : false
          }
        ],
        sunday: [
          {
            value:
              this.CronJobConfigurationDetails &&
              this.CronJobConfigurationDetails.frequency &&
              this.CronJobConfigurationDetails.frequency.indexOf('SUN') > -1
                ? true
                : false,
            disabled: this.action === 'view' ? true : false
          }
        ]
      })
    );
  }

  isRequired(control: string) {
    let isRequired = false;
    const formControlObject = this.parentForm.get(this.authControlKey).get(control);
    if (formControlObject !== null) {
      if (formControlObject.validator !== null) {
        if (formControlObject.validator({} as AbstractControl) !== null) {
          isRequired = formControlObject.validator({} as AbstractControl).required ? true : false;
        }
      }
    }
    return isRequired ? true : false;
  }
}
