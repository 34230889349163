import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CommonConfigFacade } from '@shared/common-config/state/common-config.facade';
import { GenericDateTimeFormat } from '@shared/common-config/state/common-config.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ng-long-date-dropdown',
  templateUrl: './long-date-dropdown.component.html',
  styleUrls: ['./long-date-dropdown.component.scss']
})
export class LongDateDropdownComponent implements OnInit, OnDestroy {
  @Input() ctrlLongDate: FormControl;
  @Input() placeholder: string;
  @Input() defaultLongDate;
  @Input() isOverriden: false;
  longDateList: GenericDateTimeFormat[];
  subscriptions: Subscription[] = [];
  longDate: GenericDateTimeFormat;

  constructor(private readonly commonFacade: CommonConfigFacade) {}

  ngOnInit() {
    this.subscriptions.push(
      this.commonFacade.longDateFormats$.subscribe((data) => {
        this.longDateList = data;
        if (this.defaultLongDate && this.defaultLongDate.id) {
          this.ctrlLongDate.setValue(
            this.longDateList.find((ld) => ld.id === this.defaultLongDate.id)
          );
        }
      })
    );
  }
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }
}
