import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { IMenu, NodeLevelType } from '@shared/interfaces';
import { Observable, of } from 'rxjs';
import { LocalStorageJwtService } from 'src/app/feature/auth/local-storage-jwt.service';
import { AuthFacade } from 'src/app/feature/auth/state/auth.facade';
import { APP_ROUTES_NAME } from 'src/app/routes/app.routes.names';
import CONSTANTS from 'src/app/util/Constant';
import { DynamicNameService } from './dynamic-menu-name.service';

@Injectable({
  providedIn: 'root'
})
export class NavigationMenuService {
  PERMISSION = [];
  ROOT_NODE = '';
  constructor(
    private httpClient: HttpClient,
    private localStorageJwtService: LocalStorageJwtService,
    private authFacade: AuthFacade
  ) {
    this.authFacade.user$.subscribe((r) => {
      if (r && r.permissions) {
        this.PERMISSION = r.permissions;
      }
    });
  }
  /**
   * Get SampleUI
   */
  getSampleUI(): Observable<IMenu[]> {
    return this.httpClient.get<IMenu[]>('./../../../assets/menu.json');
  }
  /**
   *  Get Dyanmic Menu
   */
  getDynamicMenu(): Observable<IMenu[]> {
    const dynamicMenu: IMenu[] = [];
    const target: IMenu[] = [];
    this.ROOT_NODE =
      this.localStorageJwtService.getCurrentUser() !== null
        ? this.localStorageJwtService.getCurrentUser().rootNode
        : '';

    this.extractElements(APP_ROUTES_NAME, target);
    const result = target;
    result.forEach((el) => dynamicMenu.push(el));
    if (environment.environmentName === 'development') {
      this.getSampleUI()
        .toPromise()
        .then((data) => {
          dynamicMenu.push(data[0]);
        });
    }
    return of(dynamicMenu);
  }

  extractElements(source, target, parent = '') {
    const menu: any = Object.values(source);

    // console.log('USERMENUS--', menu);
    menu.forEach(async (element) => {
      // Check Valid Roles
      let isValidRole = true;
      isValidRole = this.validateNodeLevel(isValidRole, element);

      // Check the user permission for menu
      let isValidAccessRight = true;
      isValidAccessRight = this.validatePermission(isValidRole, element);
      if (isValidRole && isValidAccessRight && element.name) {
        const newMenu: IMenu = {
          name: element.name
        };
        if (element.icon) {
          newMenu.icon = element.icon;
        }
        if (element.svg) {
          newMenu.svg = element.svg;
        }
        if (element.childs) {
          newMenu.childs = [];
          this.extractElements(element.childs, newMenu.childs, parent + element.path + '/');
        } else {
          newMenu.url = parent + element.path;
        }
        if (element.dynamicName) {
          // This will call dynamic Funciton and pass value to that function as current user.
          const fnDynamicName = DynamicNameService[element.dynamicName];
          if (typeof fnDynamicName === 'function') {
            newMenu.name = fnDynamicName.apply(null, [
              this.localStorageJwtService.getCurrentUser()
            ]);
          }
        }
        if (element.visible === undefined || element.visible === true) {
          // put it in our array
          target.push(newMenu);
        }
      }
    });
  }

  validateNodeLevel(currentRoleValid, element) {
    let isValidRole = currentRoleValid;
    if (element.expectedLevel && isValidRole) {
      const EXPECTED_NODE = element.expectedLevel;
      const ROOT_NODE_LEVEL = CONSTANTS.getCurrentLevel(this.ROOT_NODE);
      const EXPECTED_NODE_LEVEL = CONSTANTS.getCurrentLevel(EXPECTED_NODE);
      switch (element.expectedLevelType) {
        case NodeLevelType.GREATER:
          if (EXPECTED_NODE_LEVEL > ROOT_NODE_LEVEL) {
            isValidRole = true;
          } else {
            isValidRole = false;
          }
          break;
        case NodeLevelType.LOWER:
          if (EXPECTED_NODE_LEVEL < ROOT_NODE_LEVEL) {
            isValidRole = true;
          } else {
            isValidRole = false;
          }
          break;
        case NodeLevelType.NOT_EQUAL:
          if (EXPECTED_NODE_LEVEL !== ROOT_NODE_LEVEL) {
            isValidRole = true;
          } else {
            isValidRole = false;
          }
          break;
        case NodeLevelType.EQUAL:
          if (EXPECTED_NODE_LEVEL === ROOT_NODE_LEVEL) {
            isValidRole = true;
          } else {
            isValidRole = false;
          }
      }
    }
    return isValidRole;
  }

  validatePermission(currentRoleValid, element) {
    let isValidRole = currentRoleValid;
    if (element.expectedPermission && element.expectedPermission.length) {
      let permissionChecker = (arr, tgt) => tgt.some((v) => arr.includes(v));
      if (element.expectedPermissionType && element.expectedPermissionType === 'all') {
        permissionChecker = (arr, tgt) => tgt.every((v) => arr.includes(v));
      }
      isValidRole = permissionChecker(this.PERMISSION, element.expectedPermission);
    }
    return isValidRole;
  }

  getUserMenus(): Observable<IMenu[]> {
    const menu = this.getDynamicMenu();
    return menu;
  }
}
