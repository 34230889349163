import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NotificationService } from '@services/notification.service';
import { fromEvent, Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html'
})
export class FileUploadComponent {
  @Input() allowedTypes;
  @Input() aspectRatioType: any;
  @Input() imageSRC: string = '';
  @Output() uploadedFileBase64String: any = new EventEmitter<{ base64: string; type: string }>();

  constructor(private readonly notification: NotificationService) {}

  onUploadImage(event: Event) {
    const input = event.target as HTMLInputElement;
    if (!input.files || input.files.length === 0) {
      return;
    }

    const file = input.files[0];
    const mimeType = file.type;

    if (!['image/png', 'image/svg+xml'].includes(mimeType)) {
      this.notification.error('Only PNG and SVG images are supported.');
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      if (typeof reader.result !== 'string') {
        this.notification.error('Error reading the file.');
        return;
      }

      const base64String: string = reader.result;

      if (mimeType === 'image/svg+xml') {
        this.uploadedFileBase64String.emit({ base64: base64String, type: this.aspectRatioType });
        return;
      }

      const img = new Image();
      img.src = base64String;

      img.onload = () => {
        const width = img.width;
        const height = img.height;
        const aspectRatio = width / height;

        let isValidRatio = false;
        let isValidResolution = true;

        if (this.aspectRatioType === '1:1') {
          isValidRatio = aspectRatio === 1;
          isValidResolution = width >= 200 && height >= 200;
        } else if (this.aspectRatioType === '3:4or4:3') {
          const aspectRatio34 = 3 / 4;
          const aspectRatio43 = 4 / 3;
          isValidRatio =
            Math.abs(aspectRatio - aspectRatio34) < 0.02 ||
            Math.abs(aspectRatio - aspectRatio43) < 0.02;
        }

        if (isValidRatio && isValidResolution) {
          this.uploadedFileBase64String.emit({ base64: base64String, type: this.aspectRatioType });
        } else {
          let errorMessage = `Invalid image ratio! Please upload a ${this.aspectRatioType} image.`;
          if (this.aspectRatioType === '1:1' && !isValidResolution) {
            errorMessage = '1:1 image must be at least 200×200 pixels.';
          }
          this.notification.error(errorMessage);
        }
      };
    };
  }

  imageToBase64(fileReader: FileReader, fileToRead: File): Observable<string> {
    fileReader.readAsDataURL(fileToRead);
    return fromEvent(fileReader, 'load').pipe(pluck('currentTarget', 'result'));
  }
}
