import { MatDateFormats } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import moment, { Moment } from 'moment'; // tslint:disable-line no-duplicate-imports

export class CustomDateAdapter extends MomentDateAdapter {
  format(date: Moment, displayFormat: string): string {
    // Use DD-MM-YYYY format for en-GB
    if (displayFormat === 'input' && this.locale === 'en-GB') {
      return date.format('DD/MM/YYYY');
    }
    // Use MM-DD-YYYY format if not en-GB
    if (displayFormat === 'input') {
      return date.format('MM/DD/YYYY');
    }
    return date.format(displayFormat);
  }

  parse(value: string): Moment | null {
    // Use DD-MM-YYYY format for en-GB
    if (this.locale === 'en-GB' && value.match(/[\/-]/)) {
      return moment(value, 'DD/MM/YYYY') || null;
    }
    // Use MM-DD-YYYY format if not en-GB
    return moment(value, 'MM/DD/YYYY');
  }

  createDate(year: number, month: number, date: number): Moment {
    // Moment.js will create an invalid date if any of the components are out of bounds, but we
    // explicitly check each case so we can throw more descriptive errors.
    if (month < 0 || month > 11) {
      throw Error(`Invalid month index "${month}". Month index has to be between 0 and 11.`);
    }

    if (date < 1) {
      throw Error(`Invalid date "${date}". Date has to be greater than 0.`);
    }

    const result = moment.utc({ year, month, date }).locale(this.locale);

    // If the result isn't valid, the date must have been out of bounds for this month.
    if (!result.isValid()) {
      throw Error(`Invalid date "${date}" for month with index "${month}".`);
    }

    return result;
  }
}

export const CUSTOM_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'LL'
  },
  display: {
    dateInput: 'input',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};
