import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { PaymentProviders, Providers } from '@shared/interfaces';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'adyen-provider-api-details-settings',
  templateUrl: './adyen-provider-details-settings.component.html',
  styleUrls: ['./adyen-provider-details-settings.component.scss']
})
export class AdyenDetailsSettingsComponent implements OnInit {
  @Input() action: string;
  @Input() parentForm: FormGroup;
  @Input() portalRefundsConfigurationKey: string;
  @Input() authControlKey: string;
  @Input() provider: string;
  @Input() D2SPaymentConfiguration: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.initForm();
  }
  initForm() {
    this.parentForm.addControl(
      this.authControlKey,
      this.formBuilder.group({
        salesSetupAdyenClientKey: [
          {
            value:
              this.portalRefundsConfigurationKey && this.portalRefundsConfigurationKey !== ''
                ? Providers.SECRET
                : '',
            disabled: this.action === 'view' ? true : false
          },
          [Validators.required]
        ],
        salesSetupAdyenApiKey: [
          {
            value:
              this.portalRefundsConfigurationKey && this.portalRefundsConfigurationKey !== ''
                ? Providers.SECRET
                : '',
            disabled: this.action === 'view' ? true : false
          },
          [Validators.required]
        ],
        salesSetupAdyenMerchantAccount: [
          {
            value:
              this.portalRefundsConfigurationKey && this.portalRefundsConfigurationKey !== ''
                ? Providers.SECRET
                : '',
            disabled: this.action === 'view' ? true : false
          },
          [Validators.required]
        ],
        salesSetupAdyenliveEndpointURLPrefix: [
          {
            value:
              this.portalRefundsConfigurationKey && this.portalRefundsConfigurationKey !== ''
                ? Providers.SECRET
                : '',
            disabled: this.action === 'view' ? true : false
          }
          // [Validators.required]
        ],
        liveEndPointUrlPrefix: [
          {
            value:
              this.portalRefundsConfigurationKey && this.portalRefundsConfigurationKey !== ''
                ? Providers.SECRET
                : '',
            disabled: this.action === 'view' ? true : false
          }
        ]
      })
    );
    // console.log(this.parentForm.get('apiDetails').get('ebasketInventoryApiDetails').value.value);
  }

  isRequired(control: string) {
    let isRequired = false;
    const formControlObject = this.parentForm.get(this.authControlKey).get(control);
    if (formControlObject !== null) {
      if (formControlObject.validator !== null) {
        if (formControlObject.validator({} as AbstractControl) !== null) {
          isRequired = formControlObject.validator({} as AbstractControl).required ? true : false;
        }
      }
    }
    return isRequired ? true : false;
  }
}
