import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { HotkeyService } from '../hotkeys.service';
import * as uuid from 'uuid';
import { environment } from '@env/environment';
import { SharedService } from '@shared/shared.service';
import { NotificationService } from '@services/notification.service';

@Component({
  selector: 'app-module-assignment-add-hotkey-dialog',
  templateUrl: './add-hotkey-dialog.component.html',
  styleUrls: ['./add-hotkey-dialog.component.scss']
})
export class AddHotKeyDialogComponent implements OnInit {
  @ViewChild('inputSku', { static: false }) inputSku: ElementRef;
  @ViewChild('inputProductName', { static: false }) inputProductName: ElementRef;

  frmHotKey: FormGroup;
  orgId: string;
  nodeId: string;
  formData: any;
  options: any[] = [];
  action: string;
  filteredOptions: Observable<string[]>;
  isProductSelected: boolean = false;
  searching: boolean = false;
  dataLoading: boolean = false;
  groudId: string;
  hotkeyId: string;
  constructor(
    private formBuilder: FormBuilder,
    private notificationService: NotificationService,
    private hotKeyService: HotkeyService,
    public dialogRef: MatDialogRef<AddHotKeyDialogComponent>,
    public SharedService: SharedService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.action = data.action;
    this.formData = data.formData;
    this.groudId = data.groudId;
    this.orgId = data.orgId;
    this.nodeId = data.nodeId;
  }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.frmHotKey = this.formBuilder.group({
      // position: [{value:(this.formData  && this.formData.data && this.formData.data.position) ? this.formData.data.position:0 }, [Validators.min(1), Validators.max(99), this.noEValidator]],
      displayName: [''],
      sku: [{ value: '', disabled: true }],
      image: [''],
      filterBySkuCtrl: [''],
      filterByNameCtrl: ['']
    });
    this.hotkeyId = this.formData && this.formData.id ? this.formData.id : uuid.v4();
    this.subscribeProducts();
    if (this.formData && this.formData.SKU) {
      let product = this.formData;
      this.frmHotKey.get('sku').setValue(product.SKU);
      this.frmHotKey.get('displayName').setValue(product.displayName);
      this.frmHotKey
        .get('image')
        .setValue(product.imageUrls && product.imageUrls.length ? product.imageUrls[0] : '');
    }
    if (this.action === 'view') {
      this.frmHotKey.disable();
    }
  }
  noEValidator(control: FormControl) {
    if (control.value === null) {
      return { noE: true };
    }
    return {};
  }
  subscribeProducts() {
    this.frmHotKey
      .get('filterBySkuCtrl')
      .valueChanges.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => {
          if (this.frmHotKey.get('filterBySkuCtrl').value) {
            this.frmHotKey.get('filterByNameCtrl').disable();
            this.fetchProducts();
          } else {
            this.frmHotKey.get('filterByNameCtrl').enable();
            this.options = [];
          }
        })
      )
      .subscribe();

    this.frmHotKey
      .get('filterByNameCtrl')
      .valueChanges.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => {
          if (this.frmHotKey.get('filterByNameCtrl').value) {
            this.frmHotKey.get('filterBySkuCtrl').disable();
            this.fetchProducts();
          } else {
            this.frmHotKey.get('filterBySkuCtrl').enable();
            this.options = [];
          }
        })
      )
      .subscribe();
  }

  fetchProducts() {
    this.searching = true;
    this.hotKeyService
      .getProductList(
        this.orgId,
        this.frmHotKey.get('filterBySkuCtrl').value,
        this.frmHotKey.get('filterByNameCtrl').value
      )
      .subscribe((result: any) => {
        this.searching = false;
        let data = result.result;
        data.map((data: any) => {
          if (data.descriptions && Array.isArray(data.descriptions)) {
            let shortDescription = data.descriptions.find(
              (desc) => desc.name === 'SHORT_DESCRIPTION'
            );
            if (shortDescription) {
              data.description = shortDescription.value;
            }
          }
        });
        this.options = data;
        console.log(this.options);
      });
  }

  onSearchInput(e) {
    this.isProductSelected = false;
    if (!e.target.value) {
      this.options = [];
    }
  }

  onSelectProduct(e) {
    const product = e.option.value;
    this.frmHotKey.get('sku').setValue(product.SKU);
    this.frmHotKey.get('displayName').setValue(product.description);
    this.frmHotKey
      .get('image')
      .setValue(product.imageUrls && product.imageUrls.length ? product.imageUrls[0] : '');
    this.isProductSelected = true;
    setTimeout(() => {
      this.frmHotKey.get('filterBySkuCtrl').setValue('');
      this.frmHotKey.get('filterByNameCtrl').setValue('');
    }, 100);
  }

  displaySku(product) {
    return '';
  }

  displayProductName(product) {
    return '';
  }

  async onFileChange(event: any) {
    this.dataLoading = true;
    const input = event.target as HTMLInputElement;
    if (input && input.files) {
      const file = input.files[0];
      console.log(file);
      if (!file) {
        this.dataLoading = false; // Hide the loader
        return;
      }
      if (file.size > 6291456) {
        // 6MB in bytes
        this.dataLoading = false;
        this.notificationService.error('Please add image of size below 6MB');
        return;
      }
      const image = new Image();
      const imageUrl = URL.createObjectURL(file);
      image.onload = async () => {
        const extension = file.name.split('.').pop();
        // const uniqueFileName = uuid.v4();
        let id;
        if (this.orgId) {
          id = this.orgId ? this.orgId.split('::')[1] : '';
        } else {
          id = this.nodeId ? this.nodeId : '';
        }
        // const abc= this.orgId ? this.orgId.split('::')[1]:'';
        const path = `organization/hotkeys/${id}/${this.hotkeyId}.${extension}`;
        console.log(path);
        const bucket = environment.om_essential;
        console.log('bucket->', bucket);
        const imageToUpload = event.target.files.item(0);
        try {
          this.dataLoading = true;
          const data = await this.SharedService.uploadFile(imageToUpload, path, bucket);
          console.log(data);
          this.frmHotKey.get('image').setValue(data.Location ? data.Location : '');
          this.dataLoading = false;
          this.notificationService.success('File Uploaded Successfully');
        } catch (error) {
          this.dataLoading = false;
          this.notificationService.error('File upload failed');
        }
      };
      image.onerror = () => {
        this.dataLoading = false;
        this.notificationService.error('Invalid file format');
      };
      image.src = imageUrl; // Trigger the image load
    }
  }

  onSave() {
    const formValue = this.frmHotKey.getRawValue();
    console.log(formValue);
    const data = {
      position: 1,
      SKU: formValue.sku,
      displayName: formValue.displayName,
      imageUrls: [formValue.image],
      id: this.hotkeyId
    };
    if (this.groudId) {
      data['groupId'] = this.groudId;
    }
    this.dialogRef.close(data);
  }

  onCancel() {
    this.dialogRef.close();
  }
}
