import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CommonConfigService } from './common-config.service';
import { CommonConfigEffects } from './state/common-config.effects';
import { CommonConfigFacade } from './state/common-config.facade';
import {
  commonConfigInitialState,
  commonConfigReducer,
  metaReducers
} from './state/common-config.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('commonConfig', commonConfigReducer, {
      initialState: commonConfigInitialState,
      metaReducers
    }),
    EffectsModule.forFeature([CommonConfigEffects])
  ],
  providers: [CommonConfigEffects, CommonConfigService, CommonConfigFacade],
  declarations: []
})
export class CommonConfigModule {}
