import { PERMISSION } from '../../auth/has-permission/has-permission-directive';

export const ORGANIZATION_ROUTES_BASE_PATH = 'organisation';

export const ORG_ROUTES_NAME = {
  LIST: {
    path: '',
    name: 'org_sub_menu.org_list',
    visible: true,
    expectedPermission: [PERMISSION.LIST_ORGANISATIONS]
  },
  CREATE: {
    path: 'create',
    name: 'org_sub_menu.org_add',
    visible: true,
    expectedPermission: [PERMISSION.CREATE_ORGANISATION]
  },
  VIEW: {
    path: ':id/view',
    name: 'org_sub_menu.org_view',
    visible: false,
    expectedPermission: [PERMISSION.VIEW_ORGANISATION]
  },
  EDIT: {
    path: ':id/edit',
    name: 'org_sub_menu.org_edit',
    visible: false,
    expectedPermission: [PERMISSION.UPDATE_ORGANISATION]
  }
};
