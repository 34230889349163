import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { TranslateService } from '@ngx-translate/core';
import { PersistanceService } from '@services/persistance.service';
import { CommonConfigFacade } from '@shared/common-config/state/common-config.facade';
import { IUser } from '@shared/interfaces';
import { Subscription } from 'rxjs';
import { PERMISSION } from 'src/app/feature/auth/has-permission/has-permission-directive';
import { AuthFacade } from 'src/app/feature/auth/state/auth.facade';
import { IApplications, IOrganization } from '@shared/interfaces';

@Component({
  selector: 'app-site-header',
  templateUrl: './site-header.component.html',
  styleUrls: ['./site-header.component.scss']
})
export class SiteHeaderComponent implements OnInit, OnDestroy {
  @Input() siteLeftPanel: MatSidenav;
  @Input() organizations: IOrganization[] = [];
  @Input() siteRightPanel: MatSidenav;
  userDetail: IUser;
  logoURL: string;
  subscriptions: Subscription[] = [];
  currentLanguage: string;
  PERMISSION = PERMISSION;
  globalUser;
  constructor(
    private authFacade: AuthFacade,
    private commonConfigFacade: CommonConfigFacade,
    private readonly persistanceService: PersistanceService,
    public translate: TranslateService
  ) {
    translate.addLangs(['en', 'fr', 'zh-CN', 'nl', 'ja-JP', 'it', 'de']);
    translate.setDefaultLang('en');

    const browserLang = translate.getBrowserLang();
    this.currentLanguage = browserLang;
    translate.use(browserLang.match(/en|fr|zh-CN|nl|ja-JP|it|de/) ? browserLang : 'en');
  }
  public signOut() {
    this.authFacade.logout();
  }

  ngOnInit() {
    this.subscriptions.push(
      this.commonConfigFacade.currentUserTheme$.subscribe((theme: any) => {
        if (!theme) {
          this.logoURL = this.persistanceService.getAppSetting().logoURL
            ? this.persistanceService.getAppSetting().logoURL
            : this.persistanceService.getAppSetting().logoURLPortrait
            ? this.persistanceService.getAppSetting().logoURLPortrait
            : '';
        } else {
          this.logoURL = theme.logoURL
            ? theme.logoURL
            : theme.logoURLPortrait
            ? theme.logoURLPortrait
            : '';
        }
      })
    );
    this.subscriptions.push(
      this.authFacade.user$.subscribe((user) => {
        this.userDetail = user;
      })
    );
    this.globalUser =
      this.userDetail && this.userDetail.rootNode && this.userDetail.rootNode === 'GLOBAL'
        ? true
        : false;

    // console.log('user dtail', this.userDetail);
  }
  onLanguageSelect({ value: language }) {
    this.translate.use(language);
  }
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }
}
