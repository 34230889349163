import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICurrency, INode } from '@shared/interfaces';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService, PATH } from 'src/app/core/api/api.service';
import {
  INodeConfigurationResponse,
  IReasonCategory,
  ITenderType
} from '../organization/model/organization.model';

@Injectable()
export class NodeManagementService {
  selectedCurrencies = new BehaviorSubject<ICurrency[]>([]);
  selectedCurrencies$ = this.selectedCurrencies.asObservable();
  constructor(private apiService: ApiService) {}

  public saveNode(node: INode) {
    return this.apiService.post(PATH.POST_CREATE_NODE, node);
  }

  passCurrencyList(data) {
    this.selectedCurrencies.next(data);
  }

  public getNodeById(nodeId: string): Observable<any> {
    return this.apiService.get(PATH.GET_ORGANISATION_BY_ID(nodeId));
  }

  public getDomainListBySubDomain(subDomain: string): Observable<any> {
    return this.apiService.get(PATH.GET_DOMAIN_LIST(subDomain));
  }
  public getReasonCategory(): Observable<IReasonCategory[]> {
    return this.apiService
      .get(PATH.GET_REASON_CATEGORY)
      .pipe(map((response) => response.result as IReasonCategory[]));
  }

  public getTenderType(): Observable<ITenderType[]> {
    return this.apiService
      .get(PATH.GET_TENDER_TYPE)
      .pipe(map((response) => response.result as ITenderType[]));
  }

  public editNode(node: any, nodeId: string) {
    return this.apiService.put(PATH.PUT_EDIT_NODE(nodeId), node);
  }

  public getNodeConfigById(
    nodeId: string,
    status: boolean
  ): Observable<INodeConfigurationResponse> {
    return this.apiService.get(
      PATH.GET_NODE_CONFIG_BY_ID(nodeId),
      new HttpParams().set('status', status.toString())
    );
  }

  public getNodeOverrideById(nodeId: string): Observable<any> {
    return this.apiService
      .get(PATH.GET_NODE_OVERRIDE_BY_ID(nodeId))
      .pipe(map((data) => data.result));
  }

  public enableDisableNode(status, nodeId): Observable<any> {
    const body: any = { status };
    return this.apiService.put(PATH.PUT_UPDATE_NODE_STATUS(nodeId), body);
  }

  generateQrcode(data: any) {
    return this.apiService.post(PATH.POST_QRCODE, data);
  }

  public deleteNode(nodeId: string): Observable<any> {
    return this.apiService.delete(PATH.DELETE_NODE_BY_ID(nodeId)).pipe(map((data) => data.result));
  }
}
