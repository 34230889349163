import { Component, NgZone, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@services/notification.service';
import { PersistanceService } from '@services/persistance.service';
import { ForgotPasswordService } from '../../../forgot-password/forgot-password.service';
import { IResetPasswordReq } from 'src/app/feature/forgot-password/model';
import { ProfileService } from 'src/app/profile/profile.service';
import { LocalStorageJwtService } from '../../local-storage-jwt.service';
import { ICurrentUser, IUser } from '@shared/interfaces';
import { AuthFacade } from '../../state/auth.facade';
import * as uuid from 'uuid';

@Component({
  selector: 'app-reset-password',
  templateUrl: './forcePasswordReset.component.html',
  styleUrls: ['./forcePasswordReset.component.scss']
})
export class ForcePasswordResetComponent implements OnInit {
  resetForm: FormGroup;
  isValidateError: boolean;
  validationMassage: string;
  isSuccess: boolean;
  successMessage: string;
  logoURL = '';
  hide: boolean;
  chide: boolean;
  oldPasswordHide: boolean;
  private currentUser: ICurrentUser;
  user: IUser;
  domainName: any;
  constructor(
    private formBuilder: FormBuilder,
    private service: ForgotPasswordService,
    private readonly notification: NotificationService,
    private persistanceService: PersistanceService,
    private profileService: ProfileService,
    private localStorageJwtService: LocalStorageJwtService,
    private authFacade: AuthFacade,
    private router: Router,
    private zone: NgZone,
    private notificationService: NotificationService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    console.log('starting the init ... ');
    const source = history.state.source;
    if (source !== '/login') {
      const errorMessage = this.translate.instant('profile.reset_password');
      this.notification.error(errorMessage);
    }
    this.currentUser = this.localStorageJwtService.getCurrentUser();
    this.authFacade.user$.subscribe((res) => {
      this.user = res;
      this.domainName = res.orgConfig.domainName;
      console.log('response ->', this.user);
    });
    this.logoURL = this.persistanceService.getAppSetting().logoURL;
    this.resetForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.pattern('^(?=.{6,}).*$')]],
      confirmPassword: ['', [Validators.required, this.checkConfirmPasswords]]
    });
  }

  /**
   *
   * @param control
   * validate confirm password and password is same or not
   */
  checkConfirmPasswords(control: AbstractControl): { [key: string]: boolean } | null {
    const password: string =
      control && control.parent ? control.parent.controls['password'].value : '';
    if (control && control.value && password !== control.value) {
      control.markAsTouched();
      return { notSame: true };
    }
    control.markAsUntouched();
    return null;
  }

  noWhiteSpaceAllow(event: any) {
    if (event.which === 32) {
      event.preventDefault();
    }
  }

  public async onSubmitResetPassword() {
    // stop here if form is invalid
    if (this.resetForm.invalid) {
      return;
    }
    try {
      const req: IResetPasswordReq = {
        password: this.resetForm.controls.password.value,
        confirmPassword: this.resetForm.controls.confirmPassword.value
      };

      // call update user api set 3 fields set resetToken , resetDate , isResetTokenUsed
      this.user.resetDate = new Date();
      this.user.isResetTokenUsed = false;
      this.user.resetToken = uuid.v4();
      this.user.domainName = this.domainName;
      await this.profileService
        .updateUser(this.user, this.user.id)
        .then((res) => {
          console.log('res ', res);
          console.log('user updated successfully ...');
        })
        .catch((err) => {
          console.log('err', err);
        });

      this.service.resetUserPassword(req, this.user.resetToken).subscribe(
        async (response) => {
          this.user.isResetTokenUsed = true;
          this.user.manualPassword = true;
          this.user.password = this.resetForm.controls.password.value;
          const res = await this.updateUser();
          const successMessage = this.translate.instant('profile.confirmation_message');
          this.notificationService.success(successMessage);
          this.zone.run(() => {
            this.authFacade.logout();
          });
        },
        (err) => {
          this.isSuccess = false;
          console.log(err);
        }
      );
    } catch (err) {
      this.notification.error(err);
    }
  }

  public async updateUser() {
    await this.profileService
      .updateUser(this.user, this.user.id)
      .then((res) => {
        console.log('user updated successfully ...', res);
        return res;
      })
      .catch((err) => {
        console.log('err', err);
        return err;
      });
  }

  public async onCancelResetPassword() {
    console.log('logging out ..');
    this.authFacade.logout();
  }
}
