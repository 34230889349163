import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, PRIMARY_OUTLET, Router } from '@angular/router';
import { IUser } from '@shared/interfaces';
import { BehaviorSubject } from 'rxjs';
import { LocalStorageJwtService } from 'src/app/feature/auth/local-storage-jwt.service';
import { AuthFacade } from 'src/app/feature/auth/state/auth.facade';
import { DynamicNameService } from './dynamic-menu-name.service';

@Injectable()
export class BreadcrumbsService {
  ROUTE_DATA_TITLE = 'title';
  ROUTE_DATA_DYNAMIC_TITLE = 'dynamicTitle';
  private readonly pagetitle = new BehaviorSubject<string>('');

  private readonly title$ = this.pagetitle.asObservable();
  authUser: IUser;
  constructor(
    public router: Router,
    public activeRoute: ActivatedRoute,
    private localStorageJwtService: LocalStorageJwtService,
    private authFacade: AuthFacade
  ) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        let currentRoute: ActivatedRoute = this.activeRoute.root;
        while (currentRoute.children.length > 0) {
          const childrenRoutes: ActivatedRoute[] = currentRoute.children;
          childrenRoutes.forEach((route) => {
            // Set currentRoute to this route
            currentRoute = route;
            // Verify this is the primary route
            if (route.outlet !== PRIMARY_OUTLET) {
              return;
            }
            if (!route.firstChild) {
              const DYNAMIC_TITLE = route.snapshot.data[this.ROUTE_DATA_DYNAMIC_TITLE];
              const TITLE = route.snapshot.data[this.ROUTE_DATA_TITLE];
              if (DYNAMIC_TITLE && DYNAMIC_TITLE.length > 0) {
                // This will call dynamic Funciton and pass value to that function as current user.
                const fnDynamicName = DynamicNameService[DYNAMIC_TITLE];
                if (typeof fnDynamicName === 'function') {
                  // dashboard title should be shown as per assigned dashboard to user
                  this.authFacade.user$.subscribe((r) => {
                    this.authUser = r;
                  });
                  const dynamicTitle = fnDynamicName.apply(null, [
                    // this.localStorageJwtService.getCurrentUser()
                    this.authUser
                  ]);
                  this.pagetitle.next(dynamicTitle);
                } else {
                  this.pagetitle.next(TITLE);
                }
              } else {
                this.pagetitle.next(TITLE);
              }
            }
          });
        }
      }
    });
  }

  // Return the breadcrumbs
  public getTitle() {
    return this.title$;
  }
}
