import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@services/notification.service';
import { PersistanceService } from '@services/persistance.service';
import { error } from 'console';
import { ForgotPasswordService } from '../forgot-password.service';
import { IResetPasswordReq } from '../model';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  resetForm: FormGroup;
  resetToken;
  isValidateError: boolean;
  validationMassage: string;
  isSuccess: boolean;
  successMessage: string;
  logoURL = '';
  hide: boolean;
  chide: boolean;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private service: ForgotPasswordService,
    private readonly notification: NotificationService,
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private persistanceService: PersistanceService
  ) {}

  ngOnInit() {
    this.logoURL = this.persistanceService.getAppSetting().logoURL;
    this.resetToken = this.activatedRoute.snapshot.paramMap.get('resetToken');
    this.resetForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.pattern('^(?=.{6,}).*$')]],
      confirmPassword: ['', [Validators.required, this.checkConfirmPasswords]]
    });
    this.validateResetToken();
  }

  validateResetToken() {
    this.service.validateResetToken(this.resetToken).subscribe(
      (response) => {},
      (err) => {
        this.validationMassage = err.split(':')[2];
        this.isValidateError = true;
      }
    );
  }
  /**
   *
   * @param control
   * validate confirm password and password is same or not
   */
  checkConfirmPasswords(control: AbstractControl): { [key: string]: boolean } | null {
    const password: string =
      control && control.parent ? control.parent.controls['password'].value : '';
    if (control && control.value && password !== control.value) {
      control.markAsTouched();
      return { notSame: true };
    }
    control.markAsUntouched();
    return null;
  }

  noWhiteSpaceAllow(event: any) {
    if (event.which === 32) {
      event.preventDefault();
    }
  }

  public async onSubmitResetPassword() {
    // stop here if form is invalid
    if (this.resetForm.invalid) {
      return;
    }
    try {
      const req: IResetPasswordReq = {
        password: this.resetForm.controls.password.value,
        confirmPassword: this.resetForm.controls.confirmPassword.value
      };
      this.service.resetUserPassword(req, this.resetToken).subscribe(
        (response: any) => {
          this.isValidateError = false;
          this.isSuccess = true;
          console.log('response : ', response);
          this.successMessage = response.message;
        },
        (err) => {
          this.validationMassage = err.split(':')[2];
          this.isValidateError = true;
          this.isSuccess = false;
          console.log(err);
        }
      );
    } catch (err) {
      this.notification.error(err);
    }
  }
}
