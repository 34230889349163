import { Routes } from '@angular/router';
import { ProfileContainer } from '../profile.container';
import { PROFILE_ROUTES_NAME } from './profile.routes.names';

export const PROFILE_ROUTES: Routes = [
  {
    path: PROFILE_ROUTES_NAME.PROFILE.path,
    component: ProfileContainer,
    data: {
      title: PROFILE_ROUTES_NAME.PROFILE.name
    }
  }
];
