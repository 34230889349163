import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PersistanceService } from '@services/persistance.service';
import { SettingsService } from '@services/settings.service';
import { Observable } from 'rxjs';
import { LocalStorageJwtService } from '../local-storage-jwt.service';
import { AuthFacade } from '../state/auth.facade';
import { UserLogin } from '../state/auth.model';
import { environment } from '@env/environment';
import { NodeManagementService } from '../../node-management/node-management.service';
import { windowCount } from 'rxjs/operators';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginComponent implements OnInit, OnDestroy {
  loginForm: FormGroup;
  submitted;
  invalideUserDetails = false;
  logoURL = '';
  landscapeBGImage = '';
  portraitBGImage = '';
  isGlobalPortal = false;
  domainList = [];
  defaultDomain;
  portalDomain = '';
  isdomainList = false;
  isLargeScreen = true;
  hide: boolean;
  errorMessages$: Observable<string>;
  isRedShowErrMsg$: Observable<boolean>;
  isGrayShowErrMsg$: Observable<boolean>;

  constructor(
    private formBuilder: FormBuilder,
    private authFacade: AuthFacade,
    private router: Router,
    private persistanceService: PersistanceService,
    private localStorageJwtService: LocalStorageJwtService,
    private settingService: SettingsService,
    private nodeManagementService: NodeManagementService
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isLargeScreen = window.innerWidth > window.innerHeight;
    if (this.isLargeScreen) {
      this.getBackgroundImage('landscape');
    } else {
      this.getBackgroundImage('portrait');
    }
  }

  ngOnInit(): void {
    this.localStorageJwtService.removeItem();
    this.logoURL = this.persistanceService.getAppSetting().logoURL
      ? this.persistanceService.getAppSetting().logoURL
      : this.persistanceService.getAppSetting().logoURLPortrait
      ? this.persistanceService.getAppSetting().logoURLPortrait
      : '';
    console.log('logoURL', this.logoURL);
    this.isGlobalPortalFn();
    this.landscapeBGImage =
      this.persistanceService.getAppSetting() &&
      this.persistanceService.getAppSetting().backgroundImage &&
      this.persistanceService.getAppSetting().backgroundImage.status &&
      this.persistanceService.getAppSetting().backgroundImage.landscapeImage
        ? this.persistanceService.getAppSetting().backgroundImage.landscapeImage
        : '';

    this.portraitBGImage =
      this.persistanceService.getAppSetting() &&
      this.persistanceService.getAppSetting().backgroundImage &&
      this.persistanceService.getAppSetting().backgroundImage.status &&
      this.persistanceService.getAppSetting().backgroundImage.portraitImage
        ? this.persistanceService.getAppSetting().backgroundImage.portraitImage
        : '';

    this.isLargeScreen = window.innerWidth > window.innerHeight;

    this.loginForm = this.formBuilder.group({
      username: ['', Validators.compose([Validators.required])],
      password: ['', Validators.required],
      domainName: ['']
    });
    let subdomainName = '';
    const domain =
      window.location.hostname.indexOf('localhost') >= 0
        ? window.location.hostname.replace('localhost', environment.mainHost)
        : window.location.hostname;
    console.log(domain);
    if (domain) {
      subdomainName = this.removeHttpandwww(domain);
      this.portalDomain = subdomainName;
      subdomainName = subdomainName.split('.')[0];
    }

    this.nodeManagementService
      .getDomainListBySubDomain(subdomainName)
      .subscribe((response: any) => {
        if (response.result && response.result[0] && response.result[0].domains) {
          this.domainList = response.result[0].domains;
          const defaultDomainObj = this.domainList.find((domain) => domain.isDefault);
          if (defaultDomainObj) {
            this.defaultDomain = defaultDomainObj.domainName;
          }
          this.loginForm.get('domainName').setValue(this.defaultDomain);
          const domaininlist = this.domainList.filter((d) => d.domainName === this.portalDomain);
          this.isdomainList = true;
          console.log(response.result[0].domains);
        }
      });

    this.isRedShowErrMsg$ = this.authFacade.isRedError;
    this.isGrayShowErrMsg$ = this.authFacade.isGrayError;
    this.errorMessages$ = this.authFacade.loginError;
  }

  ngOnDestroy(): void {}

  public removeHttpandwww(url: string) {
    if (url.startsWith('https://')) {
      const https = 'https://';
      return url.slice(https.length);
    }

    if (url.startsWith('http://')) {
      const http = 'http://';
      return url.slice(http.length);
    }

    if (url.startsWith('https://www.')) {
      const https = 'https://www.';
      return url.slice(https.length);
    }

    if (url.startsWith('http://www.')) {
      const http = 'http://www.';
      return url.slice(http.length);
    }

    if (url.startsWith('www')) {
      const http = 'www.';
      return url.slice(http.length);
    }
    return url;
  }
  public hasError(controlName: string, errorName: string) {
    return this.loginForm.controls[controlName].hasError(errorName);
  }

  public async onSubmit() {
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }
    try {
      let domainName = '';
      let username = '';
      // TODO: change this oldHost to mainHost when we are ready
      domainName = btoa(
        window.location.hostname.indexOf('localhost') >= 0
          ? window.location.hostname.replace('localhost', environment.oldHost)
          : window.location.hostname.replace(environment.mainHost, environment.oldHost)
      );
      if (this.loginForm.controls.domainName.value) {
        // domainName = btoa(this.loginForm.controls.domainName.value);
        username = btoa(
          this.loginForm.controls.username.value + '@' + this.loginForm.controls.domainName.value
        );
      } else {
        username = btoa(this.loginForm.controls.username.value);
      }
      const userLogin: UserLogin = {
        username,
        password: btoa(this.loginForm.controls.password.value),
        domainName
      };
      this.invalideUserDetails = false;
      this.authFacade.login(userLogin);
      this.settingService.getSettingsAfterLogin();
    } catch (err) {
      this.invalideUserDetails = true;
      this.router.navigate(['/login']);
    }
  }

  noWhiteSpaceAllow(event: any) {
    if (event.which === 32) {
      event.preventDefault();
    }
  }

  getBackgroundImage(type) {
    if (type == 'landscape') {
      return this.landscapeBGImage;
    } else {
      return this.portraitBGImage;
    }
  }

  isGlobalPortalFn() {
    if (
      window.location.hostname == environment.mainHost ||
      window.location.hostname == environment.oldHost
    ) {
      this.isGlobalPortal = true;
      this.landscapeBGImage = '';
      this.portraitBGImage = '';
    }
  }
}
