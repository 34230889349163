import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICompanyList } from '@shared/interfaces';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService, PATH } from 'src/app/core/api/api.service';

@Injectable()
export class ConfigSettingService {
  constructor(private apiService: ApiService) {}

  getCompanyList(orgId: string, nodeType: string): Observable<ICompanyList[]> {
    return this.apiService
      .get(PATH.GET_COMPANY_LIST, new HttpParams().set('orgId', orgId).set('nodeType', nodeType))
      .pipe(
        // tslint:disable-next-line: no-string-literal
        map((res) => res as ICompanyList[])
      );
  }
}
