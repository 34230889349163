import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
@Component({
  selector: 'customer-hub-import-export-settings',
  templateUrl: './customer-hub-import-export-settings.component.html',
  styleUrls: ['./customer-hub-import-export-settings.component.scss']
})
export class CustomerHubImportExportSettingsComponent implements OnInit {
  @Input() action: string;
  @Input() parentForm: FormGroup;
  @Input() customerHubImportExportDetails: any;
  @Input() authControlKey: string;
  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.initForm();
  }
  initForm() {
    this.parentForm.addControl(
      this.authControlKey,
      this.formBuilder.group({
        customerImport: [
          {
            value:
              this.customerHubImportExportDetails &&
              this.customerHubImportExportDetails.customerImport
                ? true
                : false,
            disabled: this.action === 'view' ? true : false
          }
        ],
        customerExport: [
          {
            value:
              this.customerHubImportExportDetails &&
              this.customerHubImportExportDetails.customerExport
                ? true
                : false,
            disabled: this.action === 'view' ? true : false
          }
        ]
      })
    );
  }
}
