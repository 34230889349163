import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatTableDataSource, MatDialog } from '@angular/material';
import { Observable } from 'rxjs';
import { HotkeyService } from '../hotkeys.service';
import { SharedService } from '@shared/shared.service';
import { environment } from '@env/environment';
import { NotificationService } from '@services/notification.service';
import * as uuid from 'uuid';
import { AddHotKeyDialogComponent } from '../add-hotkey-dialog/add-hotkey-dialog.component';

@Component({
  selector: 'app-module-assignment-add-hotkey-dialog',
  templateUrl: './add-hotkey-group-dialog.component.html',
  styleUrls: ['./add-hotkey-group-dialog.component.scss']
})
export class AddHotKeyGroupDialogComponent implements OnInit {
  frmHotKey: FormGroup;
  orgId: string;
  options: any[] = [];
  filteredOptions: Observable<string[]>;
  isProductSelected: boolean = false;
  searching: boolean = false;
  hotkeyGroupId: string;
  formData: any;
  dataLoading: boolean = false;
  productColumns: any[] = ['SKU', 'position', 'displayName', 'action'];
  productDataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  childHotkeys = [];
  deletedHotkeys = [];
  nodeId: string;
  @ViewChild('matCell', { static: false }) matCell: ElementRef;
  maxHeight: string = '885px';
  constructor(
    private formBuilder: FormBuilder,
    private hotKeyService: HotkeyService,
    private notificationService: NotificationService,
    private sharedService: SharedService,
    public dialogRef: MatDialogRef<AddHotKeyGroupDialogComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.orgId = data.orgId;
    this.formData = data.formData;
    this.childHotkeys = data.childData;
    this.nodeId = data.nodeId;
  }

  ngOnInit() {
    this.initForm();
  }

  ngOnChanges() {
    if (this.matCell) {
      this.updateMaxHeight();
    } else {
      setTimeout(() => {
        if (this.matCell) {
          this.updateMaxHeight();
        }
      }, 0);
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.updateMaxHeight();
    }, 0);
  }
  updateMaxHeight() {
    if (this.matCell) {
      const height = this.matCell.nativeElement.offsetHeight;
      this.maxHeight = height * 21.5 + 'px';
    }
  }

  initForm() {
    this.productDataSource.data = [];
    this.frmHotKey = this.formBuilder.group({
      displayName: [
        this.formData && this.formData.displayName ? this.formData.displayName : '',
        Validators.required
      ],
      image: [
        this.formData &&
        this.formData.imageUrls &&
        this.formData.imageUrls.length > 0 &&
        this.formData.imageUrls[0]
          ? this.formData.imageUrls[0]
          : ''
      ]
    });
    if (this.formData && this.formData.id) {
      this.hotkeyGroupId = this.formData.id;
    } else {
      this.hotkeyGroupId = uuid.v4();
    }
    this.productDataSource.data = this.childHotkeys;
    //this.subscribeProducts();
  }
  noEValidator(control: FormControl) {
    if (control.value === null) {
      return { noE: true };
    }
    return {};
  }

  openAddHotkeyDialog() {
    const dialogRef = this.dialog.open(AddHotKeyDialogComponent, {
      width: '50%',
      data: { orgId: this.orgId, action: 'Create' }
    });
    dialogRef.afterClosed().subscribe((row) => {
      if (row) {
        row.groupId = this.hotkeyGroupId;
        this.childHotkeys = [...this.childHotkeys, row];
        this.childHotkeys = this.childHotkeys.map((p, index) => {
          return {
            ...p,
            position: index + 1
          };
        });
        this.productDataSource.data = [...this.childHotkeys];
        console.log(this.childHotkeys);
      }
    });
  }
  removeRow(row) {
    console.log(row);
    if (row.groupId) {
      delete row.groupId;
      delete row.checked;
    }
    this.deletedHotkeys.push(row);
    this.childHotkeys = this.childHotkeys.filter((p) => p.id != row.id);
    this.childHotkeys = this.childHotkeys.map((p, index) => {
      return {
        ...p,
        position: index + 1
      };
    });
    const index = this.productDataSource.data.indexOf(row);
    if (index > -1) {
      this.productDataSource.data.splice(index, 1);
      this.productDataSource.data = [...this.productDataSource.data];
    }
    this.productDataSource.data = [...this.childHotkeys];
  }
  editRow(rowData) {
    console.log(rowData);
    const dialogRef = this.dialog.open(AddHotKeyDialogComponent, {
      width: '50%',
      data: { formData: rowData, orgId: this.orgId, action: 'Edit' }
    });
    dialogRef.afterClosed().subscribe((row) => {
      if (row) {
        row.groupId = this.hotkeyGroupId;
        const index = this.childHotkeys.findIndex((product) => product.id === row.id);
        if (index !== -1) {
          this.childHotkeys[index] = row;
        }
        this.childHotkeys = this.childHotkeys.map((p, i) => {
          return {
            ...p,
            position: i + 1
          };
        });
        this.productDataSource.data = [...this.childHotkeys];
        // this.updateData.emit({ status: this.status, products: this.products });
        // setTimeout(() => {
        //   this.productDataSource.paginator = this.paginator;
        //   this.productDataSource.sort = this.sort;
        // }, 100);
      }
    });
  }
  async onFileChange(event: any) {
    this.dataLoading = true;
    const input = event.target as HTMLInputElement;
    if (input && input.files) {
      const file = input.files[0];
      console.log(file);
      if (!file) {
        this.dataLoading = false;
        return;
      }
      if (file.size > 6291456) {
        // 6MB in bytes
        this.notificationService.error('Image size should not exceed 6MB');
        this.dataLoading = false;
        return;
      }
      const image = new Image();
      const imageUrl = URL.createObjectURL(file);

      image.onload = async () => {
        const extension = file.name.split('.').pop();
        // this.hotkeyGroupId = uuid.v4();
        //const abc = this.orgId ? this.orgId.split('::')[1] : '';
        let id;
        if (this.orgId) {
          id = this.orgId ? this.orgId.split('::')[1] : '';
        } else {
          id = this.nodeId ? this.nodeId : '';
        }
        const path = `organization/hotkeys/${id}/${this.hotkeyGroupId}.${extension}`;
        const bucket = environment.om_essential;
        console.log('bucket->', bucket);

        const imageToUpload = event.target.files.item(0);

        try {
          this.dataLoading = true;
          const data = await this.sharedService.uploadFile(imageToUpload, path, bucket);
          console.log(data);
          this.frmHotKey.get('image').setValue(data.Location);
          this.dataLoading = false;
          this.notificationService.success('File Uploaded Successfully');
        } catch (error) {
          this.dataLoading = false;
          this.notificationService.error('File upload failed');
        }
      };

      image.onerror = () => {
        this.dataLoading = false;
        this.notificationService.error('Invalid file format');
      };

      image.src = imageUrl; // Trigger the image load
    }
  }
  search() {
    console.log('search called');
  }
  onSave() {
    const formValue = this.frmHotKey.getRawValue();
    const groupData = {
      position: 0,
      displayName: formValue.displayName,
      imageUrls: [formValue.image],
      isHotKeyGroup: true,
      id: this.hotkeyGroupId
    };

    const childData = this.childHotkeys.map((x) => {
      return { ...x, groupId: this.hotkeyGroupId };
    });
    const data = {
      groupData,
      childData,
      deletedData: this.deletedHotkeys
    };
    this.dialogRef.close(data);
  }

  onCancel() {
    this.dialogRef.close();
  }
}
