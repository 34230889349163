import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CommonConfigFacade } from '@shared/common-config/state/common-config.facade';
import { ILanguage } from '@shared/interfaces';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-language-dropdown',
  templateUrl: './language-dropdown.component.html'
})
export class LanguageDropdownComponent implements OnInit, OnDestroy {
  constructor(public commonFacade: CommonConfigFacade) {}
  languageList: ILanguage[];
  subscriptions: Subscription[] = [];

  @Input() ctrl: FormControl;
  @Input() placeholder: string;
  @Input() defaultLanguage: ILanguage;
  language: ILanguage;
  isDefaultLanguage = false;
  @Input() isOrgNode: boolean;

  ngOnInit() {
    if (this.ctrl.value && this.ctrl.value.isOverride) {
      this.isDefaultLanguage = true;
    }
    this.subscriptions.push(
      this.commonFacade.languages$.subscribe((data) => {
        this.languageList = data;
        if (this.defaultLanguage) {
          this.ctrl.setValue(this.languageList.find((l) => l.id === this.defaultLanguage.id));
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }
}
