import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ApplicationsService } from '@services/applications.service';
import { CommonConfigFacade } from '@shared/common-config/state/common-config.facade';
import { of, throwError as observableThrowError } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import CONSTANTS from 'src/app/util/Constant';
import { AuthService } from '../auth.service';
import { LocalStorageJwtService } from '../local-storage-jwt.service';
import * as AuthActions from './auth.actions';
import { AuthFacade } from './auth.facade';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private readonly applicationsService: ApplicationsService,
    private localStorageJwtService: LocalStorageJwtService,
    private commonConfigFacade: CommonConfigFacade,
    private router: Router,
    private authFacade: AuthFacade
  ) {}

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.login),
      exhaustMap((action) =>
        this.authService.login(action.login).pipe(
          map((loginResponse) => {
            this.localStorageJwtService.setItem(loginResponse);
            const token = this.localStorageJwtService.getDecodedItem(loginResponse);
            this.authFacade.setLoginError('', false, '');
            return AuthActions.getUser({ loginResponse: token });
          }),
          catchError((err) => {
            const errMsg = err.split('Message:');
            if (
              errMsg[1].trim() === 'User Lock! Please contact Administrator' ||
              errMsg[1].trim() === 'Incorrect username or password.' ||
              errMsg[1].trim() === 'You are not allowed to logged in, please contact system admin'
            ) {
              this.authFacade.setLoginError(errMsg[1], true, 'red');
            } else {
              this.authFacade.setLoginError(errMsg[1], true, 'gray');
            }
            return of(AuthActions.loginFail());
          })
        )
      )
    )
  );

  getUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.getUser),
      exhaustMap((action) =>
        this.authService.user(action.loginResponse.idToken['custom:user_id']).pipe(
          map((user) => {
            if (!user.manualPassword) {
              return AuthActions.forcePasswordReset({ user });
            }
            const allAccessRights =
              user.userRole[0] &&
              user.userRole[0].modules
                .filter((x) => x.shortName === CONSTANTS.OM)
                .map((r: any) => r.accessRights);
            let combineAcessRights = [];
            allAccessRights.forEach((element) => {
              combineAcessRights = combineAcessRights.concat(element);
            });
            user.permissions = this.getPermissions(combineAcessRights);
            const hostName = window.location.hostname;
            if (hostName.includes(CONSTANTS.CNDOMAIN)) {
              if (user && user.nodeStructure) {
                user.nodeStructure.filter((nodeObj, ind) => {
                  if (
                    nodeObj &&
                    nodeObj.region &&
                    nodeObj.region.length &&
                    nodeObj.region.length < 2
                  ) {
                    if (nodeObj.region.filter((e: any) => e.key === CONSTANTS.ROW).length > 0) {
                      user.nodeStructure.splice(ind, ind >= 0 ? 1 : 0);
                    }
                  }
                });
              }
            } else {
              if (user && user.nodeStructure) {
                user.nodeStructure.filter((nodeObj, ind) => {
                  if (
                    nodeObj &&
                    nodeObj.region &&
                    nodeObj.region.length &&
                    nodeObj.region.length < 2
                  ) {
                    if (nodeObj.region.filter((e: any) => e.key === CONSTANTS.CN).length > 0) {
                      user.nodeStructure.splice(ind, ind >= 0 ? 1 : 0);
                    }
                  }
                });
              }
            }
            return AuthActions.loginSuccess({ user });
          }),
          catchError((err) => of(AuthActions.loginFail()))
        )
      )
    )
  );

  forcePasswordReset$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.forcePasswordReset),
        tap((action) => {
          console.log('Forcefully password resetting ...');
          const currentUrl = this.router.url;
          this.router.navigate([this.authService.forcePassworResetRedirect], {
            state: { source: currentUrl }
          });
        })
      ),
    { dispatch: false }
  );

  refreshUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.refreshUser),
      exhaustMap((action) =>
        this.authService.user(action.loginResponse.idToken['custom:user_id']).pipe(
          map((user) => {
            const allAccessRighrs =
              user.userRole[0] && user.userRole[0].modules.map((r: any) => r.accessRights);
            let combineAcessRights = [];
            allAccessRighrs.forEach((element) => {
              combineAcessRights = combineAcessRights.concat(element);
            });
            user.permissions = this.getPermissions(combineAcessRights);
            return AuthActions.refreshUserSuccess({ user });
          })
        )
      )
    )
  );

  getUserModules$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.getUserModulesByRole),
      exhaustMap((action) => {
        if (this.localStorageJwtService.getDecodedItem()) {
          return this.applicationsService
            .getModulesByUserRoleId(
              this.localStorageJwtService.getDecodedItem()
                ? JSON.parse(
                    this.localStorageJwtService.getDecodedItem().idToken['custom:roles']
                  )[0]
                : ''
            )
            .pipe(
              map((data) => {
                return AuthActions.getUserModulesByRoleSuccess({ userModules: data });
              })
            );
        } else {
          return observableThrowError({});
        }
      })
    )
  );

  getUserModulesByRoleSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.getUserModulesByRoleSuccess),
        tap((action) => {})
      ),
    { dispatch: false }
  );

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.logout),
        tap((action) => {
          this.localStorageJwtService.removeItem();
          this.authService.redirectUrl = '';
          this.router.navigateByUrl('login');
        })
      ),
    { dispatch: false }
  );

  loginSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.loginSuccess),
        tap((action) => {
          if (this.router.url.indexOf('login') >= 0) {
            this.router.navigateByUrl(this.authService.redirectUrl);
          }
          this.commonConfigFacade.commonConfiguration();
        })
      ),
    { dispatch: false }
  );

  refeshUserSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.getUser),
        tap((action) => {})
      ),
    { dispatch: false }
  );

  setUserPermission$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.setUserPermission),
      exhaustMap((action) =>
        this.authService.user(action.loginResponse.idToken['custom:user_id']).pipe(
          map((user) => {
            const allAccessRights =
              user.userRole[0] &&
              user.userRole[0].modules
                .filter((x) => x.shortName === action.loginResponse.shortName)
                .map((r: any) => r.accessRights);
            let combineAcessRights = [];
            allAccessRights.forEach((element) => {
              combineAcessRights = combineAcessRights.concat(element);
            });
            user.permissions = this.getPermissions(combineAcessRights);
            return AuthActions.loginSuccess({ user });
          }),
          catchError((err) => of(AuthActions.loginFail()))
        )
      )
    )
  );

  private getPermissions(accessRights: any): string[] {
    if (accessRights) {
      let permissions = accessRights && accessRights.map((r) => r.shortName);
      const filterValue = (data: any) =>
        data && data.filter((d: any) => (d && Array.isArray(d) ? d.length : d.trim().length));
      const upperCase = (data: any) => data && data.map((d: any) => d.toUpperCase());
      if (accessRights) {
        accessRights
          .filter((r: any) => filterValue(r.associatePermission))
          .forEach(
            (r: any) =>
              (permissions = [...permissions, ...upperCase(filterValue(r.associatePermission))])
          );
      }
      console.log('Om permissions', permissions);
      return Array.from(new Set(permissions));
    }
    return null;
  }
}
