import { createAction, props } from '@ngrx/store';
import { User } from '../../users/state/user.model';
import { Modules, UserLogin, UserLoginResponseDecoded } from './auth.model';

export const login = createAction('[auth] LOGIN', props<{ login: UserLogin }>());

export const getUser = createAction(
  '[auth] GET_USER',
  props<{ loginResponse: UserLoginResponseDecoded }>()
);

export const refreshUser = createAction(
  '[auth] REFRESH_USER',
  props<{ loginResponse: UserLoginResponseDecoded }>()
);

export const getUserModulesByRole = createAction('[auth] GET_USER_MODULES_BY_ROLE');

export const getUserModulesByRoleSuccess = createAction(
  '[auth] GET_USER_MODULES_BY_ROLE_SUCCESS',
  props<{ userModules: Modules[] }>()
);

export const loginSuccess = createAction('[auth] LOGIN_SUCCESS', props<{ user: User }>());

export const forcePasswordReset = createAction(
  '[auth] FORCE_PASSWORD_RESET',
  props<{ user: User }>()
);

export const refreshUserSuccess = createAction(
  '[auth] REFRESH_USER_SUCCESS',
  props<{ user: User }>()
);

export const loginFail = createAction('[auth] LOGIN_FAIL');

export const logout = createAction('[auth] LOGOUT');

export const setUserPermission = createAction(
  '[auth] SET_USER_PERMISSION_SUCCESS',
  props<{ loginResponse: UserLoginResponseDecoded }>()
);
