import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from '@env/environment';
import { NotificationService } from '@services/notification.service';
import { SharedService } from '@shared/shared.service';
import { AuthFacade } from 'src/app/feature/auth/state/auth.facade';
@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {
  @Input() tableData: any[] = [];
  @Input() selectedProducts: any[] = [];
  @Input() tableCols: any[] = [];
  @Input() tableRowDisabled: any[] = [];
  @Input() externalProvideToggle: any = true;
  @Input() nodeId: any;

  // @Input() knetTableRowDisabled: any[] = [];
  @Input() disabledAllEvent: boolean = false;
  @Input() arrowIconS: any[] = [];
  // @Output() knetRowReorder: EventEmitter<any> = new EventEmitter();
  @Output() rowReorder: EventEmitter<any> = new EventEmitter();
  @Output() deleteEmmiter: EventEmitter<any> = new EventEmitter();
  @Output() durationChangeEmitter: EventEmitter<any> = new EventEmitter();
  @Output() mediaPathEmitter: EventEmitter<any> = new EventEmitter();
  @Input() orgCode: any;
  @Input() action: any;
  @Output() imageUploadingEmitter: EventEmitter<any> = new EventEmitter();
  informSetting = 'informSetting';
  Videos = 'Videos';
  Images = 'Images';
  knetdata: any[];

  @Input() parentForm: FormGroup;

  constructor(
    private sharedService: SharedService,
    private notificationService: NotificationService,
    private authFacade: AuthFacade
  ) {}
  ngOnInit() {
    console.log('orgCode ->', this.orgCode);
    console.log('nodeId->', this.nodeId);
    console.log('tableRowDisabled', this.tableRowDisabled);
    console.log('tbaleData', this.tableData);
  }

  onRowReorder(event: any) {
    console.log('onRowReorder', event);
    this.rowReorder.emit(event);
  }

  getDuration(rowData: any) {
    const filtered = this.tableData.find((ele: any) => ele.priority === rowData.priority);
    return filtered.mediaDuration;
  }

  getSelectedMediaPath(rowData: any) {
    const filtered = this.tableData.find((ele: any) => ele.priority === rowData.priority);
    return filtered.mediaPath || '';
  }
  getFileName(rowData: any) {
    const filtered = this.tableData.find((ele: any) => ele.priority === rowData.priority);
    let fileName = 'Choose Media';
    if (filtered.mediaPath && filtered.mediaPath != '') {
      const temp = filtered.mediaPath.split('/');
      fileName = temp[temp.length - 1];
    }
    return fileName;
  }
  disable(rowData) {
    console.log();
    if (rowData.mediaType === 'Video') {
      return true;
    } else {
      return false;
    }
  }
  async onFileChange(event: any, rowData: any) {
    this.imageUploadingEmitter.emit(true);
    const input = event.target as HTMLInputElement;
    if (input && input.files) {
      const file = input.files[0];
      console.log(file);
      if (!file) {
        this.imageUploadingEmitter.emit(false); // Hide the loader
        return;
      }
      if (file && file.name) {
        // Allowed file extensions for images and videos
        const imageExtensions = ['jpeg', 'png', 'bmp'];
        const videoExtensions = ['mp4', 'mov', 'mkv'];
        const extension = file.name.split('.').pop();

        if (imageExtensions.includes(extension)) {
          console.log('Processing image...');

          const image = new Image();
          const imageUrl = URL.createObjectURL(file);

          image.onload = async () => {
            const width = image.width;
            console.log('Image width ->', width);

            if (width < 600) {
              this.imageUploadingEmitter.emit(false);
              const value = {
                priority: rowData.priority,
                mediaPath: '',
                mediaType: ''
              };
              this.mediaPathEmitter.emit(value);
              return;
            }

            // Proceed with the image upload
            const path = `${this.orgCode}/${this.nodeId}/${this.informSetting}/${this.Images}/${file.name}`;
            const bucket = environment.engage_bucket;

            try {
              const data = await this.sharedService.uploadFile(file, path, bucket);
              const value = {
                priority: rowData.priority,
                mediaPath: data.Location,
                mediaType: 'Image'
              };
              this.imageUploadingEmitter.emit(false);
              this.notificationService.success('Image Uploaded Successfully');
              this.mediaPathEmitter.emit(value);
            } catch (error) {
              this.imageUploadingEmitter.emit(false);
              this.notificationService.error('Image upload failed');
            }
          };

          image.onerror = () => {
            this.imageUploadingEmitter.emit(false);
            this.notificationService.error('Invalid image file format');
          };

          image.src = imageUrl; // Trigger the image load
        } else if (videoExtensions.includes(extension)) {
          console.log('Processing video...');

          // Check file size only for video files
          const maxFileSize = 50 * 1024 * 1024; // 50 MB in bytes
          if (file.size > maxFileSize) {
            this.imageUploadingEmitter.emit(false);
            this.notificationService.error('Video file size exceeds 50 MB');
            return;
          }

          const path = `${this.orgCode}/${this.nodeId}/${this.informSetting}/${this.Videos}/${file.name}`;
          const bucket = environment.engage_bucket;

          try {
            const data = await this.sharedService.uploadFile(file, path, bucket);
            const value = {
              priority: rowData.priority,
              mediaPath: data.Location,
              mediaType: 'Video'
            };
            this.imageUploadingEmitter.emit(false);
            this.notificationService.success('Video Uploaded Successfully');
            this.mediaPathEmitter.emit(value);
          } catch (error) {
            this.imageUploadingEmitter.emit(false);
            this.notificationService.error('Video upload failed');
          }
        } else {
          // Invalid file format
          this.imageUploadingEmitter.emit(false);
          this.notificationService.error('Invalid file format');
        }
      }
    }
  }

  onInputChange(event: Event, rowData: any) {
    console.log('rowData', rowData);
    const input = event.target as HTMLInputElement;
    if (!/^[0-9]*$/.test(input.value)) {
      input.value = input.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
    }
    const value = {
      mediaDuration: input.value,
      priority: rowData.priority
    };
    this.durationChangeEmitter.emit(value);
  }

  delete(event: any) {
    console.log('delete ->', event);
    this.deleteEmmiter.emit(event);
  }
}
