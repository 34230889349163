import { DashboardAccessType, ICurrentUser, IUser, NodeType } from '@shared/interfaces';

export const DynamicNameService = {
  getUserMenuName: (user: ICurrentUser) => {
    if (user && user.rootNode) {
      switch (user.rootNode) {
        case NodeType.GLOBAL:
          return `user_menu.global_menu`;
        case NodeType.ORGANIZATION:
          return `user_menu.org_menu`;
        case NodeType.COMPANY:
          return `user_menu.company_menu`;
        case NodeType.TRADING_REGION:
          return `user_menu.tr_menu`;
        case NodeType.STORE_GROUP:
        case NodeType.SUB_STORE_GROUP:
          return `user_menu.sg_menu`;
        case NodeType.STORE:
          return `user_menu.store_menu`;
      }
    }
    return 'user_menu.common_menu';
  },
  getDashboardTitle: (user: IUser) => {
    if (user && user.dashboardAccess) {
      switch (user.dashboardAccess) {
        case DashboardAccessType.GLOBAL:
          return `dashboard_header.global`;
        case DashboardAccessType.ORGANIZATION:
          return `dashboard_header.org`;
        case DashboardAccessType.COMPANY:
          return `dashboard_header.company`;
        case DashboardAccessType.TRADING_REGION:
          return `dashboard_header.trading_region`;
        case DashboardAccessType.STORE_GROUP:
        case DashboardAccessType.SUB_STORE_GROUP:
          return `dashboard_header.store_group`;
        case DashboardAccessType.STORE:
          return `dashboard_header.store`;
      }
    }
    return 'dashboard_header.dashboard';
  }
};
