import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService, PATH } from '../core/api/api.service';

@Injectable()
export class SharedService {
  constructor(private readonly apiService: ApiService, private http: HttpClient) {}

  async uploadFile(file: File, filePath: any, bucket: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        this.apiService.get(`${PATH.PRE_SIGNED_URL}?key=${filePath}&bucket=${bucket}`).subscribe(
          async (response) => {
            console.log('url -> ', response.result);
            if (response.result) {
              const headers = new HttpHeaders({
                'Content-Type': file.type
              });
              await this.http.put(response.result, file, { headers }).toPromise();
              resolve({ Location: response.result.split('?')[0] });
            }
          },
          (error) => {
            console.log('err occured', error);
          }
        );
      } catch (err) {
        console.log(err);
      }
    });
  }
}
