import { DOCUMENT } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IPaginationResponse, IThemeSettings } from '@shared/interfaces';
import { LocalStorageJwtService } from 'src/app/feature/auth/local-storage-jwt.service';
import { AuthFacade } from 'src/app/feature/auth/state/auth.facade';
import { ApiService, PATH } from '../api/api.service';
import { PersistanceService } from './persistance.service';
import { environment } from '@env/environment';

@Injectable()
export class SettingsService {
  constructor(
    private apiService: ApiService,
    @Inject(DOCUMENT) private document,
    private persistanceService: PersistanceService,
    private localStorageJwtService: LocalStorageJwtService,
    private authFacade: AuthFacade
  ) {}

  getSettings(): Promise<IThemeSettings> {
    try {
      this.setOmPermission();
      const localConfig: IThemeSettings = this.persistanceService.getAppSetting();
      if (!localConfig) {
        return this.getConfiguration();
      } else {
        return Promise.resolve(localConfig);
      }
    } catch (err) {
      console.error(err);
    }
  }

  getSettingsAfterLogin(): Promise<IThemeSettings> {
    try {
      return this.getConfiguration();
    } catch (err) {
      return Promise.resolve(err);
    }
  }

  private getConfiguration(): Promise<IThemeSettings> {
    const defaultTheme = {
      fontFamily: '"Roboto", sans-serif',
      fontSize: '14px',
      textColour: '#151515',
      backgroundColor: '#FFFFFF',
      neutralColour: '#999999',
      primaryColour: '#1A81C5',
      contractPrimaryColour: '#FFFFFF',
      secondaryColour: '#666666',
      contractSecondaryColour: '#FFFFFF',
      warnColour: '#FFD740',
      contractWarnColour: '#FFFFFF',
      successColour: '#149C17',
      contrastSuccessColour: '#FFFFFF',
      errorColour: '#f44336',
      contrastErrorColour: '#FFFFFF',
      disabledColour: '#CCCCCC',
      contrastDisabledColour: '#666666',
      indentedBackground: '#FAFAFA'
    } as IThemeSettings;
    try {
      return new Promise((resolve, reject) => {
        // TODO: change this oldHost to mainHost when we are ready
        this.apiService
          .get(
            PATH.GET_NODE_THEME(
              window.location.hostname.indexOf('localhost') >= 0
                ? window.location.hostname.replace('localhost', environment.oldHost)
                : window.location.hostname.replace(environment.mainHost, environment.oldHost)
            ),
            new HttpParams(),
            true
          )
          .toPromise()
          .then((data: IPaginationResponse<IThemeSettings>) => {
            let orgConfig: IThemeSettings = data.result;
            if (data.result.logoURL != null || data.result.logoURLPortrait != null) {
              orgConfig = data.result;
              this.persistanceService.setAppSetting(orgConfig);
            } else {
              orgConfig = defaultTheme;
              this.persistanceService.setAppSetting(orgConfig);
            }
            resolve(orgConfig);
          })
          .catch((err) => {
            this.persistanceService.setAppSetting(defaultTheme);
            resolve(defaultTheme);
          });
      });
    } catch (err) {
      this.persistanceService.setAppSetting(defaultTheme);
      return Promise.resolve(defaultTheme);
    }
  }

  private setOmPermission() {
    const decodedItem = this.localStorageJwtService.getDecodedItem();
    if (decodedItem) {
      this.authFacade.user(decodedItem);
    }
  }
}
