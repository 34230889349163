import { Routes } from '@angular/router';
import { PermissionGuard } from '../../auth/permission.guard';
import { EmailComponent } from '../email/email.component';
import { ResetPasswordComponent } from '../reset-password/reset-password.component';
import { SecretWordComponent } from '../secret-word/secret-word.component';
import { SharedComponent } from '../shared/shared.component';
import { FORGOT_PASSWORD_ROUTES_NAME } from './forgot-password.routes.names';

export const FORGOT_PASSWORD_ROUTES: Routes = [
  {
    path: FORGOT_PASSWORD_ROUTES_NAME.FORGOT_PASSWORD.path,
    component: SharedComponent,
    data: {
      title: FORGOT_PASSWORD_ROUTES_NAME.FORGOT_PASSWORD.name
    }
  },
  {
    path: FORGOT_PASSWORD_ROUTES_NAME.EMAIL.path,
    component: EmailComponent,
    data: {
      title: FORGOT_PASSWORD_ROUTES_NAME.EMAIL.name
    }
  },
  {
    path: FORGOT_PASSWORD_ROUTES_NAME.SECRET_WORD.path,
    component: SecretWordComponent,
    data: {
      title: FORGOT_PASSWORD_ROUTES_NAME.SECRET_WORD.name
    }
  },
  {
    path: FORGOT_PASSWORD_ROUTES_NAME.RESET_PASSWORD.path,
    component: ResetPasswordComponent,
    data: {
      title: FORGOT_PASSWORD_ROUTES_NAME.RESET_PASSWORD.name
    }
  }
];
