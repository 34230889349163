import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from '@env/environment';
import { NotificationService } from '@services/notification.service';
import { SharedService } from '@shared/shared.service';
import { AuthFacade } from 'src/app/feature/auth/state/auth.facade';
@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {
  @Input() tableData: any[] = [];
  @Input() selectedProducts: any[] = [];
  @Input() tableCols: any[] = [];
  @Input() tableRowDisabled: any[] = [];
  @Input() externalProvideToggle: any = true;
  @Input() nodeId: any;

  // @Input() knetTableRowDisabled: any[] = [];
  @Input() disabledAllEvent: boolean = false;
  @Input() arrowIconS: any[] = [];
  // @Output() knetRowReorder: EventEmitter<any> = new EventEmitter();
  @Output() rowReorder: EventEmitter<any> = new EventEmitter();
  @Output() deleteEmmiter: EventEmitter<any> = new EventEmitter();
  @Output() durationChangeEmitter: EventEmitter<any> = new EventEmitter();
  @Output() mediaPathEmitter: EventEmitter<any> = new EventEmitter();
  @Input() orgCode: any;
  @Input() action: any;
  @Output() imageUploadingEmitter: EventEmitter<any> = new EventEmitter();

  knetdata: any[];

  @Input() parentForm: FormGroup;

  constructor(
    private sharedService: SharedService,
    private notificationService: NotificationService,
    private authFacade: AuthFacade
  ) {}

  ngOnInit() {
    console.log('orgCode ->', this.orgCode);
    console.log('nodeId->', this.nodeId);
    console.log('tableRowDisabled', this.tableRowDisabled);
    console.log('tbaleData', this.tableData);
  }

  onRowReorder(event: any) {
    console.log('onRowReorder', event);
    this.rowReorder.emit(event);
  }

  getDuration(rowData: any) {
    const filtered = this.tableData.find((ele: any) => ele.priority === rowData.priority);
    return filtered.mediaDuration;
  }

  getSelectedMediaPath(rowData: any) {
    const filtered = this.tableData.find((ele: any) => ele.priority === rowData.priority);
    return filtered.mediaPath || '';
  }

  async onFileChange(event: any, rowData: any) {
    this.imageUploadingEmitter.emit(true);
    const input = event.target as HTMLInputElement;
    if (input && input.files) {
      const file = input.files[0];
      console.log(file);
      if (!file) {
        this.imageUploadingEmitter.emit(false); // Hide the loader
        return;
      }

      // Create an image element to load and check its width
      const image = new Image();
      const imageUrl = URL.createObjectURL(file);

      image.onload = async () => {
        const width = image.width;
        console.log('width ->', width);

        // Check if the image width is greater than 600px
        if (width < 600) {
          this.imageUploadingEmitter.emit(false);
          this.notificationService.error('Image width should be greater than 600px');
          const value = {
            priority: rowData.priority,
            mediaPath: ''
          };
          this.mediaPathEmitter.emit(value);
          return;
        }

        // Continue with the file upload if width is acceptable
        const extension = file.name.split('.').pop();
        console.log('path ->', `${this.orgCode}/${this.nodeId}/informSetting/Images/${file.name}`);
        const path = `${this.orgCode}/${this.nodeId}/informSetting/Images/${file.name}`;
        const bucket = environment.engage_bucket;
        console.log('bucket->', bucket);

        const imageToUpload = event.target.files.item(0);

        try {
          const data = await this.sharedService.uploadFile(imageToUpload, path, bucket);
          const value = {
            priority: rowData.priority,
            mediaPath: data.Location
          };
          this.imageUploadingEmitter.emit(false);
          this.notificationService.success('File Uploaded Successfully');
          this.mediaPathEmitter.emit(value);
        } catch (error) {
          this.imageUploadingEmitter.emit(false);
          this.notificationService.error('File upload failed');
        }
      };

      image.onerror = () => {
        this.imageUploadingEmitter.emit(false);
        this.notificationService.error('Error loading image');
      };

      image.src = imageUrl; // Trigger the image load
    }
  }

  onInputChange(event: Event, rowData: any) {
    console.log('rowData', rowData);
    const input = event.target as HTMLInputElement;
    if (!/^[0-9]*$/.test(input.value)) {
      input.value = input.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
    }
    const value = {
      mediaDuration: input.value,
      priority: rowData.priority
    };
    this.durationChangeEmitter.emit(value);
  }

  delete(event: any) {
    console.log('delete ->', event);
    this.deleteEmmiter.emit(event);
  }
}
