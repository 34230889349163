import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IEBasketMgmtSettings, Providers } from '@shared/interfaces';

@Component({
  selector: 'app-ebasket-sales-export-island-pacific-provider',
  templateUrl: './island-pacific-provider.component.html'
})
export class SalesExportIslandPacificComponent implements OnInit {
  @Input() parentForm: FormGroup;
  @Input() ebasketSettings: any;
  @Input() action: string;
  @Input() authControlKey: string;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    const salesExport: any = this.ebasketSettings;
    console.log(['init', salesExport]);
    this.parentForm.addControl(
      this.authControlKey,
      this.formBuilder.group({
        ftpUrl: [
          {
            value:
              (salesExport &&
                salesExport.provider === Providers.IslandPacific &&
                salesExport.authenticationDetails) ||
              salesExport.authenticationDetailsKey
                ? Providers.SECRET
                : '',
            disabled: this.action === 'view' ? true : false
          },
          [Validators.required]
        ],
        folderName: [
          {
            value:
              salesExport &&
              salesExport.provider === Providers.IslandPacific &&
              salesExport.authenticationDetails &&
              salesExport.authenticationDetails.folderName
                ? Providers.SECRET
                : '',
            disabled: this.action === 'view' ? true : false
          },
          [Validators.required]
        ],
        userName: [
          {
            value:
              salesExport &&
              salesExport.provider === Providers.IslandPacific &&
              salesExport.authenticationDetails &&
              salesExport.authenticationDetailsKey
                ? Providers.SECRET
                : '',
            disabled: this.action === 'view' ? true : false
          },
          [Validators.required]
        ],
        password: [
          {
            value:
              salesExport &&
              salesExport.provider === Providers.IslandPacific &&
              salesExport.authenticationDetails &&
              salesExport.authenticationDetailsKey
                ? Providers.SECRET
                : '',
            disabled: this.action === 'view' ? true : false
          },
          [Validators.required]
        ]
      })
    );

    this.addValidators();
  }

  isRequired(control: string) {
    let isRequired = false;
    const formControlObject = this.parentForm.get(this.authControlKey).get(control);
    if (formControlObject !== null) {
      if (formControlObject.validator !== null) {
        if (formControlObject.validator({} as AbstractControl) !== null) {
          isRequired = formControlObject.validator({} as AbstractControl).required ? true : false;
        }
      }
    }
    return isRequired ? true : false;
  }

  addValidators() {
    if (
      this.parentForm &&
      this.parentForm.get('providerControl').value === Providers.IslandPacific &&
      Object.keys(this.parentForm.get('salesExportIslandPacific').value).length
    ) {
      this.parentForm.controls.salesExportIslandPacific
        .get('folderName')
        .setValidators(Validators.required);
      this.updateControls();
      /*this.parentForm.controls.salesExportIslandPacific
        .get('ftpUrl')
        .setValidators(Validators.required);
      this.parentForm.controls.salesExportIslandPacific
        .get('userName')
        .setValidators(Validators.required);
      this.parentForm.controls.salesExportIslandPacific
        .get('password')
        .setValidators(Validators.required); */
    }
  }

  clearValidators() {
    if (
      this.parentForm &&
      Object.keys(this.parentForm.get('salesExportIslandPacific').value).length
    ) {
      /*this.parentForm.controls.salesExportIslandPacific.get('ftpUrl').clearValidators();
      this.parentForm.controls.salesExportIslandPacific.get('userName').clearValidators();
      this.parentForm.controls.salesExportIslandPacific.get('password').clearValidators();

      this.parentForm.controls.salesExportIslandPacific.get('ftpUrl').reset();
      this.parentForm.controls.salesExportIslandPacific.get('userName').reset();
      this.parentForm.controls.salesExportIslandPacific.get('password').reset();*/

      this.parentForm.controls.salesExportIslandPacific.get('folderName').clearValidators();
      this.parentForm.controls.salesExportIslandPacific.get('folderName').reset();

      this.updateControls();
    }
  }

  updateControls() {
    /*this.parentForm.controls.salesExportIslandPacific.get('ftpUrl').updateValueAndValidity();
    this.parentForm.controls.salesExportIslandPacific.get('userName').updateValueAndValidity();
    this.parentForm.controls.salesExportIslandPacific.get('password').updateValueAndValidity();*/
    this.parentForm.controls.salesExportIslandPacific.get('folderName').updateValueAndValidity();
    this.parentForm.get('salesExportIslandPacific').updateValueAndValidity();
  }

  submitFormDetails() {
    const data = {
      ftpUrl: this.parentForm.controls.salesExportIslandPacific.get('ftpUrl').value
        ? this.parentForm.controls.salesExportIslandPacific.get('ftpUrl').value
        : '',

      folderName: this.parentForm.controls.salesExportIslandPacific.get('folderName').value
        ? this.parentForm.controls.salesExportIslandPacific.get('folderName').value
        : '',

      username: this.parentForm.controls.salesExportIslandPacific.get('userName').value
        ? this.parentForm.controls.salesExportIslandPacific.get('userName').value
        : '',

      password: this.parentForm.controls.salesExportIslandPacific.get('password').value
        ? this.parentForm.controls.salesExportIslandPacific.get('password').value
        : ''
    };
    return data;
  }
}
