import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { EbasketApiMethodTypes, Providers } from '@shared/interfaces';
import { SelectApiPopupComponent } from '@shared/select-api-popup/select-api-popup.component';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'sales-export-magento-provider-api-details-settings',
  templateUrl: './magento-provider-details-settings.component.html',
  styleUrls: ['./magento-provider-details-settings.component.scss']
})
export class SalesExportMagentoApiDetailsSettingsComponent implements OnInit {
  @Input() action: string;
  @Input() parentForm: FormGroup;
  @Input() ebasketSalesExportMagentoApiDetails: any;
  @Input() authControlKey: string;
  @Input() provider: string;
  // @Output() updateApiData: EventEmitter<any> = new EventEmitter();
  apiDetails: FormGroup;
  isebasketSalesExportAddDisabled = false;
  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.initForm();
    console.log('ebasketSalesExportMagentoApiDetails');
    console.log(this.ebasketSalesExportMagentoApiDetails);
  }
  initForm() {
    this.parentForm.addControl(
      this.authControlKey,
      this.formBuilder.group({
        ebasketSalesExportMagentoVersion: [
          {
            value:
              this.ebasketSalesExportMagentoApiDetails &&
              this.ebasketSalesExportMagentoApiDetails.authenticationDetails &&
              this.ebasketSalesExportMagentoApiDetails.authenticationDetails.magentoVersion
                ? this.ebasketSalesExportMagentoApiDetails.authenticationDetails.magentoVersion
                : '',
            disabled: this.action === 'view' ? true : false
          },
          [Validators.required]
        ],
        ebasketSalesExportMagentoUsername: [
          {
            value:
              this.ebasketSalesExportMagentoApiDetails &&
              this.ebasketSalesExportMagentoApiDetails.authenticationDetails &&
              this.ebasketSalesExportMagentoApiDetails.authenticationDetails.magentoVersion
                ? Providers.SECRET
                : '',
            disabled: this.action === 'view' ? true : false
          },
          [Validators.required]
        ],
        ebasketSalesExportMagentoApiKey: [
          {
            value:
              this.ebasketSalesExportMagentoApiDetails &&
              this.ebasketSalesExportMagentoApiDetails.authenticationDetails &&
              this.ebasketSalesExportMagentoApiDetails.authenticationDetails.magentoVersion
                ? Providers.SECRET
                : '',
            disabled: this.action === 'view' ? true : false
          },
          [Validators.required]
        ],
        ebasketSalesExportMagentoUrl: [
          {
            value:
              this.ebasketSalesExportMagentoApiDetails &&
              this.ebasketSalesExportMagentoApiDetails.authenticationDetails &&
              this.ebasketSalesExportMagentoApiDetails.authenticationDetails.magentoVersion
                ? Providers.SECRET
                : '',
            disabled: this.action === 'view' ? true : false
          },
          [Validators.required]
        ]
      })
    );
  }

  isRequired(control: string) {
    let isRequired = false;
    const formControlObject = this.parentForm.get(this.authControlKey).get(control);
    if (formControlObject !== null) {
      if (formControlObject.validator !== null) {
        if (formControlObject.validator({} as AbstractControl) !== null) {
          isRequired = formControlObject.validator({} as AbstractControl).required ? true : false;
        }
      }
    }
    return isRequired ? true : false;
  }
}
