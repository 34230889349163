import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CommonConfig } from './common-config.reducer';

export const getCommonConfig = createFeatureSelector<CommonConfig>('commonConfig');
export const getCurrencies = createSelector(
  getCommonConfig,
  (commonConfig: CommonConfig) => commonConfig.currencies
);
export const getLanguages = createSelector(
  getCommonConfig,
  (commonConfig: CommonConfig) => commonConfig.languages
);
export const getLongDateFormats = createSelector(
  getCommonConfig,
  (commonConfig: CommonConfig) => commonConfig.longDateFormats
);
export const getLongTimeFormats = createSelector(
  getCommonConfig,
  (commonConfig: CommonConfig) => commonConfig.longTimeFormats
);
export const getModules = createSelector(
  getCommonConfig,
  (commonConfig: CommonConfig) => commonConfig.modules
);
export const getPurge = createSelector(
  getCommonConfig,
  (commonConfig: CommonConfig) => commonConfig.purge
);
export const getShortDateFormats = createSelector(
  getCommonConfig,
  (commonConfig: CommonConfig) => commonConfig.shortDateFormats
);
export const getShortTimeFormats = createSelector(
  getCommonConfig,
  (commonConfig: CommonConfig) => commonConfig.shortTimeFormats
);
export const getThemes = createSelector(
  getCommonConfig,
  (commonConfig: CommonConfig) => commonConfig.themes
);
export const getTimezones = createSelector(
  getCommonConfig,
  (commonConfig: CommonConfig) => commonConfig.timezones
);
export const getCountries = createSelector(
  getCommonConfig,
  (commonConfig: CommonConfig) => commonConfig.countries
);

export const getCurrentUserTheme = createSelector(
  getCommonConfig,
  (commonConfig: CommonConfig) => commonConfig.currentUserTheme
);

export const getOrgDetails = createSelector(
  getCommonConfig,
  (commonConfig: CommonConfig) => commonConfig.orgDetails
);
export const commonConfigQuery = {
  getCommonConfig,
  getCurrencies,
  getLanguages,
  getLongDateFormats,
  getLongTimeFormats,
  getModules,
  getPurge,
  getShortDateFormats,
  getShortTimeFormats,
  getThemes,
  getTimezones,
  getCountries,
  getCurrentUserTheme,
  getOrgDetails
};
