import { Component, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-module-assignment-engage-settings',
  templateUrl: './engage-settings.component.html',
  styleUrls: ['./engage-settings.component.scss']
})
export class EngageSettingsComponent implements OnInit {
  @ViewChild('titleContainer', { static: true }) public titleContainer: any;
  @Input() action: string;
  @Input() moduleIcon: string;
  @Input() parentForm: FormGroup;
  @Input() engageMgmtSettings: any;

  constructor() {}

  ngOnInit() {}
}
