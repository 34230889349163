import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { NotificationService } from '@services/notification.service';

@Component({
  selector: 'app-module-assignment-shirt-printing-model',
  templateUrl: './shirt-printing-model.component.html'
})
export class ShirtPrintingModelComponent implements OnInit, OnChanges {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('input', { static: false }) input: ElementRef;
  @Input() action: string;
  @Input() parentForm: FormGroup;
  @Input() status: boolean;
  @Input() products: any[];
  @Input() versionURL: string;
  @Output() updateData: EventEmitter<any> = new EventEmitter();

  frmShirtModel = 'frmShirtModel';
  productColumns: any[] = ['modelName', 'modelURL', 'action'];
  productDataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  pageSizeOptions: number[] = [5, 10, 25, 50, 100];

  constructor(
    private formBuilder: FormBuilder,
    private notificationService: NotificationService,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.initForm();
    this.productDataSource.data = [...this.products];
  }

  ngOnChanges() {
    this.productDataSource.data = [...this.products];
  }

  initForm() {
    this.parentForm.addControl(
      this.frmShirtModel,
      this.formBuilder.group({
        // ebasketSalesSetupShirtPrintModel: [false, []],
        modelName: [''],
        modelURL: [''],
        addVersionURL: [
          {
            value: this.versionURL ? this.versionURL : '',
            disabled: this.action === 'view' ? true : false
          },
          [Validators.required]
        ]
      })
    );
  }

  toggleEbasketSalesSetupModel() {
    if (!this.status) {
      this.products = [];
      this.productDataSource.data = [];
      this.parentForm
        .get(this.frmShirtModel)
        .get('modelName')
        .reset();
      this.parentForm
        .get(this.frmShirtModel)
        .get('modelURL')
        .reset();
      this.parentForm
        .get(this.frmShirtModel)
        .get('addVersionURL')
        .reset();
    }
    this.updateData.emit({ status: this.status, products: this.products });
  }

  removeRow(row) {
    // tslint:disable-next-line: triple-equals
    this.products = this.products.filter((p) => p.name != row.name);
    this.productDataSource.data = [...this.products];
    this.updateData.emit({ status: this.status, products: this.products });
    // this.search();
  }

  canAddModelData() {
    const formValue = this.parentForm.get(this.frmShirtModel).value;
    return !(formValue.modelName && formValue.modelURL);
  }

  AddModelData() {
    const formValue = this.parentForm.get(this.frmShirtModel).value;
    let filtered = this.products.filter((p) => p.name === formValue.modelName);
    if (!filtered.length) {
      this.products = [{ name: formValue.modelName, url: formValue.modelURL }, ...this.products];
      this.productDataSource.data = [...this.products];

      setTimeout(() => {
        this.productDataSource.paginator = this.paginator;
        this.productDataSource.sort = this.sort;
      }, 100);
      this.parentForm
        .get(this.frmShirtModel)
        .get('modelName')
        .reset();
      this.parentForm
        .get(this.frmShirtModel)
        .get('modelURL')
        .reset();
      this.updateData.emit({ status: this.status, products: this.products });
    } else {
      this.notificationService.error('Model name already exists');
    }
  }
}
