import { Component, OnDestroy, OnInit } from '@angular/core';
import { IOrganization, IPaginationResponse, NodeType } from '@shared/interfaces';
import { Subscription } from 'rxjs';
import { LocalStorageJwtService } from 'src/app/feature/auth/local-storage-jwt.service';
import { AuthFacade } from 'src/app/feature/auth/state/auth.facade';
import { OrganizationService } from '../../../../feature/organization/organization.details.service';
import { environment } from '@env/environment';

@Component({
  selector: 'app-organization-popup-container',
  templateUrl: './organization-popup.container.html',
  styleUrls: []
})
export class OrganizationPopupContainer implements OnInit, OnDestroy {
  organizations: IOrganization[] = [];
  applications = [];
  subscriptions: Subscription[] = [];
  constructor(
    private organizationService: OrganizationService,
    private readonly localStorageJwtService: LocalStorageJwtService,
    private authFacade: AuthFacade
  ) {}

  ngOnInit() {
    const token = this.localStorageJwtService.getDecodedItem();
    if (token && token.idToken) {
      const rootNode = token.idToken['custom:rootNode'];
      if (rootNode) {
        if (rootNode !== NodeType.GLOBAL) {
          this.subscriptions.push(
            this.authFacade.userModule$.subscribe((data) => {
              if (data && data.length > 0) {
                // const hostname = window.location.origin;
                // data.forEach((a) => {
                //   if (a.shortName === 'OM') {
                //     a.logoUrl = `${hostname}/assets/images/icon-app-orchestration.svg`;
                //   }
                //   if (a.shortName === 'TM') {
                //     a.logoUrl = `${hostname}/assets/images/icon-app-transaction-manager_coloured.svg`;
                //   }
                //   if (a.shortName === 'SAGA') {
                //     a.logoUrl = `${hostname}/assets/images/icon_scan and go_coloured.svg`;
                //   }
                //   if (a.shortName === 'RJ') {
                //     a.logoUrl = `${hostname}/assets/images/icon-app-rj.svg`;
                //   }
                //   if (a.shortName === 'eBasket') {
                //     a.logoUrl = `${hostname}/assets/images/icon-app-temp-ebasket.svg`;
                //   }
                //   if (a.shortName === 'T2S') {
                //     a.logoUrl = `${hostname}/assets/images/icon-app-t2b.svg`;
                //   }
                //   if (a.shortName === 'SOTK') {
                //     a.logoUrl = `${hostname}/assets/images/icon-app-temp-sotk.svg`;
                //   }
                // });
                this.applications = data;
                //  console.log('global modules', this.applications);
              } else {
                this.authFacade.module();
              }
            })
          );
        } else {
          this.subscriptions.push(
            this.organizationService
              .organizations('', 'createdDate:desc', 0, 100)
              .subscribe((organizations: IPaginationResponse<any>) => {
                organizations.result.forEach((element) => {
                  element.url = element.url.replace(environment.oldHost, environment.mainHost);
                  console.log(element.url);
                  this.organizations.push({
                    id: element.id,
                    name: element.name,
                    accessUrl: `https://${element.url}`,
                    image: element.logoURL || element.logoURLPortrait || '',
                    status: element.status
                  });
                });
              })
          );

          console.log('ORG LIST--->', this.organizations);
        }
      }
    }
  }
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }
}
