import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { EbasketApiMethodTypes, Providers, TMGiftCardSelectApi } from '@shared/interfaces';
import { SelectApiPopupComponent } from '@shared/select-api-popup/select-api-popup.component';
@Component({
  selector: 'netsuite-gift-card-configuration',
  templateUrl: './netsuite-gift-configuration.component.html',
  styleUrls: ['./netsuite-gift-configuration.component.scss']
})
export class TMNetsuiteGiftCardConfigurationComponent implements OnInit {
  @Input() action: string;
  @Input() parentForm: FormGroup;
  @Input() netsuiteConfigurationDetails: any;
  @Input() authControlKey: string;
  isTMGiftCardAddDisabled = false;
  tm_gift_card_select_api = [
    {
      id: 1,
      name: TMGiftCardSelectApi.RetrieveGiftCard,
      value: TMGiftCardSelectApi.RetrieveGiftCard,
      isDisabled: false
    }
  ];
  method_types = [
    { id: 1, name: EbasketApiMethodTypes.Get, value: EbasketApiMethodTypes.Get },
    { id: 2, name: EbasketApiMethodTypes.Post, value: EbasketApiMethodTypes.Post },
    { id: 3, name: EbasketApiMethodTypes.Put, value: EbasketApiMethodTypes.Put },
    { id: 4, name: EbasketApiMethodTypes.Patch, value: EbasketApiMethodTypes.Patch },
    { id: 5, name: EbasketApiMethodTypes.Delete, value: EbasketApiMethodTypes.Delete }
  ];
  defaultSignatureMethod = 'HMAC-SHA256';

  constructor(private formBuilder: FormBuilder, private dialog: MatDialog) {}

  ngOnInit() {
    this.initForm();
    this.bindTmGiftCardApiDetailsFormGroup();
  }
  initForm() {
    this.parentForm.addControl(
      this.authControlKey,
      this.formBuilder.group({
        tmSignatureMethod: [
          {
            value:
              this.netsuiteConfigurationDetails &&
              this.netsuiteConfigurationDetails.authenticationDetails &&
              this.netsuiteConfigurationDetails.authenticationDetailsKey
                ? Providers.SECRET
                : this.defaultSignatureMethod,
            disabled: this.action === 'view' ? true : false
          },
          Validators.required
        ],
        tmConsumerKey: [
          {
            value:
              this.netsuiteConfigurationDetails &&
              this.netsuiteConfigurationDetails.authenticationDetails &&
              this.netsuiteConfigurationDetails.authenticationDetailsKey
                ? Providers.SECRET
                : '',
            disabled: this.action === 'view' ? true : false
          },
          Validators.required
        ],
        tmConsumerSecret: [
          {
            value:
              this.netsuiteConfigurationDetails &&
              this.netsuiteConfigurationDetails.authenticationDetails &&
              this.netsuiteConfigurationDetails.authenticationDetailsKey
                ? Providers.SECRET
                : '',
            disabled: this.action === 'view' ? true : false
          },
          Validators.required
        ],
        tmAccessToken: [
          {
            value:
              this.netsuiteConfigurationDetails &&
              this.netsuiteConfigurationDetails.authenticationDetails &&
              this.netsuiteConfigurationDetails.authenticationDetailsKey
                ? Providers.SECRET
                : '',
            disabled: this.action === 'view' ? true : false
          },
          Validators.required
        ],
        tmTokenSecret: [
          {
            value:
              this.netsuiteConfigurationDetails &&
              this.netsuiteConfigurationDetails.authenticationDetails &&
              this.netsuiteConfigurationDetails.authenticationDetailsKey
                ? Providers.SECRET
                : '',
            disabled: this.action === 'view' ? true : false
          },
          Validators.required
        ],
        tmAccountId: [
          {
            value:
              this.netsuiteConfigurationDetails &&
              this.netsuiteConfigurationDetails.authenticationDetails &&
              this.netsuiteConfigurationDetails.authenticationDetailsKey
                ? Providers.SECRET
                : '',
            disabled: this.action === 'view' ? true : false
          },
          Validators.required
        ],
        authenticationKey: [
          {
            value:
              this.netsuiteConfigurationDetails &&
              this.netsuiteConfigurationDetails.authenticationDetails &&
              this.netsuiteConfigurationDetails.authenticationDetailsKey
                ? this.netsuiteConfigurationDetails.authenticationDetailsKey
                : '',
            disabled: this.action === 'view' ? true : false
          }
        ],
        tmGiftCardApiDetails: this.formBuilder.array([])
      })
    );
  }

  isRequired(control: string) {
    let isRequired = false;
    const formControlObject = this.parentForm.get(this.authControlKey).get(control);
    if (formControlObject !== null) {
      if (formControlObject.validator !== null) {
        if (formControlObject.validator({} as AbstractControl) !== null) {
          isRequired = formControlObject.validator({} as AbstractControl).required ? true : false;
        }
      }
    }
    return isRequired ? true : false;
  }
  deleteTmGiftCardApiDetailContainer(index: number) {
    const tmGiftCardApiDetails = this.parentForm
      .get(this.authControlKey)
      .get('tmGiftCardApiDetails') as FormArray;
    this.tm_gift_card_select_api.map((element: any) => {
      if (element.value === tmGiftCardApiDetails.value[index].selectedApi) {
        element.isDisabled = false;
      }
    });
    if (tmGiftCardApiDetails.length > 0) {
      tmGiftCardApiDetails.removeAt(index);
    } else {
      tmGiftCardApiDetails.reset();
    }
    if (this.tm_gift_card_select_api.length !== tmGiftCardApiDetails.length) {
      this.isTMGiftCardAddDisabled = false;
    }
  }
  openTMGiftCardSelectApiPopup() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = this.tm_gift_card_select_api.filter((api) => !api.isDisabled);
    const dialogRef = this.dialog.open(SelectApiPopupComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result !== '') {
        this.tm_gift_card_select_api.map((element: any) => {
          if (element.value === result) {
            element.isDisabled = true;
            const tmGiftCardApiDetails = this.parentForm
              .get(this.authControlKey)
              .get('tmGiftCardApiDetails') as FormArray;

            tmGiftCardApiDetails.push(this.createApiDetailsFormGroup(result));
            if (this.tm_gift_card_select_api.length === tmGiftCardApiDetails.length) {
              this.isTMGiftCardAddDisabled = true;
            }
          }
        });
      }
    });
  }
  bindTmGiftCardApiDetailsFormGroup() {
    const control = this.parentForm
      .get(this.authControlKey)
      .get('tmGiftCardApiDetails') as FormArray;
    if (
      this.netsuiteConfigurationDetails &&
      this.netsuiteConfigurationDetails.authenticationDetails &&
      this.netsuiteConfigurationDetails.authenticationDetails.apiDetails
    ) {
      this.netsuiteConfigurationDetails.authenticationDetails.apiDetails.map((apis: any) => {
        control.push(
          this.formBuilder.group({
            url: [
              {
                value: apis.url,
                disabled: this.action === 'view' ? true : false
              },
              Validators.required
            ],
            script: [
              {
                value: apis.script,
                disabled: this.action === 'view' ? true : false
              },
              Validators.required
            ],
            deploy: [
              {
                value: apis.deploy,
                disabled: this.action === 'view' ? true : false
              },
              Validators.required
            ],
            method: [
              {
                value: apis.method,
                disabled: this.action === 'view' ? true : false
              },
              Validators.required
            ],
            selectedApi: [apis.selectedApi]
          })
        );
        this.tm_gift_card_select_api.map((key) => {
          if (key.value === apis.selectedApi) {
            key.isDisabled = true;
          }
        });
      });

      if (this.tm_gift_card_select_api.length === control.length) {
        this.isTMGiftCardAddDisabled = true;
      }
    }
  }
  private createApiDetailsFormGroup(selectedApi: string): FormGroup {
    return new FormGroup({
      url: new FormControl('', Validators.required),
      method: new FormControl('', Validators.required),
      deploy: new FormControl('', Validators.required),
      script: new FormControl('', Validators.required),
      selectedApi: new FormControl(selectedApi)
    });
  }
}
