import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { NodeLevelType } from '@shared/interfaces';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import CONSTANTS from 'src/app/util/Constant';
import { AuthFacade } from './state/auth.facade';
/**
 *
 * This is example of node gard
 */
@Injectable({
  providedIn: 'root'
})
export class NodeLevelGuard implements CanActivate {
  constructor(public router: Router, private authFacade: AuthFacade) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const expectedLevel = route.data.expectedLevel;
    const expectedLevelType = route.data.expectedLevelType || NodeLevelType.EQUAL;
    return this.authFacade.user$.pipe(
      map((r) => {
        if (!r.rootNode) {
          return true;
        } else {
          switch (expectedLevelType) {
            case NodeLevelType.EQUAL:
              if (
                CONSTANTS.getCurrentLevel(expectedLevel) === CONSTANTS.getCurrentLevel(r.rootNode)
              ) {
                return true;
              } else {
                return false;
              }
            case NodeLevelType.GREATER:
              if (
                CONSTANTS.getCurrentLevel(expectedLevel) >= CONSTANTS.getCurrentLevel(r.rootNode)
              ) {
                return true;
              } else {
                return false;
              }
            case NodeLevelType.LOWER:
              if (
                CONSTANTS.getCurrentLevel(expectedLevel) <= CONSTANTS.getCurrentLevel(r.rootNode)
              ) {
                return true;
              } else {
                return false;
              }
            case NodeLevelType.NOT_EQUAL:
              if (
                CONSTANTS.getCurrentLevel(expectedLevel) !== CONSTANTS.getCurrentLevel(r.rootNode)
              ) {
                return true;
              } else {
                return false;
              }
            default:
              return false;
          }
        }
      })
    );
  }
}
