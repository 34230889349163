import { PlatformLocation } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { IApplications, IOrganization } from '@shared/interfaces';
import { PERMISSION } from 'src/app/feature/auth/has-permission/has-permission-directive';
import { LocalStorageJwtService } from 'src/app/feature/auth/local-storage-jwt.service';
import { AuthFacade } from 'src/app/feature/auth/state/auth.facade';
import { UserLoginResponseDecoded } from 'src/app/feature/auth/state/auth.model';
import CONSTANTS from '../../../../util/Constant';

@Component({
  selector: 'app-organization-popup',
  templateUrl: './organization-popup.component.html',
  styleUrls: ['./organization-popup.component.scss']
})
export class OrganizationPopupComponent implements OnInit {
  @Input() organizations: IOrganization[] = [];
  @Input() applications: IApplications[] = [];
  urlPath: string;
  PERMISSION = PERMISSION;
  currentApplicationName = CONSTANTS.CURRENT_APPLICATION_NAME;
  constructor(
    platformLocation: PlatformLocation,
    private authFacade: AuthFacade,
    private localStorageJwtService: LocalStorageJwtService
  ) {
    this.urlPath = (platformLocation as any).location.pathname;
  }

  ngOnInit() {
    // const hostname = window.location.origin;
    // this.applications.forEach((a) => {
    //   console.log(a.shortName);
    //   if (a.shortName === 'OM') {
    //     a.logoUrl = `${hostname}/assets/images/icon-app-orchestration.svg`;
    //   }
    //   if (a.shortName === 'TM') {
    //     a.logoUrl = `${hostname}/assets/images/icon-app-transaction-manager_coloured.svg`;
    //   }
    //   if (a.shortName === 'SAGA') {
    //     a.logoUrl = `${hostname}/assets/images/icon_scan and go_coloured.svg`;
    //   }
    //   if (a.shortName === 'RJ') {
    //     a.logoUrl = `${hostname}/assets/images/icon-app-rj.svg`;
    //   }
    //   if (a.shortName === 'eBasket') {
    //     a.logoUrl = `${hostname}/assets/images/icon-app-temp-ebasket.svg`;
    //   }
    //   if (a.shortName === 'T2S') {
    //     a.logoUrl = `${hostname}/assets/images/icon-app-t2b.svg`;
    //   }
    //   if (a.shortName === 'SOTK') {
    //     a.logoUrl = `${hostname}/assets/images/icon-app-temp-sotk.svg`;
    //   }
    // });
    // currently only supporting OM and Ebasket UI
    this.applications = this.applications.filter(
      (a) =>
        a.shortName === 'OM' ||
        a.shortName === 'SOTK' ||
        a.shortName === 'eBasket' ||
        a.shortName === 'TM' ||
        a.shortName === 'T2S' ||
        a.shortName === 'RJ'
    );
    // console.log('APPLICATIONS MODDULE', this.applications);
    // console.log('ORGANIZATION', this.organizations);
  }

  updateUserPermission(appShortName: string) {
    const loginResponse: UserLoginResponseDecoded = this.localStorageJwtService.getDecodedItem();
    loginResponse.shortName = appShortName;
    this.authFacade.setPermission(loginResponse);

    console.log('loginRespone', loginResponse);
  }
}
