import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule, Optional, SkipSelf } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BreadcrumbsService } from '@services/breadcrumbs.service';
import { EventBusService } from '@services/event-bus.service';
import { PersistanceService } from '@services/persistance.service';
import { RefreshTokenService } from '@services/refreshToken.service';
import { SettingsService } from '@services/settings.service';
import { TranslationLoaderService } from '@services/translation-loader.service';
import { UtilityService } from '@services/utility.service';
import { EnsureModuleLoadedOnceGuard } from './ensure-module-loaded-once.guard';
import en from './i18n/en.json';
import zhcn from './i18n/zh-CN.json';
import fr from './i18n/fr.json';
import nl from './i18n/nl.json';
import jajp from './i18n/ja-JP.json';
import it from './i18n/it.json';
import de from './i18n/de.json';
import { ServerErrorInterceptor } from './interceptors/server-error.interceptor';

@NgModule({
  imports: [CommonModule, RouterModule],
  exports: [RouterModule],
  declarations: [],
  providers: [
    PersistanceService,
    EventBusService,
    BreadcrumbsService,
    UtilityService,
    SettingsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServerErrorInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: get_settings,
      deps: [SettingsService],
      multi: true
    },
    RefreshTokenService
  ] // these should be singleton
})
export class CoreModule extends EnsureModuleLoadedOnceGuard {
  // Ensure that CoreModule is only loaded into AppModule

  // Looks for the module in the parent injector to see if it's already been loaded (only want it loaded once)
  constructor(
    @Optional() @SkipSelf() parentModule: CoreModule,
    private translateService: TranslationLoaderService
  ) {
    super(parentModule);
    this.translateService.loadTranslations(fr);
    this.translateService.loadTranslations(en);
    this.translateService.loadTranslations(zhcn);
    this.translateService.loadTranslations(nl);
    this.translateService.loadTranslations(jajp);
    this.translateService.loadTranslations(it);
    this.translateService.loadTranslations(de);
  }
}

export function get_settings(settingsService: SettingsService) {
  return () => settingsService.getSettings();
}
