import { Component, Input, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl,
  FormControl,
  FormArray
} from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import {
  EbasketApiMethodTypes,
  EbasketSalesCrestMajorelSelectApi,
  Providers
} from '@shared/interfaces';
import { SelectApiPopupComponent } from '@shared/select-api-popup/select-api-popup.component';

@Component({
  selector: 'majorel-customer-hub-details-settings',
  templateUrl: './majorel-customer-hub-details-settings.component.html',
  styleUrls: ['./majorel-customer-hub-details-settings.component.scss']
})
export class MajorelCustomerHubDetailsSettingsComponent implements OnInit {
  @Input() action: string;
  @Input() parentForm: FormGroup;
  @Input() customerHubDetails: any;
  @Input() authControlKey: string;
  apiDetails: FormGroup;
  ebasket_sales_crest_majorel_select_api = [
    {
      id: 1,
      name: EbasketSalesCrestMajorelSelectApi.CreateOrders,
      value: EbasketSalesCrestMajorelSelectApi.CreateOrders,
      isDisabled: false
    }
  ];
  isEBasketSalesCrestMejorelAddDisabled = false;
  method_types = [
    { id: 1, name: EbasketApiMethodTypes.Get, value: EbasketApiMethodTypes.Get },
    { id: 2, name: EbasketApiMethodTypes.Post, value: EbasketApiMethodTypes.Post },
    { id: 3, name: EbasketApiMethodTypes.Put, value: EbasketApiMethodTypes.Put },
    { id: 4, name: EbasketApiMethodTypes.Patch, value: EbasketApiMethodTypes.Patch },
    { id: 5, name: EbasketApiMethodTypes.Delete, value: EbasketApiMethodTypes.Delete }
  ];
  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.initForm();
  }
  initForm() {
    this.parentForm.addControl(
      this.authControlKey,
      this.formBuilder.group({
        CrestMajorelUserName: [
          {
            value:
              this.customerHubDetails &&
              this.customerHubDetails.authenticationDetails &&
              this.customerHubDetails.authenticationDetailsKey
                ? Providers.SECRET
                : '',
            disabled: this.action === 'view' ? true : false
          },
          Validators.required
        ],
        CrestMajorelPassword: [
          {
            value:
              this.customerHubDetails &&
              this.customerHubDetails.authenticationDetails &&
              this.customerHubDetails.authenticationDetailsKey
                ? Providers.SECRET
                : '',
            disabled: this.action === 'view' ? true : false
          },
          Validators.required
        ],
        authenticationDetailKey: [
          {
            value:
              this.customerHubDetails &&
              this.customerHubDetails.authenticationDetails &&
              this.customerHubDetails.authenticationDetailsKey
                ? this.customerHubDetails.authenticationDetailsKey
                : '',
            disabled: this.action === 'view' ? true : false
          }
        ],
        ebasketSalesCrestMajorelApiDetails: this.formBuilder.array([])
      })
    );
    this.bindEbaskeCustHubDetailsFormGroup();
  }

  openEbasketCrestMajorelSelectApiPopup() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = this.ebasket_sales_crest_majorel_select_api.filter(
      (api) => !api.isDisabled
    );
    const dialogRef = this.dialog.open(SelectApiPopupComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result !== '') {
        this.ebasket_sales_crest_majorel_select_api.map((element: any) => {
          if (element.value === result) {
            element.isDisabled = true;
            const ebasketSalesCrestMajorelApiDetails = this.parentForm
              .get(this.authControlKey)
              .get('ebasketSalesCrestMajorelApiDetails') as FormArray;
            ebasketSalesCrestMajorelApiDetails.push(this.createApiDetailsFormGroup(result));
            if (
              this.ebasket_sales_crest_majorel_select_api.length ===
              ebasketSalesCrestMajorelApiDetails.length
            ) {
              this.isEBasketSalesCrestMejorelAddDisabled = true;
            }
          }
        });
      }
    });
  }
  private createApiDetailsFormGroup(selectedApi: string): FormGroup {
    return new FormGroup({
      url: new FormControl('', Validators.required),
      method: new FormControl('', Validators.required),
      selectedApi: new FormControl(selectedApi)
    });
  }

  bindEbaskeCustHubDetailsFormGroup() {
    if (
      this.customerHubDetails &&
      this.customerHubDetails.authenticationDetails &&
      this.customerHubDetails.authenticationDetails.apiDetails
    ) {
      if (this.customerHubDetails.provider === Providers.CrestMajorel) {
        const control = this.parentForm
          .get(this.authControlKey)
          .get('ebasketSalesCrestMajorelApiDetails') as FormArray;
        this.customerHubDetails.authenticationDetails.apiDetails.map((apis: any) => {
          control.push(
            this.formBuilder.group({
              url: [
                {
                  value: apis.url,
                  disabled: this.action === 'view' ? true : false
                },
                Validators.required
              ],
              method: [
                {
                  value: apis.method,
                  disabled: this.action === 'view' ? true : false
                },
                Validators.required
              ],
              selectedApi: [apis.selectedApi]
            })
          );
          this.ebasket_sales_crest_majorel_select_api.map((key) => {
            if (key.value === apis.selectedApi) {
              key.isDisabled = true;
            }
          });
        });
        if (this.ebasket_sales_crest_majorel_select_api.length === control.length) {
          this.isEBasketSalesCrestMejorelAddDisabled = true;
        }
      }
    }
  }
  deleteEbasketSalesCrestMajorelApiDetailContainer(index: number) {
    const ebasketSalesCrestMajorelApiDetails = this.parentForm
      .get(this.authControlKey)
      .get('ebasketSalesCrestMajorelApiDetails') as FormArray;
    this.ebasket_sales_crest_majorel_select_api.map((element: any) => {
      if (element.value === ebasketSalesCrestMajorelApiDetails.value[index].selectedApi) {
        element.isDisabled = false;
      }
    });
    if (ebasketSalesCrestMajorelApiDetails.length > 0) {
      ebasketSalesCrestMajorelApiDetails.removeAt(index);
    } else {
      ebasketSalesCrestMajorelApiDetails.reset();
    }
    if (
      this.ebasket_sales_crest_majorel_select_api.length !==
      ebasketSalesCrestMajorelApiDetails.length
    ) {
      this.isEBasketSalesCrestMejorelAddDisabled = false;
    }
  }
  isRequired(control: string) {
    let isRequired = false;
    const formControlObject = this.parentForm.get(this.authControlKey).get(control);

    if (formControlObject !== null) {
      if (formControlObject.validator !== null) {
        if (formControlObject.validator({} as AbstractControl) !== null) {
          isRequired = formControlObject.validator({} as AbstractControl).required ? true : false;
        }
      }
    }
    return isRequired ? true : false;
  }
}
