import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { HotkeyService } from '../hotkeys.service';

@Component({
  selector: 'app-module-assignment-add-hotkey-dialog',
  templateUrl: './add-hotkey-dialog.component.html',
  styleUrls: ['./add-hotkey-dialog.component.scss']
})
export class AddHotKeyDialogComponent implements OnInit {
  @ViewChild('inputSku', { static: false }) inputSku: ElementRef;
  @ViewChild('inputProductName', { static: false }) inputProductName: ElementRef;

  frmHotKey: FormGroup;
  orgId: string;
  options: any[] = [];
  filteredOptions: Observable<string[]>;
  isProductSelected: boolean = false;
  searching: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private hotKeyService: HotkeyService,
    public dialogRef: MatDialogRef<AddHotKeyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.orgId = data.orgId;
  }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.frmHotKey = this.formBuilder.group({
      position: ['', [Validators.min(1), Validators.max(99), this.noEValidator]],
      displayName: [''],
      sku: [{ value: '', disabled: true }],
      image: [''],
      filterBySkuCtrl: [''],
      filterByNameCtrl: ['']
    });
    this.subscribeProducts();
  }
  noEValidator(control: FormControl) {
    if (control.value === null) {
      return { noE: true };
    }
    return {};
  }
  subscribeProducts() {
    this.frmHotKey
      .get('filterBySkuCtrl')
      .valueChanges.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => {
          if (this.frmHotKey.get('filterBySkuCtrl').value) {
            this.frmHotKey.get('filterByNameCtrl').disable();
            this.fetchProducts();
          } else {
            this.frmHotKey.get('filterByNameCtrl').enable();
            this.options = [];
          }
        })
      )
      .subscribe();

    this.frmHotKey
      .get('filterByNameCtrl')
      .valueChanges.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => {
          if (this.frmHotKey.get('filterByNameCtrl').value) {
            this.frmHotKey.get('filterBySkuCtrl').disable();
            this.fetchProducts();
          } else {
            this.frmHotKey.get('filterBySkuCtrl').enable();
            this.options = [];
          }
        })
      )
      .subscribe();
  }

  fetchProducts() {
    this.searching = true;
    this.hotKeyService
      .getProductList(
        this.orgId,
        this.frmHotKey.get('filterBySkuCtrl').value,
        this.frmHotKey.get('filterByNameCtrl').value
      )
      .subscribe((result: any) => {
        this.searching = false;
        this.options = result.result;
      });
  }

  onSearchInput(e) {
    this.isProductSelected = false;
    if (!e.target.value) {
      this.options = [];
    }
  }

  onSelectProduct(e) {
    const product = e.option.value;
    this.frmHotKey.get('sku').setValue(product.SKU);
    this.frmHotKey.get('displayName').setValue(product.description);
    this.frmHotKey
      .get('image')
      .setValue(product.imageUrls && product.imageUrls.length ? product.imageUrls[0] : '');
    this.isProductSelected = true;
    setTimeout(() => {
      this.frmHotKey.get('filterBySkuCtrl').setValue('');
      this.frmHotKey.get('filterByNameCtrl').setValue('');
    }, 100);
  }

  displaySku(product) {
    return '';
  }

  displayProductName(product) {
    return '';
  }

  onSave() {
    const formValue = this.frmHotKey.getRawValue();
    const data = {
      position: +formValue.position,
      SKU: formValue.sku,
      displayName: formValue.displayName,
      imageUrls: [formValue.image]
    };
    this.dialogRef.close(data);
  }

  onCancel() {
    this.dialogRef.close();
  }
}
