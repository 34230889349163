import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Currency,
  Language,
  Modules,
  Node,
  NodeConfiguration,
  Purge,
  Theme,
  TimeZone
} from '@shared/common-config/state/common-config.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService, PATH } from 'src/app/core/api/api.service';
import { LocalStorageJwtService } from 'src/app/feature/auth/local-storage-jwt.service';

@Injectable()
export class CommonConfigService {
  constructor(
    private apiService: ApiService,
    private localStorageJwtService: LocalStorageJwtService
  ) {}

  getCurrencies(): Observable<Currency[]> {
    return this.apiService.get(PATH.GET_CURRENCIES).pipe(map((r) => r.result));
  }

  getLanguages(): Observable<Language[]> {
    return this.apiService.get(PATH.GET_LANGUAGES).pipe(map((r) => r.result));
  }

  getCountries(): Observable<any> {
    return this.apiService.get(PATH.GET_COUNTRIES).pipe(map((r) => r.result));
  }

  getThemes(): Observable<Theme[]> {
    return this.apiService.get(PATH.GET_THEMES).pipe(map((r) => r.result));
  }

  getTimezones(): Observable<TimeZone[]> {
    return this.apiService.get(PATH.GET_TIMEZONES).pipe(map((r) => r.result));
  }

  getModules(): Observable<Modules[]> {
    return this.apiService.get(PATH.GET_ALL_MODULE).pipe(map((r) => r.result));
  }

  getPurge(): Observable<Purge> {
    return this.apiService.get(PATH.GET_PURGE).pipe(map((r) => r.result[0]));
  }

  getShortDateFormats(): Observable<any> {
    return this.apiService.get(PATH.GET_SHORT_DATE_FORMATS).pipe(map((r) => r.result));
  }

  getShortTimeFormats(): Observable<any> {
    return this.apiService.get(PATH.GET_SHORT_TIME_FORMATS).pipe(map((r) => r.result));
  }

  getLongDateFormats(): Observable<any> {
    return this.apiService.get(PATH.GET_LONG_DATE_FORMATS).pipe(map((r) => r.result));
  }

  getLongTimeFormats(): Observable<any> {
    return this.apiService.get(PATH.GET_LONG_TIME_FORMATS).pipe(map((r) => r.result));
  }

  getNodeConfig(rootNodeId: string, status: boolean): Observable<NodeConfiguration> {
    return this.apiService
      .get(
        PATH.GET_NODE_CONFIG_BY_ID(rootNodeId),
        new HttpParams().set('status', status.toString())
      )
      .pipe(map((r) => r.result));
  }

  getNodeDetails(nodeId: string, status: boolean): Observable<Node> {
    return this.apiService
      .get(PATH.GET_NODE_BY_ID(nodeId), new HttpParams().set('status', status.toString()))
      .pipe(map((r) => r.result));
  }
}
