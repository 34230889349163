import { IEBasketMgmtNewSettings } from './../../interfaces';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-module-assignment-receipt-settings',
  templateUrl: './receipt-settings.component.html',
  styleUrls: ['./receipt-settings.component.scss']
})
export class ReceiptSettingsComponent implements OnInit {
  @Input() parentFormReceipt: FormGroup;
  @Input() eBasketMgmtSettings: IEBasketMgmtNewSettings[];
  @Input() mixSaleProviderCheck: boolean;
  @Input() action: string;
  constructor(private formBuilder: FormBuilder) {}
  ngOnInit() {
    this.mixSaleProviderCheck = this.mixSaleProviderCheck;
    console.log('mixSaleProviderCheck - child', this.mixSaleProviderCheck);
    this.initForm();
  }

  initForm() {
    this.parentFormReceipt.addControl(
      'receiptSettings',
      this.formBuilder.group({
        print: [
          {
            // this.mixSaleProviderCheck &&
            value:
              this.eBasketMgmtSettings &&
              this.eBasketMgmtSettings[0].mixedSales &&
              this.eBasketMgmtSettings[0].mixedSales.receiptSettings &&
              this.eBasketMgmtSettings[0].mixedSales.receiptSettings.print
                ? this.eBasketMgmtSettings[0].mixedSales.receiptSettings.print || false
                : false,
            disabled: this.action === 'view' ? true : false
          }
        ],
        email: [
          {
            value:
              this.eBasketMgmtSettings &&
              this.eBasketMgmtSettings[0].mixedSales &&
              this.eBasketMgmtSettings[0].mixedSales.receiptSettings &&
              this.eBasketMgmtSettings[0].mixedSales.receiptSettings.email
                ? this.eBasketMgmtSettings[0].mixedSales.receiptSettings.email || false
                : false,
            disabled: this.action === 'view' ? true : false
          }
          //  this.mixSaleProviderCheck &&
        ],
        giftcard: [
          {
            //  this.mixSaleProviderCheck &&
            value:
              this.eBasketMgmtSettings &&
              this.eBasketMgmtSettings[0].mixedSales &&
              this.eBasketMgmtSettings[0].mixedSales.receiptSettings &&
              this.eBasketMgmtSettings[0].mixedSales.receiptSettings.giftcard
                ? this.eBasketMgmtSettings[0].mixedSales.receiptSettings.giftcard || false
                : false,
            disabled: this.action === 'view' ? true : false
          }
        ],
        wechat: [
          {
            //  this.mixSaleProviderCheck &&
            value:
              this.eBasketMgmtSettings &&
              this.eBasketMgmtSettings[0].mixedSales &&
              this.eBasketMgmtSettings[0].mixedSales.receiptSettings &&
              this.eBasketMgmtSettings[0].mixedSales.receiptSettings.wechat
                ? this.eBasketMgmtSettings[0].mixedSales.receiptSettings.wechat || false
                : false,

            disabled: this.action === 'view' ? true : false
          }
        ]
      })
    );
    console.log(['form', this.parentFormReceipt]);
  }
}
