import { NodeLevelType, NodeType } from '@shared/interfaces';
import { PERMISSION } from '../feature/auth/has-permission/has-permission-directive';
import { NODE_ROUTES_NAME } from '../feature/node-management/routes/node.routes.names';
import {
  ORGANIZATION_ROUTES_BASE_PATH,
  ORG_ROUTES_NAME
} from '../feature/organization/routes/organization.routes.names';
import { ROLE_ROUTES_NAME } from '../feature/role/routes/role.routes.names';
import {
  USER_ROUTES_BASE_PATH,
  USER_ROUTES_NAME
} from '../feature/users/routes/users.routes.names';
import { PROFILE_ROUTES } from '../profile/routes/profile.routes';
import { ROSS_ROLE_ROUTES_NAME } from '../feature/global-portal-roles/routes/global-role.routes.names';

export const APP_ROUTES_NAME = {
  DASHBOARD: {
    path: 'dashboard',
    name: 'menu.dashboard',
    visible: true,
    icon: 'dashboard',
    expectedPermission: [PERMISSION.OM_DASHBOARD]
  },
  USERS: {
    path: USER_ROUTES_BASE_PATH,
    name: 'menu.administrators',
    visible: true,
    // No need of dyanmic title in user screen so remove dynamic method name
    // dynamicName: 'getUserMenuName',
    childs: USER_ROUTES_NAME,
    icon: 'group',
    expectedPermission: [PERMISSION.LIST_USERS, PERMISSION.CREATE_USER]
  },
  ORGANIZATION: {
    path: ORGANIZATION_ROUTES_BASE_PATH,
    name: 'menu.organisation',
    childs: ORG_ROUTES_NAME,
    icon: 'business',
    visible: true,
    expectedPermission: [PERMISSION.LIST_ORGANISATIONS, PERMISSION.CREATE_ORGANISATION],
    expectedLevel: NodeType.GLOBAL,
    expectedLevelType: NodeLevelType.EQUAL
  },
  PROFILE: {
    path: 'profile',
    name: 'menu.user_profile',
    childs: PROFILE_ROUTES,
    visible: false,
    permissions: [PERMISSION.VIEW_PROFILE]
  },
  ROLE: {
    path: 'role',
    name: 'menu.role',
    icon: 'lock',
    childs: ROLE_ROUTES_NAME,
    visible: true,
    expectedPermission: [PERMISSION.LIST_ROLES, PERMISSION.CREATE_ROLE],
    expectedLevel: NodeType.GLOBAL,
    expectedLevelType: NodeLevelType.NOT_EQUAL
  },
  ROSS_ROLE: {
    path: 'roles',
    name: 'menu.ross_roles',
    icon: 'person',
    childs: ROSS_ROLE_ROUTES_NAME,
    visible: true,
    expectedPermission: [PERMISSION.LIST_ROLES, PERMISSION.CREATE_ROLE],
    expectedLevel: NodeType.GLOBAL,
    expectedLevelType: NodeLevelType.EQUAL
  },

  STRUCTURE: {
    path: 'structure',
    name: 'menu.structure',
    childs: NODE_ROUTES_NAME,
    icon: 'group_work',
    visible: true,
    expectedPermission: [PERMISSION.LIST_NODE_STRUCTURE, PERMISSION.CREATE_NODE],
    expectedLevel: NodeType.GLOBAL,
    expectedLevelType: NodeLevelType.NOT_EQUAL
  },
  SAMPLE_UI: 'ui-element-link'
};
