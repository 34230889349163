import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CommonConfigFacade } from '@shared/common-config/state/common-config.facade';
import { GenericDateTimeFormat } from '@shared/common-config/state/common-config.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ng-long-time-dropdown',
  templateUrl: './long-time-dropdown.component.html',
  styleUrls: ['./long-time-dropdown.component.scss']
})
export class LongTimeDropdownComponent implements OnInit, OnDestroy {
  @Input() ctrlLongTime: FormControl;
  @Input() placeholder: string;
  @Input() defaultLongTime;
  @Input() isOverriden: false;
  longTimeList: GenericDateTimeFormat[];
  subscriptions: Subscription[] = [];
  longTime: GenericDateTimeFormat;
  constructor(private readonly commonFacade: CommonConfigFacade) {}

  ngOnInit() {
    this.subscriptions.push(
      this.commonFacade.longTimeFormats$.subscribe((data) => {
        this.longTimeList = data;
        if (this.defaultLongTime && this.defaultLongTime.id) {
          this.ctrlLongTime.setValue(
            this.longTimeList.find((l) => l.id === this.defaultLongTime.id)
          );
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }
}
