import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import en from './i18n/en.json';
import zhcn from './i18n/zh-CN.json';
import fr from './i18n/fr.json';
import nl from './i18n/nl.json';
import jajp from './i18n/ja-JP.json';
import it from './i18n/it.json';
import de from './i18n/de.json';
import { SharedComponent } from './shared/shared.component';
import { SecretWordComponent } from './secret-word/secret-word.component';
import { EmailComponent } from './email/email.component';
import { ForgorPasswordRoutesModule } from './routes/forgot-password.routes.module';
import { TranslationLoaderService } from '@services/translation-loader.service';
import { SharedModule } from '@shared/shared.module';
import { ForgotPasswordService } from './forgot-password.service';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

@NgModule({
  declarations: [SharedComponent, SecretWordComponent, EmailComponent, ResetPasswordComponent],
  imports: [CommonModule, ForgorPasswordRoutesModule, SharedModule],
  providers: [ForgotPasswordService]
})
export class ForgotPasswordModule {
  constructor(private translateService: TranslationLoaderService) {
    this.translateService.loadTranslations(fr);
    this.translateService.loadTranslations(en);
    this.translateService.loadTranslations(zhcn);
    this.translateService.loadTranslations(nl);
    this.translateService.loadTranslations(jajp);
    this.translateService.loadTranslations(it);
    this.translateService.loadTranslations(de);
  }
}
