import { Component, OnInit, Input } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl,
  FormArray,
  FormControl
} from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import {
  EbasketApiMethodTypes,
  EbasketGetProductApi,
  EbasketInventorySelectApi,
  Providers
} from '@shared/interfaces';
import { SelectApiPopupComponent } from '@shared/select-api-popup/select-api-popup.component';

@Component({
  selector: 'app-product-import-salesforce-provider-details-settings',
  templateUrl: './product-import-salesforce-provider-details-settings.component.html',
  styleUrls: ['./product-import-salesforce-provider-details-settings.component.scss']
})
export class ProductImportSalesforceProviderDetailsSettingsComponent implements OnInit {
  @Input() action: string;
  @Input() parentForm: FormGroup;
  @Input() ebasketProductImportSalesforceAuthDetails: any;
  @Input() authControlKey: string;
  @Input() provider: string;
  // @Output() updateApiData: EventEmitter<any> = new EventEmitter();
  apiDetails: FormGroup;
  ebasket_ProductImport_select_api = [];
  isEBasketProductImportAddDisabled = false;

  Product_Import_salsforce_api = [
    {
      id: 1,
      name: EbasketGetProductApi.GetProducts,
      value: EbasketGetProductApi.GetProducts,
      isDisabled: false
    }
  ];

  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.initForm();
  }
  initForm() {
    console.log(this.ebasketProductImportSalesforceAuthDetails);
    this.ebasket_ProductImport_select_api = this.Product_Import_salsforce_api;
    this.parentForm.addControl(
      this.authControlKey,

      this.formBuilder.group({
        salesforceUrl: [
          {
            value:
              this.ebasketProductImportSalesforceAuthDetails &&
              this.ebasketProductImportSalesforceAuthDetails.authenticationDetails &&
              this.ebasketProductImportSalesforceAuthDetails.authenticationDetails.url
                ? this.ebasketProductImportSalesforceAuthDetails.authenticationDetails.url
                : '',
            disabled: this.action === 'view' ? true : false
          },
          [Validators.required]
        ],

        salesforceUserName: [
          {
            value:
              this.ebasketProductImportSalesforceAuthDetails &&
              (this.ebasketProductImportSalesforceAuthDetails.authenticationDetails ||
                this.ebasketProductImportSalesforceAuthDetails.authenticationDetailsKey)
                ? Providers.SECRET
                : '',
            disabled: this.action === 'view' ? true : false
          },
          [Validators.required]
        ],

        salesforcePassword: [
          {
            value:
              this.ebasketProductImportSalesforceAuthDetails &&
              (this.ebasketProductImportSalesforceAuthDetails.authenticationDetails ||
                this.ebasketProductImportSalesforceAuthDetails.authenticationDetailsKey)
                ? Providers.SECRET
                : '',
            disabled: this.action === 'view' ? true : false
          },
          [Validators.required]
        ],

        salesforceGrantType: [
          {
            value:
              this.ebasketProductImportSalesforceAuthDetails &&
              this.ebasketProductImportSalesforceAuthDetails.authenticationDetails &&
              this.ebasketProductImportSalesforceAuthDetails.authenticationDetails.grantType
                ? this.ebasketProductImportSalesforceAuthDetails.authenticationDetails.grantType
                : '',
            disabled: this.action === 'view' ? true : false
          },
          [Validators.required]
        ],
        ebasketProductImportApiDetails: this.formBuilder.array([])
      })
    );

    this.bindEbasketProductImportApiDetailsFormGroup();
  }

  bindEbasketProductImportApiDetailsFormGroup() {
    console.log(this.ebasketProductImportSalesforceAuthDetails);
    const control = this.parentForm
      .get(this.authControlKey)
      .get('ebasketProductImportApiDetails') as FormArray;
    if (
      this.ebasketProductImportSalesforceAuthDetails &&
      this.ebasketProductImportSalesforceAuthDetails.authenticationDetails &&
      this.ebasketProductImportSalesforceAuthDetails.authenticationDetails.apiDetails
    ) {
      this.ebasketProductImportSalesforceAuthDetails.authenticationDetails.apiDetails.map(
        (apis: any) => {
          let apiDetails = {};
          apiDetails = {
            url: [
              {
                value: apis.url,
                disabled: this.action === 'view' ? true : false
              },
              Validators.required
            ],
            siteId: [
              {
                value: apis.siteId,
                disabled: this.action === 'view' ? true : false
              },
              Validators.required
            ],
            selectedApi: [apis.selectedApi]
          };
          control.push(this.formBuilder.group(apiDetails));
          this.ebasket_ProductImport_select_api.map((key) => {
            if (key.value === apis.selectedApi) {
              key.isDisabled = true;
            }
          });
        }
      );

      if (this.ebasket_ProductImport_select_api.length === control.length) {
        this.isEBasketProductImportAddDisabled = true;
      }
    }
  }

  openEbasketProductImportSelectApiPopup() {
    console.log(
      this.parentForm.get(this.authControlKey).get('ebasketProductImportApiDetails')['controls']
    );
    console.log('open api function');
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = this.ebasket_ProductImport_select_api.filter((api) => !api.isDisabled);
    const dialogRef = this.dialog.open(SelectApiPopupComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result !== '') {
        this.ebasket_ProductImport_select_api.map((element: any) => {
          if (element.value === result) {
            element.isDisabled = true;
            // this.tm_gift_card_api_details.push(element);
            const ebasketProductImportApiDetails = this.parentForm
              .get(this.authControlKey)
              .get('ebasketProductImportApiDetails') as FormArray;

            ebasketProductImportApiDetails.push(
              this.createProductImportApiDetailsFormGroup(result)
            );
            if (
              this.ebasket_ProductImport_select_api.length === ebasketProductImportApiDetails.length
            ) {
              this.isEBasketProductImportAddDisabled = true;
            }
          }
        });
      }
    });
  }
  private createProductImportApiDetailsFormGroup(selectedApi: string): FormGroup {
    return new FormGroup({
      url: new FormControl('', Validators.required),
      siteId: new FormControl('', Validators.required),
      selectedApi: new FormControl(selectedApi)
    });
  }

  isRequired(control: string) {
    let isRequired = false;
    const formControlObject = this.parentForm.get(this.authControlKey).get(control);
    if (formControlObject !== null) {
      if (formControlObject.validator !== null) {
        if (formControlObject.validator({} as AbstractControl) !== null) {
          isRequired = formControlObject.validator({} as AbstractControl).required ? true : false;
        }
      }
    }
    return isRequired ? true : false;
  }

  deleteEbasketProductImportApiDetailContainer(index: number) {
    const ebasketProductImportApiDetails = this.parentForm
      .get(this.authControlKey)
      .get('ebasketProductImportApiDetails') as FormArray;
    this.ebasket_ProductImport_select_api.map((element: any) => {
      console.log('ebasket_ProductImport_select_api', this.ebasket_ProductImport_select_api);
      if (element.value === ebasketProductImportApiDetails.value[index].selectedApi) {
        element.isDisabled = false;
        console.log(this.ebasket_ProductImport_select_api);
      }
    });
    console.log('my val ->', ebasketProductImportApiDetails);
    if (ebasketProductImportApiDetails.length > 0) {
      ebasketProductImportApiDetails.removeAt(index);
    } else {
      ebasketProductImportApiDetails.reset();
    }
    if (this.ebasket_ProductImport_select_api.length !== ebasketProductImportApiDetails.length) {
      this.isEBasketProductImportAddDisabled = false;
    }
  }
}
