import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class CustomMatPaginatorIntl extends MatPaginatorIntl {
  translateService: TranslateService;
  constructor(private translate: TranslateService) {
    super();
    // When language change it will change iteam per page pagination label
    this.translate.onLangChange.subscribe((e: Event) => {
      this.getAndInitTranslations();
    });

    this.getAndInitTranslations();
  }

  getAndInitTranslations() {
    this.translate.get(['paginator.items_per_page_label']).subscribe((translation: any) => {
      this.itemsPerPageLabel = translation['paginator.items_per_page_label'];
      this.changes.next();
    });
  }
}
