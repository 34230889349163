import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService, PATH } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class ApplicationsService {
  constructor(private apiService: ApiService) {}

  getModulesByUserRoleId(roleId: string): Observable<any> {
    try {
      return this.apiService
        .get(PATH.GET_ROLE_BY_ID(roleId))
        .pipe(map((r) => (r.result && r.result.modules ? r.result.modules : [])));
    } catch (ex) {
      throw ex;
    }
  }
}
