import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Providers } from '@shared/interfaces';
@Component({
  selector: 'app-petty-cash-rj-provider-details',
  templateUrl: './rj-provider-details-settings.component.html'
})
export class PettyCashRJProviderDetailsComponent implements OnInit {
  @Input() action: string;
  @Input() parentForm: FormGroup;
  @Input() pettyCashRJDetails: any;
  @Input() authControlKey: string;
  @Input() provider: string;
  apiDetails: FormGroup;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.initForm();
  }
  initForm() {
    console.log('pettyCashRJDetails');
    console.log(this.pettyCashRJDetails);
    this.parentForm.addControl(
      this.authControlKey,
      this.formBuilder.group({
        userFtpUrl: [
          {
            value:
              this.pettyCashRJDetails &&
              (this.pettyCashRJDetails.authenticationDetails ||
                this.pettyCashRJDetails.authenticationDetailsKey)
                ? Providers.SECRET
                : '',
            disabled: this.action === 'view' ? true : false
          },
          [Validators.required]
        ],

        userFtpUserName: [
          {
            value:
              this.pettyCashRJDetails &&
              (this.pettyCashRJDetails.authenticationDetails ||
                this.pettyCashRJDetails.authenticationDetailsKey)
                ? Providers.SECRET
                : '',
            disabled: this.action === 'view' ? true : false
          },
          [Validators.required]
        ],

        userFtpPassword: [
          {
            value:
              this.pettyCashRJDetails &&
              (this.pettyCashRJDetails.authenticationDetails ||
                this.pettyCashRJDetails.authenticationDetailsKey)
                ? Providers.SECRET
                : '',
            disabled: this.action === 'view' ? true : false
          },
          [Validators.required]
        ],

        userFtpFolderName: [
          {
            value:
              this.pettyCashRJDetails &&
              (this.pettyCashRJDetails.authenticationDetails ||
                this.pettyCashRJDetails.authenticationDetailsKey)
                ? Providers.SECRET
                : '',
            disabled: this.action === 'view' ? true : false
          },
          [Validators.required]
        ],
        userFtpPort: [
          {
            value:
              this.pettyCashRJDetails &&
              (this.pettyCashRJDetails.authenticationDetails ||
                this.pettyCashRJDetails.authenticationDetailsKey)
                ? Providers.SECRET
                : '',
            disabled: this.action === 'view' ? true : false
          },
          [Validators.required]
        ],
        sftp: [
          {
            value:
              this.pettyCashRJDetails &&
              this.pettyCashRJDetails.authenticationDetails &&
              this.pettyCashRJDetails.authenticationDetails.sftp
                ? this.pettyCashRJDetails.authenticationDetails.sftp
                : false,
            disabled: this.action === 'view' ? true : false
          }
        ],
        authenticationKey: [
          {
            value:
              this.pettyCashRJDetails && this.pettyCashRJDetails.authenticationDetailsKey
                ? this.pettyCashRJDetails.authenticationDetailsKey
                : '',
            disabled: this.action === 'view' ? true : false
          }
        ]
      })
    );
  }

  isRequired(control: string) {
    let isRequired = false;
    const formControlObject = this.parentForm.get(this.authControlKey).get(control);
    if (formControlObject !== null) {
      if (formControlObject.validator !== null) {
        if (formControlObject.validator({} as AbstractControl) !== null) {
          isRequired = formControlObject.validator({} as AbstractControl).required ? true : false;
        }
      }
    }
    return isRequired ? true : false;
  }
}
