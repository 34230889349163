import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material';
import {
  EbasketApiMethodTypes,
  EbasketInventorySelectApi,
  EbasketSalesExportSelectApi,
  Providers,
  WebReturnsSelectApi
} from '@shared/interfaces';
import { SelectApiPopupComponent } from '@shared/select-api-popup/select-api-popup.component';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'magentov1v2-provider-details-settings',
  templateUrl: './magentov1v2-provider-details-settings.html',
  styleUrls: ['./magentov1v2-provider-details-settings.scss']
})
export class MagentoV1V2DetailsComponent implements OnInit {
  @Input() action: string;
  @Input() parentForm: FormGroup;
  @Input() ebasketInventoryMagentoApiDetails: any;
  @Input() authControlKey: string;
  @Input() provider: string;
  @Input() enableMagento2: boolean;
  @Input() inventory: boolean;
  @Input() webReturns: boolean;

  // @Output() updateApiData: EventEmitter<any> = new EventEmitter();
  apiDetails: FormGroup;
  isEBasketInventoryAddDisabled = false;
  ebasket_Inventory_select_api = [];
  ship_to_store_select_api = [
    {
      id: 1,
      name: EbasketSalesExportSelectApi.ProcessOrders,
      value: EbasketSalesExportSelectApi.ProcessOrders,
      isDisabled: false
    }
  ];
  ship_to_store_select_api_inventory = [
    {
      id: 1,
      name: EbasketInventorySelectApi.StockEnquiry,
      value: EbasketInventorySelectApi.StockEnquiry,
      isDisabled: false
    }
  ];
  web_returns_select_api = [
    {
      id: 1,
      name: WebReturnsSelectApi.ProcessOrders,
      value: WebReturnsSelectApi.ProcessOrders,
      isDisabled: false
    }
  ];

  method_types = [
    { id: 1, name: EbasketApiMethodTypes.Get, value: EbasketApiMethodTypes.Get },
    { id: 2, name: EbasketApiMethodTypes.Post, value: EbasketApiMethodTypes.Post },
    { id: 3, name: EbasketApiMethodTypes.Put, value: EbasketApiMethodTypes.Put },
    { id: 4, name: EbasketApiMethodTypes.Patch, value: EbasketApiMethodTypes.Patch },
    { id: 5, name: EbasketApiMethodTypes.Delete, value: EbasketApiMethodTypes.Delete }
  ];
  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    if (this.enableMagento2) {
      this.ebasket_Inventory_select_api = this.ship_to_store_select_api;
    }
    if (this.inventory) {
      this.ebasket_Inventory_select_api = this.ship_to_store_select_api_inventory;
    }
    if (this.webReturns) {
      this.ebasket_Inventory_select_api = this.web_returns_select_api;
    }
    this.initForm();
  }
  initForm() {
    this.parentForm.addControl(
      this.authControlKey,
      this.formBuilder.group({
        ebasketInventoryOrderMagentoVersion: [
          {
            value:
              this.ebasketInventoryMagentoApiDetails &&
              this.ebasketInventoryMagentoApiDetails.magentoVersion
                ? this.ebasketInventoryMagentoApiDetails.magentoVersion
                : 'v1',
            disabled: this.action === 'view' ? true : false
          },
          [Validators.required]
        ],
        ebasketInventoryMagentoUsername: [
          {
            value:
              this.ebasketInventoryMagentoApiDetails &&
              this.ebasketInventoryMagentoApiDetails.magentoVersion
                ? Providers.SECRET
                : '',
            disabled: this.action === 'view' ? true : false
          },
          [Validators.required]
        ],
        ebasketInventoryMagentoApiKey: [
          {
            value:
              this.ebasketInventoryMagentoApiDetails &&
              this.ebasketInventoryMagentoApiDetails.magentoVersion
                ? Providers.SECRET
                : '',
            disabled: this.action === 'view' ? true : false
          },
          [Validators.required]
        ],
        // ebasketInventoryMagentoUrl: [
        //   {
        //     value:
        //       this.ebasketInventoryMagentoApiDetails &&
        //       this.ebasketInventoryMagentoApiDetails.magentoVersion
        //         ? Providers.SECRET
        //         : '',
        //     disabled:
        //       this.action === 'view' || this.inventory
        //         ? true
        //         : false || this.webReturns
        //         ? true
        //         : false
        //   },
        //   [Validators.required]
        // ],

        ebasketInventoryApiDetails: this.formBuilder.array([])
      })
    );

    this.bindEbasketInventoryApiDetailsFormGroup();
    // console.log(this.parentForm.get('apiDetails').get('ebasketInventoryApiDetails').value.value);
  }

  bindEbasketInventoryApiDetailsFormGroup() {
    const control = this.parentForm
      .get(this.authControlKey)
      .get('ebasketInventoryApiDetails') as FormArray;
    if (
      this.ebasketInventoryMagentoApiDetails &&
      this.ebasketInventoryMagentoApiDetails.apiDetails
    ) {
      this.ebasketInventoryMagentoApiDetails.apiDetails.map((apis: any) => {
        let apiDetails = {};
        apiDetails = {
          url: [
            {
              value: apis.url,
              disabled: this.action === 'view' ? true : false
            },
            Validators.required
          ],
          method: [
            {
              value: apis.method,
              disabled: this.action === 'view' ? true : false
            },
            Validators.required
          ],
          selectedApi: [apis.selectedApi]
        };
        control.push(this.formBuilder.group(apiDetails));
        this.ebasket_Inventory_select_api.map((key) => {
          if (key.value === apis.selectedApi) {
            key.isDisabled = true;
          }
        });
      });

      if (this.ebasket_Inventory_select_api.length === control.length) {
        this.isEBasketInventoryAddDisabled = true;
      }
    }
  }

  openEbasketInventorySelectApiPopup() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = this.ebasket_Inventory_select_api.filter((api) => !api.isDisabled);
    const dialogRef = this.dialog.open(SelectApiPopupComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result !== '') {
        this.ebasket_Inventory_select_api.map((element: any) => {
          if (element.value === result) {
            element.isDisabled = true;
            // this.tm_gift_card_api_details.push(element);
            const ebasketInventoryApiDetails = this.parentForm
              .get(this.authControlKey)
              .get('ebasketInventoryApiDetails') as FormArray;

            ebasketInventoryApiDetails.push(this.createInventoryApiDetailsFormGroup(result));
            if (this.ebasket_Inventory_select_api.length === ebasketInventoryApiDetails.length) {
              this.isEBasketInventoryAddDisabled = true;
            }
          }
        });
      }
    });
  }

  private createInventoryApiDetailsFormGroup(selectedApi: string): FormGroup {
    return new FormGroup({
      url: new FormControl('', Validators.required),
      method: new FormControl('', Validators.required),
      selectedApi: new FormControl(selectedApi)
    });
  }
  isRequired(control: string) {
    let isRequired = false;
    const formControlObject = this.parentForm.get(this.authControlKey).get(control);
    if (formControlObject !== null) {
      if (formControlObject.validator !== null) {
        if (formControlObject.validator({} as AbstractControl) !== null) {
          isRequired = formControlObject.validator({} as AbstractControl).required ? true : false;
        }
      }
    }
    return isRequired ? true : false;
  }
  deleteEbasketInventoryApiDetailContainer(index: number) {
    const ebasketInventoryApiDetails = this.parentForm
      .get(this.authControlKey)
      .get('ebasketInventoryApiDetails') as FormArray;
    this.ebasket_Inventory_select_api.map((element: any) => {
      if (element.value === ebasketInventoryApiDetails.value[index].selectedApi) {
        element.isDisabled = false;
        console.log(this.ebasket_Inventory_select_api);
      }
    });
    if (ebasketInventoryApiDetails.length > 0) {
      ebasketInventoryApiDetails.removeAt(index);
    } else {
      ebasketInventoryApiDetails.reset();
    }
    if (this.ebasket_Inventory_select_api.length !== ebasketInventoryApiDetails.length) {
      this.isEBasketInventoryAddDisabled = false;
    }
  }
}
