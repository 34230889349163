import { PERMISSION } from '../../auth/has-permission/has-permission-directive';

export const BASE_ROLE_PATH = '/role';
export const ROLE_ROUTES_NAME = {
  LIST: {
    basePath: '/role',
    path: '',
    name: 'role_sub_menu.role_list',
    visible: true,
    expectedPermission: [PERMISSION.LIST_ROLES]
  },
  CREATE: {
    basePath: '/role',
    path: 'create',
    name: 'role_sub_menu.role_create',
    visible: true,
    expectedPermission: [PERMISSION.CREATE_ROLE]
  },
  VIEW: {
    basePath: '/role',
    path: ':id/view',
    name: 'role_sub_menu.role_view',
    visible: false,
    expectedPermission: [PERMISSION.VIEW_ROLE]
  },
  EDIT: {
    basePath: '/role',
    path: ':id/edit',
    name: 'role_sub_menu.role_edit',
    visible: false,
    expectedPermission: [PERMISSION.EDIT_ROLE]
  }
};
