import { NodeType } from '@shared/interfaces';

const CONSTANTS = {
  ENGAGE_BUCKET: 'ciep-engage',
  CURRENT_APPLICATION_NAME: 'om',
  DEFAULT_LOCALE: 'en-GB',
  PASSWORD_VALIDATION_REGEX: '^(?=.{6,}).*$',
  IDLE_TIMEOUT: 2400, // Sets an idle timeout of 2400 seconds = 40 min.
  TIMEOUT: 300, // Sets a timeout period of 300 seconds = 5 min.
  // After 5 minutes of inactivity, the user will be considered timed out and logout.
  KEEP_ALIVE: 3300, // Sets the ping interval to 3300 seconds = 55 min to reset Access Token
  TM: 'TM',
  SAGA: 'SAGA',
  OM: 'OM',
  RJ: 'RJ',
  EBASKET: 'eBasket',
  T2S: 'T2S',
  SOTK: 'SOTK',
  ENGAGE: 'engage',
  T2B: 'T2B',
  CRESTENGAGEMENT: 'crestEngagement',
  ROSS: 'Ross',
  COMPANY: 'COMPANY',
  ORGANIZATION: 'ORGANIZATION',
  CREST: 'Crest',
  EBASKET_ID: '5',
  getCurrentLevel: (rootNode: string): number => {
    switch (rootNode) {
      case NodeType.GLOBAL:
        return 60;
      case NodeType.ORGANIZATION:
        return 50;
      case NodeType.COMPANY:
        return 40;
      case NodeType.TRADING_REGION:
        return 30;
      case NodeType.STORE_GROUP || NodeType.SUB_STORE_GROUP:
        return 20;
      case NodeType.STORE:
        return 10;
    }
  },
  paymentOptions: [
    {
      id: 1,
      name: 'Cash',
      type: 'TMC_CASH',
      authCode: 'cashAuthCode',
      displayName: 'cashdisplayName',
      externalType: 'cashexternal',
      externalSubType: 'cashexternalsub',
      tenderItemKey: 'cashsimpleaccounttender'
    },
    {
      id: 2,
      name: 'Card',
      type: 'Unintegrated',
      authCode: 'cardAuthCode',
      displayName: 'carddisplayName',
      externalType: 'cardexternal',
      externalSubType: 'cardexternalsub',
      tenderItemKey: 'cardsimpleaccounttender'
    },
    {
      id: 3,
      name: 'Contactless',
      type: 'Unintegrated',
      authCode: 'contactlessAuthCode',
      displayName: 'contactlessdisplayName',
      externalType: 'contactlessexternal',
      externalSubType: 'contactlessexternalsub',
      tenderItemKey: 'contactlesssimpleaccounttender'
    },
    {
      id: 4,
      name: 'Amex',
      type: 'Unintegrated',
      authCode: 'AmexAuthCode',
      displayName: 'AmexdisplayName',
      externalType: 'Amexexternal',
      externalSubType: 'Amexexternalsub',
      tenderItemKey: 'Amexsimpleaccounttender'
    },
    {
      id: 5,
      name: 'WeChat',
      type: 'Unintegrated',
      authCode: 'WeChatAuthCode',
      displayName: 'WeChatdisplayName',
      externalType: 'WeChatexternal',
      externalSubType: 'WeChatexternalsub',
      tenderItemKey: 'WeChatsimpleaccounttender'
    },
    {
      id: 6,
      name: 'AliPay',
      type: 'Unintegrated',
      authCode: 'AliPayAuthCode',
      displayName: 'AliPaydisplayName',
      externalType: 'AliPayexternal',
      externalSubType: 'AliPayexternalsub',
      tenderItemKey: 'Alisimpleaccounttender'
    },
    {
      id: 7,
      name: 'Gift Card',
      type: 'TMC_GIFTCARD',
      authCode: 'GiftAuthCode',
      displayName: 'GiftdisplayName',
      externalType: 'Giftexternal',
      externalSubType: 'Giftexternalsub',
      tenderItemKey: 'Giftsimpleaccounttender'
    },
    {
      id: 8,
      name: 'Disney Points',
      type: 'Unintegrated',
      authCode: 'DisneyAuthCode',
      displayName: 'DisneydisplayName',
      externalType: 'Disneyexternal',
      externalSubType: 'Disneyexternalsub',
      tenderItemKey: 'Disneysimpleaccounttender'
    },
    {
      id: 9,
      name: 'Venmo',
      type: 'Unintegrated',
      authCode: 'VenmoAuthCode',
      displayName: 'VenmodisplayName',
      externalType: 'Venmoexternal',
      externalSubType: 'Venmoexternalsub',
      tenderItemKey: 'Venmosimpleaccounttender'
    },
    {
      id: 10,
      name: 'Afterpay',
      type: 'Unintegrated',
      authCode: 'AfterpayAuthCode',
      displayName: 'AfterpaydisplayName',
      externalType: 'Afterpayexternal',
      externalSubType: 'Afterpayexternalsub',
      tenderItemKey: 'Afterpayexternalsubtender'
    },
    {
      id: 11,
      name: 'Unionpay',
      type: 'Unintegrated',
      authCode: 'UnionpayAuthCode',
      displayName: 'UnionpaydisplayName',
      externalType: 'Unionpayexternal',
      externalSubType: 'Unionpayexternalsub',
      tenderItemKey: 'Unionsimpleaccounttender'
    },
    {
      id: 12,
      name: 'Paypal',
      type: 'Unintegrated',
      authCode: 'PaypalAuthCode',
      displayName: 'PaypaldisplayName',
      externalType: 'Paypalexternal',
      externalSubType: 'Paypalexternalsub',
      tenderItemKey: 'Paypalsimpleaccounttender'
    },
    {
      id: 13,
      name: 'Klarna',
      type: 'Unintegrated',
      authCode: 'KlarnaAuthCode',
      displayName: 'KlarndisplayName',
      externalType: 'Klarnaexternal',
      externalSubType: 'Klarnaexternalsub',
      tenderItemKey: 'Klarnasimpleaccounttender'
    },
    {
      id: 14,
      name: 'Remote Pay',
      type: 'Unintegrated',
      authCode: 'RemoteAuthCode',
      displayName: 'RemotedisplayName',
      externalType: 'Remoteexternal',
      externalSubType: 'Remotexternalsub',
      tenderItemKey: 'Remotesimpleaccounttender'
    },
    {
      id: 15,
      name: 'Card',
      type: 'SEPAY',
      authCode: 'SEPAYAuthCode',
      displayName: 'SEPAYdisplayName',
      externalType: 'SEPAYexternal',
      externalSubType: 'SEPAYexternalsub',
      tenderItemKey: 'SEPAYsimpleaccounttender'
    },
    {
      id: 16,
      name: 'PDQ',
      type: 'Unintegrated',
      authCode: 'PDQAuthCode',
      displayName: 'PDQdisplayName',
      externalType: 'PDQexternal',
      externalSubType: 'PDQexternalsub',
      tenderItemKey: 'PDQsimpleaccounttender'
    },
    {
      id: 17,
      name: 'Pay By Link',
      type: 'Unintegrated',
      authCode: 'paybylinkeAuthCode',
      displayName: 'paybylinkdisplayName',
      externalType: 'paybylinkexternal',
      externalSubType: 'paybylinkexternalsub',
      tenderItemKey: 'paybylinkesimpleaccounttender'
    },
    {
      id: 18,
      name: 'KNET',
      type: 'KNET',
      authCode: 'KNETAuthCode',
      displayName: 'KNETdisplayName',
      externalType: 'KNETxternal',
      externalSubType: 'KNETxternalsub',
      tenderItemKey: 'KNETimpleaccounttender'
    },
    {
      id: 19,
      name: 'Cheque',
      type: 'TMC_CHEQUE',
      authCode: 'ChequeAuthCode',
      displayName: 'ChequedisplayName',
      externalType: 'Chequexternal',
      externalSubType: 'Chequexternalsub',
      tenderItemKey: 'Chequeimpleaccounttender'
    },
    {
      id: 20,
      name: 'Gift Voucher',
      type: 'TMC_GIFTVOUCHER',
      authCode: 'GiftvAuthCode',
      displayName: 'GiftvdisplayName',
      externalType: 'GiftvTxternal',
      externalSubType: 'Giftvxternalsub',
      tenderItemKey: 'Giftvimpleaccounttender'
    }
  ],
  subPaymentOption: [
    {
      id: 1,
      name: 'Debit Card',
      type: 'Debit_Card'
    },
    {
      id: 2,
      name: 'Mastercard Credit',
      type: 'Mastercard_Credit'
    },
    {
      id: 3,
      name: 'Visa Credit',
      type: 'Visa_Credit'
    },
    {
      id: 4,
      name: 'Diner',
      type: 'Diner'
    },
    {
      id: 5,
      name: 'American Express',
      type: 'American_Express'
    },
    {
      id: 6,
      name: 'Apple Pay',
      type: 'Apple_Pay'
    },
    {
      id: 7,
      name: 'Android Pay',
      type: 'Android_Pay'
    }
  ],
  CNDOMAIN: '.cn',
  ROW: 'Rest of World',
  CN: 'China',
  saleFlowList: [
    {
      name: 'Default',
      value: 'default'
    },
    {
      name: 'Custom Ticketing',
      value: 'customTicketing'
    }
  ],
  promotionApplicationOptions: [
    {
      name: 'Apply best promotion',
      value: 'APPLY_BEST_PROMOTION'
    },
    {
      name: 'Apply staff discount in addition to other promotion',
      value: 'APPLY_STAFF_DISCOUNT_In_ADDITION_TO_OTHER_PROMOTION'
    }
  ],
  customTicketing: 'customTicketing',
  fiscalCountries: [
    {
      id: 1,
      name: 'France',
      value: 'France'
    },
    {
      id: 2,
      name: 'Germany',
      value: 'Germany'
    },
    {
      id: 3,
      name: 'Poland',
      value: 'Poland'
    }
  ],
  REASONID_ALREADY_EXISTS_MSG: 'Reason ID already exists',
  IP_ALREADY_EXISTS_MSG: 'Duplicate: Device already exists'
};
export enum COUNTRIES {
  POLAND = 'Poland',
  FRANCE = 'France',
  GERMANY = 'Germany'
}
export enum globalPortalAccessRights {
  OM_DASHBOARD = 'OM_DASHBOARD',
  VIEW_PROFILE = 'VIEW_PROFILE',
  LIST_USERS = 'LIST_USERS',
  CREATE_USER = 'CREATE_USER',
  VIEW_USER = 'VIEW_USER',
  ENABLED_USER = 'ENABLED_USER',
  EDIT_USER = 'EDIT_USER',
  DELETE_USER = 'DELETE_USER',
  LIST_ROLES = 'LIST_ROLES',
  CREATE_ROLE = 'CREATE_ROLE',
  DELETE_ROLE = 'DELETE_ROLE',
  EDIT_ROLE = 'EDIT_ROLE',
  VIEW_ROLE = 'VIEW_ROLE',
  ENABLED_ROLE = 'ENABLED_ROLE',
  CREATE_ORGANISATION = 'CREATE_ORGANISATION',
  VIEW_ORGANISATION = 'VIEW_ORGANISATION',
  MODULE_APP_ICON = 'MODULE_APP_ICON',
  ALLOW_TO_VIEW_MODULE_ICON = 'ALLOW_TO_VIEW_MODULE_ICON',
  UPDATE_ORGANISATION = 'UPDATE_ORGANISATION'
}

export enum TEMPLATE {
  USER_IMPORT_CSV_TEMPLATE_NAME = 'USER_IMPORT_CSV_TEMPLATE',
  PRE_FIX = 'Users',
  EXTENSION = 'csv',
  CONTENT_ENCODING = 'base64',
  CONTENT_TYPE = 'text/csv',
  UPLOAD_LOCATION = 'pmc'
}

export const IP_ADDRESS_PATTERN = /^(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)$/;

export default CONSTANTS;
