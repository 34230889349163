import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { MatDialog } from '@angular/material';
import { Providers } from '@shared/interfaces';
@Component({
  selector: 'gift-card-common-configuration',
  templateUrl: './gift-card-common-configuration.component.html',
  styleUrls: ['./gift-card-common-configuration.component.scss']
})
export class TMGiftCardCommonConfigurationComponent implements OnInit {
  @Input() action: string;
  @Input() parentForm: FormGroup;
  @Input() giftCardConfigurationDetails: any;
  @Input() authControlKey: string;
  @Input() provider: string;

  constructor(private formBuilder: FormBuilder, private dialog: MatDialog) {}

  ngOnInit() {
    this.initForm();
    this.changeExpirySetByUser();
    this.changeFlexibleCardAllowed();
  }
  initForm() {
    this.parentForm.addControl(
      this.authControlKey,
      this.formBuilder.group({
        expiryDate: [
          {
            value:
              this.giftCardConfigurationDetails && this.giftCardConfigurationDetails.expiryDate
                ? this.giftCardConfigurationDetails.expiryDate
                : '',
            disabled: this.action === 'view' ? true : false
          },
          this.provider === Providers.PARASPAR ? '' : Validators.required
        ],
        giftCardSKU: [
          {
            value:
              this.giftCardConfigurationDetails && this.giftCardConfigurationDetails.giftCardSKU
                ? this.giftCardConfigurationDetails.giftCardSKU
                : '',
            disabled: this.action === 'view' ? true : false
          },
          this.provider === Providers.PARASPAR ? '' : Validators.required
        ],
        maximumAmount: [
          {
            value:
              this.giftCardConfigurationDetails && this.giftCardConfigurationDetails.maximumAmount
                ? this.giftCardConfigurationDetails.maximumAmount
                : 0,
            disabled: this.action === 'view' ? true : false
          },
          this.provider === Providers.GIVEX ? '' : Validators.required
        ],
        minimumAmount: [
          {
            value:
              this.giftCardConfigurationDetails && this.giftCardConfigurationDetails.minimumAmount
                ? this.giftCardConfigurationDetails.minimumAmount
                : 0.01,
            disabled: this.action === 'view' ? true : false
          },
          this.provider === Providers.PARASPAR ? Validators.required : ''
        ],
        maxPerBasket: [
          {
            value:
              this.giftCardConfigurationDetails && this.giftCardConfigurationDetails.maxPerBasket
                ? this.giftCardConfigurationDetails.maxPerBasket
                : 0,
            disabled: this.action === 'view' ? true : false
          },
          this.provider === Providers.PARASPAR ? Validators.required : ''
        ],
        shippingCode: [
          {
            value:
              this.giftCardConfigurationDetails &&
              this.giftCardConfigurationDetails.shippingDetails &&
              this.giftCardConfigurationDetails.shippingDetails.length &&
              this.giftCardConfigurationDetails.shippingDetails[0].shippingCode
                ? this.giftCardConfigurationDetails.shippingDetails[0].shippingCode
                : '',
            disabled: this.action === 'view' ? true : false
          },
          this.provider === Providers.PARASPAR ? Validators.required : ''
        ],
        shippingName: [
          {
            value:
              this.giftCardConfigurationDetails &&
              this.giftCardConfigurationDetails.shippingDetails &&
              this.giftCardConfigurationDetails.shippingDetails.length &&
              this.giftCardConfigurationDetails.shippingDetails[0].shippingName
                ? this.giftCardConfigurationDetails.shippingDetails[0].shippingName
                : '',
            disabled: this.action === 'view' ? true : false
          },
          this.provider === Providers.PARASPAR ? Validators.required : ''
        ],
        shippingVat: [
          {
            value:
              this.giftCardConfigurationDetails &&
              this.giftCardConfigurationDetails.shippingDetails &&
              this.giftCardConfigurationDetails.shippingDetails.length &&
              this.giftCardConfigurationDetails.shippingDetails[0].shippingVat
                ? this.giftCardConfigurationDetails.shippingDetails[0].shippingVat
                : 0,
            disabled: this.action === 'view' ? true : false
          },
          this.provider === Providers.PARASPAR ? Validators.required : ''
        ],
        shippingTotal: [
          {
            value:
              this.giftCardConfigurationDetails &&
              this.giftCardConfigurationDetails.shippingDetails &&
              this.giftCardConfigurationDetails.shippingDetails.length &&
              this.giftCardConfigurationDetails.shippingDetails[0].shippingTotal
                ? this.giftCardConfigurationDetails.shippingDetails[0].shippingTotal
                : 0,
            disabled: this.action === 'view' ? true : false
          },
          this.provider === Providers.PARASPAR ? Validators.required : ''
        ],
        vatCode: [
          {
            value:
              this.giftCardConfigurationDetails && this.giftCardConfigurationDetails.vatCode
                ? this.giftCardConfigurationDetails.vatCode
                : '',
            disabled: this.action === 'view' ? true : false
          }
        ],
        vatRate: [
          {
            value:
              this.giftCardConfigurationDetails && this.giftCardConfigurationDetails.vatRate
                ? Number(this.giftCardConfigurationDetails.vatRate) || 0
                : 0,
            disabled: this.action === 'view' ? true : false
          },
          Validators.required
        ],
        allowSaleTopupGiftCard: [
          {
            value:
              this.giftCardConfigurationDetails &&
              this.giftCardConfigurationDetails.allowSaleTopupGiftCard
                ? true
                : false,
            disabled: this.action === 'view' ? true : false
          }
        ],
        expirySetByUser: [
          {
            value:
              this.giftCardConfigurationDetails && this.giftCardConfigurationDetails.expirySetByUser
                ? true
                : false,
            disabled: this.action === 'view' ? true : false
          }
        ],
        flexibleCardAllowed: [
          {
            value:
              this.giftCardConfigurationDetails &&
              this.giftCardConfigurationDetails.flexibleCardAllowed &&
              this.giftCardConfigurationDetails.flexibleCardAllowed.status
                ? true
                : false,
            disabled: this.action === 'view' ? true : false
          }
        ],
        cardPrefix: [
          {
            value:
              this.giftCardConfigurationDetails &&
              this.giftCardConfigurationDetails.flexibleCardAllowed &&
              this.giftCardConfigurationDetails.flexibleCardAllowed.status &&
              this.giftCardConfigurationDetails.flexibleCardAllowed.cardPrefix
                ? this.giftCardConfigurationDetails.flexibleCardAllowed.cardPrefix
                : 0,
            disabled: this.action === 'view' ? true : false
          }
        ],
        minimumValue: [
          {
            value:
              this.giftCardConfigurationDetails &&
              this.giftCardConfigurationDetails.flexibleCardAllowed &&
              this.giftCardConfigurationDetails.flexibleCardAllowed.status &&
              this.giftCardConfigurationDetails.flexibleCardAllowed.minimumValue
                ? this.giftCardConfigurationDetails.flexibleCardAllowed.minimumValue
                : 0.01,
            disabled: this.action === 'view' ? true : false
          }
        ],
        maximumValue: [
          {
            value:
              this.giftCardConfigurationDetails &&
              this.giftCardConfigurationDetails.flexibleCardAllowed &&
              this.giftCardConfigurationDetails.flexibleCardAllowed.maximumValue
                ? this.giftCardConfigurationDetails.flexibleCardAllowed.maximumValue
                : 0,
            disabled: this.action === 'view' ? true : false
          }
        ],
        extraSlipForSaleOfNewGC: [
          {
            value:
              this.giftCardConfigurationDetails &&
              this.giftCardConfigurationDetails.extraSlipForSaleOfNewGC &&
              this.giftCardConfigurationDetails.extraSlipForSaleOfNewGC.status
                ? true
                : false,
            disabled: this.action === 'view' ? true : false
          }
        ],
        includeGCInCalculationOfTransactionPromotionOrDiscount: [
          {
            value:
              this.giftCardConfigurationDetails &&
              this.giftCardConfigurationDetails
                .includeGCInCalculationOfTransactionPromotionOrDiscount &&
              this.giftCardConfigurationDetails
                .includeGCInCalculationOfTransactionPromotionOrDiscount.status
                ? true
                : false,
            disabled: this.action === 'view' ? true : false
          }
        ]
      })
    );
  }

  isRequired(control: string) {
    let isRequired = false;
    const formControlObject = this.parentForm.get(this.authControlKey).get(control);
    if (formControlObject !== null) {
      if (formControlObject.validator !== null) {
        if (formControlObject.validator({} as AbstractControl) !== null) {
          isRequired = formControlObject.validator({} as AbstractControl).required ? true : false;
        }
      }
    }
    return isRequired ? true : false;
  }
  // this function will be called when the Expiry Set By User field is updated
  // if the toggle is enabled then expiryDate field is mendatory to fill
  changeExpirySetByUser() {
    if (
      !this.parentForm.get(this.authControlKey).value.expirySetByUser &&
      this.provider === Providers.GIVEX
    ) {
      this.parentForm
        .get(this.authControlKey)
        .get('expiryDate')
        .clearValidators();
      this.parentForm
        .get(this.authControlKey)
        .get('expiryDate')
        .reset();
    } else {
      if (this.provider != Providers.PARASPAR) {
        this.parentForm
          .get(this.authControlKey)
          .get('expiryDate')
          .setValidators(Validators.required);
      }
    }
    this.parentForm
      .get(this.authControlKey)
      .get('expiryDate')
      .updateValueAndValidity();
  }
  // this function will be called when the Flexible Card Allowed field is updated
  // if the toggle is enabled then cardPrefix,minimumValue,maximumValue fields are mendatory to fill
  changeFlexibleCardAllowed() {
    if (!this.parentForm.get(this.authControlKey).value.flexibleCardAllowed) {
      this.parentForm
        .get(this.authControlKey)
        .get('cardPrefix')
        .clearValidators();
      this.parentForm
        .get(this.authControlKey)
        .get('cardPrefix')
        .reset();
      this.parentForm
        .get(this.authControlKey)
        .get('minimumValue')
        .clearValidators();
      this.parentForm
        .get(this.authControlKey)
        .get('minimumValue')
        .reset();
      this.parentForm
        .get(this.authControlKey)
        .get('maximumValue')
        .clearValidators();
      this.parentForm
        .get(this.authControlKey)
        .get('maximumValue')
        .reset();
    } else {
      this.parentForm
        .get(this.authControlKey)
        .get('cardPrefix')
        .setValidators(Validators.required);
      this.parentForm
        .get(this.authControlKey)
        .get('minimumValue')
        .setValidators(Validators.required);
      this.parentForm
        .get(this.authControlKey)
        .get('maximumValue')
        .setValidators(Validators.required);
    }
    this.parentForm
      .get(this.authControlKey)
      .get('cardPrefix')
      .updateValueAndValidity();
    this.parentForm
      .get(this.authControlKey)
      .get('minimumValue')
      .updateValueAndValidity();
    this.parentForm
      .get(this.authControlKey)
      .get('maximumValue')
      .updateValueAndValidity();
  }
  // this function will be called to set the visibility of the expirySetByUser configuration
  enableExpiryDate() {
    if (
      this.provider === Providers.GIVEX &&
      this.parentForm.get(this.authControlKey).get('expirySetByUser').value
    ) {
      return true;
    } else if (this.provider != Providers.GIVEX && this.provider != Providers.PARASPAR) {
      return true;
    }
    return false;
  }
  // this function will be called to set the visibility of the flexibile card configuration
  enableFlexibleCard() {
    if (
      (this.provider === Providers.GIVEX || this.provider === Providers.Netsuite) &&
      this.parentForm.get(this.authControlKey).get('flexibleCardAllowed').value
    ) {
      return true;
    }
    return false;
  }
  enableMaximumAmount() {
    return this.provider === Providers.GIVEX ? false : true;
  }
  enableMinimumAmount() {
    return this.provider === Providers.PARASPAR ? true : false;
  }
}
