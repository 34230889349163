import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@services/notification.service';
import {
  IPaginationResponse,
  IPasswordResetRequest,
  IProfilePhotoUpdate,
  IUser
} from '@shared/interfaces';
import { Observable } from 'rxjs';
import { ApiService, PATH } from '../core/api/api.service';

@Injectable()
export class ProfileService {
  user: IUser = {
    communicationEmail: null,
    communicationPush: null,
    communicationSms: null,
    dateOfBirth: null,
    emailId: null,
    firstName: null,
    id: null,
    lastActive: null,
    lastName: null,
    manualPassword: null,
    phoneNumber: null,
    language: null,
    secretWord: null,
    status: null,
    timeZone: null,
    userName: null,
    userRole: null,
    pin: null,
    country: null,
    domainName: null
  };

  constructor(
    private apiService: ApiService,
    private notificationService: NotificationService,
    private translate: TranslateService
  ) {}

  async resetPassword(
    oldPassword: string,
    newPassword: string,
    confirmPassword: string,
    updatedBy: string,
    accessKey: string
  ) {
    try {
      const passwordResetRequest: IPasswordResetRequest = {
        oldPassword,
        newPassword,
        confirmPassword,
        updatedBy,
        accessKey
      };
      const response = await this.apiService
        .post(PATH.POST_RESET_PASSWORD, passwordResetRequest)
        .toPromise();
      // tslint:disable-next-line: no-string-literal
      this.notificationService.success(JSON.parse(JSON.stringify(response)).result);
    } catch (err) {
      this.notificationService.error(err.message);
    }
  }

  /**
   * @desc update user detail
   * @param user IUser
   * @param userId string
   * @return Promise<IUser>
   */
  async updateUser(user: IUser, userId: string): Promise<IUser> {
    try {
      const response = await this.apiService.put(PATH.PUT_UPDATE_USER(userId), user).toPromise();
      // this.notificationService.success('User details updated successfully');
      return response as IUser;
    } catch (err) {
      console.log(err);
      // this.notificationService.error(err.message);
    }
  }

  async saveProfilePhotoToS3(request: object): Promise<IProfilePhotoUpdate> {
    try {
      const response = await this.apiService.post(PATH.UPLOAD_PROFILE_PHOTO, request).toPromise();
      console.log(response);
      return response.result as IProfilePhotoUpdate;
    } catch (error) {
      this.notificationService.error('Error uploading profile picture');
    }
  }

  get(userId: string): Observable<IPaginationResponse<IUser>> {
    return this.apiService.get(PATH.GET_USER(userId.trim()));
  }
}
