import { Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MatTableDataSource, MAT_DIALOG_DATA, MatPaginator } from '@angular/material';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { FemaleShirtAndShortsService } from '../female-shirt-and-shorts-configuration.service';
import { IPaginationResponse, IProductListResponse } from '@shared/interfaces';

@Component({
  selector: 'app-module-assignment-add-female-name-and-number-dialog',
  templateUrl: './add-name-and-number-dialog.component.html',
  styleUrls: ['./add-name-and-number-dialog.component.scss']
})
export class AddFemaleNameAndNumberDialogComponent implements OnInit {
  frmNameAndNumberKey: FormGroup;
  orgId: string;
  rowdata: any;
  options: any[] = [];
  filteredOptions: Observable<string[]>;
  isProductSelected = false;
  searching = false;
  nameSKU: string;
  numberSKU: string;
  @Input() skuDataForNumber: any[];
  @Input() skuDataForName: any[];
  nameAndNumbersColumns: any[] = ['SKU_ID', 'SKU_Name', 'action'];

  pageSizeOptions: number[] = [5, 10, 25, 50, 100];

  skuTableDataForName: MatTableDataSource<any>;
  skuTableDataForNumber: MatTableDataSource<any>;

  @ViewChild('paginatorForName', { static: true }) paginatorForName: MatPaginator;
  @ViewChild('paginatorForNumber', { static: true }) paginatorForNumber: MatPaginator;
  constructor(
    private formBuilder: FormBuilder,
    private femaleShirtAndShortsService: FemaleShirtAndShortsService,
    public dialogRef: MatDialogRef<AddFemaleNameAndNumberDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.orgId = data.orgId;
    this.rowdata = data.rowdata;
  }

  ngOnInit() {
    this.initForm();
    this.skuDataForNumber = [];
    this.skuDataForName = [];
    this.skuTableDataForName = new MatTableDataSource([]);
    this.skuTableDataForNumber = new MatTableDataSource([]);
  }

  initForm() {
    this.frmNameAndNumberKey = this.formBuilder.group({
      filterBySkuCtrl: [''],
      skuByIdForName: [''],
      skuByNameForName: [''],
      skuByIdForNumber: [''],
      skuByNameForNumber: [''],
      skuRadioForName: [''],
      skuRadioForNumber: [''],
      nameSKU: [
        this.rowdata && this.rowdata.name_SKU_selection ? this.rowdata.name_SKU_selection : ''
      ],
      numberSKU: [
        this.rowdata && this.rowdata.number_SKU_selection ? this.rowdata.number_SKU_selection : ''
      ]
    });
    if (this.rowdata.disable_for === 'name') {
      this.frmNameAndNumberKey.get('numberSKU').setValidators(Validators.required);
    } else if (this.rowdata.disable_for === 'number') {
      this.frmNameAndNumberKey.get('nameSKU').setValidators(Validators.required);
    } else {
      this.frmNameAndNumberKey.get('nameSKU').setValidators(Validators.required);
      this.frmNameAndNumberKey.get('numberSKU').setValidators(Validators.required);
    }
    this.getSkuDataPreFilledNameNumber();
  }
  filterChange() {
    this.frmNameAndNumberKey.get('filterBySkuCtrl').setValue('');
  }

  makeRowSelected(skuDataFor: string) {
    const formValue = this.frmNameAndNumberKey.getRawValue();
    if (skuDataFor == 'skuDataForName') {
      if (formValue.skuRadioForName && formValue.skuRadioForName.SKU) {
        this.skuDataForName.forEach((skuData: IProductListResponse) => {
          if (skuData.SKU == formValue.skuRadioForName.SKU) {
            skuData.isChecked = true;
          }
        });
      }
    }
    if (skuDataFor == 'skuDataForNumber') {
      if (formValue.skuRadioForNumber && formValue.skuRadioForNumber.SKU) {
        this.skuDataForNumber.forEach((skuData: IProductListResponse) => {
          if (skuData.SKU == formValue.skuRadioForNumber.SKU) {
            skuData.isChecked = true;
          }
        });
      }
    }
  }
  getSkuDataPreFilledNameNumber() {
    const formValue = this.frmNameAndNumberKey.getRawValue();

    if (formValue.nameSKU && formValue.nameSKU != '') {
      this.femaleShirtAndShortsService
        .getProductList(this.orgId, formValue.nameSKU)
        .subscribe((result: IPaginationResponse<IProductListResponse[]>) => {
          this.searching = false;
          this.skuDataForName = result.result;
          this.skuDataForName = this.skuDataForName.map((skuData) => ({
            isChecked: true,
            ...skuData
          }));
          this.skuDataForName = this.skuDataForName.filter((skuData) => {
            return skuData.SKU == formValue.nameSKU;
          });
          // doing this because there is multiple data coming for same sku
          this.skuTableDataForName = new MatTableDataSource(this.skuDataForName);
          this.skuTableDataForName.paginator = this.paginatorForName;
        });
    }
    if (formValue.numberSKU && formValue.numberSKU != '') {
      this.femaleShirtAndShortsService
        .getProductList(this.orgId, formValue.numberSKU)
        .subscribe((result: IPaginationResponse<IProductListResponse[]>) => {
          this.searching = false;
          this.skuDataForNumber = result.result;
          this.skuDataForNumber = this.skuDataForNumber.map((skuData) => ({
            isChecked: true,
            ...skuData
          }));
          this.skuDataForNumber = this.skuDataForNumber.filter((skuData) => {
            return skuData.SKU == formValue.numberSKU;
          });
          // doing this because there is multiple data coming for same sku
          this.skuTableDataForNumber = new MatTableDataSource(this.skuDataForNumber);
          this.skuTableDataForNumber.paginator = this.paginatorForNumber;
        });
    }
  }

  setNameAndNumberData(skuId: string, skuName: string, skuFor: string) {
    this.femaleShirtAndShortsService
      .getProductList(this.orgId, skuId, skuName)
      .subscribe((result: IPaginationResponse<IProductListResponse[]>) => {
        switch (skuFor) {
          case 'NUMBER': {
            this.searching = false;
            this.skuDataForNumber = result.result;
            this.makeRowSelected('skuDataForNumber');
            this.skuTableDataForNumber = new MatTableDataSource(this.skuDataForNumber);

            this.skuTableDataForNumber.paginator = this.paginatorForNumber;
            break;
          }

          case 'NAME': {
            this.searching = false;
            this.skuDataForName = result.result;
            this.makeRowSelected('skuDataForName');
            this.skuTableDataForName = new MatTableDataSource(this.skuDataForName);

            this.skuTableDataForName.paginator = this.paginatorForName;
            break;
          }
        }
      });
  }
  fetchProducts() {
    this.searching = true;
    if (
      (this.frmNameAndNumberKey.get('skuByIdForName').value &&
        this.frmNameAndNumberKey.get('skuByIdForName').value.length >= 3) ||
      (this.frmNameAndNumberKey.get('skuByNameForName').value &&
        this.frmNameAndNumberKey.get('skuByNameForName').value.length >= 2)
    ) {
      this.setNameAndNumberData(
        this.frmNameAndNumberKey.get('skuByIdForName').value,
        this.frmNameAndNumberKey.get('skuByNameForName').value,
        'NAME'
      );
    }
    if (
      (this.frmNameAndNumberKey.get('skuByIdForNumber').value &&
        this.frmNameAndNumberKey.get('skuByIdForNumber').value.length >= 3) ||
      (this.frmNameAndNumberKey.get('skuByNameForNumber').value &&
        this.frmNameAndNumberKey.get('skuByNameForNumber').value.length >= 2)
    ) {
      this.setNameAndNumberData(
        this.frmNameAndNumberKey.get('skuByIdForNumber').value,
        this.frmNameAndNumberKey.get('skuByNameForNumber').value,
        'NUMBER'
      );
    }
  }

  onSearchInput(e) {
    this.isProductSelected = false;
    this.fetchProducts();
    if (!e.target.value) {
      this.options = [];
    }
  }
  selectSKUForName(element) {
    this.frmNameAndNumberKey.get('nameSKU').setValue(element.SKU);
  }
  selectSKUForNumber(element) {
    this.frmNameAndNumberKey.get('numberSKU').setValue(element.SKU);
  }
  onSave() {
    const formValue = this.frmNameAndNumberKey.getRawValue();
    const data = {
      SKU: formValue.skuRadioForNumber,
      nameSKU: formValue.nameSKU,
      numberSKU: formValue.numberSKU
    };

    this.dialogRef.close(data);
  }

  onCancel() {
    this.dialogRef.close();
  }
}
