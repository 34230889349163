import { PERMISSION } from '../../auth/has-permission/has-permission-directive';
import { NodeLevelType, NodeType } from '@shared/interfaces';

export const USER_ROUTES_BASE_PATH = 'users';

export const USER_ROUTES_NAME = {
  LIST: {
    basePath: '/users',
    path: '',
    name: 'user_sub_menu.user_list',
    header: 'User List',
    visible: true,
    expectedPermission: [PERMISSION.LIST_USERS]
  },
  CREATE: {
    basePath: '/user',
    path: 'create',
    name: 'user_sub_menu.user_add',
    visible: true,
    expectedPermission: [PERMISSION.CREATE_USER]
  },
  USER_IMPORT: {
    basePath: '/user',
    path: 'import',
    name: 'user_sub_menu.user_import',
    visible: true,
    expectedPermission: [PERMISSION.SHOW_BULK_USER_IMPORT_SUBMENU],
    expectedLevel: NodeType.GLOBAL,
    expectedLevelType: NodeLevelType.NOT_EQUAL
  },
  VIEW: {
    basePath: '/user',
    path: ':id/view',
    name: 'user_sub_menu.user_view',
    visible: false,
    expectedPermission: [PERMISSION.VIEW_USER]
  },
  EDIT: {
    path: ':id/edit',
    name: 'user_sub_menu.user_edit',
    visible: false,
    expectedPermission: [PERMISSION.EDIT_USER]
  }
};
