import { Injectable } from '@angular/core';
import { IApiResponse } from '@shared/interfaces';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService, PATH } from 'src/app/core/api/api.service';
import { User } from 'src/app/feature/users/state/user.model';
import { UserLogin, UserLoginResponse } from './state/auth.model';

@Injectable()
export class AuthService {
  redirectUrl = '/';
  forcePassworResetRedirect = 'reset-password';
  constructor(private apiService: ApiService) {}

  login(userLogin: UserLogin): Observable<UserLoginResponse> {
    return this.apiService.post(PATH.POST_LOGIN, userLogin).pipe(map((r) => r.result));
  }

  user(userId: string): Observable<User> {
    return this.apiService.get(PATH.GET_USER(userId)).pipe(map((r) => r.result));
  }

  logout(): Observable<IApiResponse> {
    return this.apiService.put(PATH.LOGOUT, {});
  }
}
