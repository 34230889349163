import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CommonConfigFacade } from '@shared/common-config/state/common-config.facade';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-shortdate-dropdown',
  templateUrl: './shortdate-dropdown.component.html',
  styleUrls: ['./shortdate-dropdown.component.scss']
})
export class ShortdateDropdownComponent implements OnInit, OnDestroy {
  @Input() ctrlShortDate: FormControl;
  @Input() placeholder: string;
  @Input() defaultShortDate;
  @Input() isOverriden: false;
  shortDateList;
  subscriptions: Subscription[] = [];

  constructor(private readonly commonFacade: CommonConfigFacade) {}

  ngOnInit() {
    this.subscriptions.push(
      this.commonFacade.shortDateFormats$.subscribe((data) => {
        this.shortDateList = data;
        if (this.defaultShortDate && this.defaultShortDate.id) {
          this.ctrlShortDate.setValue(
            this.shortDateList.find((s) => s.id === this.defaultShortDate.id)
          );
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }
}
