import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslationLoaderService } from '@services/translation-loader.service';
import { APP_ROUTES } from './app.routes';
import en from './i18n/en.json';
import zhcn from './i18n/zh-CN.json';
import fr from './i18n/fr.json';
import nl from './i18n/nl.json';
import jajp from './i18n/ja-JP.json';
import it from './i18n/it.json';
import de from './i18n/de.json';

@NgModule({
  imports: [RouterModule.forRoot(APP_ROUTES)],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor(private translateService: TranslationLoaderService) {
    this.translateService.loadTranslations(fr);
    this.translateService.loadTranslations(en);
    this.translateService.loadTranslations(zhcn);
    this.translateService.loadTranslations(nl);
    this.translateService.loadTranslations(jajp);
    this.translateService.loadTranslations(it);
    this.translateService.loadTranslations(de);
  }

  // {
  //   enableTracing: environment.environmentName === 'development' ? true : false
  // }
}
