import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { AddMaleShirtAndShortDialogComponent } from './add-maleShirt-And-Shorts-dialog/add-maleShirt-And-Shorts-dialog.component';
import { AddNameAndNumberDialogComponent } from './add-name-and-number-dialog/add-name-and-number-dialog.component';

@Component({
  selector: 'app-module-assignment-male-shirt-and-shorts-configuration',
  templateUrl: './male-shirt-and-shorts-configuration.component.html',
  styleUrls: ['./male-shirt-and-shorts-configuration.component.scss']
})
export class MaleShirtAndShortsConfigurationComponent implements OnInit, OnChanges {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('input', { static: false }) input: ElementRef;
  @Input() status: boolean;
  @Input() maleBadges: any[];
  @Input() modelSKU: any[];
  @Input() action: string;
  @Input() nameAndNumbers: any[];
  @Input() orgId: string;
  @Output() updateData2: EventEmitter<any> = new EventEmitter();
  frmHotKeys: FormGroup;
  productColumns: any[] = ['ADULY_SHIRT_BADGE', 'SKU_selection', 'badgeUrl', 'logoUrl', 'action'];

  nameAndNumbersColumns: any[] = [
    'ADULT_SHIRT_HOME_NAME_AND_NUMBER',
    'name_SKU_selection',
    'number_SKU_selection',
    'action'
  ];
  maleShirtAndShortBadges: MatTableDataSource<any> = new MatTableDataSource([]);
  nameAndNumberDataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  pageSizeOptions: number[] = [5, 10, 25, 50, 100];

  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.orgId = this.orgId ? `NODE::${this.orgId}` : '';
    this.initForm();
  }

  ngOnChanges() {
    this.initForm();
    this.maleShirtAndShortBadges.data = [...this.maleBadges];
    this.nameAndNumberDataSource.data = [...this.nameAndNumbers];
    this.updateData2.emit({
      status: this.status,
      maleNameAndNumbers: this.nameAndNumbers,
      maleBadges: this.maleBadges
    });
  }

  initForm() {
    this.maleBadges = [
      {
        id: 'ADULY_SHIRT_PREMIER_BADGE',
        value: 'ADULT_SHIRT_BADGE',
        ADULY_SHIRT_BADGE: 'ADULT SHIRT PREMIER LEAGUE',
        SKU_selection: '',
        badgeUrl: '',
        logoUrl: ''
      },
      {
        id: 'ADULY_SHIRT_EUROPA_BADGE',
        value: 'ADULT_SHIRT_BADGE',
        ADULY_SHIRT_BADGE: 'ADULT SHIRT EUROPA LEAGUE',
        SKU_selection: '',
        badgeUrl: '',
        logoUrl: ''
      },
      {
        id: 'ADULY_SHIRT_CHAMPIONS_BADGE',
        value: 'ADULT_SHIRT_BADGE',
        ADULY_SHIRT_BADGE: 'ADULT SHIRT CHAMPIONS LEAGUE',
        SKU_selection: '',
        badgeUrl: '',
        logoUrl: ''
      },
      {
        id: 'KID_SHIRT_PREMIER_BADGE',
        value: 'KID_SHIRT_BADGE',
        ADULY_SHIRT_BADGE: 'KID SHIRT PREMIER LEAGUE',
        SKU_selection: '',
        badgeUrl: '',
        logoUrl: ''
      },
      {
        id: 'KID_SHIRT_EUROPA_BADGE',
        value: 'KID_SHIRT_BADGE',
        ADULY_SHIRT_BADGE: 'KID SHIRT EUROPA LEAGUE',
        SKU_selection: '',
        badgeUrl: '',
        logoUrl: ''
      },
      {
        id: 'KID_SHIRT_CHAMPIONS_BADGE',
        value: 'KID_SHIRT_BADGE',
        ADULY_SHIRT_BADGE: 'KID SHIRT CHAMPIONS LEAGUE',
        SKU_selection: '',
        badgeUrl: '',
        logoUrl: ''
      },
      {
        id: 'ADULT_SHORTS_PREMIER_BADGE',
        value: 'ADULT_SHORTS_BADGE',
        ADULY_SHIRT_BADGE: 'ADULT SHORTS PREMIER LEAGUE',
        SKU_selection: '',
        badgeUrl: '',
        logoUrl: ''
      },
      {
        id: 'ADULT_SHORTS_EUROPA_BADGE',
        value: 'ADULT_SHORTS_BADGE',
        ADULY_SHIRT_BADGE: 'ADULT SHORTS EUROPA LEAGUE',
        SKU_selection: '',
        badgeUrl: '',
        logoUrl: ''
      },
      {
        id: 'ADULT_SHORTS_CHAMPIONS_BADGE',
        value: 'ADULT_SHORTS_BADGE',
        ADULY_SHIRT_BADGE: 'ADULT SHORTS CHAMPIONS LEAGUE',
        SKU_selection: '',
        badgeUrl: '',
        logoUrl: ''
      },
      {
        id: 'KID_SHORTS_PREMIER_BADGE',
        value: 'KID_SHORTS_BADGE',
        ADULY_SHIRT_BADGE: 'KID SHORTS PREMIER LEAGUE',
        SKU_selection: '',
        badgeUrl: '',
        logoUrl: ''
      },
      {
        id: 'KID_SHORTS_EUROPA_BADGE',
        value: 'KID_SHORTS_BADGE',
        ADULY_SHIRT_BADGE: 'KID SHORTS EUROPA LEAGUE',
        SKU_selection: '',
        badgeUrl: '',
        logoUrl: ''
      },
      {
        id: 'KID_SHORTS_CHAMPIONS_BADGE',
        value: 'KID_SHORTS_BADGE',
        ADULY_SHIRT_BADGE: 'KID SHORTS CHAMPIONS LEAGUE',
        SKU_selection: '',
        badgeUrl: '',
        logoUrl: ''
      },
      {
        value: 'FEMALE_SHIRT_BADGE',
        ADULY_SHIRT_BADGE: 'FEMALE SHIRT BADGE BUNDLE',
        SKU_selection: '',
        badgeUrl: '',
        id: 'FEMALE_SHIRT_BADGE_BUNDLE',
        logoUrl: ''
      },
      {
        id: 'LARGE_PREMIER_LEAGUE_PATCH',
        value: 'MALE_SHIRT_BADGE',
        ADULY_SHIRT_BADGE: 'LARGE PREMIER LEAGUE PATCH',
        SKU_selection: '',
        badgeUrl: '',
        logoUrl: ''
      },
      {
        id: 'LARGE_CHAMPIONS_LEAGUE_PATCH',
        value: 'MALE_SHIRT_BADGE',
        ADULY_SHIRT_BADGE: 'LARGE CHAMPIONS LEAGUE PATCH',
        SKU_selection: '',
        badgeUrl: '',
        logoUrl: ''
      },
      {
        id: 'MINI_PREMIER_LEAGUE_PATCH',
        value: 'MALE_SHIRT_BADGE',
        ADULY_SHIRT_BADGE: 'MINI PREMIER LEAGUE PATCH',
        SKU_selection: '',
        badgeUrl: '',
        logoUrl: ''
      },
      {
        id: 'MINI_CHAMPIONS_LEAGUE_PATCH',
        value: 'MALE_SHIRT_BADGE',
        ADULY_SHIRT_BADGE: 'MINI CHAMPIONS LEAGUE PATCH',
        SKU_selection: '',
        badgeUrl: '',
        logoUrl: ''
      }
    ];
    this.nameAndNumbers = [
      {
        value: 'ADULT_SHIRT_HOME_NAME_AND_NUMBER',
        ADULT_SHIRT_HOME_NAME_AND_NUMBER: 'ADULT SHIRT HOME',
        name_SKU_selection: '',
        number_SKU_selection: '',
        id: 'ADULT_SHIRT_HOME',
        disable_for: ''
      },
      {
        value: 'ADULT_SHIRT_AWAY_NAME_AND_NUMBER',
        ADULT_SHIRT_HOME_NAME_AND_NUMBER: 'ADULT SHIRT AWAY',
        name_SKU_selection: '',
        number_SKU_selection: '',
        id: 'ADULT_SHIRT_AWAY',
        disable_for: ''
      },
      {
        value: 'ADULT_SHIRT_THIRD_NAME_AND_NUMBER',
        ADULT_SHIRT_HOME_NAME_AND_NUMBER: 'ADULT SHIRT THIRD',
        name_SKU_selection: '',
        number_SKU_selection: '',
        id: 'ADULT_SHIRT_THIRD',
        disable_for: ''
      },
      {
        value: 'KID_SHIRT_HOME_NAME_AND_NUMBER',
        ADULT_SHIRT_HOME_NAME_AND_NUMBER: 'KID SHIRT HOME',
        name_SKU_selection: '',
        number_SKU_selection: '',
        id: 'KID_SHIRT_HOME',
        disable_for: ''
      },
      {
        value: 'KID_SHIRT_AWAY_NAME_AND_NUMBER',
        ADULT_SHIRT_HOME_NAME_AND_NUMBER: 'KID SHIRT AWAY',
        name_SKU_selection: '',
        number_SKU_selection: '',
        id: 'KID_SHIRT_AWAY',
        disable_for: ''
      },
      {
        value: 'KID_SHIRT_THIRD_NAME_AND_NUMBER',
        ADULT_SHIRT_HOME_NAME_AND_NUMBER: 'KID SHIRT THIRD',
        name_SKU_selection: '',
        number_SKU_selection: '',
        id: 'KID_SHIRT_THIRD',
        disable_for: ''
      },
      {
        value: 'ADULT_SHORTS_HOME_NUMBER',
        ADULT_SHIRT_HOME_NAME_AND_NUMBER: 'ADULT SHORTS HOME',
        name_SKU_selection: '',
        number_SKU_selection: '',
        id: 'ADULT_SHORTS_HOME',
        disable_for: 'name'
      },
      {
        value: 'ADULT_SHORTS_AWAY_NUMBER',
        ADULT_SHIRT_HOME_NAME_AND_NUMBER: 'ADULT SHORTS AWAY',
        name_SKU_selection: '',
        number_SKU_selection: '',
        id: 'ADULT_SHORTS_AWAY',
        disable_for: 'name'
      },
      {
        value: 'ADULT_SHORTS_THIRD_NUMBER',
        ADULT_SHIRT_HOME_NAME_AND_NUMBER: 'ADULT SHORTS THIRD',
        name_SKU_selection: '',
        number_SKU_selection: '',
        id: 'ADULT_SHORTS_THIRD',
        disable_for: 'name'
      },
      {
        value: 'KID_SHORTS_HOME_NUMBER',
        ADULT_SHIRT_HOME_NAME_AND_NUMBER: 'KID SHORTS HOME',
        name_SKU_selection: '',
        number_SKU_selection: '',
        id: 'KID_SHORTS_HOME',
        disable_for: 'name'
      },
      {
        value: 'KID_SHORTS_AWAY_NUMBER',
        ADULT_SHIRT_HOME_NAME_AND_NUMBER: 'KID SHORTS AWAY',
        name_SKU_selection: '',
        number_SKU_selection: '',
        id: 'KID_SHORTS_AWAY',
        disable_for: 'name'
      },
      {
        value: 'KID_SHORTS_THIRD_NUMBER',
        ADULT_SHIRT_HOME_NAME_AND_NUMBER: 'KID SHORTS THIRD',
        name_SKU_selection: '',
        number_SKU_selection: '',
        id: 'KID_SHORTS_THIRD',
        disable_for: 'name'
      },
      {
        value: 'ADULT_SHIRT_RETRO_NAME',
        ADULT_SHIRT_HOME_NAME_AND_NUMBER: 'ADULT SHIRT RETRO',
        name_SKU_selection: '',
        number_SKU_selection: '',
        id: 'ADULT_SHIRT_RETRO',
        disable_for: 'number'
      },
      {
        value: 'KID_SHIRT_RETRO_NAME',
        ADULT_SHIRT_HOME_NAME_AND_NUMBER: 'KID SHIRT RETRO',
        name_SKU_selection: '',
        number_SKU_selection: '',
        id: 'KID_SHIRT_RETRO',
        disable_for: 'number'
      }
    ];
    console.log('maleComponent ', this.modelSKU);

    this.modelSKU.forEach((item: any) => {
      if (item.type === 'BADGE' && item.gender === 'MALE') {
        this.maleBadges = this.maleBadges.map((x: any) => {
          let isVat = {};
          let sku_selection = '';
          item.SKU.map((ele) => {
            if (ele.isVat) {
              isVat[`${ele.SKU}`] = true;
              if (sku_selection.length > 0) {
                sku_selection += `${ele.SKU}`;
              } else {
                sku_selection += `${ele.SKU},`;
              }
            } else {
              isVat[`${ele.SKU}`] = false;
              if (sku_selection.length > 0) {
                sku_selection += `${ele.SKU}`;
              } else {
                sku_selection += `${ele.SKU},`;
              }
            }
          });
          if (x.id === item.id) {
            x.SKU_selection = sku_selection;
            x.badgeUrl = item.SKU[0] ? item.SKU[0].badgeUrl : '';
            x.logoUrl = item.SKU[0] ? item.SKU[0].logoUrl : '';
            x.isVat = isVat;
          }
          return x;
        });
      }
      if (item.type === 'NAME_AND_NUMBER' && item.gender === 'MALE') {
        this.nameAndNumbers = this.nameAndNumbers.map((x: any) => {
          if (x.id === item.id) {
            x.name_SKU_selection = item.SKU.filter((y) => y.type === 'name')[0]
              ? item.SKU.filter((y) => y.type === 'name')[0].SKU
              : '';
            x.number_SKU_selection = item.SKU.filter((y) => y.type === 'number')[0]
              ? item.SKU.filter((y) => y.type === 'number')[0].SKU
              : '';
          }
          return x;
        });
      }
    });

    this.maleShirtAndShortBadges.data = [...this.maleBadges];
    this.nameAndNumberDataSource.data = [...this.nameAndNumbers];
    this.frmHotKeys = this.formBuilder.group({
      ebasketSalesSetupHotkeys: [false, []],
      maleBadges: this.formBuilder.array([]),
      searchInput: ['']
    });
  }

  toggleEbasketManShirtConfiguration() {
    if (!this.status) {
      this.maleBadges = [];
      this.maleShirtAndShortBadges.data = [];
      this.nameAndNumbers = [];
    } else {
      this.initForm();
    }
    this.updateData2.emit({
      status: this.status,
      maleNameAndNumbers: this.nameAndNumbers,
      maleBadges: this.maleBadges
    });
  }

  removeRow(ADULY_SHIRT_BADGE) {
    this.maleBadges = this.maleBadges.map((p) => {
      if (p.ADULY_SHIRT_BADGE === ADULY_SHIRT_BADGE) {
        p.SKU_selection = '';
        p.badgeUrl = '';
        p.logoUrl = '';
      }
      return p;
    });
    this.updateData2.emit({
      status: this.status,
      maleNameAndNumbers: this.nameAndNumbers,
      maleBadges: this.maleBadges
    });
    this.maleShirtAndShortBadges.data = [...this.maleBadges];
  }

  removeNameAndNumberRow(element) {
    this.nameAndNumbers = this.nameAndNumbers.map((p) => {
      if (p.ADULT_SHIRT_HOME_NAME_AND_NUMBER === element.ADULT_SHIRT_HOME_NAME_AND_NUMBER) {
        p.SKU_selection = '';
        p.name_SKU_selection = '';
        p.number_SKU_selection = '';
      }
      return p;
    });
    this.updateData2.emit({
      status: this.status,
      maleNameAndNumbers: this.nameAndNumbers,
      maleBadges: this.maleBadges
    });
    this.nameAndNumberDataSource.data = [...this.nameAndNumbers];
  }

  openShirtBadgeDialog(rowdata) {
    const dialogRef = this.dialog.open(AddMaleShirtAndShortDialogComponent, {
      width: '50%',
      data: { orgId: this.orgId, rowdata }
    });
    dialogRef.afterClosed().subscribe((row) => {
      if (row) {
        this.maleBadges = this.maleBadges.map((p) => {
          let isVat = {};
          if (rowdata && p.ADULY_SHIRT_BADGE === rowdata.ADULY_SHIRT_BADGE) {
            if (row.SKU) {
              if (typeof row.SKU === 'string') {
                p.SKU_selection = row.SKU;
              }
              if (typeof row.SKU === 'object') {
                let sku_selection = '';
                row.SKU.map((ele) => {
                  if (ele.isVat) {
                    isVat[`${ele.SKU}`] = true;
                    if (sku_selection.length > 0) {
                      sku_selection += `${ele.SKU}`;
                    } else {
                      sku_selection += `${ele.SKU},`;
                    }
                  } else {
                    isVat[`${ele.SKU}`] = false;
                    if (sku_selection.length > 0) {
                      sku_selection += `${ele.SKU}`;
                    } else {
                      sku_selection += `${ele.SKU},`;
                    }
                  }
                });
                p.SKU_selection = sku_selection;
              }
            }
            p.badgeUrl = row.badgeURL;
            p.logoUrl = row.logoURL;
            p.isVat = isVat;
          }
          return p;
        });
        this.maleBadges = [...this.maleBadges];
        this.maleShirtAndShortBadges.data = [...this.maleBadges];
        this.updateData2.emit({
          status: this.status,
          maleNameAndNumbers: this.nameAndNumbers,
          maleBadges: this.maleBadges
        });
      }
    });
  }

  displayName(element) {
    let display = '';
    if (element && element.SKU_selection) {
      let temp = element.SKU_selection.split(',');
      temp.map((el) => {
        if (el.length > 0) {
          if (display.length > 0) {
            display += `,${el}(${element.isVat[el] ? 'VAT' : 'NON-VAT'})`;
          } else {
            display += `${el}(${element.isVat[el] ? 'VAT' : 'NON-VAT'})`;
          }
        }
      });
    }
    return display;
  }

  openAddNameAndNumberDialog(rowdata) {
    const dialogRef = this.dialog.open(AddNameAndNumberDialogComponent, {
      width: '50%',
      data: { orgId: this.orgId, rowdata }
    });
    dialogRef.afterClosed().subscribe((row) => {
      if (row) {
        this.nameAndNumbers = this.nameAndNumbers.map((p) => {
          if (
            rowdata &&
            p.ADULT_SHIRT_HOME_NAME_AND_NUMBER === rowdata.ADULT_SHIRT_HOME_NAME_AND_NUMBER
          ) {
            p.SKU_selection = row.SKU.SKU;
            p.name_SKU_selection = row.nameSKU;
            p.number_SKU_selection = row.numberSKU;
          }
          return p;
        });

        this.nameAndNumbers = [...this.nameAndNumbers];
        this.nameAndNumberDataSource.data = [...this.nameAndNumbers];
        this.updateData2.emit({
          status: this.status,
          maleNameAndNumbers: this.nameAndNumbers,
          maleBadges: this.maleBadges
        });
      }
    });
  }
}
