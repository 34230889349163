import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material';
import {
  EbasketApiMethodTypes,
  EbasketInventoryParasparSelectApi,
  EbasketInventorySelectApi,
  EbasketSalesExportSelectApi,
  Providers
} from '@shared/interfaces';
import { SelectApiPopupComponent } from '@shared/select-api-popup/select-api-popup.component';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'paraspar-provider-api-details-settings',
  templateUrl: './paraspar-provider-details-settings.component.html',
  styleUrls: ['./paraspar-provider-details-settings.component.scss']
})
export class ParasparApiDetailsSettingsComponent implements OnInit {
  @Input() action: string;
  @Input() parentForm: FormGroup;
  @Input() ebasketInventoryparasparApiDetails: any;
  @Input() authControlKey: string;
  @Input() provider: string;
  @Input() inventory: boolean;
  // @Output() updateApiData: EventEmitter<any> = new EventEmitter();
  apiDetails: FormGroup;
  isEBasketInventoryAddDisabled = false;
  t;
  defaultSignatureMethod = 'HMAC-SHA256';
  ebasket_Inventory_select_api = [];

  method_types = [
    { id: 1, name: EbasketApiMethodTypes.Get, value: EbasketApiMethodTypes.Get },
    { id: 2, name: EbasketApiMethodTypes.Post, value: EbasketApiMethodTypes.Post },
    { id: 3, name: EbasketApiMethodTypes.Put, value: EbasketApiMethodTypes.Put },
    { id: 4, name: EbasketApiMethodTypes.Patch, value: EbasketApiMethodTypes.Patch },
    { id: 5, name: EbasketApiMethodTypes.Delete, value: EbasketApiMethodTypes.Delete }
  ];

  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    if (this.inventory) {
      this.ebasket_Inventory_select_api = [
        {
          id: 1,
          name: EbasketInventorySelectApi.StockEnquiry,
          value: EbasketInventorySelectApi.StockEnquiry,
          isDisabled: false
        },
        {
          id: 2,
          name: EbasketInventoryParasparSelectApi.ShippingEnquiry,
          value: EbasketInventoryParasparSelectApi.ShippingEnquiry,
          isDisabled: false
        }
      ];
    } else {
      this.ebasket_Inventory_select_api = [
        {
          id: 1,
          name: EbasketSalesExportSelectApi.ProcessOrders,
          value: EbasketSalesExportSelectApi.ProcessOrders,
          isDisabled: false
        }
      ];
    }
    this.initForm();
    this.bindEbasketInventoryApiDetailsFormGroup();
  }
  initForm() {
    this.parentForm.addControl(
      this.authControlKey,
      this.formBuilder.group({
        ebasketInventoryApiKey: [
          {
            value:
              this.ebasketInventoryparasparApiDetails &&
              this.ebasketInventoryparasparApiDetails.apiDetails
                ? Providers.SECRET
                : '',
            disabled: this.action === 'view' ? true : false
          },
          [Validators.required]
        ],
        ebasketInventoryApiDetails: this.formBuilder.array([])
      })
    );
  }

  bindEbasketInventoryApiDetailsFormGroup() {
    const control = this.parentForm
      .get(this.authControlKey)
      .get('ebasketInventoryApiDetails') as FormArray;
    if (
      this.ebasketInventoryparasparApiDetails &&
      this.ebasketInventoryparasparApiDetails.apiDetails
    ) {
      this.ebasketInventoryparasparApiDetails.apiDetails.map((apis: any) => {
        let apiDetails = {};
        if (this.inventory) {
          apiDetails = {
            url: [
              {
                value: apis.url,
                disabled: this.action === 'view' ? true : false
              },
              Validators.required
            ],
            method: [
              {
                value: apis.method,
                disabled: this.action === 'view' ? true : false
              },
              Validators.required
            ],
            selectedApi: [apis.selectedApi]
          };
        } else {
          apiDetails = {
            url: [
              {
                value: apis.url,
                disabled: this.action === 'view' ? true : false
              },
              Validators.required
            ],
            method: [
              {
                value: apis.method,
                disabled: this.action === 'view' ? true : false
              },
              Validators.required
            ],
            deploy: [
              {
                value: apis.deploy,
                disabled: this.action === 'view' ? true : false
              },
              Validators.required
            ],
            script: [
              {
                value: apis.script,
                disabled: this.action === 'view' ? true : false
              },
              Validators.required
            ],
            selectedApi: [apis.selectedApi]
          };
        }
        control.push(this.formBuilder.group(apiDetails));
        this.ebasket_Inventory_select_api.map((key) => {
          if (key.value === apis.selectedApi) {
            key.isDisabled = true;
          }
        });
      });
      if (this.ebasket_Inventory_select_api.length === control.length) {
        this.isEBasketInventoryAddDisabled = true;
      }
    }
  }
  openEbasketInventorySelectApiPopup() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = this.ebasket_Inventory_select_api.filter((api) => !api.isDisabled);
    const dialogRef = this.dialog.open(SelectApiPopupComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result !== '') {
        this.ebasket_Inventory_select_api.map((element: any) => {
          if (element.value === result) {
            element.isDisabled = true;
            // this.tm_gift_card_api_details.push(element);
            const ebasketInventoryApiDetails = this.parentForm
              .get(this.authControlKey)
              .get('ebasketInventoryApiDetails') as FormArray;

            ebasketInventoryApiDetails.push(this.createInventoryApiDetailsFormGroup(result));
            if (this.ebasket_Inventory_select_api.length === ebasketInventoryApiDetails.length) {
              this.isEBasketInventoryAddDisabled = true;
            }
          }
        });
      }
    });
  }

  private createInventoryApiDetailsFormGroup(selectedApi: string): FormGroup {
    if (this.inventory) {
      return new FormGroup({
        url: new FormControl('', Validators.required),
        method: new FormControl('', Validators.required),
        selectedApi: new FormControl(selectedApi)
      });
    } else {
      return new FormGroup({
        url: new FormControl('', Validators.required),
        method: new FormControl('', Validators.required),
        deploy: new FormControl('', Validators.required),
        script: new FormControl('', Validators.required),
        selectedApi: new FormControl(selectedApi)
      });
    }
  }
  isRequired(control: string) {
    let isRequired = false;
    const formControlObject = this.parentForm.get(this.authControlKey).get(control);
    if (formControlObject !== null) {
      if (formControlObject.validator !== null) {
        if (formControlObject.validator({} as AbstractControl) !== null) {
          isRequired = formControlObject.validator({} as AbstractControl).required ? true : false;
        }
      }
    }
    return isRequired ? true : false;
  }
  deleteEbasketInventoryApiDetailContainer(index: number) {
    const ebasketInventoryApiDetails = this.parentForm
      .get(this.authControlKey)
      .get('ebasketInventoryApiDetails') as FormArray;
    this.ebasket_Inventory_select_api.map((element: any) => {
      if (element.value === ebasketInventoryApiDetails.value[index].selectedApi) {
        element.isDisabled = false;
        console.log(this.ebasket_Inventory_select_api);
      }
    });
    if (ebasketInventoryApiDetails.length > 0) {
      ebasketInventoryApiDetails.removeAt(index);
    } else {
      ebasketInventoryApiDetails.reset();
    }
    if (this.ebasket_Inventory_select_api.length !== ebasketInventoryApiDetails.length) {
      this.isEBasketInventoryAddDisabled = false;
    }
  }
}
