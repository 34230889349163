import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from '@env/environment';
import { AppModule } from './app/app.module';

if (environment.production) {
  enableProdMode();
}

if (environment.environmentName === 'testing') {
  console.log('RUM Code is running');

  const script = document.createElement('script');
  script.innerHTML = 'window.DD_RUM&&window.DD_RUM.init({clientToken:"pub50364b8e0fdc935fb0ec96f1d98fc216",applicationId:"735f1c22-83b6-47dc-8d92-a528b0cdafd4",site:"datadoghq.eu",service:"cp-om",env:"qa",sessionSampleRate:100,sessionReplaySampleRate:20,trackUserInteractions:!0,trackResources:!0,trackLongTasks:!0,defaultPrivacyLevel:"mask-user-input"});';

  document.head.appendChild(script);
}


platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
