import { Injectable } from '@angular/core';
import { NotificationService } from '@services/notification.service';
import { IApiResponse } from '@shared/interfaces';
import { Observable } from 'rxjs';
import { ApiService, PATH } from 'src/app/core/api/api.service';
import { IResetPasswordReq, IValidateEmailReq, IValidateEmailRes } from './model';

@Injectable()
export class ForgotPasswordService {
  constructor(private readonly apiService: ApiService) {}

  public validateEmailId(req: IValidateEmailReq): Observable<IValidateEmailRes> {
    return this.apiService.post(PATH.VALIDATE_EMAIL_ID, req);
  }

  public sendEmail(userId: string): Observable<IApiResponse> {
    return this.apiService.put(PATH.SEND_EMAIL(userId), {});
  }

  public resetUserPassword(
    resetPasswordReq: IResetPasswordReq,
    resetToken: string
  ): Observable<IApiResponse> {
    return this.apiService.put(PATH.RESET_PASSWORD(resetToken), resetPasswordReq);
  }

  public validateResetToken(resetToken: string): Observable<IApiResponse> {
    return this.apiService.get(PATH.VALIDATE_RESET_TOKEN(resetToken));
  }
}
