import { Pipe, PipeTransform } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IModuleNamesInLower, LogoOptions, ModuleLogos, NodeType } from '@shared/interfaces';
import { LocalStorageJwtService } from 'src/app/feature/auth/local-storage-jwt.service';
import { OrganizationService } from 'src/app/feature/organization/organization.details.service';

@Pipe({
  name: 'moduleDisplayLogo'
})
export class ModuleDisplayLogoPipe implements PipeTransform {
  private result: string | undefined;

  constructor(
    private readonly localStorageJwtService: LocalStorageJwtService,
    private organizationService: OrganizationService,
    private activatedRoute: ActivatedRoute
  ) {}

  transform(value: string): any {
    const hostname = window.location.origin;
    const baseUrl = `${hostname}/assets/images/`;
    value = value.replace(/-/g, '').toLowerCase();
    const token = this.localStorageJwtService.getDecodedItem();
    if (token && token.idToken) {
      const rootNodeId =
        token.idToken['custom:rootNode'] === NodeType.GLOBAL
          ? this.activatedRoute.snapshot.paramMap.get('id')
          : token.idToken['custom:rootNodeId'].replace('NODE::', '');
      if (rootNodeId) {
        return this.organizationService
          .getNodeConfigById(rootNodeId, true)
          .toPromise()
          .then((res: any) => {
            const nodeConfig: any = res.result;

            switch (value) {
              case IModuleNamesInLower.om:
                this.result = baseUrl + ModuleLogos.om;
                break;
              case IModuleNamesInLower.ebasket:
                this.result = baseUrl + ModuleLogos.ebasket;
                if (
                  nodeConfig.eBasketMgmtSettings &&
                  nodeConfig.eBasketMgmtSettings.length &&
                  nodeConfig.eBasketMgmtSettings[0].displaySettings &&
                  nodeConfig.eBasketMgmtSettings[0].displaySettings.displayLogo &&
                  nodeConfig.eBasketMgmtSettings[0].displaySettings.displayLogo ===
                    LogoOptions.Deckers
                ) {
                  this.result = baseUrl + ModuleLogos.ebasketDeckers;
                }
                break;
              case IModuleNamesInLower.rj:
                this.result = baseUrl + ModuleLogos.rj;
                break;
              case IModuleNamesInLower.saga:
                this.result = baseUrl + ModuleLogos.saga;
                break;
              case IModuleNamesInLower.tm:
                this.result = baseUrl + ModuleLogos.tm;
                break;
              case IModuleNamesInLower.t2s:
                this.result = baseUrl + ModuleLogos.t2s;
                break;
              case IModuleNamesInLower.sotk:
                this.result = baseUrl + ModuleLogos.sotk;
              case IModuleNamesInLower.engage:
                this.result = baseUrl + ModuleLogos.engage;
                break;
              default:
                break;
            }
            return this.result;
          });
      }
      return '';
    }
    return Promise.resolve(this.result);
  }
}
