import {
  Component,
  Input,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
  ChangeDetectorRef
} from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormArray,
  FormControl,
  Validators,
  ValidationErrors
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { NotificationService } from '@services/notification.service';
import CONSTANTS, { IP_ADDRESS_PATTERN } from 'src/app/util/Constant';

@Component({
  selector: 'app-module-assignment-engage-settings',
  templateUrl: './engage-settings.component.html',
  styleUrls: ['./engage-settings.component.scss']
})
export class EngageSettingsComponent implements OnInit {
  @ViewChild('titleContainer', { static: true }) public titleContainer: any;
  @Input() action: string;
  @Input() moduleIcon: string;
  @Input() parentForm: FormGroup;
  @Input() engageSettings: any;
  @Input() engageSettingsForm: any;
  @Input() nodeId: any;
  @Input() orgCode: any;
  @Output() imageUploadingEmitter: EventEmitter<any> = new EventEmitter();

  // @Input() engageSettings: string;

  constructor(
    private formBuilder: FormBuilder,
    private notificationService: NotificationService,
    private changeDetection: ChangeDetectorRef
  ) {}
  // isToggleInteract: boolean;
  isToggleInform: boolean;
  isDeviceAdded: boolean = false;
  subscriptions: Subscription[] = [];
  deviceData: any = [];
  hostName: any;
  // interactCtrlOptions: any[] = [
  //   {
  //     id: 1,
  //     name: 'Create Customer'
  //   }
  // ];
  tableCols = [
    { field: 'mediaPath', header: 'Media' },
    { field: 'mediaDuration', header: 'Duration (in seconds)' }
  ];
  tableData: any = [];
  selectedProducts = [];
  tableRowDisabled = [];
  tableTenderData: FormGroup = new FormGroup({});
  paymentTenderSelectedRowData2(event: any) {
    console.log('paymentTenderSelectedRowData2', event);
  }

  handleimageUploadingEmitter(event: any) {
    this.imageUploadingEmitter.emit(event);
  }

  tableRowOrder(event: any) {
    console.log('thsi table', this.tableData);
    this.tableData.forEach((item, index) => {
      item.priority = index + 1;
    });
    this.updateFormValue();
  }

  addtableData() {
    this.tableData.push({
      priority: this.tableData.length + 1,
      mediaType: 'Image',
      mediaDuration: '',
      mediaPath: ''
    });
    this.updateFormValue();
    this.changeDetection.detectChanges();
  }

  handleDelete(event: any) {
    this.tableData = this.tableData.filter((ele) => ele.priority !== event.priority);
    this.updateFormValue();
  }

  handleDurationChange(value: any) {
    this.tableData.map((ele: any) => {
      if (ele.priority === value.priority) {
        ele.mediaDuration = Number(value.mediaDuration);
      }
    });
    this.updateFormValue();
  }

  handleMediaPathChange(value: any) {
    this.tableData.map((ele: any) => {
      if (ele.priority === value.priority) {
        ele.mediaPath = value.mediaPath;
        ele.mediaType = value.mediaType;
      }
    });
    this.updateFormValue();
  }

  ngOnInit() {
    this.hostName = window.location.origin;
    console.log('engageSettings | Line  27 : ', this.engageSettings);
    if (
      this.engageSettings &&
      this.engageSettings.deviceManagementSetting &&
      this.engageSettings.deviceManagementSetting.displayList &&
      this.engageSettings.deviceManagementSetting.displayList.length
    ) {
      this.deviceData = this.engageSettings.deviceManagementSetting.displayList;
      this.isDeviceAdded = true;
    }
    this.parentForm.addControl(
      this.engageSettingsForm,
      this.formBuilder.group({
        // interact: [
        //   {
        //     value:
        //       this.engageSettings &&
        //       this.engageSettings.interactSetting &&
        //       this.engageSettings.interactSetting.status
        //         ? this.engageSettings.interactSetting.status
        //         : false,
        //     disabled: this.action === 'view' ? true : false
        //   }
        // ],
        inform: [
          {
            value:
              this.engageSettings &&
              this.engageSettings.informSetting &&
              this.engageSettings.informSetting.status
                ? this.engageSettings.informSetting.status
                : false,
            disabled: this.action === 'view' ? true : false
          }
        ],
        // interactCtrl: [
        //   {
        //     value: this.interactCtrlOptions ? this.interactCtrlOptions : '',
        //     disabled: this.action === 'view' ? true : false
        //   }
        // ],
        displayList: this.formBuilder.array([]),
        mediaConfig: this.formBuilder.array([])
      })
    );
    console.log('parentForm', this.parentForm);
    this.bindForm();
    // this.isToggleInteract =
    //   this.engageSettings &&
    //   this.engageSettings.interactSetting &&
    //   this.engageSettings.interactSetting.status
    //     ? false
    //     : true;
    this.isToggleInform =
      this.engageSettings &&
      this.engageSettings.informSetting &&
      this.engageSettings.informSetting.status
        ? false
        : true;
    // this.toggleInteract();
    // edit scenario code
    this.subscriptions.push(
      this.parentForm.get(this.engageSettingsForm).valueChanges.subscribe((value) => {
        const invalid = [];
        const controls = (this.parentForm.controls[this.engageSettingsForm] as FormArray).controls;
        for (const name in controls) {
          if (controls[name].invalid) {
            invalid.push(name);
          }
        }
        // if (invalid.length === 0) {
        //   this.campaignService.setCategorySaveBtn(true);
        // } else {
        //   this.campaignService.setCategorySaveBtn(false);
        // }
      })
    );
  }

  // toggleInteract() {
  //   if (this.parentForm.get(this.engageSettingsForm)) {
  //     this.isToggleInteract = this.parentForm.get(this.engageSettingsForm).get('interact').value
  //       ? false
  //       : true;
  //   } else {
  //     this.isToggleInteract = false;
  //   }
  //   console.log('toggle interact after', this.isToggleInteract);
  // }

  toggleInform() {
    if (this.parentForm.get(this.engageSettingsForm)) {
      this.isToggleInform = this.parentForm.get(this.engageSettingsForm).get('inform').value
        ? false
        : true;
    } else {
      this.isToggleInform = false;
    }

    if (this.isToggleInform) {
      this.tableData = [];
    }
    console.log('toggle interact after', this.isToggleInform);
  }

  updateFormValue() {
    const control = this.parentForm.get(this.engageSettingsForm).get('mediaConfig') as FormArray;
    control.clear();
    if (this.tableData && this.tableData.length) {
      this.tableData.forEach((ele: any) => {
        const isVideo = ele.mediaType === 'Video';
        let mediaConfigObj = {
          priority: [
            { value: ele.priority, disabled: this.action === 'view' },
            [Validators.required]
          ],
          mediaPath: [
            { value: ele.mediaPath || '', disabled: this.action === 'view' },
            [Validators.required]
          ],
          mediaDuration: [
            { value: ele.mediaDuration || '', disabled: this.action === 'view' },
            isVideo ? [] : [Validators.required]
          ],
          mediaType: [
            { value: ele.mediaType, disabled: this.action === 'view' },
            [Validators.required]
          ]
        };
        control.push(this.formBuilder.group(mediaConfigObj));
      });
    }
    console.log('control', control);
  }

  bindForm() {
    const control = this.parentForm.get(this.engageSettingsForm).get('displayList') as FormArray;
    control.clear();
    if (
      this.engageSettings &&
      this.engageSettings.deviceManagementSetting &&
      this.engageSettings.deviceManagementSetting.displayList &&
      this.engageSettings.deviceManagementSetting.displayList.length
    ) {
      this.engageSettings.deviceManagementSetting.displayList.forEach((item) => {
        let deviceMgmtSettingsData = {};
        deviceMgmtSettingsData = {
          displayName: [
            { value: item.displayName, disabled: this.action === 'view' },
            [Validators.required, Validators.minLength(5)]
          ],
          ipAddress: [
            { value: item.ipAddress, disabled: this.action === 'view' },
            [Validators.required, Validators.pattern(IP_ADDRESS_PATTERN)]
          ]
        };
        control.push(this.formBuilder.group(deviceMgmtSettingsData));
      });
    }

    const control2 = this.parentForm.get(this.engageSettingsForm).get('mediaConfig') as FormArray;
    control2.clear();
    if (
      this.engageSettings &&
      this.engageSettings.informSetting &&
      this.engageSettings.informSetting.mediaConfig &&
      this.engageSettings.informSetting.mediaConfig.length
    ) {
      this.engageSettings.informSetting.mediaConfig.forEach((item) => {
        const isVideo = item.mediaType === 'Video';
        let informSetting = {};
        informSetting = {
          priority: [
            { value: item.priority, disabled: this.action === 'view' },
            [Validators.required]
          ],
          mediaPath: [
            { value: item.mediaPath, disabled: this.action === 'view' },
            [Validators.required]
          ],
          mediaDuration: [
            { value: item.mediaDuration, disabled: this.action === 'view' },
            isVideo ? [] : [Validators.required]
          ],
          mediaType: [
            { value: item.mediaType, disabled: this.action === 'view' },
            [Validators.required]
          ]
        };
        control2.push(this.formBuilder.group(informSetting));
      });
      if (control2.value) {
        this.tableData = control2.value;
      }
    }
  }
  openScreenConfigurationOption() {
    const panels = this.parentForm.get(this.engageSettingsForm).get('displayList') as FormArray;
    // this.enableUrl[panels.length] = false;
    panels.push(this.createSelectOptionFormGroup());
    this.deviceData = panels.value;
    this.isDeviceAdded = true;
    // this.maxSelection = panels.value.length;
    // this.campaignService.setIsSaveBtn(true);
  }
  createSelectOptionFormGroup(): FormGroup {
    let formControls = {};
    formControls['displayName'] = new FormControl('', [
      Validators.required,
      Validators.minLength(5)
    ]);
    formControls['ipAddress'] = new FormControl('', [
      Validators.required,
      Validators.pattern(IP_ADDRESS_PATTERN)
    ]);

    const form = this.formBuilder.group(formControls);
    console.log('FOMR', form);
    return form;
  }

  checkIpAddress(e) {
    if (this.deviceData && this.deviceData.length > 1) {
      const filtered = this.deviceData.filter((device) => {
        return device.ipAddress === e.target.value;
      });
      if (filtered && filtered.length) {
        this.notificationService.error(CONSTANTS.IP_ALREADY_EXISTS_MSG);
      }
    }
  }
  async deleteScreenDetailContainer(pindex) {
    const panels = this.parentForm.get(this.engageSettingsForm).get('displayList') as FormArray;
    if (panels.length > 0) {
      panels.removeAt(pindex);
    }
    // if (!panels.length) {
    //   this.campaignService.setIsSaveBtn(false);
    //   this.campaignService.setCategorySaveBtn(false);
    // }
  }
}
