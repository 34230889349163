import { Component, Input, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl,
  FormControl,
  FormArray
} from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import {
  EbasketApiMethodTypes,
  EbasketSalesExportXiatechSelectApi,
  Providers
} from '@shared/interfaces';
import { SelectApiPopupComponent } from '@shared/select-api-popup/select-api-popup.component';

@Component({
  selector: 'xiatech-salesexport-provider-details-settings',
  templateUrl: './xiatech-salesexport-provider-details-settings.component.html',
  styleUrls: ['./xiatech-salesexport-provider-details-settings.component.scss']
})
export class XiatechSalesExportProviderDetailsSettingsComponent implements OnInit {
  @Input() action: string;
  @Input() parentForm: FormGroup;
  @Input() xiatechSalesExportAuthDetails: any;
  @Input() authControlKey: string;
  apiDetails: FormGroup;
  xiatech_salesexport_select_api = [
    {
      id: 1,
      name: EbasketSalesExportXiatechSelectApi.ProcessOrders,
      value: EbasketSalesExportXiatechSelectApi.ProcessOrders,
      isDisabled: false
    },
    {
      id: 2,
      name: EbasketSalesExportXiatechSelectApi.ProcessReturns,
      value: EbasketSalesExportXiatechSelectApi.ProcessReturns,
      isDisabled: false
    },
    {
      id: 3,
      name: EbasketSalesExportXiatechSelectApi.PartialRefunds,
      value: EbasketSalesExportXiatechSelectApi.PartialRefunds,
      isDisabled: false
    },
    {
      id: 4,
      name: EbasketSalesExportXiatechSelectApi.ProcessExchanges,
      value: EbasketSalesExportXiatechSelectApi.ProcessExchanges,
      isDisabled: false
    }
  ];
  isXiatechAddDisabled = false;
  method_types = [
    { id: 1, name: EbasketApiMethodTypes.Get, value: EbasketApiMethodTypes.Get },
    { id: 2, name: EbasketApiMethodTypes.Post, value: EbasketApiMethodTypes.Post },
    { id: 3, name: EbasketApiMethodTypes.Put, value: EbasketApiMethodTypes.Put },
    { id: 4, name: EbasketApiMethodTypes.Patch, value: EbasketApiMethodTypes.Patch },
    { id: 5, name: EbasketApiMethodTypes.Delete, value: EbasketApiMethodTypes.Delete }
  ];
  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.initForm();
  }
  initForm() {
    this.parentForm.addControl(
      this.authControlKey,
      this.formBuilder.group({
        xiatechToken: [
          {
            value:
              this.xiatechSalesExportAuthDetails && this.xiatechSalesExportAuthDetails.apiDetails
                ? Providers.SECRET
                : '',
            disabled: this.action === 'view' ? true : false
          },
          [Validators.required]
        ],
        xiatechApiDetails: this.formBuilder.array([])
      })
    );
    this.bindXiatechApiDetailsFormGroup();
  }

  openXiatechSelectApiPopup() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = this.xiatech_salesexport_select_api.filter((api) => !api.isDisabled);
    const dialogRef = this.dialog.open(SelectApiPopupComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result !== '') {
        this.xiatech_salesexport_select_api.map((element: any) => {
          if (element.value === result) {
            element.isDisabled = true;
            const xiatechApiDetails = this.parentForm
              .get(this.authControlKey)
              .get('xiatechApiDetails') as FormArray;

            xiatechApiDetails.push(this.createXiatechApiDetailsFormGroup(result));
            if (this.xiatech_salesexport_select_api.length === xiatechApiDetails.length) {
              this.isXiatechAddDisabled = true;
            }
          }
        });
      }
    });
  }
  private createXiatechApiDetailsFormGroup(selectedApi: string): FormGroup {
    return new FormGroup({
      url: new FormControl('', Validators.required),
      method: new FormControl('', Validators.required),
      selectedApi: new FormControl(selectedApi)
    });
  }
  deleteXiatechApiDetailContainer(index: number) {
    const xiatechApiDetails = this.parentForm
      .get(this.authControlKey)
      .get('xiatechApiDetails') as FormArray;
    this.xiatech_salesexport_select_api.map((element: any) => {
      if (element.value === xiatechApiDetails.value[index].selectedApi) {
        element.isDisabled = false;
      }
    });
    if (xiatechApiDetails.length > 0) {
      xiatechApiDetails.removeAt(index);
    } else {
      xiatechApiDetails.reset();
    }
    if (this.xiatech_salesexport_select_api.length !== xiatechApiDetails.length) {
      this.isXiatechAddDisabled = false;
    }
  }

  bindXiatechApiDetailsFormGroup() {
    const control = this.parentForm.get(this.authControlKey).get('xiatechApiDetails') as FormArray;
    if (this.xiatechSalesExportAuthDetails && this.xiatechSalesExportAuthDetails.apiDetails) {
      this.xiatechSalesExportAuthDetails.apiDetails.map((apis: any) => {
        let apiDetails = {};
        apiDetails = {
          url: [
            {
              value: apis.url,
              disabled: this.action === 'view' ? true : false
            },
            Validators.required
          ],
          method: [
            {
              value: apis.method,
              disabled: this.action === 'view' ? true : false
            },
            Validators.required
          ],
          selectedApi: [apis.selectedApi]
        };
        control.push(this.formBuilder.group(apiDetails));
        this.xiatech_salesexport_select_api.map((key) => {
          if (key.value === apis.selectedApi) {
            key.isDisabled = true;
          }
        });
      });

      if (this.xiatech_salesexport_select_api.length === control.length) {
        this.isXiatechAddDisabled = true;
      }
    }
  }
  isRequired(control: string) {
    let isRequired = false;
    const formControlObject = this.parentForm.get(this.authControlKey).get(control);

    if (formControlObject !== null) {
      if (formControlObject.validator !== null) {
        if (formControlObject.validator({} as AbstractControl) !== null) {
          isRequired = formControlObject.validator({} as AbstractControl).required ? true : false;
        }
      }
    }
    return isRequired ? true : false;
  }
}
