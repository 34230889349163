import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewEncapsulation
} from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';

@Component({
  selector: 'ng-idle-box',
  template: `
    <div class="idle-modal">
      <!-- Modal content -->
      <div class="idle-modal-content">
        <h3 class="idle-alert">You have been Idle!</h3>
        <p>{{ idleMessage | async }}</p>
      </div>
    </div>
  `,
  styleUrls: ['./idle.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class IdleBoxComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  @Input() idleMessage: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(private renderer: Renderer2, private el: ElementRef) {}

  ngOnInit() {
    this.subscriptions.push(
      this.idleMessage.subscribe((data) => {
        if (data && data.trim().length > 0) {
          this.renderer.removeClass(this.el.nativeElement, 'idle-modal-hide');
        } else {
          this.renderer.addClass(this.el.nativeElement, 'idle-modal-hide');
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
    this.renderer.addClass(this.el.nativeElement, 'idle-modal-hide');
  }
}
