import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit
} from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl,
  FormArray,
  FormControl
} from '@angular/forms';
import { GiftCardType, Providers } from '@shared/interfaces';

@Component({
  selector: 'gift-card-settings',
  templateUrl: './gift-card-settings.component.html',
  styleUrls: ['./gift-card-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class GiftCardSettingsComponent implements OnInit {
  @Input() action: string;
  @Input() parentForm: FormGroup;
  @Input() giftCardSetting: any;
  @Input() authControlKey: string;
  apiDetails: FormGroup;
  tmGiftCardCommonAuthControlKey = 'tmGiftCardCommonConfiguration';
  tmPGCCommonAuthControlKey = 'tmPGCCommonConfiguration';
  tmEGCCommonAuthControlKey = 'tmEGCCommonConfiguration';

  // Mixed gift card selection toggles
  enableTmProviderNetsuiteDetails = false;
  enableTmProviderGivexDetails = false;
  enableTmGiftCardCommonDetails = false;
  // Physical gift card selection toggles
  enableTmProviderPGCNetsuiteDetails = false;
  enableTmProviderPGCGivexDetails = false;
  enableTmPGCCommonDetails = false;
  // E-gift card selection toggles
  enableTmEGCCommonDetails = false;

  // GiftCard selection toggles
  enableTmPhysicalGiftCardDetails = false;
  enableTmEGiftCardDetails = false;
  enableTmMixedGiftCardDetails = false;

  // Mixed gift card selection Details
  tmGiftCardCommonConfigDetails;
  tmGiftCardGivexAuthDetails;
  tmGiftCardNetsuiteAuthDetails;
  // E-gift card selection Details
  tmEGCCommonConfigDetails;
  // Physical gift card selection Details
  tmPGCCommonConfigDetails;
  tmPGCGivexAuthDetails;
  tmPGCNetsuiteAuthDetails;

  constructor(private formBuilder: FormBuilder, private changeDetection: ChangeDetectorRef) {}

  ngOnInit() {
    this.initForm();
  }
  initForm() {
    this.parentForm.addControl(
      this.authControlKey,
      this.formBuilder.group({
        mixedGiftCard: [
          {
            value:
              this.giftCardSetting &&
              this.giftCardSetting.giftCard &&
              this.giftCardSetting.mixGiftCards
                ? true
                : false,
            disabled: this.action === 'view' ? true : false
          }
        ],
        mixedGiftCardProvider: [
          {
            value:
              this.giftCardSetting &&
              this.giftCardSetting.giftCard &&
              this.giftCardSetting.mixGiftCards &&
              this.giftCardSetting.providers &&
              this.giftCardSetting.providers.length &&
              this.giftCardSetting.providers.filter((x) => x.giftCardType === '').length > 0
                ? this.giftCardSetting.providers.filter((x) => x.giftCardType === '')[0].provider
                : '',
            disabled: this.action === 'view' ? true : false
          }
        ],
        physicalGiftCard: [
          {
            value:
              this.giftCardSetting &&
              this.giftCardSetting.giftCard &&
              (this.giftCardSetting.mixGiftCards ||
                (this.giftCardSetting.providers &&
                  this.giftCardSetting.providers.length &&
                  this.giftCardSetting.providers.filter((x) => x.giftCardType === GiftCardType.PGC)
                    .length > 0))
                ? true
                : false,
            disabled: this.action === 'view' || this.giftCardSetting.mixGiftCards ? true : false
          }
        ],
        physicalGiftCardProvider: [
          {
            value:
              this.giftCardSetting &&
              this.giftCardSetting.giftCard &&
              !this.giftCardSetting.mixGiftCards &&
              this.giftCardSetting.providers &&
              this.giftCardSetting.providers.length &&
              this.giftCardSetting.providers.filter((x) => x.giftCardType === GiftCardType.PGC)
                .length > 0
                ? this.giftCardSetting.providers.filter(
                    (x) => x.giftCardType === GiftCardType.PGC
                  )[0].provider
                : '',
            disabled: this.action === 'view' ? true : false
          }
        ],
        eGiftCard: [
          {
            value:
              this.giftCardSetting &&
              this.giftCardSetting.giftCard &&
              (this.giftCardSetting.mixGiftCards ||
                (this.giftCardSetting.providers &&
                  this.giftCardSetting.providers.length &&
                  this.giftCardSetting.providers.filter((x) => x.giftCardType === GiftCardType.EGC)
                    .length > 0))
                ? true
                : false,
            disabled: this.action === 'view' || this.giftCardSetting.mixGiftCards ? true : false
          }
        ],
        eGiftCardProvider: [
          {
            value:
              this.giftCardSetting &&
              this.giftCardSetting.giftCard &&
              !this.giftCardSetting.mixGiftCards &&
              this.giftCardSetting.providers &&
              this.giftCardSetting.providers.length &&
              this.giftCardSetting.providers.filter((x) => x.giftCardType === GiftCardType.EGC)
                .length > 0
                ? this.giftCardSetting.providers.filter(
                    (x) => x.giftCardType === GiftCardType.EGC
                  )[0].provider
                : '',
            disabled: this.action === 'view' ? true : false
          }
        ]
      })
    );
    this.toggleVisibility();
    this.BindOldStructure();
  }
  BindOldStructure() {
    if (this.giftCardSetting && this.giftCardSetting.giftCard && this.giftCardSetting.provider) {
      const provider = this.giftCardSetting.provider;
      this.parentForm
        .get(this.authControlKey)
        .get('physicalGiftCard')
        .setValue(true);
      this.parentForm
        .get(this.authControlKey)
        .get('physicalGiftCardProvider')
        .setValue(provider);
      this.enableTmPhysicalGiftCardDetails = true;
      if (provider === Providers.Netsuite) {
        this.enableTmProviderPGCNetsuiteDetails = true;
        this.tmPGCNetsuiteAuthDetails = {
          authenticationDetails: this.giftCardSetting.authenticationDetails,
          authenticationDetailsKey: this.giftCardSetting.authenticationDetailsKey
        };
      } else if (provider === Providers.GIVEX) {
        this.enableTmProviderPGCGivexDetails = true;
        this.tmPGCGivexAuthDetails = {
          authenticationDetails: this.giftCardSetting.authenticationDetails,
          authenticationDetailsKey: this.giftCardSetting.authenticationDetailsKey
        };
      }
      this.tmPGCCommonConfigDetails =
        this.giftCardSetting && this.giftCardSetting.provider && this.giftCardSetting.provider != ''
          ? this.giftCardSetting
          : {};
      this.enableTmPGCCommonDetails =
        this.giftCardSetting && this.giftCardSetting.provider && this.giftCardSetting.provider != ''
          ? true
          : false;
    }
  }
  toggleVisibility() {
    this.enableTmMixedGiftCardDetails =
      this.giftCardSetting && this.giftCardSetting.giftCard && this.giftCardSetting.mixGiftCards
        ? true
        : false;
    this.enableTmEGiftCardDetails =
      this.giftCardSetting &&
      this.giftCardSetting.giftCard &&
      !this.giftCardSetting.mixGiftCards &&
      this.giftCardSetting.providers &&
      this.giftCardSetting.providers.length &&
      this.giftCardSetting.providers.filter((x) => x.giftCardType === GiftCardType.EGC).length > 0
        ? true
        : false;
    this.enableTmPhysicalGiftCardDetails =
      this.giftCardSetting &&
      this.giftCardSetting.giftCard &&
      !this.giftCardSetting.mixGiftCards &&
      this.giftCardSetting.providers &&
      this.giftCardSetting.providers.length &&
      this.giftCardSetting.providers.filter((x) => x.giftCardType === GiftCardType.PGC).length > 0
        ? true
        : false;

    // IF the gift card is physical
    if (this.enableTmPhysicalGiftCardDetails) {
      const provider = this.parentForm.get(this.authControlKey).get('physicalGiftCardProvider')
        .value;
      const physicalGiftCardDetails =
        this.giftCardSetting.providers.filter((x) => x.giftCardType === GiftCardType.PGC).length > 0
          ? this.giftCardSetting.providers.filter((x) => x.giftCardType === GiftCardType.PGC)[0]
          : {};
      if (provider === Providers.Netsuite) {
        this.enableTmProviderPGCNetsuiteDetails = true;
        this.tmPGCNetsuiteAuthDetails = {
          authenticationDetails: physicalGiftCardDetails.authenticationDetails,
          authenticationDetailsKey: physicalGiftCardDetails.authenticationDetailsKey
        };
      } else if (provider === Providers.GIVEX) {
        this.enableTmProviderPGCGivexDetails = true;
        this.tmPGCGivexAuthDetails = {
          authenticationDetails: physicalGiftCardDetails.authenticationDetails,
          authenticationDetailsKey: physicalGiftCardDetails.authenticationDetailsKey
        };
      }
      this.tmPGCCommonConfigDetails = provider != '' ? physicalGiftCardDetails : {};
      this.enableTmPGCCommonDetails = provider != '' ? true : false;
    }
    if (this.enableTmEGiftCardDetails) {
      const provider = this.parentForm.get(this.authControlKey).get('eGiftCardProvider').value;
      const eGiftCardDetails =
        this.giftCardSetting.providers.filter((x) => x.giftCardType === GiftCardType.EGC).length > 0
          ? this.giftCardSetting.providers.filter((x) => x.giftCardType === GiftCardType.EGC)[0]
          : {};
      this.tmEGCCommonConfigDetails = provider != '' ? eGiftCardDetails : {};
      this.enableTmEGCCommonDetails = provider != '' ? true : false;
    }

    // If the gift card is mixed
    if (this.enableTmMixedGiftCardDetails) {
      const provider = this.parentForm.get(this.authControlKey).get('mixedGiftCardProvider').value;
      const mixedGiftCardDetails =
        this.giftCardSetting.providers.filter((x) => x.giftCardType === '').length > 0
          ? this.giftCardSetting.providers.filter((x) => x.giftCardType === '')[0]
          : {};
      if (provider === Providers.Netsuite) {
        this.enableTmProviderNetsuiteDetails = true;
        this.tmGiftCardNetsuiteAuthDetails = {
          authenticationDetails: mixedGiftCardDetails.authenticationDetails,
          authenticationDetailsKey: mixedGiftCardDetails.authenticationDetailsKey
        };
      } else if (provider === Providers.GIVEX) {
        this.enableTmProviderGivexDetails = true;
        this.tmGiftCardGivexAuthDetails = {
          authenticationDetails: mixedGiftCardDetails.authenticationDetails,
          authenticationDetailsKey: mixedGiftCardDetails.authenticationDetailsKey
        };
      }
      this.tmGiftCardCommonConfigDetails = provider != '' ? mixedGiftCardDetails : {};
      this.enableTmGiftCardCommonDetails = provider != '' ? true : false;
    }
  }
  toggleTMMixedGiftCard() {
    this.enableTmMixedGiftCardDetails = this.parentForm
      .get(this.authControlKey)
      .get('mixedGiftCard').value
      ? true
      : false;
    if (!this.enableTmMixedGiftCardDetails) {
      // Remove all the provider for mixed gift card
      this.parentForm
        .get(this.authControlKey)
        .get('mixedGiftCardProvider')
        .reset();
      this.parentForm
        .get(this.authControlKey)
        .get('mixedGiftCardProvider')
        .clearValidators();
      this.parentForm
        .get(this.authControlKey)
        .get('physicalGiftCard')
        .enable();
      this.parentForm
        .get(this.authControlKey)
        .get('eGiftCard')
        .enable();
      this.enableTmProviderNetsuiteDetails = false;
      this.enableTmProviderGivexDetails = false;
      this.enableTmGiftCardCommonDetails = false;
      (this.parentForm.get(this.authControlKey) as FormGroup).removeControl(
        'tmGiftCardNetsuiteConfiguration'
      );
      (this.parentForm.get(this.authControlKey) as FormGroup).removeControl(
        'tmGiftCardGivexConfiguration'
      );
      (this.parentForm.get(this.authControlKey) as FormGroup).removeControl(
        this.tmGiftCardCommonAuthControlKey
      );
      this.parentForm
        .get(this.authControlKey)
        .get('physicalGiftCard')
        .setValue(false);
      this.parentForm
        .get(this.authControlKey)
        .get('eGiftCard')
        .setValue(false);
    } else {
      this.parentForm
        .get(this.authControlKey)
        .get('mixedGiftCardProvider')
        .setValidators(Validators.required);
      this.toggleTMPhysicalGiftCard();
      this.toggleTMEGiftCard();
      this.parentForm
        .get(this.authControlKey)
        .get('physicalGiftCard')
        .setValue(true);
      this.parentForm
        .get(this.authControlKey)
        .get('physicalGiftCard')
        .disable();
      this.parentForm
        .get(this.authControlKey)
        .get('eGiftCard')
        .setValue(true);
      this.parentForm
        .get(this.authControlKey)
        .get('eGiftCard')
        .disable();
    }
  }
  toggleTMPhysicalGiftCard() {
    this.enableTmPhysicalGiftCardDetails =
      this.parentForm.get(this.authControlKey).get('physicalGiftCard').value &&
      !this.parentForm.get(this.authControlKey).get('mixedGiftCard').value
        ? true
        : false;
    if (!this.enableTmPhysicalGiftCardDetails) {
      // Remove all the provider for physical gift card
      this.parentForm
        .get(this.authControlKey)
        .get('physicalGiftCardProvider')
        .reset();
      this.parentForm
        .get(this.authControlKey)
        .get('physicalGiftCardProvider')
        .clearValidators();
      this.parentForm
        .get(this.authControlKey)
        .get('physicalGiftCardProvider')
        .updateValueAndValidity();
      this.enableTmProviderPGCNetsuiteDetails = false;
      this.enableTmProviderPGCGivexDetails = false;
      this.enableTmPGCCommonDetails = false;
      (this.parentForm.get(this.authControlKey) as FormGroup).removeControl(
        'tmPGCNetsuiteConfiguration'
      );
      (this.parentForm.get(this.authControlKey) as FormGroup).removeControl(
        'tmPGCGivexConfiguration'
      );
      (this.parentForm.get(this.authControlKey) as FormGroup).removeControl(
        this.tmPGCCommonAuthControlKey
      );
    } else {
      this.parentForm
        .get(this.authControlKey)
        .get('physicalGiftCardProvider')
        .setValidators(Validators.required);
    }
  }
  toggleTMEGiftCard() {
    this.enableTmEGiftCardDetails =
      this.parentForm.get(this.authControlKey).get('eGiftCard').value &&
      !this.parentForm.get(this.authControlKey).get('mixedGiftCard').value
        ? true
        : false;
    if (!this.enableTmEGiftCardDetails) {
      // Remove all the provider for e-gift card
      this.parentForm
        .get(this.authControlKey)
        .get('eGiftCardProvider')
        .reset();
      this.parentForm
        .get(this.authControlKey)
        .get('eGiftCardProvider')
        .clearValidators();
      this.parentForm
        .get(this.authControlKey)
        .get('eGiftCardProvider')
        .updateValueAndValidity();
    } else {
      this.parentForm
        .get(this.authControlKey)
        .get('eGiftCardProvider')
        .setValidators(Validators.required);
    }
  }
  getTMMixedGiftCardProvider() {
    return this.parentForm.get(this.authControlKey).get('mixedGiftCardProvider').value || '';
  }
  getTMPGCProvider() {
    return this.parentForm.get(this.authControlKey).get('physicalGiftCardProvider').value || '';
  }
  getTMEGCProvider() {
    return this.parentForm.get(this.authControlKey).get('eGiftCardProvider').value || '';
  }

  changeTmMixedGCProvider() {
    const giftCardProvider = this.parentForm.get(this.authControlKey).get('mixedGiftCardProvider')
      .value;
    if (this.parentForm.get(this.authControlKey).get(this.tmGiftCardCommonAuthControlKey)) {
      this.enableTmGiftCardCommonDetails = false;
      (this.parentForm.get(this.authControlKey) as FormGroup).removeControl(
        this.tmGiftCardCommonAuthControlKey
      );
    }
    this.changeDetection.detectChanges();
    if (giftCardProvider === Providers.Netsuite) {
      this.enableTmProviderNetsuiteDetails = true;
      this.enableTmProviderGivexDetails = false;
      (this.parentForm.get(this.authControlKey) as FormGroup).removeControl(
        'tmGiftCardGivexConfiguration'
      );
      this.enableTmGiftCardCommonDetails = true;
      this.tmGiftCardNetsuiteAuthDetails = {};
      this.tmGiftCardCommonConfigDetails = {};
    } else if (giftCardProvider === Providers.GIVEX) {
      this.enableTmProviderGivexDetails = true;
      this.enableTmProviderNetsuiteDetails = false;
      (this.parentForm.get(this.authControlKey) as FormGroup).removeControl(
        'tmGiftCardNetsuiteConfiguration'
      );
      this.tmGiftCardGivexAuthDetails = {};
      this.tmGiftCardCommonConfigDetails = {};
      this.enableTmGiftCardCommonDetails = true;
    } else if (giftCardProvider === Providers.SVS || giftCardProvider === Providers.PARASPAR) {
      this.enableTmProviderNetsuiteDetails = false;
      this.enableTmProviderGivexDetails = false;
      (this.parentForm.get(this.authControlKey) as FormGroup).removeControl(
        'tmGiftCardGivexConfiguration'
      );
      (this.parentForm.get(this.authControlKey) as FormGroup).removeControl(
        'tmGiftCardNetsuiteConfiguration'
      );
      this.enableTmGiftCardCommonDetails = true;
      this.tmGiftCardCommonConfigDetails = {};
    } else {
      this.enableTmProviderNetsuiteDetails = false;
      this.enableTmProviderGivexDetails = false;
      (this.parentForm.get(this.authControlKey) as FormGroup).removeControl(
        'tmGiftCardGivexConfiguration'
      );
      (this.parentForm.get(this.authControlKey) as FormGroup).removeControl(
        'tmGiftCardNetsuiteConfiguration'
      );
      this.enableTmGiftCardCommonDetails = false;
      (this.parentForm.get(this.authControlKey) as FormGroup).removeControl(
        this.tmGiftCardCommonAuthControlKey
      );
    }
  }
  changeTmPGCProvider() {
    const giftCardProvider = this.parentForm
      .get(this.authControlKey)
      .get('physicalGiftCardProvider').value;
    if (this.parentForm.get(this.authControlKey).get(this.tmPGCCommonAuthControlKey)) {
      this.enableTmPGCCommonDetails = false;
      (this.parentForm.get(this.authControlKey) as FormGroup).removeControl(
        this.tmPGCCommonAuthControlKey
      );
    }
    this.changeDetection.detectChanges();
    if (giftCardProvider === Providers.Netsuite) {
      this.enableTmProviderPGCNetsuiteDetails = true;
      this.enableTmProviderPGCGivexDetails = false;
      (this.parentForm.get(this.authControlKey) as FormGroup).removeControl(
        'tmPGCGivexConfiguration'
      );
      this.enableTmPGCCommonDetails = true;
      this.tmPGCNetsuiteAuthDetails = {};
      this.tmPGCCommonConfigDetails = {};
    } else if (giftCardProvider === Providers.GIVEX) {
      this.enableTmProviderPGCGivexDetails = true;
      this.enableTmProviderPGCNetsuiteDetails = false;
      (this.parentForm.get(this.authControlKey) as FormGroup).removeControl(
        'tmPGCNetsuiteConfiguration'
      );
      this.tmPGCGivexAuthDetails = {};
      this.tmPGCCommonConfigDetails = {};
      this.enableTmPGCCommonDetails = true;
    } else if (giftCardProvider === Providers.SVS) {
      this.enableTmProviderPGCNetsuiteDetails = false;
      this.enableTmProviderPGCGivexDetails = false;
      (this.parentForm.get(this.authControlKey) as FormGroup).removeControl(
        'tmPGCGivexConfiguration'
      );
      (this.parentForm.get(this.authControlKey) as FormGroup).removeControl(
        'tmPGCNetsuiteConfiguration'
      );
      this.enableTmPGCCommonDetails = true;
      this.tmPGCCommonConfigDetails = {};
    } else {
      this.enableTmProviderPGCNetsuiteDetails = false;
      this.enableTmProviderPGCGivexDetails = false;
      (this.parentForm.get(this.authControlKey) as FormGroup).removeControl(
        'tmPGCGivexConfiguration'
      );
      (this.parentForm.get(this.authControlKey) as FormGroup).removeControl(
        'tmPGCNetsuiteConfiguration'
      );
      this.enableTmPGCCommonDetails = false;
      (this.parentForm.get(this.authControlKey) as FormGroup).removeControl(
        this.tmPGCCommonAuthControlKey
      );
    }
  }
  changeTmEGCProvider() {
    const giftCardProvider = this.parentForm.get(this.authControlKey).get('eGiftCardProvider')
      .value;
    if (this.parentForm.get(this.authControlKey).get(this.tmEGCCommonAuthControlKey)) {
      this.enableTmEGCCommonDetails = false;
      (this.parentForm.get(this.authControlKey) as FormGroup).removeControl(
        this.tmEGCCommonAuthControlKey
      );
    }
    this.changeDetection.detectChanges();
    if (giftCardProvider === Providers.PARASPAR) {
      this.enableTmEGCCommonDetails = true;
      this.tmEGCCommonConfigDetails = {};
    }
  }
  isRequired(control: string) {
    let isRequired = false;
    const formControlObject = this.parentForm.get(this.authControlKey).get(control);

    if (formControlObject !== null) {
      if (formControlObject.validator !== null) {
        if (formControlObject.validator({} as AbstractControl) !== null) {
          isRequired = formControlObject.validator({} as AbstractControl).required ? true : false;
        }
      }
    }
    return isRequired ? true : false;
  }
}
