import { Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material';

@Component({
  selector: 'app-cron-job-configuration',
  templateUrl: './cron-job-configuration.component.html',
  styleUrls: ['./cron-job-configuration.component.scss']
})
export class CronJobConfigurationComponent implements OnInit {
  multiplePriceDayFrequency: any[] = [];
  @Input() action: string;
  @Input() parentForm: FormGroup;
  @Input() CronJobConfigurationDetails: any;
  @Input() authControlKey: string;
  @Input() provider: string;
  frmModuleAssignment: FormGroup;

  constructor(private formBuilder: FormBuilder) {}
  flag = false;
  apiDetails: FormGroup;
  weekdays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

  selecteddays: [];

  onAllDaysToggleChange(event: any) {
    console.log(this.parentForm.get(this.authControlKey).get('monday'));

    const allDayValue = event.checked;
    this.weekdays.forEach((weekday) => {
      this.parentForm
        .get(this.authControlKey)
        .get(weekday)
        .setValue(allDayValue);
    });
    this.atLeastOneSelected();
  }

  onWeekdayToggleChange(event: any) {
    if (this.parentForm.get(this.authControlKey).get('alldays').value) {
      if (!event.checked) {
        this.parentForm
          .get(this.authControlKey)
          .get('alldays')
          .setValue(event.checked);
      }
    }
    this.atLeastOneSelected();
  }

  ngOnInit() {
    this.initForm();
  }
  initForm() {
    this.parentForm.addControl(
      this.authControlKey,
      this.formBuilder.group({
        multipleTimeFrequency: [
          {
            value:
              this.CronJobConfigurationDetails && this.CronJobConfigurationDetails.time
                ? this.CronJobConfigurationDetails.time
                : '00:00',
            disabled: this.action === 'view' ? true : false
          },
          Validators.required
        ],
        error: [
          {
            value: this.flag == true ? 'true' : '',
            disabled: this.flag == true ? true : false
          },
          Validators.required
        ],
        alldays: [
          {
            value:
              this.CronJobConfigurationDetails &&
              this.CronJobConfigurationDetails.frequency &&
              this.CronJobConfigurationDetails.frequency.length == 7
                ? true
                : false,
            disabled: this.action === 'view' ? true : false
          }
        ],
        monday: [
          {
            value:
              this.CronJobConfigurationDetails &&
              this.CronJobConfigurationDetails.frequency &&
              this.CronJobConfigurationDetails.frequency.indexOf('MON') > -1
                ? true
                : false,
            disabled: this.action === 'view' ? true : false
          }
        ],
        tuesday: [
          {
            value:
              this.CronJobConfigurationDetails &&
              this.CronJobConfigurationDetails.frequency &&
              this.CronJobConfigurationDetails.frequency.indexOf('TUE') > -1
                ? true
                : false,
            disabled: this.action === 'view' ? true : false
          }
        ],
        wednesday: [
          {
            value:
              this.CronJobConfigurationDetails &&
              this.CronJobConfigurationDetails.frequency &&
              this.CronJobConfigurationDetails.frequency.indexOf('WED') > -1
                ? true
                : false,
            disabled: this.action === 'view' ? true : false
          }
        ],
        thursday: [
          {
            value:
              this.CronJobConfigurationDetails &&
              this.CronJobConfigurationDetails.frequency &&
              this.CronJobConfigurationDetails.frequency.indexOf('THU') > -1
                ? true
                : false,
            disabled: this.action === 'view' ? true : false
          }
        ],
        friday: [
          {
            value:
              this.CronJobConfigurationDetails &&
              this.CronJobConfigurationDetails.frequency &&
              this.CronJobConfigurationDetails.frequency.indexOf('FRI') > -1
                ? true
                : false,
            disabled: this.action === 'view' ? true : false
          }
        ],
        saturday: [
          {
            value:
              this.CronJobConfigurationDetails &&
              this.CronJobConfigurationDetails.frequency &&
              this.CronJobConfigurationDetails.frequency.indexOf('SAT') > -1
                ? true
                : false,
            disabled: this.action === 'view' ? true : false
          }
        ],
        sunday: [
          {
            value:
              this.CronJobConfigurationDetails &&
              this.CronJobConfigurationDetails.frequency &&
              this.CronJobConfigurationDetails.frequency.indexOf('SUN') > -1
                ? true
                : false,
            disabled: this.action === 'view' ? true : false
          }
        ]
      })
    );
    this.atLeastOneSelected();
  }

  atLeastOneSelected() {
    let selectedday = [];
    const togglevalue = this.weekdays.forEach((weekday) => {
      this.parentForm.get(this.authControlKey).get(weekday).value;
      if (this.parentForm.get(this.authControlKey).get(weekday).value == true) {
        selectedday.push(weekday);
      }
      if (selectedday.length == 0) {
        this.flag = true;
      } else {
        this.flag = false;
      }

      // if(selectedday.length==7){
      //   this.parentForm
      //     .get(this.authControlKey)
      //     .get('alldays')
      //     .setValue(true);
      // }
    });
    this.updateErrorControl(this.flag);
    return this.flag;
  }

  private updateErrorControl(flag: boolean) {
    const errorControl = this.parentForm.get(this.authControlKey).get('error');

    if (flag) {
      errorControl.setValidators([Validators.required]);
    } else {
      errorControl.clearValidators();
    }

    errorControl.updateValueAndValidity();
  }

  isRequired(control: string) {
    let isRequired = false;
    const formControlObject = this.parentForm.get(this.authControlKey).get(control);
    if (formControlObject !== null) {
      if (formControlObject.validator !== null) {
        if (formControlObject.validator({} as AbstractControl) !== null) {
          isRequired = formControlObject.validator({} as AbstractControl).required ? true : false;
        }
      }
    }
    return isRequired ? true : false;
  }
}
