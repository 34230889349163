import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-payment-tender-configuration',
  templateUrl: './payment-tender-configuration.component.html',
  styleUrls: ['./payment-tender-configuration.component.scss']
})
export class PaymentTenderConfigurationComponent implements OnInit {
  @Input() paymentTender: FormGroup;
  @Input() externalProvideToggle: boolean;
  @Input() paymentTenderDataSource: any[] = [];
  @Input() subPaymentOption: any[] = [];
  @Input() paymentTenderSelectedData: any[] = [];
  @Input() viewScreen: string;
  @Output() selectedRowData: EventEmitter<any> = new EventEmitter();

  tableData: any[] = [];
  selectedProducts: any[] = [];
  tableCols: any[] = [];
  knetcols: any[] = [];
  knetTableData: any[] = [];
  knetSelectedData: any[] = [];
  tableRowDisabled: any[] = [];
  knetTableRowDisabled: any[] = [];
  arrowIconS: any[] = [];
  editScreen: boolean = false;
  // paymentTender: FormGroup = new FormGroup({});
  constructor(private formBuilder: FormBuilder, private translate: TranslateService) {}

  ngOnInit() {
    this.editScreen = this.viewScreen === 'view' ? true : false;
    this.tableData = this.paymentTenderDataSource;
    this.knetTableData = this.subPaymentOption;
    this.arrowIconS = [18];
    this.selectedProducts = [...new Set(this.paymentTenderSelectedData)]
      .map((s) => {
        if (s.name.replace(/ /g, '') === 'KNET') {
          this.knetSelectedData = s.subPaymentMethods;
        }
        return this.paymentTenderDataSource.find((t) => {
          return t.name.replace(/ /g, '') === s.name.replace(/ /g, '') && t.type === s.type;
        });
      })
      .filter((item) => !!item);
    if (this.knetSelectedData && this.knetSelectedData.length) {
      this.knetTableRowDisabled = [
        ...this.knetSelectedData.map((d: any) => {
          return d.id;
        })
      ];
    }

    this.initForm();
  }
  initForm() {
    // payment tender config

    if (this.externalProvideToggle) {
      this.tableCols = [
        { field: 'name', header: 'Payment Tender' },
        { field: 'type', header: 'Type' },
        { field: 'displayName', header: 'Display Name' },
        { field: 'externalType', header: 'External Type' },
        { field: 'externalSubType', header: 'External Sub Type' },
        { field: 'tenderItemKey', header: 'Tender ItemKey' },
        { field: 'authCode', header: 'Auth Code' }
      ];
    } else {
      this.tableCols = [
        { field: 'name', header: 'Payment Tender' },
        { field: 'type', header: 'Type' },
        { field: 'displayName', header: 'Display Name' },
        { field: 'authCode', header: 'Auth Code' }
      ];
    }

    this.knetcols = [{ field: 'name', header: 'Sub Tender' }];

    this.tableData.forEach((row: any) => {
      this.paymentTender.addControl(row.authCode, new FormControl(false));
      this.paymentTender.addControl(row.displayName, new FormControl());
      if (this.externalProvideToggle) {
        this.paymentTender.addControl(row.externalSubType, new FormControl());
        this.paymentTender.addControl(row.externalType, new FormControl());
        this.paymentTender.addControl(row.tenderItemKey, new FormControl());
      } else {
        this.paymentTender.removeControl(row.externalSubType);
        this.paymentTender.removeControl(row.externalType);
        this.paymentTender.removeControl(row.tenderItemKey);
      }
    });

    this.tableData.forEach((row: any) => {
      this.paymentTender.addControl(row.authCode, new FormControl());
      this.paymentTender.addControl(row.displayName, new FormControl());
      if (this.selectedProducts.length) {
        const index = this.paymentTenderSelectedData.findIndex(
          (pm) => pm.name === row.name.replace(/ /g, '') && pm.type === row.type
        );
        const externalData = this.paymentTenderSelectedData.filter(
          (pm) => pm.name === row.name.replace(/ /g, '') && pm.type === row.type
        );

        if (index > -1) {
          this.tableRowDisabled.push(row.id);

          if (this.externalProvideToggle) {
            this.paymentTender.get(row.externalSubType).setValue(externalData[0].externalSubType);
            this.paymentTender.get(row.externalType).setValue(externalData[0].externalType);
            this.paymentTender.get(row.tenderItemKey).setValue(externalData[0].tenderItemKey);
            this.paymentTender.get(row.externalType).setValidators(Validators.required);
            this.paymentTender.get(row.externalType).updateValueAndValidity();
            this.paymentTender.get(row.externalSubType).setValidators(Validators.required);
            this.paymentTender.get(row.externalSubType).updateValueAndValidity();
          }
          this.paymentTender
            .get(row.displayName)
            .setValue(externalData[0].displayName ? externalData[0].displayName : '');
          this.paymentTender
            .get(row.authCode)
            .setValue(externalData[0].authEntry ? externalData[0].authEntry : false);
        }
      } else {
        this.paymentTender.get(row.displayName).setValue('');
        this.paymentTender.get(row.authCode).setValue(false);
      }
    });

    let temarray = this.tableData.filter((d: any) => {
      return !this.tableRowDisabled.includes(d.id);
    });

    temarray = [...this.selectedProducts, ...temarray];
    this.tableData = [...new Set(temarray)];

    let knettemarray = this.knetTableData.filter((d: any) => {
      return !this.knetTableRowDisabled.includes(d.id);
    });

    knettemarray = [...this.knetSelectedData, ...knettemarray];
    this.knetTableData = [...new Set(knettemarray)];

    this.selectedRowData.emit({
      selectedProducts: this.selectedProducts,
      knetSelectedData: this.knetSelectedData
    });
  }

  TableRowSelect(event: any) {
    this.selectedProducts.push(event.data);

    this.tableRowDisabled.push(event.data.id);

    let temarray = this.tableData.filter((d: any) => {
      return !this.tableRowDisabled.includes(d.id);
    });

    temarray = [...this.selectedProducts, ...temarray];
    this.tableData = [...new Set(temarray)];

    if (this.externalProvideToggle) {
      if (this.paymentTender.get(event.data.externalType)) {
        this.paymentTender.get(event.data.externalType).setValidators(Validators.required);
        this.paymentTender.get(event.data.externalType).updateValueAndValidity();
      }
      if (this.paymentTender.get(event.data.externalSubType)) {
        this.paymentTender.get(event.data.externalSubType).setValidators(Validators.required);
        this.paymentTender.get(event.data.externalSubType).updateValueAndValidity();
      }
    }

    this.selectedRowData.emit({
      selectedProducts: this.selectedProducts,
      knetSelectedData: this.knetSelectedData
    });
  }
  TableRowUnSelect(event: any) {
    this.tableRowDisabled = this.tableRowDisabled.filter((d: any) => {
      return d !== event.data.id;
    });

    let temarray = this.tableData.filter((d: any) => {
      return !this.tableRowDisabled.includes(d.id) && d.id !== event.data.id;
    });

    this.selectedProducts = this.selectedProducts.filter((s: any) => {
      return s.id !== event.data.id;
    });
    temarray = [...new Set(this.selectedProducts), ...temarray];

    this.tableData = [...new Set(temarray)];
    this.tableData.push(event.data);
    if (this.externalProvideToggle) {
      this.paymentTender.get(event.data.externalSubType).reset();
      this.paymentTender.get(event.data.externalType).reset();
      this.paymentTender.get(event.data.tenderItemKey).reset();

      this.paymentTender.get(event.data.externalSubType).clearValidators();
      this.paymentTender.get(event.data.externalType).clearValidators();
      this.paymentTender.get(event.data.externalType).updateValueAndValidity();
      this.paymentTender.get(event.data.externalSubType).updateValueAndValidity();
    }
    this.paymentTender.get(event.data.displayName).reset();
    this.paymentTender.get(event.data.authCode).reset();
    this.paymentTender.get(event.data.authCode).clearValidators();
    this.paymentTender.get(event.data.authCode).updateValueAndValidity();
    this.selectedRowData.emit({
      selectedProducts: this.selectedProducts,
      knetSelectedData: this.knetSelectedData
    });
    if (event.data.name === 'KNET') {
      this.knetTableAllRowSelect({ checked: false });
    }
  }
  TableAllRowSelect(data: any) {
    if (data.checked) {
      this.selectedProducts = this.tableData;
      this.tableData.filter((d: any) => {
        if (this.externalProvideToggle) {
          if (this.paymentTender.get(d.externalType)) {
            this.paymentTender.get(d.externalType).setValidators(Validators.required);
            this.paymentTender.get(d.externalType).updateValueAndValidity();
          }
          if (this.paymentTender.get(d.externalSubType)) {
            this.paymentTender.get(d.externalSubType).setValidators(Validators.required);
            this.paymentTender.get(d.externalSubType).updateValueAndValidity();
          }
        }
      });
      this.tableRowDisabled = this.tableData.map((t: any) => {
        return t.id;
      });
    } else {
      this.tableData.filter((d: any) => {
        if (this.externalProvideToggle) {
          this.paymentTender.get(d.externalSubType).reset();
          this.paymentTender.get(d.externalType).reset();
          this.paymentTender.get(d.tenderItemKey).reset();

          this.paymentTender.get(d.externalSubType).clearValidators();
          this.paymentTender.get(d.externalType).clearValidators();
          this.paymentTender.get(d.externalType).updateValueAndValidity();
          this.paymentTender.get(d.externalSubType).updateValueAndValidity();
        }
        this.paymentTender.get(d.displayName).reset();
        this.paymentTender.get(d.authCode).reset();
        this.paymentTender.get(d.authCode).clearValidators();
        this.paymentTender.get(d.authCode).updateValueAndValidity();
      });

      this.selectedProducts = [];
      this.tableRowDisabled = [];
    }
    this.selectedRowData.emit({
      selectedProducts: this.selectedProducts,
      knetSelectedData: this.knetSelectedData
    });
  }
  knetTableRowSelect(event: any) {
    this.knetSelectedData.push(event.data);

    this.knetTableRowDisabled.push(event.data.id);

    let temarray = this.knetTableData.filter((d: any) => {
      return !this.knetTableRowDisabled.includes(d.id);
    });

    temarray = [...this.knetSelectedData, ...temarray];
    this.knetTableData = [...new Set(temarray)];
    this.selectedRowData.emit({
      selectedProducts: this.selectedProducts,
      knetSelectedData: this.knetSelectedData
    });
  }
  knetTableRowUnSelect(event: any) {
    this.knetTableRowDisabled = this.knetTableRowDisabled.filter((d: any) => {
      return d !== event.data.id;
    });

    let temarray = this.knetTableData.filter((d: any) => {
      return !this.knetTableRowDisabled.includes(d.id) && d.id !== event.data.id;
    });
    this.knetSelectedData = this.knetSelectedData.filter((s: any) => {
      return s.id !== event.data.id;
    });
    temarray = [...new Set(this.knetSelectedData), ...temarray];

    this.knetTableData = [...new Set(temarray)];
    this.knetTableData.push(event.data);
    this.selectedRowData.emit({
      selectedProducts: this.selectedProducts,
      knetSelectedData: this.knetSelectedData
    });
  }
  knetTableAllRowSelect(data: any) {
    if (data.checked) {
      this.knetSelectedData = this.knetTableData;
      this.knetTableRowDisabled = this.knetTableData.map((t: any) => {
        return t.id;
      });
    } else {
      this.knetSelectedData = [];
      this.knetTableRowDisabled = [];
    }
    this.selectedRowData.emit({
      selectedProducts: this.selectedProducts,
      knetSelectedData: this.knetSelectedData
    });
  }
  rowReorder(data: any) {
    const tempt = [];

    if (data.dropIndex > data.dragIndex) {
      const temp = this.selectedProducts[data.dragIndex];
      delete this.selectedProducts[data.dragIndex];
      this.selectedProducts.forEach((items) => {
        tempt.push(items);
      });
      tempt.splice(data.dropIndex, 0, temp);
      this.selectedProducts = tempt;
    } else {
      this.selectedProducts.splice(data.dropIndex, 0, this.selectedProducts[data.dragIndex]);
      this.selectedProducts = [...new Set(this.selectedProducts)];
    }

    this.selectedRowData.emit({
      selectedProducts: this.selectedProducts,
      knetSelectedData: this.knetSelectedData
    });
  }

  knetRowReorder(data: any) {
    const tempt = [];

    if (data.dropIndex > data.dragIndex) {
      const temp = this.knetSelectedData[data.dragIndex];
      delete this.knetSelectedData[data.dragIndex];
      this.knetSelectedData.forEach((items) => {
        tempt.push(items);
      });
      tempt.splice(data.dropIndex, 0, temp);
      this.knetSelectedData = tempt;
    } else {
      this.knetSelectedData.splice(data.dropIndex, 0, this.knetSelectedData[data.dragIndex]);
      this.knetSelectedData = [...new Set(this.knetSelectedData)];
    }

    this.selectedRowData.emit({
      selectedProducts: this.selectedProducts,
      knetSelectedData: this.knetSelectedData
    });
  }
}
