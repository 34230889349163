import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IApiResponse } from '@shared/interfaces';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from '../auth.service';
import { LocalStorageJwtService } from '../local-storage-jwt.service';
import * as AuthActions from './auth.actions';
import { UserLogin, UserLoginResponseDecoded } from './auth.model';
import { AuthState } from './auth.reducer';
import { authQuery } from './auth.selectors';

@Injectable()
export class AuthFacade {
  auht$ = this.store.select(authQuery.getAuth);
  user$ = this.store.select(authQuery.getUser);
  isLoggedIn$ = this.store.select(authQuery.getLoggedIn);
  userModule$ = this.store.select(authQuery.getUserModules);
  private loginErrorSrc = new BehaviorSubject<any>('');
  loginError = this.loginErrorSrc.asObservable();
  private isRedErrorSrc = new BehaviorSubject<any>(false);
  isRedError = this.isRedErrorSrc.asObservable();
  private isGrayErrorSrc = new BehaviorSubject<any>(false);
  isGrayError = this.isGrayErrorSrc.asObservable();

  constructor(
    private store: Store<AuthState>,
    private readonly localStorageJwtService: LocalStorageJwtService,
    private service: AuthService
  ) {}

  login(userLogin: UserLogin) {
    this.store.dispatch(AuthActions.login({ login: userLogin }));
  }

  logout() {
    if (this.localStorageJwtService.getItem()) {
      this.service.logout().subscribe(
        (response: IApiResponse) => {
          console.log(response);
          this.store.dispatch(AuthActions.logout());
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }

  user(loginResponse: UserLoginResponseDecoded = this.localStorageJwtService.getDecodedItem()) {
    this.store.dispatch(AuthActions.getUser({ loginResponse }));
  }

  refreshUser(
    loginResponse: UserLoginResponseDecoded = this.localStorageJwtService.getDecodedItem()
  ) {
    this.store.dispatch(AuthActions.refreshUser({ loginResponse }));
  }

  module() {
    this.store.dispatch(AuthActions.getUserModulesByRole());
  }

  setPermission(
    loginResponse: UserLoginResponseDecoded = this.localStorageJwtService.getDecodedItem()
  ) {
    this.store.dispatch(AuthActions.setUserPermission({ loginResponse }));
  }

  setLoginError(loginError: string, isError: boolean, color: string) {
    if (color === 'gray') {
      this.isGrayErrorSrc.next(isError);
      this.isRedErrorSrc.next(false);
    } else if (color === 'red') {
      this.isRedErrorSrc.next(isError);
      this.isGrayErrorSrc.next(false);
    } else {
      this.isRedErrorSrc.next(isError);
      this.isGrayErrorSrc.next(isError);
    }
    this.loginErrorSrc.next(loginError);
  }
}
