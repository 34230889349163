import {
  Component,
  Input,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
  OnChanges
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  FormArray,
  Validators
} from '@angular/forms';
import {
  DeilveryImportProviders,
  ISOTKMgmtSettings,
  Providers,
  SalesImportProviders,
  DeilveryExportProviders,
  DispatchExportProviders
} from '@shared/interfaces';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-module-assignment-sotk-settings',
  templateUrl: './sotk-settings.component.html',
  styleUrls: ['./sotk-settings.component.scss']
})
export class SotkSettingsComponent implements OnInit, OnChanges {
  @ViewChild('titleContainer', { static: true }) public titleContainer: any;
  exceptionDataSource = new BehaviorSubject<AbstractControl[]>([]);
  execeptionColumns: any[] = ['buttonName', 'savedSearchName', 'deleteBtn'];
  hostName: any;
  isToggleDeliveryImport = true;
  isToggleDeliveryExport = true;
  isToggleDispatchExport = true;
  isToggleStokMovement = true;
  sotkModule = true;
  deliveryImportProviderOptions: any[] = [DeilveryImportProviders];
  enableSotkDeliveryImportBILDetails = [false];
  deliveryExportProviderOptions: any[] = [DeilveryExportProviders];
  enableSotkDeliveryExportBILDetails = [false];
  dispatchExportProviderOptions: any[] = [DispatchExportProviders];
  enableSotkDispatchExportBILDetails = [false];
  @Input() action: string;
  @Input() moduleIcon: string;
  @Input() parentForm: FormGroup;
  @Input() sotkMgmtSettings: ISOTKMgmtSettings[];
  @Output() deletedRow: EventEmitter<number> = new EventEmitter();
  @Input() eBasketMgmtSettings: any;
  @Input() frmModuleAssignment: any;
  @Input() nodeType?: any;
  sotkDeliveryImportBILDetails: any[] = [];
  sotkDeliveryImportBILCronJonConfig: any;
  sotkDeliveryExportBILDetails: any[] = [];
  sotkDeliveryExportBILCronJonConfig: any;
  sotkDispatchExportBILDetails: any[] = [];
  sotkDispatchExportBILCronJonConfig: any;
  providers = Providers;
  exceptionArray: FormArray = this.formBuilder.array([]);
  isAddBtnEnabled = false;
  disableDeliveryImport = false;
  minutes: number[] = Array.from({ length: 60 }, (_, i) => i);
  hours: number[] = Array.from({ length: 24 }, (_, i) => i);
  values: any[] = this.minutes;
  timeUnits: any[] = [
    { value: 'minutes', viewValue: 'minutes' },
    { value: 'hours', viewValue: 'hours' }
  ];
  get dc() {
    return this.parentForm;
  }
  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.hostName = window.location.origin;
    console.log('parentform ->', this.parentForm);
    console.log('sotkMgmtSettings', this.sotkMgmtSettings);
    console.log('nodeType', this.nodeType);

    this.initForm();
    console.log('after init', this.parentForm.get('sotkSettings'));
    if (
      this.sotkMgmtSettings &&
      this.sotkMgmtSettings[0] &&
      this.sotkMgmtSettings[0].stokMovement &&
      this.sotkMgmtSettings[0].stokMovement.status &&
      this.sotkMgmtSettings[0].stokMovement.deliveryImport &&
      this.sotkMgmtSettings[0].stokMovement.deliveryImport.status
    ) {
      this.bindSotkDeliveryImport();
    }
    if (
      this.sotkMgmtSettings &&
      this.sotkMgmtSettings[0] &&
      this.sotkMgmtSettings[0].stokMovement &&
      this.sotkMgmtSettings[0].stokMovement.status &&
      this.sotkMgmtSettings[0].stokMovement.deliveryExport &&
      this.sotkMgmtSettings[0].stokMovement.deliveryExport.status
    ) {
      this.bindSotkDeliveryExport();
    }
    if (
      this.sotkMgmtSettings &&
      this.sotkMgmtSettings[0] &&
      this.sotkMgmtSettings[0].stokMovement &&
      this.sotkMgmtSettings[0].stokMovement.status &&
      this.sotkMgmtSettings[0].stokMovement.dispatchExport &&
      this.sotkMgmtSettings[0].stokMovement.dispatchExport.status
    ) {
      this.bindSotkDispatchExport();
    }
    if (this.nodeType === 'COMPANY' || this.nodeType === 'ORGANIZATION') {
      this.disableDeliveryImport = false;
    } else {
      this.parentForm.get('sotkSettings.deliveryImport').setValue(false);
      this.toggleDeliveryImport();
      this.disableDeliveryImport = true;
    }
    if (!this.parentForm.get('sotkSettings.deliveryImport').value) {
      this.deleteAllSotkDeliveryImportApiDetail();
    }
    if (!this.parentForm.get('sotkSettings.deliveryExport').value) {
      this.deleteAllSotkDeliveryExportApiDetail();
    }
    if (!this.parentForm.get('sotkSettings.dispatchExport').value) {
      this.deleteAllSotkDispatchExportApiDetail();
    }

    console.log('parentform 2 ->', this.parentForm);
  }

  bindSotkDeliveryImport() {
    if (
      this.sotkMgmtSettings &&
      this.sotkMgmtSettings[0] &&
      this.sotkMgmtSettings[0].stokMovement &&
      this.sotkMgmtSettings[0].stokMovement.status &&
      this.sotkMgmtSettings[0].stokMovement.deliveryImport &&
      this.sotkMgmtSettings[0].stokMovement.deliveryImport.status
    ) {
      console.log('started binding... ');
      this.isToggleDeliveryImport = false;
      this.isToggleStokMovement = false;
      const sotkDeliveryImportApiDetails = this.parentForm.get(
        'sotkSettings.sotkDeliveryImportApiDetails'
      ) as FormArray;
      let providers = [];
      let insertedProviders = [];
      if (
        this.sotkMgmtSettings &&
        this.sotkMgmtSettings[0] &&
        this.sotkMgmtSettings[0].stokMovement &&
        this.sotkMgmtSettings[0].stokMovement.status &&
        this.sotkMgmtSettings[0].stokMovement.deliveryImport &&
        this.sotkMgmtSettings[0].stokMovement.deliveryImport.status &&
        this.sotkMgmtSettings[0].stokMovement.deliveryImport.providers &&
        this.sotkMgmtSettings[0].stokMovement.deliveryImport.providers.length
      ) {
        providers = this.sotkMgmtSettings[0].stokMovement.deliveryImport.providers;
        insertedProviders = this.sotkMgmtSettings[0].stokMovement.deliveryImport.providers.map(
          (p) => {
            return p.provider;
          }
        );
      }

      console.log('providers');
      console.log(providers);
      providers.forEach((providerDetail, sindex) => {
        const selectedProviders = this.parentForm
          .get('sotkSettings.sotkDeliveryImportApiDetails')
          .value.map((p) => {
            return p.providerControls;
          });

        this.deliveryImportProviderOptions = this.deliveryImportProviderOptions
          .map((e, index) => {
            if (index === 0) {
              return e;
            } else if (
              index < this.parentForm.get('sotkSettings.sotkDeliveryImportApiDetails').value.length
            ) {
              return e;
            }
            // tslint:disable-next-line: only-arrow-functions
          })
          .filter((element) => {
            return element !== undefined;
          });

        this.deliveryImportProviderOptions.map((e, index) => {
          if (this.parentForm.get('sotkSettings.sotkDeliveryImportApiDetails').value.length > 0) {
            console.log(
              this.parentForm.get('sotkSettings.sotkDeliveryImportApiDetails').value[index]
                .providerControls
            );

            const data = e.filter((ea) => {
              if (
                this.parentForm.get('sotkSettings.sotkDeliveryImportApiDetails').value[index]
                  .providerControls === ea.value
              ) {
                return ea;
              } else if (!selectedProviders.includes(ea.value)) {
                return ea;
              }
            });
            const providerslenght = index + 1;
            if (
              this.parentForm.get('sotkSettings.sotkDeliveryImportApiDetails').value.length >
              providerslenght
            ) {
              this.deliveryImportProviderOptions[index] = data;

              console.log('insi de If', this.deliveryImportProviderOptions.length, index);
              console.log(data);
            } else {
              this.deliveryImportProviderOptions[index] = data;
              console.log(this.deliveryImportProviderOptions.length, index);

              const newProviderlist = e.filter((ea) => {
                if (!selectedProviders.includes(ea.value)) {
                  return ea;
                }
              });
              this.deliveryImportProviderOptions.push(newProviderlist);
            }
          }
        });

        sotkDeliveryImportApiDetails.push(
          this.createSelectProviderFormGroup(providerDetail.provider)
        );
        this.changeStokDeliveryImportProvider(providerDetail.provider, sindex);

        if (providerDetail.provider === Providers.BIL) {
          this.sotkDeliveryImportBILDetails = providerDetail;
          this.sotkDeliveryImportBILCronJonConfig = providerDetail.cronJobConfiguration;
        }
      });
    }
  }

  bindSotkDeliveryExport() {
    if (
      this.sotkMgmtSettings &&
      this.sotkMgmtSettings[0] &&
      this.sotkMgmtSettings[0].stokMovement &&
      this.sotkMgmtSettings[0].stokMovement.status &&
      this.sotkMgmtSettings[0].stokMovement.deliveryExport &&
      this.sotkMgmtSettings[0].stokMovement.deliveryExport.status
    ) {
      console.log('started binding... ');
      this.isToggleDeliveryExport = false;
      this.isToggleStokMovement = false;
      const sotkDeliveryExportApiDetails = this.parentForm.get(
        'sotkSettings.sotkDeliveryExportApiDetails'
      ) as FormArray;
      let providers = [];
      let insertedProviders = [];
      if (
        this.sotkMgmtSettings &&
        this.sotkMgmtSettings[0] &&
        this.sotkMgmtSettings[0].stokMovement &&
        this.sotkMgmtSettings[0].stokMovement.status &&
        this.sotkMgmtSettings[0].stokMovement.deliveryExport &&
        this.sotkMgmtSettings[0].stokMovement.deliveryExport.status &&
        this.sotkMgmtSettings[0].stokMovement.deliveryExport.providers &&
        this.sotkMgmtSettings[0].stokMovement.deliveryExport.providers.length
      ) {
        providers = this.sotkMgmtSettings[0].stokMovement.deliveryExport.providers;
        insertedProviders = this.sotkMgmtSettings[0].stokMovement.deliveryExport.providers.map(
          (p) => {
            return p.provider;
          }
        );
      }

      console.log('providers');
      console.log(providers);
      providers.forEach((providerDetail, sindex) => {
        const selectedProviders = this.parentForm
          .get('sotkSettings.sotkDeliveryExportApiDetails')
          .value.map((p) => {
            return p.providerControls;
          });

        this.deliveryExportProviderOptions = this.deliveryExportProviderOptions
          .map((e, index) => {
            if (index === 0) {
              return e;
            } else if (
              index < this.parentForm.get('sotkSettings.sotkDeliveryExportApiDetails').value.length
            ) {
              return e;
            }
            // tslint:disable-next-line: only-arrow-functions
          })
          .filter((element) => {
            return element !== undefined;
          });

        this.deliveryExportProviderOptions.map((e, index) => {
          if (this.parentForm.get('sotkSettings.sotkDeliveryExportApiDetails').value.length > 0) {
            console.log(
              this.parentForm.get('sotkSettings.sotkDeliveryExportApiDetails').value[index]
                .providerControls
            );

            const data = e.filter((ea) => {
              if (
                this.parentForm.get('sotkSettings.sotkDeliveryExportApiDetails').value[index]
                  .providerControls === ea.value
              ) {
                return ea;
              } else if (!selectedProviders.includes(ea.value)) {
                return ea;
              }
            });
            const providerslenght = index + 1;
            if (
              this.parentForm.get('sotkSettings.sotkDeliveryExportApiDetails').value.length >
              providerslenght
            ) {
              this.deliveryExportProviderOptions[index] = data;

              console.log('insi de If', this.deliveryExportProviderOptions.length, index);
              console.log(data);
            } else {
              this.deliveryExportProviderOptions[index] = data;
              console.log(this.deliveryExportProviderOptions.length, index);

              const newProviderlist = e.filter((ea) => {
                if (!selectedProviders.includes(ea.value)) {
                  return ea;
                }
              });
              this.deliveryExportProviderOptions.push(newProviderlist);
            }
          }
        });

        sotkDeliveryExportApiDetails.push(
          this.createSelectProviderFormGroup(providerDetail.provider)
        );
        this.changeStokDeliveryExportProvider(providerDetail.provider, sindex);

        if (providerDetail.provider === Providers.BIL) {
          this.sotkDeliveryExportBILDetails = providerDetail;
          this.sotkDeliveryExportBILCronJonConfig = providerDetail.cronJobConfiguration;
        }
      });
    }
  }

  bindSotkDispatchExport() {
    if (
      this.sotkMgmtSettings &&
      this.sotkMgmtSettings[0] &&
      this.sotkMgmtSettings[0].stokMovement &&
      this.sotkMgmtSettings[0].stokMovement.status &&
      this.sotkMgmtSettings[0].stokMovement.dispatchExport &&
      this.sotkMgmtSettings[0].stokMovement.dispatchExport.status
    ) {
      console.log('started binding... ');
      this.isToggleDispatchExport = false;
      this.isToggleStokMovement = false;
      const sotkDispatchExportApiDetails = this.parentForm.get(
        'sotkSettings.sotkDispatchExportApiDetails'
      ) as FormArray;
      let providers = [];
      let insertedProviders = [];
      if (
        this.sotkMgmtSettings &&
        this.sotkMgmtSettings[0] &&
        this.sotkMgmtSettings[0].stokMovement &&
        this.sotkMgmtSettings[0].stokMovement.status &&
        this.sotkMgmtSettings[0].stokMovement.dispatchExport &&
        this.sotkMgmtSettings[0].stokMovement.dispatchExport.status &&
        this.sotkMgmtSettings[0].stokMovement.dispatchExport.providers &&
        this.sotkMgmtSettings[0].stokMovement.dispatchExport.providers.length
      ) {
        providers = this.sotkMgmtSettings[0].stokMovement.dispatchExport.providers;
        insertedProviders = this.sotkMgmtSettings[0].stokMovement.dispatchExport.providers.map(
          (p) => {
            return p.provider;
          }
        );
      }

      console.log('providers');
      console.log(providers);
      providers.forEach((providerDetail, sindex) => {
        const selectedProviders = this.parentForm
          .get('sotkSettings.sotkDispatchExportApiDetails')
          .value.map((p) => {
            return p.providerControls;
          });

        this.dispatchExportProviderOptions = this.dispatchExportProviderOptions
          .map((e, index) => {
            if (index === 0) {
              return e;
            } else if (
              index < this.parentForm.get('sotkSettings.sotkDispatchExportApiDetails').value.length
            ) {
              return e;
            }
            // tslint:disable-next-line: only-arrow-functions
          })
          .filter((element) => {
            return element !== undefined;
          });

        this.dispatchExportProviderOptions.map((e, index) => {
          if (this.parentForm.get('sotkSettings.sotkDispatchExportApiDetails').value.length > 0) {
            console.log(
              this.parentForm.get('sotkSettings.sotkDispatchExportApiDetails').value[index]
                .providerControls
            );

            const data = e.filter((ea) => {
              if (
                this.parentForm.get('sotkSettings.sotkDispatchExportApiDetails').value[index]
                  .providerControls === ea.value
              ) {
                return ea;
              } else if (!selectedProviders.includes(ea.value)) {
                return ea;
              }
            });
            const providerslenght = index + 1;
            if (
              this.parentForm.get('sotkSettings.sotkDispatchExportApiDetails').value.length >
              providerslenght
            ) {
              this.dispatchExportProviderOptions[index] = data;

              console.log('insi de If', this.dispatchExportProviderOptions.length, index);
              console.log(data);
            } else {
              this.dispatchExportProviderOptions[index] = data;
              console.log(this.dispatchExportProviderOptions.length, index);

              const newProviderlist = e.filter((ea) => {
                if (!selectedProviders.includes(ea.value)) {
                  return ea;
                }
              });
              this.dispatchExportProviderOptions.push(newProviderlist);
            }
          }
        });

        sotkDispatchExportApiDetails.push(
          this.createSelectProviderFormGroup(providerDetail.provider)
        );
        this.changeStokDispatchExportProvider(providerDetail.provider, sindex);

        if (providerDetail.provider === Providers.BIL) {
          this.sotkDispatchExportBILDetails = providerDetail;
          this.sotkDispatchExportBILCronJonConfig = providerDetail.cronJobConfiguration;
        }
      });
    }
  }

  toggleDeliveryImport() {
    this.isToggleDeliveryImport = this.parentForm.get('sotkSettings.deliveryImport').value
      ? false
      : true;

    if (!this.parentForm.get('sotkSettings.deliveryImport').value) {
      this.deleteAllSotkDeliveryImportApiDetail();
    }
  }

  toggleDeliveryExport() {
    this.isToggleDeliveryExport = this.parentForm.get('sotkSettings.deliveryExport').value
      ? false
      : true;
    if (!this.parentForm.get('sotkSettings.deliveryExport').value) {
      this.deleteAllSotkDeliveryExportApiDetail();
    }
  }

  toggleDispatchExport() {
    this.isToggleDispatchExport = this.parentForm.get('sotkSettings.dispatchExport').value
      ? false
      : true;
    if (!this.parentForm.get('sotkSettings.dispatchExport').value) {
      this.deleteAllSotkDispatchExportApiDetail();
    }
  }

  updateTimeControl() {
    const timeUnitValue = this.parentForm.get('sotkSettings').value.timeUnit;
    if (timeUnitValue === 'hours') {
      this.values = this.hours;
    } else {
      this.values = this.minutes;
    }
    this.parentForm
      .get('sotkSettings')
      .get('timeValue')
      .setValue(0);
  }

  initForm() {
    if (this.parentForm.get('sotkSettings')) {
      this.parentForm.get('sotkSettings').reset();
      this.parentForm.removeControl('sotkSettings');
    }
    this.parentForm.addControl(
      'sotkSettings',
      this.formBuilder.group({
        expectedQuantity: [
          this.sotkMgmtSettings && this.sotkMgmtSettings.length
            ? this.sotkMgmtSettings[0].expectedQuantity || false
            : false,
          { disabled: this.action === 'view' }
        ],
        location: [
          this.sotkMgmtSettings && this.sotkMgmtSettings.length
            ? this.sotkMgmtSettings[0].location || false
            : false,
          { disabled: this.action === 'view' }
        ],
        mod10: [
          this.sotkMgmtSettings && this.sotkMgmtSettings.length
            ? this.sotkMgmtSettings[0].mod10 || false
            : false,
          { disabled: this.action === 'view' }
        ],
        mod11: [
          this.sotkMgmtSettings && this.sotkMgmtSettings.length
            ? this.sotkMgmtSettings[0].mod11 || false
            : false,
          { disabled: this.action === 'view' }
        ],
        stokCount: [
          this.sotkMgmtSettings && this.sotkMgmtSettings.length
            ? this.sotkMgmtSettings[0].stokCount || false
            : false,
          { disabled: this.action === 'view' }
        ],
        appName: [
          this.sotkMgmtSettings &&
          this.sotkMgmtSettings.length &&
          this.sotkMgmtSettings[0].splunkReportConfiguration &&
          this.sotkMgmtSettings[0].splunkReportConfiguration.authenticationDetailsKey
            ? Providers.SECRET
            : '',
          { disabled: this.action === 'view' }
        ],
        userName: [
          this.sotkMgmtSettings &&
          this.sotkMgmtSettings.length &&
          this.sotkMgmtSettings[0].splunkReportConfiguration &&
          this.sotkMgmtSettings[0].splunkReportConfiguration.authenticationDetailsKey
            ? Providers.SECRET
            : '',
          { disabled: this.action === 'view' }
        ],
        password: [
          this.sotkMgmtSettings &&
          this.sotkMgmtSettings.length &&
          this.sotkMgmtSettings[0].splunkReportConfiguration &&
          this.sotkMgmtSettings[0].splunkReportConfiguration.authenticationDetailsKey
            ? Providers.SECRET
            : '',
          { disabled: this.action === 'view' }
        ],
        host: [
          this.sotkMgmtSettings &&
          this.sotkMgmtSettings.length &&
          this.sotkMgmtSettings[0].splunkReportConfiguration &&
          this.sotkMgmtSettings[0].splunkReportConfiguration.authenticationDetailsKey
            ? Providers.SECRET
            : '',
          { disabled: this.action === 'view' }
        ],
        port: [
          this.sotkMgmtSettings &&
          this.sotkMgmtSettings.length &&
          this.sotkMgmtSettings[0].splunkReportConfiguration &&
          this.sotkMgmtSettings[0].splunkReportConfiguration.authenticationDetailsKey
            ? Providers.SECRET
            : '',
          { disabled: this.action === 'view' }
        ],
        addReportBtn: [
          this.sotkMgmtSettings &&
          this.sotkMgmtSettings.length &&
          this.sotkMgmtSettings[0].addReportBtn
            ? this.sotkMgmtSettings[0].addReportBtn || false
            : false,
          { disabled: this.action === 'view' }
        ],
        authenticationDetailsKey: [
          this.sotkMgmtSettings &&
          this.sotkMgmtSettings.length &&
          this.sotkMgmtSettings[0].splunkReportConfiguration &&
          this.sotkMgmtSettings[0].splunkReportConfiguration.authenticationDetailsKey
            ? this.sotkMgmtSettings[0].splunkReportConfiguration.authenticationDetailsKey
            : ''
        ],
        stokMovementStatus: [
          {
            value:
              this.sotkMgmtSettings &&
              this.sotkMgmtSettings[0] &&
              this.sotkMgmtSettings[0].stokMovement &&
              this.sotkMgmtSettings[0].stokMovement.status
                ? true
                : false,
            disabled: this.action === 'view' ? true : false
          }
        ],
        deliveryImport: [
          {
            value:
              this.sotkMgmtSettings &&
              this.sotkMgmtSettings[0] &&
              this.sotkMgmtSettings[0].stokMovement &&
              this.sotkMgmtSettings[0].stokMovement.deliveryImport &&
              this.sotkMgmtSettings[0].stokMovement.deliveryImport.status
                ? true
                : false,
            disabled: this.action === 'view' ? true : false
          }
        ],
        deliveryExport: [
          {
            value:
              this.sotkMgmtSettings &&
              this.sotkMgmtSettings[0] &&
              this.sotkMgmtSettings[0].stokMovement &&
              this.sotkMgmtSettings[0].stokMovement.deliveryExport &&
              this.sotkMgmtSettings[0].stokMovement.deliveryExport.status
                ? true
                : false,
            disabled: this.action === 'view' ? true : false
          }
        ],
        dispatchExport: [
          {
            value:
              this.sotkMgmtSettings &&
              this.sotkMgmtSettings[0] &&
              this.sotkMgmtSettings[0].stokMovement &&
              this.sotkMgmtSettings[0].stokMovement.dispatchExport &&
              this.sotkMgmtSettings[0].stokMovement.dispatchExport.status
                ? true
                : false,
            disabled: this.action === 'view' ? true : false
          }
        ],
        deliveryImportBILAuthenticationDetailsKey: [
          {
            value:
              this.sotkMgmtSettings &&
              this.sotkMgmtSettings[0] &&
              this.sotkMgmtSettings[0].stokMovement &&
              this.sotkMgmtSettings[0].stokMovement.status &&
              this.sotkMgmtSettings[0].stokMovement.deliveryImport &&
              this.sotkMgmtSettings[0].stokMovement.deliveryImport.status &&
              this.sotkMgmtSettings[0].stokMovement.deliveryImport.providers &&
              this.sotkMgmtSettings[0].stokMovement.deliveryImport.providers.length &&
              this.sotkMgmtSettings[0].stokMovement.deliveryImport.providers[0].provider ===
                Providers.BIL
                ? this.sotkMgmtSettings[0].stokMovement.deliveryImport.providers[0]
                    .authenticationDetailsKey
                : ''
          }
        ],
        deliveryExportBILAuthenticationDetailsKey: [
          {
            value:
              this.sotkMgmtSettings &&
              this.sotkMgmtSettings[0] &&
              this.sotkMgmtSettings[0].stokMovement &&
              this.sotkMgmtSettings[0].stokMovement.status &&
              this.sotkMgmtSettings[0].stokMovement.deliveryExport &&
              this.sotkMgmtSettings[0].stokMovement.deliveryExport.status &&
              this.sotkMgmtSettings[0].stokMovement.deliveryExport.providers &&
              this.sotkMgmtSettings[0].stokMovement.deliveryExport.providers.length &&
              this.sotkMgmtSettings[0].stokMovement.deliveryExport.providers[0].provider ===
                Providers.BIL
                ? this.sotkMgmtSettings[0].stokMovement.deliveryExport.providers[0]
                    .authenticationDetailsKey
                : ''
          }
        ],
        dispatchExportBILAuthenticationDetailsKey: [
          {
            value:
              this.sotkMgmtSettings &&
              this.sotkMgmtSettings[0] &&
              this.sotkMgmtSettings[0].stokMovement &&
              this.sotkMgmtSettings[0].stokMovement.status &&
              this.sotkMgmtSettings[0].stokMovement.dispatchExport &&
              this.sotkMgmtSettings[0].stokMovement.dispatchExport.status &&
              this.sotkMgmtSettings[0].stokMovement.dispatchExport.providers &&
              this.sotkMgmtSettings[0].stokMovement.dispatchExport.providers.length &&
              this.sotkMgmtSettings[0].stokMovement.dispatchExport.providers[0].provider ===
                Providers.BIL
                ? this.sotkMgmtSettings[0].stokMovement.dispatchExport.providers[0]
                    .authenticationDetailsKey
                : ''
          }
        ],
        timeUnit: [
          {
            value:
              this.sotkMgmtSettings &&
              this.sotkMgmtSettings.length &&
              this.sotkMgmtSettings[0].timeUnit
                ? this.sotkMgmtSettings[0].timeUnit
                : 'minutes',
            disabled: this.action === 'view'
          }
        ],
        timeValue: [
          {
            value:
              this.sotkMgmtSettings &&
              this.sotkMgmtSettings.length &&
              this.sotkMgmtSettings[0].timeValue
                ? this.sotkMgmtSettings[0].timeValue
                : 0,
            disabled: this.action === 'view'
          }
        ],
        sotkDeliveryImportApiDetails: this.formBuilder.array([]),
        sotkDeliveryExportApiDetails: this.formBuilder.array([]),
        sotkDispatchExportApiDetails: this.formBuilder.array([]),
        /*,
        reportButton: [] */
        reportButton: this.formBuilder.array([])
      })
    );

    if (this.action === 'view') {
      this.parentForm.disable();
    } else {
      this.parentForm.enable();
    }
    if (
      this.sotkMgmtSettings &&
      this.sotkMgmtSettings.length &&
      this.sotkMgmtSettings[0].addReportBtn
    ) {
      this.isAddBtnEnabled = this.sotkMgmtSettings[0].addReportBtn;
    }

    this.loadReportBtnData();
  }

  loadReportBtnData() {
    if (
      this.sotkMgmtSettings &&
      this.sotkMgmtSettings.length &&
      this.sotkMgmtSettings[0]['reportButton']
    ) {
      const control = this.parentForm.controls.sotkSettings['controls'].reportButton as FormArray;
      control.clear();
      this.updateExceptionView();
      this.sotkMgmtSettings[0].reportButton.forEach((val) => {
        const row = this.formBuilder.group({
          buttonName: [
            val.buttonName ? val.buttonName : '',
            [Validators.required, Validators.maxLength(30)]
          ],
          savedSearchName: [val.savedSearchName ? val.savedSearchName : '']
        });
        if (this.action === 'view') {
          row.disable();
        } else {
          row.enable();
        }
        control.push(row);
      });
      this.updateExceptionView();
    }
  }

  private createSelectProviderFormGroup(providerName?: string): FormGroup {
    providerName = providerName ? providerName : '';
    let form = new FormGroup({});
    form = new FormGroup({
      providerControls: new FormControl(providerName, Validators.required)
    });
    return form;
  }

  ngOnChanges(value) {
    console.log('value changes ->', value);
    if (value.sotkMgmtSettings) {
      this.sotkMgmtSettings = value.sotkMgmtSettings.currentValue;
      this.ngOnInit();
    }
  }

  openSotkDeliveryImportSelectApiPopup() {
    const sotkDeliveryImportApiDetails = this.parentForm.get(
      'sotkSettings.sotkDeliveryImportApiDetails'
    ) as FormArray;
    this.assignDeliveryImportProviders();
    console.log(this.deliveryImportProviderOptions);
    sotkDeliveryImportApiDetails.push(this.createSelectProviderFormGroup());
    console.log('sotkDeliveryImportApiDetails');
    console.log(sotkDeliveryImportApiDetails.value);

    const i = this.parentForm.get('sotkSettings.sotkDeliveryImportApiDetails').value.length - 1;

    this.enableSotkDeliveryImportBILDetails[i] = false;
  }

  openSotkDeliveryExportSelectApiPopup() {
    const sotkDeliveryExportApiDetails = this.parentForm.get(
      'sotkSettings.sotkDeliveryExportApiDetails'
    ) as FormArray;
    this.assignDeliveryExportProviders();
    console.log(this.deliveryExportProviderOptions);
    sotkDeliveryExportApiDetails.push(this.createSelectProviderFormGroup());
    console.log('sotkDeliveryExportApiDetails');
    console.log(sotkDeliveryExportApiDetails.value);

    const i = this.parentForm.get('sotkSettings.sotkDeliveryExportApiDetails').value.length - 1;

    this.enableSotkDeliveryExportBILDetails[i] = false;
  }

  openSotkDispatchExportSelectApiPopup() {
    const sotkDispatchExportApiDetails = this.parentForm.get(
      'sotkSettings.sotkDispatchExportApiDetails'
    ) as FormArray;
    this.assignDispatchExportProviders();
    console.log(this.dispatchExportProviderOptions);
    sotkDispatchExportApiDetails.push(this.createSelectProviderFormGroup());
    console.log('sotkDispatchExportApiDetails');
    console.log(sotkDispatchExportApiDetails.value);

    const i = this.parentForm.get('sotkSettings.sotkDispatchExportApiDetails').value.length - 1;

    this.enableSotkDispatchExportBILDetails[i] = false;
  }

  enableDisableAddProvidersDeliveryImport() {
    return this.parentForm.get('sotkSettings.sotkDeliveryImportApiDetails').value.length ===
      DeilveryImportProviders.length
      ? true
      : false;
  }

  enableDisableAddProvidersDeliveryExport() {
    return this.parentForm.get('sotkSettings.sotkDeliveryExportApiDetails').value.length ===
      DeilveryExportProviders.length
      ? true
      : false;
  }

  enableDisableAddProvidersDispatchExport() {
    return this.parentForm.get('sotkSettings.sotkDispatchExportApiDetails').value.length ===
      DispatchExportProviders.length
      ? true
      : false;
  }

  isProviderSelectedDeliveryImport() {
    const sotkDeliveryImportApiDetails = this.parentForm.get(
      'sotkSettings.sotkDeliveryImportApiDetails'
    ) as FormArray;
    return sotkDeliveryImportApiDetails.value.some((p) => {
      return p.providerControls === '';
    });
  }

  isProviderSelectedDeliveryExport() {
    const sotkDeliveryExportApiDetails = this.parentForm.get(
      'sotkSettings.sotkDeliveryExportApiDetails'
    ) as FormArray;
    return sotkDeliveryExportApiDetails.value.some((p) => {
      return p.providerControls === '';
    });
  }

  isProviderSelectedDispatchExport() {
    const sotkDispatchExportApiDetails = this.parentForm.get(
      'sotkSettings.sotkDispatchExportApiDetails'
    ) as FormArray;
    return sotkDispatchExportApiDetails.value.some((p) => {
      return p.providerControls === '';
    });
  }

  toggleStokMovementStatus() {
    this.isToggleStokMovement = this.parentForm.get('sotkSettings.stokMovementStatus').value
      ? false
      : true;
    console.log('sotk setting grp', this.parentForm.get('sotkSettings'));
    if (!this.parentForm.get('sotkSettings.stokMovementStatus').value) {
      this.isToggleDeliveryImport = true;
      this.isToggleDeliveryExport = true;
      this.isToggleDispatchExport = true;
      this.deleteAllSotkDispatchExportApiDetail();
      this.deleteAllSotkDeliveryImportApiDetail();
      this.deleteAllSotkDeliveryExportApiDetail();
    }
  }

  changeStokDeliveryImportProvider(provider, i) {
    const selectedProviders = this.parentForm
      .get('sotkSettings.sotkDeliveryImportApiDetails')
      .value.map((p) => {
        return p.providerControls;
      });

    if (provider === Providers.BIL) {
      this.enableSotkDeliveryImportBILDetails[i] = true;
    }
    this.assignDeliveryImportProviders();
    DeilveryImportProviders.forEach((provider) => {
      if (!selectedProviders.includes(provider.value)) {
        if (provider.value === Providers.BIL) {
          this.enableSotkDeliveryImportBILDetails[i] = false;
          this.parentForm.removeControl('deliveryImportBILDetails');
          this.parentForm.removeControl('deliveryImportBILCronJobConfig');
        }
      }
    });
  }

  changeStokDeliveryExportProvider(provider, i) {
    const selectedProviders = this.parentForm
      .get('sotkSettings.sotkDeliveryExportApiDetails')
      .value.map((p) => {
        return p.providerControls;
      });

    if (provider === Providers.BIL) {
      this.enableSotkDeliveryExportBILDetails[i] = true;
    }
    this.assignDeliveryExportProviders();
    DeilveryExportProviders.forEach((provider) => {
      if (!selectedProviders.includes(provider.value)) {
        if (provider.value === Providers.BIL) {
          this.enableSotkDeliveryExportBILDetails[i] = false;
          this.parentForm.removeControl('deliveryExportBILDetails');
          this.parentForm.removeControl('deliveryExportBILCronJobConfig');
        }
      }
    });
  }

  changeStokDispatchExportProvider(provider, i) {
    const selectedProviders = this.parentForm
      .get('sotkSettings.sotkDispatchExportApiDetails')
      .value.map((p) => {
        return p.providerControls;
      });

    if (provider === Providers.BIL) {
      this.enableSotkDispatchExportBILDetails[i] = true;
    }
    this.assignDispatchExportProviders();
    DispatchExportProviders.forEach((provider) => {
      if (!selectedProviders.includes(provider.value)) {
        if (provider.value === Providers.BIL) {
          this.enableSotkDispatchExportBILDetails[i] = false;
          this.parentForm.removeControl('dispatchExportBILDetails');
          this.parentForm.removeControl('dispatchExportBILCronJobConfig');
        }
      }
    });
  }

  assignDeliveryImportProviders() {
    const selectedProviders = this.parentForm
      .get('sotkSettings.sotkDeliveryImportApiDetails')
      .value.map((p) => {
        return p.providerControls;
      });

    this.deliveryImportProviderOptions = this.deliveryImportProviderOptions
      .map((e, index) => {
        if (index === 0) {
          return e;
        } else if (
          index < this.parentForm.get('sotkSettings.sotkDeliveryImportApiDetails').value.length
        ) {
          return e;
        }
        // tslint:disable-next-line: only-arrow-functions
      })
      .filter((element) => {
        return element !== undefined;
      });
    console.log(this.parentForm.get('sotkSettings.sotkDeliveryImportApiDetails').value.length);

    console.log(this.deliveryImportProviderOptions);
    this.deliveryImportProviderOptions.map((e, index) => {
      if (this.parentForm.get('sotkSettings.sotkDeliveryImportApiDetails').value.length > 0) {
        console.log(
          this.parentForm.get('sotkSettings.sotkDeliveryImportApiDetails').value[index]
            .providerControls
        );

        const data = e.filter((ea) => {
          if (
            this.parentForm.get('sotkSettings.sotkDeliveryImportApiDetails').value[index]
              .providerControls === ea.value
          ) {
            return ea;
          } else if (!selectedProviders.includes(ea.value)) {
            return ea;
          }
        });
        const providerslenght = index + 1;
        if (
          this.parentForm.get('sotkSettings.sotkDeliveryImportApiDetails').value.length >
          providerslenght
        ) {
          this.deliveryImportProviderOptions[index] = data;

          console.log('insi de If', this.deliveryImportProviderOptions.length, index);
          console.log(data);
        } else {
          this.deliveryImportProviderOptions[index] = data;
          console.log(this.deliveryImportProviderOptions.length, index);

          const newProviderlist = e.filter((ea) => {
            if (!selectedProviders.includes(ea.value)) {
              return ea;
            }
          });
          this.deliveryImportProviderOptions.push(newProviderlist);
        }
      }
    });

    console.log('after assigning ->', this.deliveryImportProviderOptions);
  }

  assignDeliveryExportProviders() {
    const selectedProviders = this.parentForm
      .get('sotkSettings.sotkDeliveryExportApiDetails')
      .value.map((p) => {
        return p.providerControls;
      });

    this.deliveryExportProviderOptions = this.deliveryExportProviderOptions
      .map((e, index) => {
        if (index === 0) {
          return e;
        } else if (
          index < this.parentForm.get('sotkSettings.sotkDeliveryExportApiDetails').value.length
        ) {
          return e;
        }
        // tslint:disable-next-line: only-arrow-functions
      })
      .filter((element) => {
        return element !== undefined;
      });
    console.log(this.parentForm.get('sotkSettings.sotkDeliveryExportApiDetails').value.length);

    console.log(this.deliveryExportProviderOptions);
    this.deliveryExportProviderOptions.map((e, index) => {
      if (this.parentForm.get('sotkSettings.sotkDeliveryExportApiDetails').value.length > 0) {
        console.log(
          this.parentForm.get('sotkSettings.sotkDeliveryExportApiDetails').value[index]
            .providerControls
        );

        const data = e.filter((ea) => {
          if (
            this.parentForm.get('sotkSettings.sotkDeliveryExportApiDetails').value[index]
              .providerControls === ea.value
          ) {
            return ea;
          } else if (!selectedProviders.includes(ea.value)) {
            return ea;
          }
        });
        const providerslenght = index + 1;
        if (
          this.parentForm.get('sotkSettings.sotkDeliveryExportApiDetails').value.length >
          providerslenght
        ) {
          this.deliveryExportProviderOptions[index] = data;

          console.log('insi de If', this.deliveryExportProviderOptions.length, index);
          console.log(data);
        } else {
          this.deliveryExportProviderOptions[index] = data;
          console.log(this.deliveryExportProviderOptions.length, index);

          const newProviderlist = e.filter((ea) => {
            if (!selectedProviders.includes(ea.value)) {
              return ea;
            }
          });
          this.deliveryExportProviderOptions.push(newProviderlist);
        }
      }
    });

    console.log('after assigning ->', this.deliveryExportProviderOptions);
  }

  assignDispatchExportProviders() {
    const selectedProviders = this.parentForm
      .get('sotkSettings.sotkDispatchExportApiDetails')
      .value.map((p) => {
        return p.providerControls;
      });

    this.dispatchExportProviderOptions = this.dispatchExportProviderOptions
      .map((e, index) => {
        if (index === 0) {
          return e;
        } else if (
          index < this.parentForm.get('sotkSettings.sotkDispatchExportApiDetails').value.length
        ) {
          return e;
        }
        // tslint:disable-next-line: only-arrow-functions
      })
      .filter((element) => {
        return element !== undefined;
      });
    console.log(this.parentForm.get('sotkSettings.sotkDispatchExportApiDetails').value.length);

    console.log(this.dispatchExportProviderOptions);
    this.dispatchExportProviderOptions.map((e, index) => {
      if (this.parentForm.get('sotkSettings.sotkDispatchExportApiDetails').value.length > 0) {
        console.log(
          this.parentForm.get('sotkSettings.sotkDispatchExportApiDetails').value[index]
            .providerControls
        );

        const data = e.filter((ea) => {
          if (
            this.parentForm.get('sotkSettings.sotkDispatchExportApiDetails').value[index]
              .providerControls === ea.value
          ) {
            return ea;
          } else if (!selectedProviders.includes(ea.value)) {
            return ea;
          }
        });
        const providerslenght = index + 1;
        if (
          this.parentForm.get('sotkSettings.sotkDispatchExportApiDetails').value.length >
          providerslenght
        ) {
          this.dispatchExportProviderOptions[index] = data;

          console.log('insi de If', this.dispatchExportProviderOptions.length, index);
          console.log(data);
        } else {
          this.dispatchExportProviderOptions[index] = data;
          console.log(this.dispatchExportProviderOptions.length, index);

          const newProviderlist = e.filter((ea) => {
            if (!selectedProviders.includes(ea.value)) {
              return ea;
            }
          });
          this.dispatchExportProviderOptions.push(newProviderlist);
        }
      }
    });

    console.log('after assigning ->', this.dispatchExportProviderOptions);
  }

  deleteAllSotkDeliveryImportApiDetail() {
    // REMOVE ALL SALES PROVIDER SETTING
    const sotkDeliveryImportApiDetails = this.parentForm.get(
      'sotkSettings.sotkDeliveryImportApiDetails'
    ) as FormArray;
    this.parentForm.get('sotkSettings.sotkDeliveryImportApiDetails').reset();
    console.log(this.parentForm.get('sotkSettings.sotkDeliveryImportApiDetails').value);
    this.parentForm
      .get('sotkSettings.sotkDeliveryImportApiDetails')
      .value.forEach((provider, index) => {
        sotkDeliveryImportApiDetails.removeAt(0);
      });

    this.isToggleDeliveryImport = true;

    this.enableSotkDeliveryImportBILDetails = [false];

    this.sotkDeliveryImportBILDetails = [];
    this.sotkDeliveryImportBILCronJonConfig = {};

    this.deliveryImportProviderOptions = [DeilveryImportProviders];
    this.parentForm.removeControl('deliveryImportBILCronJobConfig');
    this.parentForm.removeControl('deliveryImportBILDetails');

    console.log('sotkDeliveryImportBILCronJonConfig', this.sotkDeliveryImportBILCronJonConfig);
  }

  deleteAllSotkDeliveryExportApiDetail() {
    // REMOVE ALL SALES PROVIDER SETTING
    const sotkDeliveryExportApiDetails = this.parentForm.get(
      'sotkSettings.sotkDeliveryExportApiDetails'
    ) as FormArray;
    this.parentForm.get('sotkSettings.sotkDeliveryExportApiDetails').reset();
    console.log(this.parentForm.get('sotkSettings.sotkDeliveryExportApiDetails').value);
    this.parentForm
      .get('sotkSettings.sotkDeliveryExportApiDetails')
      .value.forEach((provider, index) => {
        sotkDeliveryExportApiDetails.removeAt(0);
      });

    this.isToggleDeliveryExport = true;

    this.enableSotkDeliveryExportBILDetails = [false];

    this.sotkDeliveryExportBILDetails = [];
    this.sotkDeliveryExportBILCronJonConfig = {};

    this.deliveryExportProviderOptions = [DeilveryExportProviders];
    this.parentForm.removeControl('deliveryExportBILCronJobConfig');
    this.parentForm.removeControl('deliveryExportBILDetails');
    this.parentForm.removeControl('');
  }

  deleteAllSotkDispatchExportApiDetail() {
    // REMOVE ALL SALES PROVIDER SETTING
    const sotkDispatchExportApiDetails = this.parentForm.get(
      'sotkSettings.sotkDispatchExportApiDetails'
    ) as FormArray;
    this.parentForm.get('sotkSettings.sotkDispatchExportApiDetails').reset();
    console.log(this.parentForm.get('sotkSettings.sotkDispatchExportApiDetails').value);
    this.parentForm
      .get('sotkSettings.sotkDispatchExportApiDetails')
      .value.forEach((provider, index) => {
        sotkDispatchExportApiDetails.removeAt(0);
      });

    this.isToggleDispatchExport = true;

    this.enableSotkDispatchExportBILDetails = [false];

    this.sotkDispatchExportBILDetails = [];
    this.sotkDispatchExportBILCronJonConfig = {};

    this.dispatchExportProviderOptions = [DispatchExportProviders];
    this.parentForm.removeControl('dispatchExportBILCronJobConfig');
    this.parentForm.removeControl('dispatchExportBILDetails');
  }

  deleteSotkDeliveryImportApiDetailContainer(pindex: number) {
    console.log('index', pindex);
    console.log('1', this.deliveryImportProviderOptions);
    const sotkDeliveryImportApiDetails = this.parentForm.get(
      'sotkSettings.sotkDeliveryImportApiDetails'
    ) as FormArray;
    console.log('check delete func ', sotkDeliveryImportApiDetails.value[pindex].providerControls);
    if (sotkDeliveryImportApiDetails.length > 0) {
      const deletedProvider = sotkDeliveryImportApiDetails.value[pindex].providerControls;
      console.log('deleted provider ->', deletedProvider);
      if (sotkDeliveryImportApiDetails.value[pindex].providerControls === this.providers.BIL) {
        this.parentForm.removeControl('deliveryImportBILDetails');
        this.parentForm.removeControl('deliveryImportBILCronJobConfig');
        this.enableSotkDeliveryImportBILDetails[pindex] = false;
        this.sotkDeliveryImportBILDetails = [];
        this.sotkDeliveryImportBILCronJonConfig = {};
      }
      if (
        sotkDeliveryImportApiDetails.value.length !== this.deliveryImportProviderOptions.length &&
        sotkDeliveryImportApiDetails.value.length > 0
      ) {
        const addProvider = DeilveryImportProviders.filter((p) => p.value === deletedProvider);
        this.deliveryImportProviderOptions.map((e, index) => {
          this.deliveryImportProviderOptions[index].push(addProvider[0]);
          return pindex !== index;
        });
      }

      sotkDeliveryImportApiDetails.removeAt(pindex);

      console.log('this.deliveryImportProviderOptions');
      console.log(this.deliveryImportProviderOptions);

      this.deliveryImportProviderOptions = this.deliveryImportProviderOptions.filter(
        (item, index) => {
          return pindex !== index;
        }
      );
      console.log('this.deliveryImportProviderOptions');
      console.log(this.deliveryImportProviderOptions);
      const ebasketSalesImport = sotkDeliveryImportApiDetails.value;
      console.log('ebasketSalesImport', ebasketSalesImport);
      ebasketSalesImport.map((p, index) => {
        const provider = p.providerControls;
        // tslint:disable-next-line: one-variable-per-declaration
        const i = index;
        if (provider === Providers.BIL) {
          this.sotkDeliveryImportBILDetails[i] = true;
          this.sotkDeliveryImportBILCronJonConfig = {};
          this.sotkDeliveryImportBILDetails = [];
        }
      });
    } else {
      sotkDeliveryImportApiDetails.reset();
    }
  }

  deleteSotkDeliveryExportApiDetailContainer(pindex: number) {
    console.log('index', pindex);
    console.log('1', this.deliveryExportProviderOptions);
    const sotkDeliveryExportApiDetails = this.parentForm.get(
      'sotkSettings.sotkDeliveryExportApiDetails'
    ) as FormArray;
    console.log('check delete func ', sotkDeliveryExportApiDetails.value[pindex].providerControls);
    if (sotkDeliveryExportApiDetails.length > 0) {
      const deletedProvider = sotkDeliveryExportApiDetails.value[pindex].providerControls;
      console.log('deleted provider ->', deletedProvider);
      if (sotkDeliveryExportApiDetails.value[pindex].providerControls === this.providers.BIL) {
        this.parentForm.removeControl('deliveryExportBILDetails');
        this.parentForm.removeControl('deliveryExportBILCronJobConfig');
        this.enableSotkDeliveryExportBILDetails[pindex] = false;
        this.sotkDeliveryExportBILDetails = [];
        this.sotkDeliveryExportBILCronJonConfig = {};
      }
      if (
        sotkDeliveryExportApiDetails.value.length !== this.deliveryExportProviderOptions.length &&
        sotkDeliveryExportApiDetails.value.length > 0
      ) {
        const addProvider = DeilveryExportProviders.filter((p) => p.value === deletedProvider);
        this.deliveryExportProviderOptions.map((e, index) => {
          this.deliveryExportProviderOptions[index].push(addProvider[0]);
          return pindex !== index;
        });
      }

      sotkDeliveryExportApiDetails.removeAt(pindex);

      console.log('this.deliveryExportProviderOptions');
      console.log(this.deliveryExportProviderOptions);

      this.deliveryExportProviderOptions = this.deliveryExportProviderOptions.filter(
        (item, index) => {
          return pindex !== index;
        }
      );
      console.log('this.deliveryExportProviderOptions');
      console.log(this.deliveryExportProviderOptions);
      const ebasketSalesExport = sotkDeliveryExportApiDetails.value;
      console.log('ebasketSalesExport', ebasketSalesExport);
      ebasketSalesExport.map((p, index) => {
        const provider = p.providerControls;
        // tslint:disable-next-line: one-variable-per-declaration
        const i = index;
        if (provider === Providers.BIL) {
          this.sotkDeliveryExportBILDetails[i] = true;
          this.sotkDeliveryExportBILCronJonConfig = {};
          this.sotkDeliveryExportBILDetails = [];
        }
      });
    } else {
      sotkDeliveryExportApiDetails.reset();
    }
  }

  deleteSotkDispatchExportApiDetailContainer(pindex: number) {
    console.log('index', pindex);
    console.log('1', this.dispatchExportProviderOptions);
    const sotkDispatchExportApiDetails = this.parentForm.get(
      'sotkSettings.sotkDispatchExportApiDetails'
    ) as FormArray;
    console.log('check delete func ', sotkDispatchExportApiDetails.value[pindex].providerControls);
    if (sotkDispatchExportApiDetails.length > 0) {
      const deletedProvider = sotkDispatchExportApiDetails.value[pindex].providerControls;
      console.log('deleted provider ->', deletedProvider);
      if (sotkDispatchExportApiDetails.value[pindex].providerControls === this.providers.BIL) {
        this.parentForm.removeControl('dispatchExportBILDetails');
        this.parentForm.removeControl('dispatchExportBILCronJobConfig');
        this.enableSotkDispatchExportBILDetails[pindex] = false;
        this.sotkDispatchExportBILDetails = [];
        this.sotkDispatchExportBILCronJonConfig = {};
      }
      if (
        sotkDispatchExportApiDetails.value.length !== this.dispatchExportProviderOptions.length &&
        sotkDispatchExportApiDetails.value.length > 0
      ) {
        const addProvider = DispatchExportProviders.filter((p) => p.value === deletedProvider);
        this.dispatchExportProviderOptions.map((e, index) => {
          this.dispatchExportProviderOptions[index].push(addProvider[0]);
          return pindex !== index;
        });
      }

      sotkDispatchExportApiDetails.removeAt(pindex);

      console.log('this.dispatchExportProviderOptions');
      console.log(this.dispatchExportProviderOptions);

      this.dispatchExportProviderOptions = this.dispatchExportProviderOptions.filter(
        (item, index) => {
          return pindex !== index;
        }
      );
      console.log('this.dispatchExportProviderOptions');
      console.log(this.dispatchExportProviderOptions);
      const ebasketSalesExport = sotkDispatchExportApiDetails.value;
      console.log('ebasketSalesExport', ebasketSalesExport);
      ebasketSalesExport.map((p, index) => {
        const provider = p.providerControls;
        // tslint:disable-next-line: one-variable-per-declaration
        const i = index;
        if (provider === Providers.BIL) {
          this.sotkDispatchExportBILDetails[i] = true;
          this.sotkDispatchExportBILCronJonConfig = {};
          this.sotkDispatchExportBILDetails = [];
        }
      });
    } else {
      sotkDispatchExportApiDetails.reset();
    }
  }

  expectedQuantityChecked() {}

  updateExceptionView() {
    const reportButton = this.parentForm.controls.sotkSettings['controls']
      .reportButton as FormArray;
    this.exceptionDataSource.next(reportButton.controls);
  }

  addReportBtnChecked(values: any): void {
    const control = this.parentForm.controls.sotkSettings['controls'].reportButton as FormArray;
    if (control && control.controls && control.controls.length) {
      control.controls.forEach((val, index) => {
        control.removeAt(index);
        this.deletedRow.emit(index);
      });
    }
    this.updateExceptionView();

    if (values.checked) {
      this.isAddBtnEnabled = true;
      this.addButtonRow();
    } else {
      this.isAddBtnEnabled = false;
      this.parentForm.controls.sotkSettings['controls'].reportButton.reset();
    }
  }

  addButtonRow() {
    const row = this.formBuilder.group({
      buttonName: ['', [Validators.required, Validators.maxLength(30)]],
      savedSearchName: ['', Validators.required]
    });
    console.log('row : ', row);
    console.log(
      'this.parentForm.controls : ',
      this.parentForm.controls.sotkSettings['controls'].reportButton
    );
    const control = this.parentForm.controls.sotkSettings['controls'].reportButton as FormArray;
    console.log('control: ', control);
    control.push(row);
    this.updateExceptionView();
  }

  deleteBtnRow(index: number) {
    const control = this.parentForm.controls.sotkSettings['controls'].reportButton as FormArray;
    control.removeAt(index);
    this.updateExceptionView();
    // delete row from formClone as well
    this.deletedRow.emit(index);
    console.log('163 : ', control.controls.length);
    if (control && control.controls && control.controls.length === 0) {
      const row = this.formBuilder.group({
        buttonName: ['', Validators.required, Validators.maxLength(30)],
        savedSearchName: ['', Validators.required]
      });
      const ctrl = this.parentForm.controls.sotkSettings['controls'].reportButton as FormArray;
      console.log('control: ', ctrl);
      ctrl.push(row);
    }
  }

  isRequired(control: string) {
    let isRequired = false;
    const formControlObject = this.parentForm.get('sotkSettings').get(control);
    if (formControlObject !== null) {
      if (formControlObject.validator !== null) {
        if (formControlObject.validator({} as AbstractControl) !== null) {
          isRequired = formControlObject.validator({} as AbstractControl).required ? true : false;
        }
      }
    }
    return isRequired ? true : false;
  }
  checkBtnLength(control: string) {
    const formControlObject = this.parentForm.get('sotkSettings').get(control);
    let isValid = true;
    if (formControlObject !== null) {
      if (formControlObject.validator !== null) {
        if (formControlObject.validator({} as AbstractControl) !== null) {
          isValid = formControlObject.validator({} as AbstractControl).maxLength ? true : false;
        }
      }
    }
    return isValid ? true : false;
  }

  getIsAddBtnEnabledValue() {
    return this.parentForm.get('sotkSettings').get('addReportBtn').value;
  }
}
