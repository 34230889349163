import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CommonConfigFacade } from '@shared/common-config/state/common-config.facade';
import { ITimezone } from '@shared/interfaces';
import { Subscription } from 'rxjs';
import { DefaultTimeZone } from 'src/app/feature/organization/shared/configuration-settings/configuration-settings-default-data';

@Component({
  selector: 'app-timezone-dropdown',
  templateUrl: './timezone-dropdown.component.html'
})
export class TimezoneDropdownComponent implements OnInit, OnDestroy {
  constructor(public commonFacade: CommonConfigFacade) {}
  timezoneList: ITimezone[];
  subscriptions: Subscription[] = [];

  @Input() ctrl: FormControl;
  @Input() placeholder: string;
  @Input() isOverriden: false;
  @Input() isDefaultTimeZone;

  ngOnInit() {
    this.subscriptions.push(
      this.commonFacade.timezones$.subscribe((data) => {
        this.timezoneList = data;
        if (this.isDefaultTimeZone) {
          this.ctrl.setValue(
            this.timezoneList.find(
              (id) => id.id === DefaultTimeZone.id && id.name === DefaultTimeZone.name
            )
          );
        }
      })
    );
  }

  compareTimezones(o1: ITimezone, o2: ITimezone): boolean {
    return o1.id === o2.id;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }
}
