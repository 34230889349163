import { Component, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { IUser } from '@shared/interfaces';
import { Subscription } from 'rxjs';
import { AuthFacade } from '../feature/auth/state/auth.facade';
import { ProfileService } from './profile.service';
import { USER_DASHBOARD_BASE_PATH } from './routes/profile.routes.names';

@Component({
  selector: 'app-profile-container',
  templateUrl: './profile.container.html'
})
export class ProfileContainer implements OnInit, OnDestroy {
  @Output() response: IUser;
  subscriptions: Subscription[] = [];
  constructor(
    private readonly profileService: ProfileService,
    private readonly authFacade: AuthFacade,
    private router: Router
  ) {}

  ngOnInit() {
    let userId = '';
    this.subscriptions.push(
      this.authFacade.user$.subscribe((response: IUser) => {
        userId = response ? response.id : '';
      })
    );
    this.profileService.get(userId).subscribe((res) => {
      this.response = res.result;
    });
  }

  async updateUser(updatedUser: IUser) {
    try {
      await this.profileService.updateUser(updatedUser, updatedUser.id);
      this.router.navigate([`/${USER_DASHBOARD_BASE_PATH}`]);
    } catch (error) {
      console.log(error);
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }
}
