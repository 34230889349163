import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, ThemePalette } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-select-api-popup',
  templateUrl: './select-api-popup.component.html',
  styleUrls: ['./select-api-popup.component.scss']
})
export class SelectApiPopupComponent implements OnInit {
  form: FormGroup;
  selectApiOptions: [];

  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<SelectApiPopupComponent>,
    private translate: TranslateService
  ) {
    this.selectApiOptions = data;
  }

  ngOnInit() {
    console.log('this.selectApiOptions', this.selectApiOptions);
    this.initializeForm();
  }

  private initializeForm() {
    this.form = this.formBuilder.group({
      selectApi: ['', [Validators.required]]
    });
  }

  save() {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.dialogRef.close(this.form.get('selectApi').value);
    }
  }
}
