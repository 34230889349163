import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material';
import {
  EbasketApiMethodTypes,
  EbasketInventorySelectApi,
  EbasketSlectQueue,
  Providers
} from '@shared/interfaces';
import { SelectQueuePopupComponent } from '@shared/select-queue-popup/select-queue-popup.component';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'crestEngagement-provider-api-details-settings',
  templateUrl: './crestEngagement-provider-details-settings.component.html',
  styleUrls: ['./crestEngagement-provider-details-settings.component.scss']
})
export class CrestEngagementApiDetailsSettingsComponent implements OnInit {
  @Input() action: string;
  @Input() parentForm: FormGroup;
  @Input() ebasketCrestEngagementApiDetails: any;
  @Input() authControlKey: string;
  @Input() provider: string;
  // @Output() updateApiData: EventEmitter<any> = new EventEmitter();
  apiDetails: FormGroup;
  isEBasketInventoryAddDisabled = false;
  showQueueDetails = false;
  // isEBasketSalesExportAddQueuDetailsDisabled = true;
  ebasket_Inventory_select_api = [
    {
      id: 1,
      name: EbasketSlectQueue.SalesExportQueue,
      value: EbasketSlectQueue.SalesExportQueue,
      isDisabled: false
    }
  ];

  method_types = [
    { id: 1, name: EbasketApiMethodTypes.Get, value: EbasketApiMethodTypes.Get },
    { id: 2, name: EbasketApiMethodTypes.Post, value: EbasketApiMethodTypes.Post },
    { id: 3, name: EbasketApiMethodTypes.Put, value: EbasketApiMethodTypes.Put },
    { id: 4, name: EbasketApiMethodTypes.Patch, value: EbasketApiMethodTypes.Patch },
    { id: 5, name: EbasketApiMethodTypes.Delete, value: EbasketApiMethodTypes.Delete }
  ];
  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.initForm();
    // this.bindEbasketInventoryApiDetailsFormGroup();
  }
  initForm() {
    console.log('ebasketInventoryaptosApiDetails');
    console.log(this.ebasketCrestEngagementApiDetails.queueDetails);
    console.log(this.authControlKey);
    this.isEBasketInventoryAddDisabled =
      this.ebasketCrestEngagementApiDetails && this.ebasketCrestEngagementApiDetails.queueDetails
        ? true
        : false;
    this.showQueueDetails = this.ebasketCrestEngagementApiDetails.queueDetails ? true : false;
    this.parentForm.addControl(
      this.authControlKey,
      this.formBuilder.group({
        ebasketInventoryAptosAuthorisation: [
          {
            value:
              this.ebasketCrestEngagementApiDetails &&
              this.ebasketCrestEngagementApiDetails.queueDetails
                ? Providers.SECRET
                : '',
            disabled: this.action === 'view' ? true : false
          },
          [Validators.required]
        ],
        ebasketSalesExportRoleName: [
          {
            value:
              this.ebasketCrestEngagementApiDetails &&
              this.ebasketCrestEngagementApiDetails.queueDetails
                ? Providers.SECRET
                : '',
            disabled: this.action === 'view' ? true : false
          }
        ],
        ebasketSalesExportQueueName: [
          {
            value:
              this.ebasketCrestEngagementApiDetails &&
              this.ebasketCrestEngagementApiDetails.queueDetails
                ? Providers.SECRET
                : '',
            disabled: this.action === 'view' ? true : false
          }
        ],
        ebasketSalesExportExternalId: [
          {
            value:
              this.ebasketCrestEngagementApiDetails &&
              this.ebasketCrestEngagementApiDetails.queueDetails
                ? Providers.SECRET
                : '',
            disabled: this.action === 'view' ? true : false
          }
        ]
      })
    );
    console.log('ebasketWebOrderaptosVersion');
    // console.log(this.parentForm.get('apiDetails').get('ebasketInventoryApiDetails').value.value);
  }

  openEbasketInventorySelectApiPopup() {
    // this.isEBasketSalesExportAddQueuDetailsDisabled = true;

    const dialogRef = this.dialog.open(SelectQueuePopupComponent);

    dialogRef.afterClosed().subscribe((result: any) => {
      console.log('result : ', result);

      if (result !== '') {
        this.showQueueDetails = true;
        this.isEBasketInventoryAddDisabled = true;
        this.parentForm
          .get(this.authControlKey)
          .get('ebasketSalesExportRoleName')
          .setValidators(Validators.required);
        this.parentForm
          .get(this.authControlKey)
          .get('ebasketSalesExportQueueName')
          .setValidators(Validators.required);
        this.parentForm
          .get(this.authControlKey)
          .get('ebasketSalesExportExternalId')
          .setValidators(Validators.required);
        this.parentForm
          .get(this.authControlKey)
          .get('ebasketSalesExportQueueName')
          .updateValueAndValidity();
        this.parentForm
          .get(this.authControlKey)
          .get('ebasketSalesExportRoleName')
          .updateValueAndValidity();
        this.parentForm
          .get(this.authControlKey)
          .get('ebasketSalesExportExternalId')
          .updateValueAndValidity();
      }
    });
  }
  isRequired(control: string) {
    let isRequired = false;
    const formControlObject = this.parentForm.get(this.authControlKey).get(control);
    if (formControlObject !== null) {
      if (formControlObject.validator !== null) {
        if (formControlObject.validator({} as AbstractControl) !== null) {
          isRequired = formControlObject.validator({} as AbstractControl).required ? true : false;
        }
      }
    }
    return isRequired ? true : false;
  }
  deleteeBasketSalesExportQueueDetails() {
    this.parentForm
      .get(this.authControlKey)
      .get('ebasketSalesExportQueueName')
      .clearValidators();
    this.parentForm
      .get(this.authControlKey)
      .get('ebasketSalesExportRoleName')
      .clearValidators();
    this.parentForm
      .get(this.authControlKey)
      .get('ebasketSalesExportExternalId')
      .clearValidators();

    this.parentForm
      .get(this.authControlKey)
      .get('ebasketSalesExportQueueName')
      .reset();
    this.parentForm
      .get(this.authControlKey)
      .get('ebasketSalesExportRoleName')
      .reset();
    this.parentForm
      .get(this.authControlKey)
      .get('ebasketSalesExportExternalId')
      .reset();
    // this.isEBasketSalesExportAddQueuDetailsDisabled = false;
    this.showQueueDetails = false;
    this.isEBasketInventoryAddDisabled = false;
  }
}
